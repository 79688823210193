import TokenService from "../services/tokenService";
import axios from "axios";
import _get from "lodash/get";
import _isNumber from 'lodash/isNumber'

const Client = {
    axiosClient: axios.create(),

    // notifications all
    updateUserPreference(email, preference) {
        let path = `${_get(window, 'CONFIG.notifications.api.path')}/users/${email}/preferences`;
        return this.post(path, preference);
    },
    getUser(email) {
        let path = `${_get(window, 'CONFIG.notifications.api.path')}/users/${email}`;
        return this.get(path);
    },
    getAllNotifications(page, size, types, seen) {
        let path = `${_get(window, 'CONFIG.notifications.api.path')}/notifications?page=${page}&size=${size}&sort=dt,desc`;

        if (types && types.length > 0) {
            types.forEach(type => path += "&types=" + type)
        }

        if (seen !== undefined) {
            path += "&seen=" + seen;
        }

        return this.get(path);
    },
    getNotificationPreferenceSettings(sort) {
        let path = `${_get(window, 'CONFIG.notifications.api.path')}/users/notification-preference-settings`;
        if(sort) path += `?sort=${sort.join(',')}`;

        // TODO: if the UI supports another way to switch languages then use that instead of navigator.language
        return this.get(path, {
            'Accept-Language': navigator.language || navigator.userLanguage
        });
    },
    getNotificationTemplates(page = 0, size = 1000) {
        let path = `${_get(window, 'CONFIG.notifications.api.path')}/notification-templates?page=${page}&size=${size}`;

        return this.get(path);
    },
    getNotificationTemplate(id) {
        let path = `${_get(window, 'CONFIG.notifications.api.path')}/notification-templates/${id}`;

        return this.get(path);
    },
    patchNotificationTemplate(id, data) {
        let path = `${_get(window, 'CONFIG.notifications.api.path')}/notification-templates/${id}`;

        return this.patch(path, data);
    },
    createNotificationTemplate(data) {
        let path = `${_get(window, 'CONFIG.notifications.api.path')}/notification-templates`;

        return this.post(path, data);
    },
    deleteNotificationTemplate(id) {
        let path = `${_get(window, 'CONFIG.notifications.api.path')}/notification-templates/${id}`;

        return this.delete(path);
    },
    getNotificationTemplateDefinitions(page = 0, size = 1000) {
        let path = `${_get(window, 'CONFIG.notifications.api.path')}/notification-template-definitions?page=${page}&size=${size}`;

        return this.get(path);
    },
    getNotificationTemplateDefinition(id) {
        let path = `${_get(window, 'CONFIG.notifications.api.path')}/notification-template-definitions/${id}`;

        return this.get(path);
    },
    patchNotificationTemplateDefinition(id, data) {
        let path = `${_get(window, 'CONFIG.notifications.api.path')}/notification-template-definitions/${id}`;

        return this.patch(path, data);
    },
    createNotificationTemplateDefinition(data) {
        let path = `${_get(window, 'CONFIG.notifications.api.path')}/notification-template-definitions`;

        return this.post(path, data);
    },
    deleteNotificationTemplateDefinition(id) {
        let path = `${_get(window, 'CONFIG.notifications.api.path')}/notification-template-definitions/${id}`;

        return this.delete(path);
    },
    markAllSeen(types) {
        let path = `${_get(window, "CONFIG.notifications.api.path")}/notifications/seen`;
        if (types && types.length > 0) {
            path += "?";
            types.forEach(type => path += "&types=" + type)
        }

        return this.post(path);
    },
    markNotificationSeen(id) {
        let path = `${_get(window, "CONFIG.notifications.api.path")}/notifications/${id}/seen`;
        return this.post(path);
    },

    getTransportationOrderNotifications(transportationOrderId, page, size) {
        let path = `${_get(window, 'CONFIG.notifications.api.path')}/notification-logs?transportationOrderId=${transportationOrderId}`;
        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`)
        }

        if (size) {
            query.push(`size=${size}`)
        }

        if (query.length > 0) {
            path += `&${query.join('&')}`;
        }
        return this.get(path);
    },

    // common calls with auth token
    get(path, headers) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        return Client.axiosClient.get(path, {
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            },
            params
        });
    },
    post(path, data) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        return Client.axiosClient.post(path, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params
        });
    },
    patch(path, data) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        return Client.axiosClient.patch(path, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params
        });
    },
    delete(path) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        return Client.axiosClient.delete(path, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params
        });
    }
};

export default Client;
