<template>
  <div class="component">
    <v-tab-item class="tab-item">
      <v-list
        two-line
        nav
        class="notification-list"
        v-if="this.notifications.length > 0"
      >
        <template v-for="(item, index) in notifications" :value="item.userSeen">
          <v-list-item :key="item.id" @click="onNotificationClicked(item)">
            <v-list-item-icon>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content
              class="mr-2"
              v-bind:class="{ 'font-weight-bold': !item.userSeen }"
            >
              <v-list-item-title class="font-14-700">
                {{ item.title }}
              </v-list-item-title>
              <v-list-item-subtitle class="text--primary font-13-500 mt-2">
                {{ item.text }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text>
                {{ item.dt }}
              </v-list-item-action-text>
              <v-row class="pt-2">
                <v-btn
                  v-if="showViewItem(item)"
                  color="primary"
                  small
                  @click="onViewItemClicked(item)"
                  outlined
                  class="font-weight-bold"
                >
                  {{ $i18n("common.label.view", "View") }}
                  <v-icon dark small class="ml-2">
                    fas fa-arrow-alt-circle-right
                  </v-icon>
                </v-btn>
              </v-row>
            </v-list-item-action>
          </v-list-item>
          <v-divider
            v-if="index + 1 < notifications.length"
            :key="index"
          ></v-divider>
        </template>
      </v-list>
      <v-row class="empty-list" v-else>
        <v-col class="pa-8">
          {{ $i18n("common.label.noNew", "No new") }}
          {{ title.toLowerCase() }}.
        </v-col>
      </v-row>
    </v-tab-item>
  </div>
</template>

<script type="text/babel">
import ROLES from "SC/constants/roles";
import RunBuggyOrdersApi from "SC/services/runBuggyOrdersApi";
import _find from "lodash/find";
import _get from "lodash/get";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "components-notifications-display-tab-item-index",
  watch: {},
  props: {
    notifications: {
      required: true,
      type: Array,
      default: undefined,
    },
    title: {
      required: true,
      type: String,
      default: undefined,
    },
    removeSeen: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    markAsSeen(notification) {
      this.$emit("notification-seen", notification);
    },
    onNotificationClicked(notification) {
      this.markAsSeen(notification);
    },
    showViewItem(notification) {
      if (notification.type === "TransportationOrder" && notification.event === "RECOMMENDED_ORDERS") {
        return true;
      }
      return !!notification.sourceId;
    },
    onViewItemClicked(notification) {
      this.$emit("view-item-clicked");

      this.markAsSeen(notification);
      if (notification.type === "TransportationOrder" && notification.event === "RECOMMENDED_ORDERS" ) {
        return this.$router.push({
          name: "orders-list-transporter",
          query: {
            orderBy: "featured",
            selectedCategory: "t-available",
          },
        });
      }

      if (
        notification.type === "TransportationOrder" ||
        notification.type === "Order" ||
        notification.type === "OrderTransportationEvent"
      ) {
        return this.viewOrder(notification);
      } else if (notification.type === "Message") {
        return this.viewMessageOrder(notification.sourceId);
      }
    },
    viewMessageOrder(messageId) {
      let perspective = this.getPerspective();
      RunBuggyOrdersApi.getMessage(messageId).then((res) => {
        let orderId =
          perspective === "shipper"
            ? _get(res, "data.orderId")
            : _get(res, "data.transportationOrderId");
        if (orderId) {
          return this.$router.push({
            name: "orders-view",
            params: {
              perspective: perspective,
              orderId: orderId,
            },
            query: {
              referrer: this.navigationReferrer,
            },
          });
        } else {
          //TODO: No order found for this message
        }
      });
    },
    viewOrder(notification) {
      let orderId = notification.sourceId;
      let perspective = this.getPerspective();
      if (
        this.userRoles.indexOf(ROLES.TMS_ADMIN) > -1 ||
        this.userRoles.indexOf(ROLES.TMS_USER) > -1
      ) {
        perspective =
          notification.type === "Order" ||
          notification.type === "OrderTransportationEvent"
            ? "shipper"
            : "transporter";
      }
      return this.$router.push({
        name: "orders-view",
        params: {
          perspective: perspective,
          orderId: orderId,
        },
        query: {
          referrer: this.navigationReferrer,
        },
      });
    },
    getPerspective() {
      let role;

      if (
        this.userRoles.indexOf(ROLES.DEALER) > -1 ||
        this.userRoles.indexOf(ROLES.SHIPPER_ADMIN) > -1 ||
        this.userRoles.indexOf(ROLES.SHIPPER_USER) > -1 ||
        this.userRoles.indexOf(ROLES.SHIPPER_MANAGER) > -1 ||
        this.userRoles.indexOf(ROLES.SHIPPER_BUSINESS_UNIT_MANAGER) > -1 ||
        this.userRoles.indexOf(ROLES.PRIVATE_SHIPPER_ADMIN) > -1 ||
        this.userRoles.indexOf(ROLES.PRIVATE_SHIPPER_USER) > -1 ||
        this.userRoles.indexOf(ROLES.AUCTION_HOUSE) > -1 ||
        this.userRoles.indexOf(ROLES.AUCTION_ADMIN) > -1 ||
        this.userRoles.indexOf(ROLES.AUCTION_USER) > -1 ||
        this.userRoles.indexOf(ROLES.TMS_ADMIN) > -1 ||
        this.userRoles.indexOf(ROLES.TMS_USER) > -1
      ) {
        role = "shipper";
      } else if (
        this.userRoles.indexOf(ROLES.TRANSPORTER) > -1 ||
        this.userRoles.indexOf(ROLES.TRANSPORTER_ADMIN) > -1 ||
        this.userRoles.indexOf(ROLES.TRANSPORTER_USER) > -1 ||
        this.userRoles.indexOf(ROLES.DRIVER) > -1 ||
        this.userRoles.indexOf(ROLES.TRANSPORTER_DRIVER) > -1
      ) {
        role = "transporter";
      } else {
        return null;
      }

      return role;
    },
  },
  computed: {
    ...mapGetters("user", {
      userRoles: "userRoles",
      userName: "userName",
    }),
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tab-item {
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  .v-list-item__icon:first-child {
    margin-right: 20px;
  }

  .v-list-item__title {
    white-space: normal !important;
  }

  .v-list-item__subtitle {
    white-space: normal !important;
  }

  .empty-list {
    min-width: 550px;
  }
}
</style>
