<template>
  <v-dialog 
    class="component" 
    content-class="generic-dialog"
    v-model="isDialogOpenState" 
    persistent 
    scrollable 
    :width="dialogWidth" 
    :max-width="maxWidth"
    :transition="transition">
    <template v-if="isHitchPaymentFeatureEnabled" v-slot:activator="{ on, attrs }">
      <slot 
        name="activator"
        v-bind="{ on, attrs }"
      ></slot>
    </template>
    <template v-if="isHitchPaymentFeatureEnabled">
      <v-card :loading="true">
        <v-toolbar
          dark
          color="primary"
          flat
          >
          
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text icon @click="resizeDialog()">
                  <v-icon>{{ expanded ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}</v-icon>
                </v-btn>
              </template>
              <span> {{ expanded ? $i18n("common.label.collapse", "Collapse") :  $i18n("common.label.expand", "Expand") }} </span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  dark
                  @click="onClose"
                  v-on="on"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span> {{ $i18n("common.label.close", "Close") }} </span>
            </v-tooltip>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text data-name="generic-dialog-content" class="pa-0" :class="sizeClass">
          <slot name="dialog-content"></slot>
        </v-card-text>
      </v-card>
    </template>
    <template v-else>
      <v-card>
        <v-toolbar
          dark
          color="primary"
          flat
          >
          
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text icon @click="resizeDialog()">
                  <v-icon>{{ expanded ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}</v-icon>
                </v-btn>
              </template>
              <span> {{ expanded ? $i18n("common.label.collapse", "Collapse") :  $i18n("common.label.expand", "Expand") }} </span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  dark
                  @click="onClose"
                  v-on="on"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span> {{ $i18n("common.label.close", "Close") }} </span>
            </v-tooltip>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text data-name="generic-dialog-content" class="pa-0" :class="sizeClass">
          <slot name="dialog-content"></slot>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script type="text/babel">

export default {
  name: "components-gneric_dialog-index",
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.isDialogOpenState = newValue;
      }
    },
    width: {
      immediate: true,
      handler(newValue) {
        this.dialogWidth = newValue;
      }
    },
    expand: {
      immediate: true,
      handler(newValue) {
        if(!!newValue)
          this.expandDialog();
      }
    },
    expanded: {
      immediate: true,
      handler(newValue) {
        this.$emit("resized", newValue);
      }
    },
    isDialogOpenState: {
      immediate: true,
      handler(newValue) {
        this.$emit("input", newValue);
      }
    }
  },
  props: {
    value: {
      default() {
        return false;
      }
    },
    title: {
      type: String,
    },
    persistent: {
      default() {
        return true;
      }
    },
    scrollable: {
      default() {
        return true;
      }
    },
    width: {
      default() {
        return "650";
      }
    },
    maxWidth: {
      default() {
        return "100%";
      }
    },
    transition: {
      default() {
        return "dialog-bottom-transition";
      }
    },
    expand: {
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      isDialogOpenState: undefined,
      dialogWidth: undefined,
      expanded: false,
    };
  },
  methods: {
    onClose() {
      this.isDialogOpenState = false;
      this.$emit("input", this.isDialogOpenState);
    },
    resizeDialog() {
      this.dialogWidth = this.expanded ? this.width : '90%';
      this.expanded = !this.expanded;
    },
    expandDialog() {
      this.dialogWidth = '90%';
      this.expanded = true;
    }
    
  },
  computed: {
    sizeClass() {
      return this.expanded ? "dialog-content-max" : "dialog-content";
    }
  },
  mixins: [],
  components: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.generic-dialog {

  .dialog-content{
    min-height: 30vh;
    max-height:85vh
  }
  
  .dialog-content-max {
    height: 100vh;
    max-height: 100vh;
  }
}
</style>
