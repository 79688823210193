<template>
  <v-list-item
    class="position-relative"
    :style="{ 'margin-left': `${8 * depth}px` }"
  >
    <v-tooltip
      :fixed="false"
      right
      v-bind:disabled="!mini && item.label && item.label.length < 15"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on:click="onClick"
          v-on="on"
          v-bind:color="buttonColor"
          v-bind:input-value="isActive"
          active-class="text-white"
          class="button d-flex item-button"
          v-bind:class="{ expanded: !mini, 'group-expanded': groupExpanded }"
          block
          depressed
        >
          <div class="color-box" v-bind:style="colorBox"></div>

          <template v-if="!mini">
            <span
              class="ml-2 navigation-text-overflow"
              :class="{ 'w-100-i': !count || count == 0 }"
              >{{ item.label }}</span
            >
          </template>
          <div class="custom-badge ml-auto" v-if="count > 0 && !mini">
            {{ count }}
          </div>
          <div class="item-actions d-flex align-center">
            <v-menu
              top
              :offset-x="true"
              ref="menu"
              v-if="!mini && (item.canDelete || item.canReset)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon dark v-bind="attrs" v-on="on" color="navigation_text"
                  >mdi-dots-horizontal</v-icon
                >
              </template>

              <v-card class="mx-auto" max-width="300" tile>
                <v-list dense>
                  <v-list-item-group color="primary">
                    <v-dialog
                      v-model="deleteViewDialog"
                      max-width="500"
                      v-if="item.canDelete"
                    >
                      <template #activator="{ on: dialog }">
                        <v-list-item v-on="{ ...dialog }">
                          <v-list-item-icon>
                            <v-icon v-text="'mdi-delete'"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="'Delete'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <delete-view-dialog
                        :view="item"
                        :operation="'delete'"
                        v-on:close-delete-view-dialog="closeDeleteViewDialog"
                      ></delete-view-dialog>
                    </v-dialog>
                    <v-dialog
                      v-model="resetViewDialog"
                      max-width="500"
                      v-if="item.canReset"
                    >
                      <template #activator="{ on: dialog }">
                        <v-list-item v-on="{ ...dialog }">
                          <v-list-item-icon>
                            <v-icon v-text="'mdi-restart'"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="'Reset'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <delete-view-dialog
                        :view="item"
                        :operation="'reset'"
                        v-on:close-delete-view-dialog="closeResetViewDialog"
                      ></delete-view-dialog>
                    </v-dialog>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-menu>
          </div>

          <template v-if="hasChildren">
            <div class="expand-icon">
              <template v-if="groupExpanded">
                <v-icon>mdi-chevron-up</v-icon>
              </template>
              <template v-else>
                <v-icon>mdi-chevron-down</v-icon>
              </template>
            </div>
          </template>
        </v-btn>
      </template>
      <span>{{ item.label }}</span>
    </v-tooltip>
  </v-list-item>
</template>

<script type="text/babel">
import _get from "lodash/get";
import _has from "lodash/has";
import * as designTokens from "DT";
import { mapGetters } from "vuex";

import DeleteViewDialog from "@/components/task-management/components/delete-view-dialog";

export default {
  name: "components-navigation-button",
  watch: {},
  props: {
    hasChildren: undefined,
    mini: {
      default() {
        return false;
      }
    },
    depth: {
      default() {
        return 0;
      }
    },
    groupExpanded: undefined,
    item: {
      required: true
    }
  },
  data() {
    return {
      designTokens,
      deleteViewDialog: false,
      resetViewDialog: false
    };
  },
  methods: {
    emitNavigated() {
      this.$emit("navigated", this.route);
    },
    onClick() {
      if (this.hasRoute) {
        this.$router.push(this.route);
        this.emitNavigated();
      }

      this.$emit("click");
    },
    closeDeleteViewDialog(refresh = false) {
      this.deleteViewDialog = false;
      this.$refs.menu.save();
      if (refresh) {
        this.$emit("refresh-menu", true);
      }
    },
    closeResetViewDialog(refresh = false) {
      this.resetViewDialog = false;
      this.$refs.menu.save();
      if (refresh) {
        this.$emit("refresh-menu", true);
      }
    }
  },
  computed: {
    ...mapGetters("user", {
      userData: "userData"
    }),
    colorBox() {
      return {
        backgroundColor: this.color ? this.color : "white"
      };
    },
    color() {
      return _get(this.item, "color");
    },
    count() {
      let count = _get(this.item, "count");
      return count ? count : 0;
    },
    filter() {
      return this.item.filter;
    },
    buttonColor() {
      if (this.isActive) {
        return this.$vuetify.theme.themes.light.navigation_bg;
      }

      return "transparent";
    },
    isActive() {
      if (this.item.isActive) {
        return this.item.isActive();
      }

      if (!this.hasRoute) {
        return false;
      }

      return this.routeParsed.route.fullPath === this.$route.fullPath;
    },
    routeParsed() {
      return this.$router.resolve(this.route);
    },
    route() {
      return _get(this.item, "route");
    },
    hasRoute() {
      return _has(this.item, "route");
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {
    DeleteViewDialog
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../../scss/variables";

.group-children {
  .button .count-badge {
    right: 1rem;
  }
}

.button {
  .color-box {
    display: inline-block;
    width: 10px;
    height: 10px;
  }

  &.expanded {
    justify-content: left;

    .expanded-icon {
      right: 5px;
    }
  }

  .count-badge {
    position: absolute;
    top: 0.875rem;
    right: 1rem;
  }

  .expand-icon {
    font-size: 0.8em;
    position: absolute;
    right: -5px;
    opacity: 0.5;
  }
}

.navigation-text-overflow {
  color: var(--v-navigation_text-base);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 162px;
}

.w-100-i {
  width: 100% !important;
}

.custom-badge {
  background: #f24906 !important;
  border-radius: 10px;
  color: #ffffff;
  font-size: 12px;
  height: 20px;
  letter-spacing: 0;
  line-height: 1;
  min-width: 20px;
  padding: 4px 6px;
}

.item-button {
  padding: 0px 10px !important;
  .item-actions {
    display: none !important;
  }

  &:hover {
    .item-actions {
      display: flex !important;
      position: absolute;
      top: -0.25rem;
      right: 0;
    }

    .custom-badge {
      display: none;
    }
  }
}

.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 16px !important;
}

.mx-auto {
  .v-list {
    width: 250px;
  }
}
</style>
