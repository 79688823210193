<template>
  <div>
    <v-app-bar app color="white" dense>
      <v-btn icon depressed v-on:click="menuToggle" class="d-lg-none d-xl-none">
        <v-icon>{{ isNavigationExpanded ? "mdi-menu-open" : "mdi-menu" }}</v-icon>
      </v-btn>
      <v-spacer></v-spacer>

      <tms-accounts-switcher class="mr-5"></tms-accounts-switcher>

      <div v-if="showCreateOrder" class="mr-3">
        <v-btn
          depressed
          color="primary"
          class="mr-2 d-none d-sm-inline-flex new-order-btn"
          v-bind:to="{ name: 'orders-create' }"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          depressed
          color="primary"
          class="mr-2 d-none d-sm-inline-flex new-order-btn"
          v-bind:to="{ name: 'orders-create-bulk' }"
        >
          <v-icon>mdi-table-plus</v-icon>
        </v-btn>
      </div>
      <modal-user-policy-conformant
        v-if="showPolicyConformance && !isTmsUser"
        v-model="userConformantChanged"
        :account-id="conformantAccountId"
        :account-policies="conformantPolicies"
      >
      </modal-user-policy-conformant>
      <notification-display
        v-if="showActions"
        id="topBarMessagesBtn"
        title="Messages"
        :types-filter="messageNotificationTypes"
      >
        <v-icon size="24">mdi-message</v-icon>
      </notification-display>
      <notification-display
        v-if="showActions"
        id="topBarNotificationsBtn"
        title="Notifications"
        :types-filter="generalNotificationTypes"
      >
        <v-icon size="24">mdi-bell</v-icon>
      </notification-display>
      <v-btn
          class="mr-2"
          min-width="0"
          text
          @click="onMessagesButtonClicked"
          v-if="isMessagingSpaV3FeatureEnabled"
      >
        <v-icon size="24">mdi-chat</v-icon>
      </v-btn>
      <export-status v-if="showActions"></export-status>
      <batch-operation-status v-if="showActions && showBatchOperationStatus"></batch-operation-status>
      <pod-filtering
        class="mr-5 ml-10"
        @pods-updated="onPodsUpdated"
        :selected="selectedPods"
        :pods="podList"
        ref="podFiltering"
        v-if="showPods && hasAdminRole && !isTmsUser"
      >
      </pod-filtering>

      <intercompany-switcher
        class="d-inline mr-2"
        v-if="['xs', 'sm'].indexOf($mq) === -1 && !isTmsUser"
      ></intercompany-switcher>

      <tms-company-switcher
        style="max-width: 300px"
        class="d-inline my-2"
        v-if="['xs', 'sm'].indexOf($mq) === -1 && companyEnableCompanyFilter"
      ></tms-company-switcher>

      <v-dialog v-model="showSupportDialog" max-width="500">
        <enable-support-dialog
          @close="showSupportDialog = false"
          :account-id="defaultAccountId"
        >
        </enable-support-dialog>
      </v-dialog>

      <div class="d-flex align-center">
        <v-menu
          offset-y
          class="mr-2"
          v-model="showUserMenu"
          v-bind:close-on-content-click="false"
          z-index="1001"
        >
          <template v-slot:activator="{ on, value }">
            <v-btn depressed color="white" v-on="on" class="user-menu-btn">
              <div class="d-none d-sm-block">
                <span class="impersonation-label" v-if="userIsImpersonating">
                  {{ $i18n("common.label.impersonating", "Impersonating") }}
                </span>
                <span class="user-menu-button d-flex align-center">
                  <name-avatar :name="name" class="mr-3"></name-avatar>
                  {{ name }}
                  <v-icon v-bind:class="{ 'mdi-rotate-180': value }"
                    >mdi-menu-down</v-icon
                  >
                </span>
              </div>
              <div class="d-block d-sm-none">
                <v-icon size="24">mdi-account</v-icon>
              </div>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="shouldShowImpersonation">
              <impersonator></impersonator>
            </v-list-item>

            <intercompany-switcher
              v-if="['xs', 'sm'].indexOf($mq) > -1 && !isTmsUser"
              v-bind:as-list-item="true"
            ></intercompany-switcher>

            <tms-company-switcher
              class="d-inline my-2"
              v-if="['xs', 'sm'].indexOf($mq) > -1 && companyEnableCompanyFilter"
            ></tms-company-switcher>

            <v-list-item
              v-bind:to="{ name: 'user-account-management-profile' }"
              v-on:click="hideUserMenu"
            >
              <v-list-item-title>
                <span class="list-item">
                  {{
                    $i18n(
                      "accountManagement.section.header.menu",
                      "Account Management"
                    )
                  }}
                </span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-bind:to="{ name: 'user-account-management-company' }"
              v-on:click="hideUserMenu"
              v-if="hasAdminRole || hasTMSAdminRole"
            >
              <v-list-item-title>
                <span class="list-item">
                  {{
                    $i18n("accountManagement.section.company", "Company")
                  }}</span
                >
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-bind:to="{ name: 'user-account-management-companies' }"
              v-on:click="hideUserMenu"
              v-if="hasAdminRole || hasTMSAdminRole"
            >
              <v-list-item-title>
                <span class="list-item">
                  {{
                    $i18n(
                      "accountManagement.section.companies.menu",
                      "Companies Management"
                    )
                  }}</span
                >
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-bind:to="{ name: 'user-account-management-sub-contractors' }"
              v-on:click="hideUserMenu"
              v-if="hasTransporterBrokerRole"
            >
              <v-list-item-title>
                <span class="list-item">
                  {{
                    $i18n(
                      "accountManagement.section.subContractors.menu",
                      "Sub Contractors"
                    )
                  }}</span
                >
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-bind:to="{ name: 'user-account-management-documents' }"
              v-on:click="hideUserMenu"
              v-if="isTransporterAdmin && !isTmsUser && !hasTransporterBrokerRole"
            >
              <v-list-item-title>
                <span class="list-item">
                  {{
                    $i18n(
                      "accountManagement.section.documents.menu",
                      "Document Management"
                    )
                  }}
                </span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-bind:to="{ name: 'user-account-management-users' }"
              v-on:click="hideUserMenu"
              v-if="hasAdminRole || hasTMSAdminRole || hasTransporterBrokerRole"
            >
              <v-list-item-title>
                <span class="list-item">
                  {{
                    $i18n(
                      "accountManagement.section.users.menu",
                      "User Management"
                    )
                  }}
                </span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-bind:to="{ name: 'user-account-management-notifications' }"
              v-on:click="hideUserMenu"
              v-if="showNotifications"
            >
              <v-list-item-title>
                <span class="list-item">
                  {{
                    $i18n(
                      "accountManagement.section.notifications.menu",
                      "Notification Settings"
                    )
                  }}
                </span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-on:click="showSupportDialog = true"
              v-if="hasTMSAdminRole"
            >
              <v-list-item-title>
                <span class="list-item">
                  {{ $i18n("common.label.enableSupport", "Enable Support") }}
                </span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-on:click="logout">
              <v-list-item-title>
                <span class="list-item">
                  {{ $i18n("common.label.logout", "Logout") }}
                </span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <document-expiring-dialog
        :days-left="daysLeft"
        :is-dialog-open="openDocumentExpiringDialog"
        @modal-close="onCloseDocumentExpiringDialog"
      >
      </document-expiring-dialog>
    </v-app-bar>
    <messages-web-component-loader>
      <messages-display :show-pop-up="showMessagesDisplayPopUp"></messages-display>
    </messages-web-component-loader>
  </div>
</template>

<script type="text/babel">
import { mapActions, mapGetters } from "vuex";
import _get from "lodash/get";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _intersection from "lodash/intersection";
import _includes from "lodash/includes";
import moment from "moment";

import COLORS from "@/constants/colors";
import ROLES from "SC/constants/roles";
import Impersonator from "@/components/impersonator";
import IntercompanySwitcher from "@/components/intercompany-switcher";
import TmsCompanySwitcher from "@/components/tms-company-switcher";
import NotificationDisplay from "@/components/top-bar/components/notification-display";
import NameAvatar from "@/components/name-avatar";
import DocumentExpiringDialog from "@/components/document-expiring";
import ExportStatus from "@/components/export-status";
import BatchOperationStatus from "@/components/batch-operation-status";

import OnboardingApiService from "SC/services/onboardingApi";

import ModalUserPolicyConformant from "@/components/modal-user-policy-conformant";

const DOCUMENT_TYPES = [
  "CERTIFICATE_OF_OPERATING_AUTHORITY",
  "CERTIFICATE_OF_INSURANCE",
  "CERTIFICATE_OF_W9",
];

import PodFiltering from "@/components/task-management/components/table/pod-filtering";
import RunBuggyTasksApiService from "SC/services/runBuggyTasksApi";

import EventBus from "@/services/eventBus";
import EventBusConstants from "@/constants/eventBus";

import LocalStorageService from "SC/services/localStorage";
import LocalStorageKeys from "@/constants/localstorage";
import EnableSupportDialog from "@/components/top-bar/components/enable-support-dialog";

import OktaService from "@/services/okta";
import TokenService from "SC/services/tokenService";

import TmsAccountsSwitcher from "@/components/tms-accounts-switcher";
import MessagesDisplay from "@/components/top-bar/components/messages-display";
import MessagesWebComponentLoader from "@/components/messages-web-component-loader";

const TENANT_TMS = 0;
const TENANT_MARKETPLACE = 1;

export default {
  name: "components-top_bar-index",
  watch: {},
  props: {},
  data() {
    return {
      showPolicyConformance: false,
      showUserMenu: false,
      interval: null,
      ROLES,
      COLORS,
      generalNotificationTypes: [
        "VehicleTransferOrder",
        "TransportationOrder",
        "Order",
        "OrderTransportationEvent",
        "Charge",
      ],
      messageNotificationTypes: ["Message"],
      daysLeft: 0,
      openDocumentExpiringDialog: false,
      podList: [],
      selectedPods: [],
      showPods: false,

      userConformantChanged: false,
      conformantAccountId: undefined,
      conformantPolicies: undefined,
      showSupportDialog: false,
      showMessagesDisplayPopUp: false
    };
  },
  methods: {
    hideUserMenu() {
      this.showUserMenu = false;
    },
    menuToggle() {
      this.toggleNavigationExpanded();
    },
    navigateToDashboard() {
      return this.$router.push({
        name: "dashboard",
      });
    },
    logout() {
      this.$router.push({
        name: "logout/index",
      });
    },
    checkDocumentsExpirationDate() {
      let isValidDocument = false;
      let isDocumentExpiring = false;
      let defaultAccount = this.userData.accounts.filter(
        (account) => account.default
      );
      let accountId =
        _get(defaultAccount, "0.id") || _get(this.userData, "accounts.0.id");

      OnboardingApiService.getDocumentsByAccountId(accountId, 0, 100)
        .then((response) => {
          let documents = _get(response, "content", []);
          let acceptedDocuments = documents.filter(
            (document) =>
              _get(document, "verificationStatus") === "ACCEPTED" &&
              _get(document, "expirationDate") &&
              DOCUMENT_TYPES.indexOf(_get(document, "type")) > -1
          );

          acceptedDocuments.some((document) => {
            let dateFormat = "YYYY-MM-DD";
            let documentExpiration = moment(document.expirationDate).format(
              dateFormat
            );
            let currentDate = moment().format(dateFormat);
            let thirtyDaysFromDate = moment()
              .add(30, "days")
              .format(dateFormat);

            if (
              moment(documentExpiration).isBetween(
                currentDate,
                thirtyDaysFromDate,
                null,
                "[]"
              )
            ) {
              let days = moment(documentExpiration).diff(currentDate, "days");
              this.daysLeft = moment.duration(days, "days").asDays();
              isDocumentExpiring = true;
            } else if (
              moment(document.expirationDate, dateFormat).isAfter(moment())
            ) {
              isValidDocument = true;
              return true;
            }
          });

          if (isDocumentExpiring && !isValidDocument) {
            this.openDocumentExpiringDialog = true;
          }
        })
        .catch((error) => console.log(error));
    },
    onCloseDocumentExpiringDialog() {
      this.openDocumentExpiringDialog = false;
    },
    onPodsUpdated(data) {
      EventBus.$emit(EventBusConstants.PODS_UPDATED, data);
    },
    getPodGroups() {
      RunBuggyTasksApiService.getGroups()
        .then((res) => {
          this.podList = res;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    loadPodFilters() {
      this.podsFilters = this.getPodsFiltersFromLocalStorage();
      if (this.podsFilters && !_isEmpty(this.podsFilters)) {
        this.selectedPods = this.podsFilters.split(",");
      }
      this.showPods = true;
    },
    getPodsFiltersFromLocalStorage() {
      let podsData = LocalStorageService.get(this.podsLocalStorageKey);
      return podsData ? podsData.toString() : "";
    },
    onMessagingAccountPolicyChangedEvent(params) {
      this.conformantAccountId =
        _get(params, "accountId") || this.defaultAccountId;
      this.conformantPolicies = _get(params, "policies");
      this.userConformantChanged = true;
    },
    clearSpecifiedRegistrationIds() {
      TokenService.setSpecifiedRegistrationIds([]);
      TokenService.setSpecifiedRegistrationIdsForTenantId([], this.tenantId);
      TokenService.setIntercompaniesCount(0);
    },
    onMessagesButtonClicked() {
      this.showMessagesDisplayPopUp = !this.showMessagesDisplayPopUp
    },
    ...mapActions("ui", ["toggleNavigationExpanded"]),
  },
  computed: {
    defaultAccountId() {
      let accounts = _get(this.userData, "accounts", []);
      let defaultAccount = accounts.filter((account) => account.default);
      return _get(defaultAccount, "0.id");
    },
    showPolicyConformanceForUserRoles() {
      return (
        _intersection(
          [
            ROLES.TRANSPORTER_ADMIN,
            ROLES.TRANSPORTER_ADMIN_TRIAL,
            ROLES.TRANSPORTER_USER,
            ROLES.TRANSPORTER_USER_TRIAL,
            ROLES.SHIPPER_ADMIN,
            ROLES.SHIPPER_ADMIN_TRIAL,
            ROLES.SHIPPER_USER,
            ROLES.SHIPPER_USER_TRIAL,
            ROLES.PRIVATE_SHIPPER_ADMIN,
            ROLES.PRIVATE_SHIPPER_ADMIN_TRIAL,
            ROLES.PRIVATE_SHIPPER_USER,
            ROLES.PRIVATE_SHIPPER_USER_TRIAL,
            ROLES.AUCTION_ADMIN,
            ROLES.AUCTION_ADMIN_TRIAL,
            ROLES.AUCTION_USER,
            ROLES.AUCTION_USER_TRIAL,
          ],
          this.userRoles
        ).length > 0
      );
    },
    showCreateOrder() {
      return (
        _intersection(
          [
            ROLES.RUNBUGGY_SUPER_ADMIN,
            ROLES.RUNBUGGY_ADMIN,
            ROLES.RUNBUGGY_OPERATIONS,
            ROLES.DEALER,
            ROLES.SHIPPER_ADMIN,
            ROLES.SHIPPER_USER,
            ROLES.PRIVATE_SHIPPER_ADMIN,
            ROLES.PRIVATE_SHIPPER_USER,
            ROLES.AUCTION_HOUSE,
            ROLES.AUCTION_USER,
            ROLES.AUCTION_ADMIN,
            ROLES.TMS_ADMIN,
            ROLES.TMS_SHIPPER,
          ],
          this.userRoles
        ).length > 0
      );
    },
    showNotifications() {
      return (
        _intersection(
          [
            ROLES.RUNBUGGY_SUPER_ADMIN,
            ROLES.RUNBUGGY_ADMIN,
            ROLES.RUNBUGGY_OPERATIONS,
            ROLES.TRANSPORTER_ADMIN,
            ROLES.TRANSPORTER_USER,
            ROLES.TRANSPORTER_DRIVER,
            ROLES.AUCTION_ADMIN,
            ROLES.AUCTION_USER,
            ROLES.SHIPPER_ADMIN,
            ROLES.SHIPPER_USER,
            ROLES.SHIPPER_MANAGER,
            ROLES.SHIPPER_BUSINESS_UNIT_MANAGER,
            ROLES.PRIVATE_SHIPPER_ADMIN,
            ROLES.PRIVATE_SHIPPER_USER,
            ROLES.TMS_ADMIN,
            ROLES.TMS_USER,
          ],
          this.userRoles
        ).length > 0
      );
    },
    showActions() {
      return (
        !this.isDriverOnly || (this.isDriverOnly && this.userIsImpersonating)
      );
    },
    shouldShowImpersonation() {
      if (this.userIsImpersonating) {
        return true;
      }

      return this.hasAdminRole;
    },
    name() {
      if (_isEmpty(this.userFirstName) && _isEmpty(this.userLastName)) {
        return this.userUserName;
      }

      return `${this.userFirstName} ${this.userLastName}`;
    },
    isTransporterAdmin() {
      return (
        _intersection(
          [ROLES.TRANSPORTER_ADMIN, ROLES.TRANSPORTER_ADMIN_TRIAL],
          this.userRoles
        ).length > 0
      );
    },
    podsLocalStorageKey() {
      return `${LocalStorageKeys.PODS_TASK_GROUPS}_${this.userId}`;
    },
    showBatchOperationStatus() {
      return this.hasAnyTmsRole;
    },
    ...mapGetters("user", {
      companyEnableCompanyFilter: "companyEnableCompanyFilter",
      hasAdminRole: "hasAdminRole",
      userData: "userData",
      userRoles: "userRoles",
      userUserName: "userName",
      userFirstName: "firstName",
      userLastName: "lastName",
      userIsImpersonating: "isImpersonating",
      userId: "userId",
      hasAnyTmsRole: "hasAnyTmsRole",
      tenantId: "tenantId",
      isTmsChildAccount: "isTmsChildAccount",
      hasTMSAdminRole: "hasTMSAdminRole",
      hasTMSUserRole: "hasTMSUserRole",
      additionalUserRoles: "additionalUserRoles",
      isDriverOnly: "isDriverOnly",
      hasTransporterBrokerRole: "hasTransporterBrokerRole",
    }),
    ...mapGetters("ui", {
      isNavigationExpanded: "isNavigationExpanded",
    }),
    isTmsUser() {
      return this.isTmsChildAccount || this.hasAnyTmsRole;
    },
  },
  mounted() {
    if (this.isTransporterAdmin) {
      this.checkDocumentsExpirationDate();
    }
    EventBus.$on(EventBusConstants.UAM_COMPANY_INFO_UPDATED, () => {
      this.showPolicyConformance =
        this.showPolicyConformanceForUserRoles && !this.isTmsUser;
    });
    EventBus.$on(
      EventBusConstants.MESSAGING_ACCOUNT_POLICY_CHANGED,
      (params) => {
        this.showPolicyConformance =
          this.showPolicyConformanceForUserRoles && !this.isTmsUser;
        if (this.showPolicyConformance)
          this.onMessagingAccountPolicyChangedEvent(params);
      }
    );
  },
  created() {
    if (!this.isDriverOnly) {
      this.getPodGroups();
    }
    if (this.hasAnyTmsRole && !this.companyEnableCompanyFilter) {
      this.clearSpecifiedRegistrationIds();
    }
    this.loadPodFilters();
    this.conformantAccountId = this.defaultAccountId;
  },
  beforeDestroy: function () {
    clearInterval(this.interval);
  },
  mixins: [],
  components: {
    IntercompanySwitcher,
    TmsCompanySwitcher,
    Impersonator,
    NotificationDisplay,
    NameAvatar,
    DocumentExpiringDialog,
    PodFiltering,
    ModalUserPolicyConformant,
    TmsAccountsSwitcher,
    EnableSupportDialog,
    ExportStatus,
    BatchOperationStatus,
    MessagesDisplay,
    MessagesWebComponentLoader
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../scss/variables";

.logo {
  width: 100%;
  max-width: 100px;
  cursor: pointer;
}

.top-bar {
  background: $color-WHITE;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.04);
  color: #06225a;
}

.list-item,
.user-menu-button {
  color: #06225a;
  text-transform: none;
}

.user-menu-btn {
  height: 45px !important;
}

.name-avatar {
  width: 32px;
  height: 32px;

  .initial {
    font-size: 0.9em;
  }
}

.impersonation-label {
  position: absolute;
  top: -7px;
  background: var(--primary-color);
  right: -15px;
  padding: 3px;
  color: white;
  border-radius: 5px;
  font-size: 8px;
  text-transform: uppercase;
  font-weight: bold;
}

.new-order-btn {
  min-width: 0 !important;
  padding: 8px !important;
  height: 32px !important;
  width: 32px !important;
}
</style>
