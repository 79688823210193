<template>
  <div ref="wc-target"></div>
</template>

<script type="text/babel">

export default {
  name: "components-top_bar-messages_display",
  props: {
    showPopUp: {
      required: true
    }
  },
  data: function() {
    return {
      elementNode: undefined
    }
  },
  watch: {
    showPopUp(newValue) {
        if (newValue) {
          this.elementNode.setAttribute('show', 'true');
        } else {
          this.elementNode.setAttribute('show', 'false');
        }
    },
  },
  methods: {
    createElement() {
      const node = document.createElement('messaging-pop-up');
      node.setAttribute('show', `${this.showPopUp}`);
      this.$refs['wc-target'].innerHTML = '';
      this.$refs['wc-target'].appendChild(node);
      this.elementNode = node;
    },
  },
  mounted() {
    this.createElement();
  },
  beforeDestroy() {
    if (this.elementNode) {
      this.elementNode.vueComponent.$destroy();
    }
  },
}
</script>
