import TokenService from "../services/tokenService";
import axios from "axios";
import _get from "lodash/get";
import _isNumber from 'lodash/isNumber'

const Client = {
    axiosClient: axios.create(),
    acceptTerms(accountId) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/accept-terms`;
        return this.post(path);
    },
    unlock(email, factoryType, passCode) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${email}/unlock`;
        return Client.axiosClient.post(path, {
            factoryType,
            passCode
        });
    },
    mfaVerify(email, factoryType, passCode) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${email}/verify`;
        return Client.axiosClient.post(path, {
            factoryType,
            passCode
        });
    },
    factors(email, payload) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${email}/factors`;
        return Client.axiosClient.post(path, payload);
    },

    forgotPassword(email) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${email}/forgotPassword`;
        return Client.axiosClient.post(path);
    },

    resetPassword(email) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${email}/resetPassword`;
        return Client.axiosClient.post(path);
    },

    getPasswordStatus(email) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${email}/passwordStatus`;
        return Client.axiosClient.get(path);
    },

    createAccount(data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/`;
        return this.post(path, data);
    },

    getAccounts(page, size, sort, params = {}) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts`;
        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`);
        }

        if (size) {
            query.push(`size=${size}`);
        }

        if (sort) {
            query.push(`sort=${sort}`);
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }
        return this.get(path);
    },

    getAccountsFull(page, size, sort, params = {}) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/full`;
        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`);
        }

        if (size) {
            query.push(`size=${size}`);
        }

        if (sort) {
            query.push(`sort=${sort}`);
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }
        return this.get(path);
    },

    getAccount(accountId) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}`;
        return this.get(path);
    },

    getAccountFull(accountId) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/full`;
        return this.get(path);
    },

    getAccountConfigStatuses(accountId) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/status`;
        return this.get(path);
    },

    getAccountRoles(accountId) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/roles`;
        return this.get(path);
    },

    getAllAccountRoles(accountId) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/roles/all`;
        return this.get(path);
    },

    patchAccountRoles(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/roles`;
        return this.patch(path, data);
    },

    enableSupportByEmail(email, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/rel/support/${email}`;
        return this.post(path, data)
    },

    getAccountPayers(accountId) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/payers`;
        return this.get(path);
    },

    getSubAccounts(accountId, page, size, sort, params = {}) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/subAccounts`;
        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`);
        }

        if (size) {
            query.push(`size=${size}`);
        }

        if (sort) {
            query.push(`sort=${sort}`);
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }
        return this.get(path);
    },

    getUserSubAccounts(email, page, size, sort, params = {}) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/rel/user/${email}/subAccounts`;
        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`);
        }

        if (size) {
            query.push(`size=${size}`);
        }

        if (sort) {
            query.push(`sort=${sort}`);
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }
        return this.get(path);
    },

    createSubAccount(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/subAccounts`;
        return this.post(path, data);
    },

    createSubContractorAccount(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/subContractors`;
        return this.post(path, data);
    },

    activateAccount(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/activate`;
        return this.post(path, data);
    },

    deactivateAccount(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/deactivate`;
        return this.post(path, data);
    },

    updateAccount(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}`;
        return this.patch(path, data);
    },

    setAccountBankAccount(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/bank-account`;
        return this.post(path, data);
    },

    updateAccountContact(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/contact`;
        return this.put(path, data);
    },

    updateAccountFeatureStatus(accountId, feature, enable) {
        let status = enable ? "enable" : "disable";
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/features/${status}/${feature}`
        return this.patch(path);
    },

    setAccountContacts(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/contacts`;
        return this.post(path, data);
    },

    setAccountLocations(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/locations`;
        return this.post(path, data);
    },

    getAccountRatings(accountId) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/ratings`;
        return this.get(path);
    },

    addAccountRating(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/rating`;
        return this.post(path, data);
    },

    updateAccountRatings(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/ratings`;
        return this.patch(path, data);
    },

    deleteAccountRating(accountId, category) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/rating?category=${category}`;
        return this.delete(path);
    },

    getAccountBadges(accountId) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/badges`;
        return this.get(path);
    },

    addAccountBadge(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/badge`;
        return this.post(path, data);
    },

    updateAccountBadges(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/badges`;
        return this.patch(path, data);
    },

    deleteAccountBadge(accountId, badgeName) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/badge?name=${badgeName}`;
        return this.delete(path);
    },

    setAccountTrucks(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/trucks`;
        return this.post(path, data);
    },

    getAccountTrucks(accountId) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/trucks`;
        return this.get(path);
    },

    updateAccountTruck(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/truck`;
        return this.put(path, data);
    },

    removeAccountTruck(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/truck`;
        return this.delete(path, data);
    },

    getAccountUsers(accountId, page, size, sort, params = {}) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/users`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`);
        }

        if (size) {
            query.push(`size=${size}`);
        }

        if (sort) {
            query.push(`sort=${sort}`);
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }
        return this.get(path);
    },

    createAccountUser(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/users`;
        return this.post(path, data);
    },

    createAccountNote(accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/comments`;
        return this.post(path, data);
    },

    createAccountTaskComment(accountId, taskId, message) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/accounts/${accountId}/tasks/${taskId}/comment`;
        return this.post(path, { message });
    },

    getUsers(page, size, sort, params = {}) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`);
        }

        if (size) {
            query.push(`size=${size}`);
        }

        if (sort) {
            query.push(`sort=${sort}`);
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }
        return this.get(path);
    },

    getUsersForImpersonation(page, size, sort, params = {}) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`);
        }

        if (size) {
            query.push(`size=${size}`);
        }

        if (sort) {
            query.push(`sort=${sort}`);
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }
        return this.getWithMainToken(path);
    },

    getUsersFull(page, size, sort, params = {}) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/full`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`);
        }

        if (size) {
            query.push(`size=${size}`);
        }

        if (sort) {
            query.push(`sort=${sort}`);
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }
        return this.get(path);
    },

    getDrivers(page, size, sort, params = {}) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/drivers`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`)
        }

        if (size) {
            query.push(`size=${size}`)
        }

        if (sort) {
            query.push(`sort=${sort}`);
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }
        return this.get(path);
    },

    getUser(email) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${email}`;
        return this.get(path);
    },

    getUserFull(email) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${email}/full`;
        return this.get(path);
    },

    signupUser(data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/signup`;
        return this.post(path, data);
    },

    updateUser(data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users`;
        return this.put(path, data);
    },

    updateUserPatch(userId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${userId}`;
        return this.patch(path, data);
    },

    deleteUserRequest() {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users`;
        return this.delete(path);
    },

    addUserRole(email, role, accountId) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/rel/user/${email}/${role}/${accountId}`;
        return this.post(path);
    },

    removeUserRole(email, role, accountId) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/rel/user/${email}/${role}/${accountId}`;
        return this.delete(path);
    },

    updateUserRoles(userId, accountId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/rel/user/${userId}/${accountId}`;
        return this.put(path, data);
    },

    setUserLocations(email, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${email}/locations`;
        return this.post(path, data);
    },

    getUserRatings(userId) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${userId}/ratings`;
        return this.get(path);
    },

    addUserRating(userId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${userId}/rating`;
        return this.post(path, data);
    },

    updateUserRatings(userId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${userId}/ratings`;
        return this.patch(path, data);
    },

    deleteUserRating(userId, category) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${userId}/rating?category=${category}`;
        return this.delete(path);
    },

    getUserBadges(userId) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${userId}/badges`;
        return this.get(path);
    },

    addUserBadge(userId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${userId}/badge`;
        return this.post(path, data);
    },

    updateUserBadges(userId, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${userId}/badges`;
        return this.patch(path, data);
    },

    deleteUserBadge(userId, badgeName) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${userId}/badge?name=${badgeName}`;
        return this.delete(path);
    },

    linkAccount(email, role, accountId, data={}) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/rel/user/${email}/${role}/${accountId}`;
        return this.post(path, data);
    },

    linkUserToAccount(email, role, accountId) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/rel/user/${email}/${role}/${accountId}`;
        return this.post(path);
    },

    detachUserFromAccount(email, role, accountId) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/rel/user/${email}/${role}/${accountId}`;
        return this.delete(path);
    },

    enableUser(email) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${email}/enable`;
        return this.post(path);
    },

    disableUser(email, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${email}/disable`;
        return this.post(path, data);
    },

    deleteUser(email) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${email}`;
        return this.delete(path);
    },

    changePassword(email, data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/${email}/changePassword`;
        return this.post(path, data);
    },

    getPasswordSettings(policy = 'PASSWORD_POLICY_RUNBUGGY') {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/users/passwordSettings/${policy}`;
        return this.get(path);
    },

    linkAccounts(account, rel, toAccount, data={}) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/rel/${account}/${rel}/${toAccount}`;
        return this.post(path, data);
    },

    detachAccounts(account, rel, toAccount) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/rel/${account}/${rel}/${toAccount}`;
        return this.delete(path);
    },

    getRelations(page, size, sort, params = {}) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/rel`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`);
        }

        if (size) {
            query.push(`size=${size}`);
        }

        if (sort) {
            query.push(`sort=${sort}`);
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }
        return this.get(path);
    },

    getRelatedAccounts(rel, page, size, sort, params = {}) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/rel/${rel}`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`);
        }

        if (size) {
            query.push(`size=${size}`);
        }

        if (sort) {
            query.push(`sort=${sort}`);
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }
        return this.get(path);
    },

    getRelatedUserAccounts() {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/rel/user/accounts`;
        return this.get(path);
    },

    getRelatedFromAccount(accountId, rel, page, size, sort, params = {}) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/rel/from/${accountId}/${rel}`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`);
        }

        if (size) {
            query.push(`size=${size}`);
        }

        if (sort) {
            query.push(`sort=${sort}`);
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }
        return this.get(path);
    },
    getRelatedToAccount(accountId, rel, page, size, sort, params = {}) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/rel/to/${accountId}/${rel}`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`);
        }

        if (size) {
            query.push(`size=${size}`);
        }

        if (sort) {
            query.push(`sort=${sort}`);
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }
        return this.get(path);
    },

    updateRelationStatus(data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/rel/status`;
        return this.patch(path, data);
    },

    recoverBeyondIdentity(externalId, bindingTokenDeliveryMethod = 'email') {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/byndid/v1/manage/recover-user`;

        return Client.axiosClient.post(path, {
            username: externalId,
            binding_token_delivery_method: bindingTokenDeliveryMethod
        });
    },

    listBeyondIdentityDevices() {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/byndid/v0/devices`;

        return this.get(path);
    },

    convertByndidIdToken(idToken) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/bynd-login`;

        return Client.axiosClient.post(path, {
            idToken
        });
    },

    removeBeyondIdentityDevices(data) {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/byndid/v0/devices/delete-requests`;

        // itentionally only get top token
        let token = TokenService.getToken();
        return Client.axiosClient.post(path, {
            device_ids: data
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    },

    getWithMainToken(path) {
        console.log("getWithMainToken: ", TokenService.getImpersonationToken(), TokenService.getToken());
        let token = TokenService.getToken();
        let params = {};

        if (token) {
          return Client.axiosClient.get(path, {
              headers: {
                  'Authorization': `Bearer ${token}`
              },
              params
          });
        }
        return Client.axiosClient.get(path, { params });
    },

    async unauthenticate() {
        let path = `${_get(window, 'CONFIG.accounts.api.path')}/logout`;
        return this.post(path, {});
    },


    // common calls with auth token
    get(path) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params.byAdmin = TokenService.getImpersonatedByUsername();
        }

        if (token) {
          return Client.axiosClient.get(path, {
              headers: {
                  'Authorization': `Bearer ${token}`
              },
              params
          });
        }
        return Client.axiosClient.get(path, { params });
    },

    post(path, data) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        return Client.axiosClient.post(path, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params
        });
    },

    patch(path, data) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        return Client.axiosClient.patch(path, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params
        });
    },

    put(path, data) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        return Client.axiosClient.put(path, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params
        });
    },

    delete(path) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        return Client.axiosClient.delete(path, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params
        });
    }
};

export default Client;
