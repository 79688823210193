/**
https://runbuggy.docs.stoplight.io/guides/vto-statuses
----------------
DRAFT
READY
AVAILABLE
CLAIMED
UNCLAIMED
ASSIGNED
REJECTED
ACCEPTED
DRIVER_ARRIVED
PICKED_UP
SIGNATURE_ON_PICKUP
DELIVERED
CANCELED
COMPLETE
ERROR
 */
export default {
  PLANNING: "PLANNING",
  DRAFT: "DRAFT",
  READY: "READY",
  ON_HOLD: "ON_HOLD",
  AVAILABLE: "AVAILABLE",
  CLAIMED: "CLAIMED",
  UNCLAIMED: "UNCLAIMED",
  ASSIGNED: "ASSIGNED",
  REJECTED: "REJECTED",
  ACCEPTED: "ACCEPTED",
  DRIVER_ARRIVED: "DRIVER_ARRIVED",
  PICKED_UP: "PICKED_UP",
  SIGNATURE_ON_PICKUP: "SIGNATURE_ON_PICKUP",
  DELIVERED: "DELIVERED",
  CANCELED: "CANCELED",
  COMPLETE: "COMPLETE",
  ERROR: "ERROR",
  UNLOADED: "UNLOADED",
};
