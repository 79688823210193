export const H1_FONT_FAMILY = "'Montserrat',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol";
      
export const H1_FONT_SIZE = '24.00px';
      
export const H1_FONT_WEIGHT = '500';
      
export const H1_LETTER_SPACING = '0.50px';
      
export const H1_LINE_HEIGHT = '32.00px';
export const H2_FONT_FAMILY = "'Montserrat',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol";
      
export const H2_FONT_SIZE = '18.00px';
      
export const H2_FONT_WEIGHT = '500';
      
export const H2_LETTER_SPACING = '0.50px';
      
export const H2_LINE_HEIGHT = '24.00px';
export const H3_FONT_FAMILY = "'Montserrat',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol";
      
export const H3_FONT_SIZE = '16.00px';
      
export const H3_FONT_WEIGHT = '500';
      
export const H3_LETTER_SPACING = '0.30px';
      
export const H3_LINE_HEIGHT = '22.00px';
export const H4_FONT_FAMILY = "'Montserrat',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol";
      
export const H4_FONT_SIZE = '15.00px';
      
export const H4_FONT_WEIGHT = '500';
      
export const H4_LETTER_SPACING = '0.20px';
      
export const H4_LINE_HEIGHT = '23.00px';
export const P_FONT_FAMILY = "'Montserrat',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol";
      
export const P_FONT_SIZE = '16.00px';
      
export const P_FONT_WEIGHT = '400';
      
export const P_LETTER_SPACING = '0.00px';
      
export const P_LINE_HEIGHT = '20.00px';
export const LABEL_FONT_FAMILY = "'Montserrat',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol";
      
export const LABEL_FONT_SIZE = '14.00px';
      
export const LABEL_FONT_WEIGHT = '400';
      
export const LABEL_LETTER_SPACING = '0.70px';
      
export const LABEL_LINE_HEIGHT = '16.00px';
export const SPAN_FONT_FAMILY = "'Montserrat',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol";
      
export const SPAN_FONT_SIZE = '12.00px';
      
export const SPAN_FONT_WEIGHT = '400';
      
export const SPAN_LETTER_SPACING = '0.90px';
      
export const SPAN_LINE_HEIGHT = '12.00px';