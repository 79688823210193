import OnboardingApiClient from '../clients/onboardingApi'

const Service = {

  async getDocuments(page, size, params = {}) {
    return OnboardingApiClient.getDocuments(page, size, params)
      .then(response => response.data);
  },

  async getDocumentsByAccountId(accountId, page, size, params = {}) {
    return OnboardingApiClient.getDocumentsByAccountId(accountId, page, size, params)
      .then(response => response.data);
  },

  async getDocument(documentId) {
    return OnboardingApiClient.getDocument(documentId)
      .then(response => response.data);
  },

  async createDocument(data) {
    return OnboardingApiClient.createDocument(data)
      .then(response => response.data);
  },

  async downloadDocument(documentId, params = {}) {
    return OnboardingApiClient.downloadDocument(documentId, params)
      .then(response => response);
  },

  async deleteDocument(documentId) {
    return OnboardingApiClient.deleteDocument(documentId)
      .then(response => response.data);
  },

  async getAccountPolicies(accountId) {
    return OnboardingApiClient.getAccountPolicies(accountId)
      .then(response => response.data);
  },

  async addBankAccount(accountId, data) {
    return OnboardingApiClient.addBankAccount(accountId, data)
      .then(response => response.data);
  },

  async updateBankAccount(accountId, bankAccountId, data) {
    return OnboardingApiClient.updateBankAccount(accountId, bankAccountId, data)
      .then(response => response.data);
  },

  async deleteBankAccount(accountId, bankAccountId) {
    return OnboardingApiClient.deleteBankAccount(accountId, bankAccountId)
      .then(response => response.data);
  },

  async getBankAccount(accountId, bankAccountType) {
    return OnboardingApiClient.getBankAccount(accountId, bankAccountType)
      .then(response => response.data);
  },

  async getBankAccounts(accountId) {
    return OnboardingApiClient.getBankAccounts(accountId)
      .then(response => response.data);
  },

  async getStripeBankAccount(accountId, bankAccountId) {
    return OnboardingApiClient.getStripeBankAccount(accountId, bankAccountId)
      .then(response => response.data);
  },

  async verifyStripeBankAccount(accountId, bankAccountId, data) {
    return OnboardingApiClient.verifyStripeBankAccount(accountId, bankAccountId, data)
      .then(response => response.data);
  },

  async getStripeCard(accountId, cardId) {
    return OnboardingApiClient.getStripeCard(accountId, cardId)
      .then(response => response.data);
  },

  async connectOnboarding(accountId, params) {
    return OnboardingApiClient.connectOnboarding(accountId, params)
      .then(response => response.data);
  },

  async connectUpdateProfile(accountId, params) {
    return OnboardingApiClient.connectUpdateProfile(accountId, params)
      .then(response => response.data);
  },

  async getInvoiceAccounts(accountId) {
    return OnboardingApiClient.getInvoiceAccounts(accountId)
      .then(response => response.data);
  },

  async saveInvoiceAccount(accountId, data) {
    return OnboardingApiClient.saveInvoiceAccount(accountId, data)
      .then(response => response.data);
  },

  async deleteInvoiceAccount(accountId, invoiceId) {
    return OnboardingApiClient.deleteInvoiceAccount(accountId, invoiceId)
      .then(response => response.data);
  }

};

export default Service;
