<template>
  <div>
    <template v-if="shouldShow">
      <div class="list-group" v-bind:class="{ expanded: groupExpanded }">
        <item-button
          v-on:click="onClick"
          v-bind:mini="mini"
          v-bind:depth="depth"
          v-bind:item="item"
          v-bind:group-expanded="groupExpanded"
          v-bind:has-children="true"
          v-on:navigated="emitNavigated"
          v-if="!mini"
          v-bind:id="
            item.label
              ? `side-menu-item-${item.label.split(' ').join('-')}`
              : undefined
          "
        ></item-button>
        <div class="children text-color" v-if="groupExpanded">
          <template v-if="isLoadingFilters">
            <v-progress-circular
              class="ml-8"
              indeterminate
              width="2"
              size="16"
            ></v-progress-circular>
          </template>
          <template v-else>
            <div v-for="(child, index) in children" :key="index">
              <camunda-filter-item-button
                v-bind:mini="mini"
                v-bind:depth="depth + 1"
                v-bind:item="child"
                v-on:navigated="emitNavigated"
              ></camunda-filter-item-button>
            </div>
            <div v-if="!children.length && !mini" class="pa-6 pl-12 pt-0">
              <small><em>No tasks</em></small>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script type="text/babel">
import _get from "lodash/get";
import _sortBy from "lodash/sortBy";
import { mapActions, mapGetters } from "vuex";
import CamundaFilterItemButton from "./camunda-filter-item-button";
import ItemButton from "./button";
import Item from "./item";
import CamundaIntegrationsApiService from "SC/services/camundaIntegrationsApi";
import CamundaOnboardingApiService from "SC/services/camundaOnboardingApi";
import CamundaOrdersApiService from "SC/services/camundaOrdersApi";
import CamundaPricingApiService from "SC/services/camundaPricingApi";

export default {
  name: "components-navigation-camunda_filter_item",
  watch: {},
  props: {
    mini: {
      default() {
        return false;
      },
    },
    roles: undefined,
    depth: {
      default() {
        return 0;
      },
    },
    params: {
      default() {
        return 0;
      },
    },
    item: {
      required: true,
    },
  },
  data() {
    return {
      additionalChildren: [],
      filters: [],
      isLoadingFilters: false,
      groupExpanded: false,
    };
  },
  methods: {
    emitNavigated(route) {
      console.log(route);
      this.$emit("navigated", route);
    },
    onClick() {
      this.groupExpanded = !this.groupExpanded;
      if (this.filters.length > 0) {
        return;
      }
      if (this.hasAdminRole) {
        this.getFilters();
      }
    },
    getFilters() {
      this.isLoadingFilters = true;
      this.filters = [];
      return this.camundaApiService
        .getFilters()
        .then((res) => {
          this.filters = res.sort((a, b) => {
            return a.properties.priority - b.properties.priority;
          });
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.isLoadingFilters = false;
        });
    },
  },
  computed: {
    groupKey() {
      return _get(this.item, "groupKey");
    },
    shouldShow() {
      let shouldShow = true;
      if (this.item.roles) {
        let matches = this.item.roles.filter(
          (value) => -1 !== this.userRoles.indexOf(value)
        );
        return matches.length > 0;
      }

      return shouldShow;
    },
    hasChildren() {
      return this.children.length > 0;
    },
    children() {
      let serviceKey = _get(this.item, "typeParams.params.processKey");
      return _sortBy(this.filters, "properties.priority").map((filter) => {
        return {
          filter: filter,
          processKey: serviceKey,
          label: filter.name,
          icon: '<i class="fas fa-tag"></i>',
          route: {
            name: "admin-task_list-view",
            params: {
              processKey: serviceKey,
              filterId: filter.id,
            },
            query: {
              "show-as-table": _get(
                this.item,
                "typeParams.query.showAsTable",
                true
              ),
            },
          },
          isActive: () => {
            return (
              this.$route.path.indexOf(
                `admin/task-list/${serviceKey}/${filter.id}`
              ) > -1
            );
          },
        };
      });
    },
    camundaApiService() {
      let serviceKey = _get(this.item, "typeParams.params.processKey");
      switch (serviceKey) {
        case "pricing":
          return CamundaPricingApiService;
        case "integrations":
          return CamundaIntegrationsApiService;
        case "orders-workflow":
        case "orders":
          return CamundaOrdersApiService;
        case "onboarding":
        case "default":
        default:
          return CamundaOnboardingApiService;
      }
    },
    ...mapGetters("user", ["userRoles", "hasAdminRole"]),
    ...mapGetters("ui", {
      uiCollapsedGroupKeys: "collapsedGroupKeys",
    }),
  },
  created() {
    if (this.hasChildren && !this.item.groupKey) {
      throw new Error("Navigation item with children must have a group key");
    }
  },
  mounted() {
    let serviceKey = _get(this.item, "typeParams.params.processKey");
    if (serviceKey !== "onboarding") {
      this.onClick();
    }
  },
  beforeDestroy() {},
  mixins: [],
  components: {
    CamundaFilterItemButton,
    ItemButton,
    Item,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.list-group {
  position: relative;
  border-top: 1px solid rgba(#fff, 0.15);

  &.expanded {
    background: rgba(#fff, 0.0125);
  }
}

.text-color {
  color: var(--v-navigation_text-base);
}
</style>
