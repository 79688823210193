import NotificationsApiClient from '../clients/notificationsApi'
import _get from 'lodash/get'

const Service = {
  updateUserPreference(email, preference) {
    return NotificationsApiClient.updateUserPreference(email, preference);
  },
  getUser(email) {
    return NotificationsApiClient.getUser(email);
  },
  getNotifications(page, size, types, seen) {
    return NotificationsApiClient.getAllNotifications(page, size, types, seen);
  },
  getNotificationPreferenceSettings(sort) {
    return NotificationsApiClient.getNotificationPreferenceSettings(sort)
        .then((res) => {
          let data = _get(res, 'data');

          if (!data) {
            return;
          }

          return data;
        });
  },
  async getNotificationTemplates() {
    let page = 0;
    let size = 1000;
    let allData = [];

    while (true) {
      const response = await NotificationsApiClient.getNotificationTemplates(page, size);

      let data = _get(response, 'data');
      if (!data || !_get(data, 'content')) {
        // Break if no more data is available
        break;
      }

      // Append the current page's data to the result
      allData = allData.concat(_get(data, 'content'));

        // Move to the next page
      page++;

      // If the current page has less data than the page size, it means it's the last page
      if (_get(data, 'content.length') < size) {
        break;
      }
    }

    return allData;
  },
  getNotificationTemplate(id) {
    return NotificationsApiClient.getNotificationTemplate(id);
  },
  patchNotificationTemplate(id, data) {
    return NotificationsApiClient.patchNotificationTemplate(id, data);
  },
  deleteNotificationTemplate(id) {
    return NotificationsApiClient.deleteNotificationTemplate(id);
  },
  createNotificationTemplate(data) {
    return NotificationsApiClient.createNotificationTemplate(data);
  },
  async getNotificationTemplateDefinitions() {
    let page = 0;
    let size = 1000;
    let allData = [];

    while (true) {
      const response = await NotificationsApiClient.getNotificationTemplateDefinitions(page, size);

      let data = _get(response, 'data');
      if (!data || !_get(data, 'content')) {
        // Break if no more data is available
        break;
      }

      // Append the current page's data to the result
      allData = allData.concat(_get(data, 'content'));

        // Move to the next page
      page++;

      // If the current page has less data than the page size, it means it's the last page
      if (_get(data, 'content.length') < size) {
        break;
      }
    }

    return allData;
  },
  getNotificationTemplateDefinition(id) {
    return NotificationsApiClient.getNotificationTemplateDefinition(id);
  },
  patchNotificationTemplateDefinition(id, data) {
    return NotificationsApiClient.patchNotificationTemplateDefinition(id, data);
  },
  deleteNotificationTemplateDefinition(id) {
    return NotificationsApiClient.deleteNotificationTemplateDefinition(id);
  },
  createNotificationTemplateDefinition(data) {
    return NotificationsApiClient.createNotificationTemplateDefinition(data);
  },
  markAllSeen(id) {
    return NotificationsApiClient.markAllSeen(id);
  },
  markNotificationSeen(id) {
    return NotificationsApiClient.markNotificationSeen(id);
  },
  getTransportationOrderNotifications(transportationOrderId, page, size) {
    return NotificationsApiClient.getTransportationOrderNotifications(transportationOrderId, page, size)
  }
};

export default Service;
