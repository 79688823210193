import _each from "lodash/each";

var mixin = {
  methods: {
    parseURLQuery() {
      let urlParams = window.location.search.replace("?", "").split("&");

      let params = {};
      _each(urlParams, function (urlParam) {
        let parts = urlParam.split("=");
        params[parts[0]] = decodeURIComponent(parts[1]);
      });

      return params;
    },
  },
};

export default mixin;
