export const RESET_PERSPECTIVE = "RESET_PERSPECTIVE";

export const HAS_RETRIEVED_ORDER = "HAS_RETRIEVED_ORDER";
export const IS_RETRIEVING_ORDER = "IS_RETRIEVING_ORDER";
export const RETRIEVAL_ERROR = "RETRIEVAL_ERROR";
export const RETRIEVING_MESSAGE = "RETRIEVING_MESSAGE";
export const RETRIEVING_ORDER_ID = "RETRIEVING_ORDER_ID";

export const SHIPPER_ORDER = "SHIPPER_ORDER";
export const SHIPPER_TRANSPORTER_ORDERS = "SHIPPER_TRANSPORTER_ORDERS";
export const SHIPPER_VEHICLE_TRANSFER_ORDERS =
  "SHIPPER_VEHICLE_TRANSFER_ORDERS";

export const TRANSPORTER_ORDER = "TRANSPORTER_ORDER";
export const TRANSPORTER_SHIPPER_ORDERS = "TRANSPORTER_SHIPPER_ORDERS";
export const TRANSPORTER_VEHICLE_TRANSFER_ORDERS =
  "TRANSPORTER_VEHICLE_TRANSFER_ORDERS";

export const VEHICLE_TRANSFER_ORDER = "VEHICLE_TRANSFER_ORDER";
export const VEHICLE_TRANSFER_ORDER_GATEPASSES =
  "VEHICLE_TRANSFER_ORDER_GATEPASSES";
export const VEHICLE_TRANSFER_ORDER_PHOTOS = "VEHICLE_TRANSFER_ORDER_PHOTOS";

export const ALL_VEHICLE_TRANSFER_ORDERS = "ALL_VEHICLE_TRANSFER_ORDERS";
export const SHARED_VEHICLE_TRANSFER_ORDERS = "SHARED_VEHICLE_TRANSFER_ORDERS";

export const EXPANDED_ORDER = "EXPANDED_ORDER";

export const ORDER_TASKS = "ORDER_TASKS";
