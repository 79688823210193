<template>
  <div class="component d-flex py-2">
    <template v-if="rowStatus === 'processing'">
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" medium color="warning">mdi-timer-sand</v-icon>
        </template>
        <span>{{ $i18n("common.label.processing", "Processing") }}<template v-if="rowStatusMessage"> - {{rowStatusMessage}}</template></span>
      </v-tooltip>
    </template>
    <template v-else-if="rowStatus === 'completed'">
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" medium color="success">mdi-check-circle</v-icon>
        </template>
        <span>{{ $i18n("common.label.completed", "Completed") }}<template v-if="rowStatusMessage"> - {{rowStatusMessage}}</template></span>
      </v-tooltip>
    </template>
    <template v-else-if="rowStatus === 'error'">
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" medium color="error">mdi-alert-circle</v-icon>
        </template>
        <span>{{ $i18n("common.label.error", "Error") }}<template v-if="rowStatusMessage"> - {{rowStatusMessage}}</template></span>
      </v-tooltip>
    </template>
    <template v-else-if="rowStatus === 'pending'">
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" medium color="primary">mdi-timer-sand-paused</v-icon>
        </template>
        <span>{{ $i18n("common.label.pending", "Pending") }}<template v-if="rowStatusMessage"> - {{rowStatusMessage}}</template></span>
      </v-tooltip>
    </template>
  </div>
</template>
<script type="text/babel">
import Vue from "vue";
import _get from "lodash/get";

export default Vue.extend({
  name: "components-batch_operation_status-grid_row_batch_item_status_renderer-index",
  components: {},
  data() {
    return {};
  },
  computed: {
    rowStatus() {
      return _get(this.params, "value", "").toLowerCase();
    },
    rowStatusMessage() {
      return  _get(this.params, "data.statusDetail");
    },
  },
  methods: {},
  created() {}
});
</script>
<style lang="scss" scope>
</style>
