export const BLACK = '#000000';
export const WHITE = '#ffffff';
export const GRAY_1 = '#333333';
export const GRAY_2 = '#4f4f4f';
export const GRAY_3 = '#828282';
export const GRAY_4 = '#bdbdbd';
export const GRAY_5 = '#e0e0e0';
export const GRAY_6 = '#f2f2f2';
export const DARK_BLUE_1 = '#023246';
export const DARK_BLUE_2 = '#034c68';
export const BLUE_GRAY_1 = '#b1c1d2';
export const BLUE_GRAY_2 = '#e0e7ee';
export const BLUE_GRAY_3 = '#f8fafc';
export const RED = '#cc0000';
export const ORANGE = '#f24906';
export const YELLOW = '#f9af1f';
export const GREEN_1 = '#219653';
export const GREEN_2 = '#27ae60';
export const GREEN_3 = '#6fcf97';
export const BLUE_1 = '#0597d1';
export const BLUE_2 = '#06b0f2';
export const BLUE_3 = '#26bdf9';
export const PURPLE_1 = '#4539de';
export const PURPLE_2 = '#4f51e2';