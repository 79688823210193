<template>
  <div class="component d-flex order-tenant-details">
    <div class="align-self-center" style="line-height: 0;">
      <template v-if="withHover">
        <template v-if="!tenantId">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on">
                <v-img contain src="images/mini-logo-blue.png" />
              </div>
            </template>
            {{ tenantName }}
          </v-tooltip>
        </template>
        <template v-else-if="imageExists">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on">
                <v-img contain :height="20" :width="20" :src="imageSource"/>
              </div>
            </template>
            {{ tenantName }}
          </v-tooltip>
        </template>
        <template v-else>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on">
                <avatar v-bind:name="tenantName" :size="20" />
              </div>
            </template>
            {{ tenantName }}
          </v-tooltip>
        </template>
      </template>
      <template v-else>
        <template v-if="!tenantId">
          <v-img contain src="images/mini-logo-blue.png" />
        </template>
        <template v-else-if="imageExists">
          <v-img contain :height="20" :width="20" :src="imageSource"/>
        </template>
        <template v-else>
          <avatar v-bind:name="tenantName" :size="20" />
        </template>
      </template>
    </div>
    <div v-if="showName" class="d-flex h-100">
      <div class="tenant-name pa-2 align-self-center">{{ tenantName }}</div>
    </div>
  </div>
</template>

<script type="text/babel">
import MiniLogo from '@/assets/mini-logo.svg'
import Avatar from "SC/components/avatar";

export default {
  name: "components-order_tenant_details-index",
  watch: {},
  props: {
    tenantName: undefined,
    tenantId: undefined,
    withHover: undefined,
    showName: undefined
  },
  data() {
    return {
      imageExists: undefined
    };
  },
  methods: {
    determineImageExists() {
      let promise = Promise.resolve();

      return promise
          .then(() => {
            if (!this.tenantId) {
              return;
            }

            return new Promise((resolve, reject) => {
              let img = new Image();
              img.onload = () => {
                this.imageExists = true;
                return resolve();
              };
              img.onerror = () => {
                return reject();
              };
              img.src = this.imageSource;
            });
          })
          .catch((e) => {
            this.imageExists = false;
          })
    }
  },
  computed: {
    imageSource() {
      if (this.tenantId) {
        return `https://raw.githubusercontent.com/runbuggyinc/runbuggyinc.github.io/master/assets/logos/${this.tenantId}.png`;
      }
    }
  },
  created() {

  },
  mounted() {
    this.determineImageExists();
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {
    Avatar
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tenant-name {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #001427;
}

</style>

<style lang="scss">
.order-tenant-details {
  .initials {
    font-size:.5rem !important;
  }
}
</style>