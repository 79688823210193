var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.shouldShow)?[(!_vm.hasChildren)?[_c('item-button',{attrs:{"id":_vm.item.label
            ? `side-menu-item-${_vm.item.label.split(' ').join('-')}`
            : undefined,"mini":_vm.mini,"depth":_vm.depth,"item":_vm.item},on:{"click":_vm.onClick,"navigated":_vm.emitNavigated}})]:[_c('div',{staticClass:"list-group",class:{ expanded: _vm.groupExpanded }},[(!_vm.mini)?_c('item-button',{attrs:{"id":_vm.item.label
              ? `side-menu-item-${_vm.item.label.split(' ').join('-')}`
              : undefined,"mini":_vm.mini,"depth":_vm.depth,"item":_vm.item,"group-expanded":_vm.groupExpanded,"has-children":_vm.hasChildren},on:{"click":_vm.onClick,"navigated":_vm.emitNavigated}}):_vm._e(),(_vm.groupExpanded)?_c('div',{staticClass:"children"},[_vm._l((_vm.children),function(child,index){return [(child.type === _vm.ITEM_TYPE_CAMUNDA_FILTER)?[_c('camunda-filter-item',{key:`camunda_filter_${index}`,attrs:{"params":child.typeParams,"mini":_vm.mini,"item":child,"depth":_vm.depth + 1},on:{"navigated":_vm.emitNavigated}})]:(child.type === _vm.TASK_MANAGEMENT_FILTER)?[_c('task-management-menu-item',{key:`navigation_filter_${index}`,attrs:{"params":child.typeParams,"mini":_vm.mini,"item":child,"depth":_vm.depth + 1},on:{"navigated":_vm.emitNavigated}})]:[_c('components-navigation-item',{key:_vm.item.label
                    ? `navigation_item_${_vm.item.label}_child_${index}`
                    : undefined,attrs:{"mini":_vm.mini,"depth":_vm.depth + 1,"item":child},on:{"navigated":_vm.emitNavigated}})]]})],2):_vm._e()],1)]]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }