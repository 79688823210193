function applyIdToElement(element, dataKey, keyArray, depth) {
  if (element.dataset[dataKey]) {
    return;
  }
  element.dataset[dataKey] = [...keyArray, depth].join("-");
}

const ID = {
  install(Vue, options = {}) {
    Vue.directive("id", {
      bind: function (el, binding, vnode) {
        const dataKey = options.dataKey ? options.dataKey : "vid";
        const uniqueKey = [binding.value, "vid", vnode.tag];
        let depth = 0;

        applyIdToElement(el, dataKey, uniqueKey, depth);
        el.getElementsByTagName("*").forEach((child) => {
          depth++;
          applyIdToElement(child, dataKey, uniqueKey, depth);
        });
      },
    });
  },
};

export default ID;
