<template>
  <div class="component tms-runbuggy-switcher" v-if="showSwitch">
    <v-progress-circular
      :size="25"
      color="primary"
      indeterminate
      class="mr-3"
      v-if="isSwitchingTenant"
    ></v-progress-circular>
    
   
    <v-menu
      offset-y
      z-index="1001"
      >
        <template v-slot:activator="{ on, value }">
          <v-btn outlined color="primary" v-on="on" class="menu-btn">
            <div>
              <span class="user-menu-button d-flex align-center" v-if="selectedAccount">

                <template v-if="selectedAccount.tenantId === `runbuggy`">
                  <v-avatar color="primary" size="30" class="mr-3">
                    <img class="logo-img avatar-icon" alt="RB" src="../../assets/mini-logo.svg" />
                  </v-avatar>
                </template>
                <template v-else>
                  <order-tenant-details
                    class="align-self-center mr-5"
                    :tenant-name="selectedAccount.tenantName"
                    :tenant-id="selectedAccount.tenantId"
                    :with-hover="false" 
                    :show-name="false"
                  />
                </template>
                <span v-if="['xs', 'sm'].indexOf($mq) === -1">{{ selectedAccount.tenantName }}</span>
                <v-icon v-bind:class="{ 'mdi-rotate-180': value }"
                  >mdi-menu-down</v-icon
                >
              </span>
            </div>
          </v-btn>
        </template>
        <v-list class="py-0">
          <v-list-item 
            v-for="(item, index) in menuItems" 
            :key="`menuItem_${index}`"
            @click="onItemClick(item)">
            
            <v-list-item-title class="d-flex">
              <template v-if="item.tenantId === `runbuggy`">
                <v-avatar 
                  color="primary" 
                  size="20" 
                  class="mr-5">
                  <img class="logo-img avatar-icon" alt="RB" src="../../assets/mini-logo.svg" />
                </v-avatar>
              </template>
              <template v-else>
                <order-tenant-details
                  class="align-self-center mr-5"
                  :tenant-name="item.tenantName"
                  :tenant-id="item.tenantId"
                  :with-hover="false" 
                  :show-name="false"
                />
              </template>
              <span>
                {{item.tenantName}}
              </span>
            </v-list-item-title>
            
            <v-list-item-action v-if="item.tenantId === selectedAccount.tenantId">
              <v-icon small color="success">mdi-checkbox-marked-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
    </v-menu>
    
  </div>
</template>

<script type="text/babel">
import ROLES from "SC/constants/roles";

import OktaService from "@/services/okta";
import TokenService from "SC/services/tokenService";
import OrderTenantDetails from "@/components/order-tenant-details";
import EventBusConstants from "@/constants/eventBus";
import EventBus from "@/services/eventBus";

import { mapGetters } from "vuex";
import _get from "lodash/get";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _cloneDeep from "lodash/cloneDeep";
import _sortBy from "lodash/sortBy";
import _uniqBy from "lodash/uniqBy";

export default {
  name: "components-tms_runbuggy_switcher-index",
  watch: {},
  props: {
  },
  data() {
    return {
      ROLES,
      isSwitchingTenant: false,
    };
  },
  methods: {
    onItemClick(item) {
      if(_get(item, "tenantId") !== _get(this.selectedAccount, 'tenantId')) {
        this.switchTenantById(_get(item, "tenantId"));
      }
    },
    switchTenantById(id) {
      if(this.isSwitchingTenant) return;
      this.isSwitchingTenant = true;

      return OktaService.setTenantToken(id)
        .then((res) => {
          EventBus.$emit(EventBusConstants.SELECTED_TENANT_CHANGED, id);
          TokenService.clearSpecifiedRegistrationIds();
          window.location.reload();
        })
        .catch((e) => {
          console.error("setTenantToken: ", e);
        })
        .finally(() => {
          this.isSwitchingTenant = false;
        });
    },
  },
  computed: {
    marketplaceAccount() {
      return {
        tenantId: "runbuggy",
        tenantName: "RunBuggy",
        id: "runbuggy",
        name: "RunBuggy",
        type: "RUNBUGGY"
      };
    },
    menuItems() {
      let tmsAccounts = _cloneDeep(this.allTmsAccount) || [];
      if(this.hasMarketPlaceAccount)
        tmsAccounts.splice(0, 0, this.marketplaceAccount);

      return tmsAccounts;
    },
    selectedAccount() {
      let selectedAccount = _find(this.menuItems, {tenantId: _get(this.userData, "selectedTenantId")});
      return selectedAccount || this.marketplaceAccount;
    },
    allTmsAccount() {
      let tmsAccounts = [
          ..._get(this.userData, "accounts", []),
          ..._get(this.userData, "additionalAccounts", [])
        ]
        .filter((account) =>{
          return _get(account, 'tenantId', 'runbuggy').toLowerCase() !== "runbuggy";
        });

      return _sortBy(_uniqBy(tmsAccounts, "tenantId"), ["name"]);
    },
    hasMarketPlaceAccount() {
      return [
          ..._get(this.userData, "accounts", []),
          ..._get(this.userData, "additionalAccounts", [])
        ]
        .filter((account) =>{
          return _get(account, 'tenantId', 'runbuggy').toLowerCase() === "runbuggy";
        }).length > 0;
    },
    hasRunBuggyAccount() {
      return [
          ..._get(this.userData, "accounts", []),
          ..._get(this.userData, "additionalAccounts", [])
        ]
        .filter((account) =>{
          return _get(account, "type", "").toUpperCase() === "RUNBUGGY"
        }).length > 0;
    },
    isMarketplaceToggle() {
      return !this.hasRunBuggyAccount && this.allTmsAccount.length === 1 && this.hasMarketPlaceAccount;
    },
    showSwitch() {
      return _get(this.userData, "additionalAccounts", []).length > 0 ||
        (this.hasMarketPlaceAccount && this.allTmsAccount.length > 0);
    },
    ...mapGetters("user", [
      "userData",
    ]),
  },
  mounted() { 
  },
  mixins: [],
  components: {
    OrderTenantDetails
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .menu-btn{
    padding: 0 5px !important;
  }

  .avatar-icon {
    width: 25px;
    height: 25px;
    font-size: 28px;
  }
  
</style>
