import * as types from "./mutation-types";

// initial state
const state = {
  initialized: false,
  initializationPromise: undefined,
};

// getters
const getters = {
  isInitialized(state) {
    return state.initialized;
  },
  initializationPromise(state) {
    return state.initializationPromise;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  [types.INITIALIZED](state, bool) {
    state.initialized = bool;
  },
  [types.INITIALIZATION_PROMISE](state, promise) {
    state.initializationPromise = promise;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
