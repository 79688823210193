<template>
  <div v-if="loaded">
    <slot />
  </div>
</template>
<script type="text/babel">

import _get from "lodash/get";

function loadExternalComponent(url) {
  const name = url
      .split("/")
      .reverse()[0];

  if (window[name]) return window[name];

  window[name] = new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.async = true;

    script.addEventListener("load", () => {
      console.log("external: Loaded", window[name]);
      resolve();
    });
    script.addEventListener("error", () => {
      reject(new Error(`Error loading ${url}`));
    });

    script.src = url;
    document.head.appendChild(script);
  });

  return window[name];
}


export default {
  name: "components-messages_web_component_loader-index",
  data: function() {
    return {
      loaded: false
    }
  },
  computed: {
    libraryPath() {
      return _get(window, "CONFIG.messagingWebComponentPath");
    }
  },
  methods: {
    loadScript() {
      if (!this.libraryPath || this.libraryPath.trim().length === 0) {
        return;
      }

      loadExternalComponent(this.libraryPath)
          .then(() => {
            this.loaded = true;
          })
    },
  },
  mounted() {
    this.loadScript();
  }
}
</script>
