<template>
  <router-view></router-view>
</template>

<script type="text/babel">
export default {
  name: "components-router_view-index",
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
  created() {},
  mounted() {},
  mixins: [],
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
