<template>
  <div class="component tms-company-switcher">
    <v-menu :close-on-content-click="false" v-model="selectMenu">
      <v-sheet color="white">
        <div class="pa-10">
          <v-text-field
            class="d-flex align-center"
            :label="$i18n('common.label.searchCompanies', 'Search Companies')"
            outlined
            dense
            hide-details
            v-model="query"
            v-on:input="onQueryDebounced"
            autofocus
          />
        </div>
      </v-sheet>
      <v-list class="d-flex flex-column pt-0" min-width="530">
        <div style="max-height: 400px; overflow: auto">
          <template v-for="selectedAccountsItem in selectedAccountsItems">
            <v-list-item
              v-bind:key="`selected-accounts-item-${selectedAccountsItem.id}`"
              v-on:click="toggleSelectedAccount(selectedAccountsItem)"
              style="cursor: pointer"
            >
              <v-list-item-content>
                <div class="d-flex">
                  <div>
                    <v-checkbox
                      :ripple="false"
                      v-on:click="toggleSelectedAccount(selectedAccountsItem)"
                      v-model="selectedAccounts"
                      :value="selectedAccountsItem.id"
                    >
                    </v-checkbox>
                  </div>
                  <div class="flex-grow-1">
                    <company-list-item
                      :hide-action="true"
                      :item="selectedAccountsItem"
                    ></company-list-item>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-divider />

          <template v-for="item in itemsFiltered">
            <v-list-item
              v-bind:key="`items-filtered-${item.id}`"
              v-on:click="toggleSelectedAccount(item)"
              style="cursor: pointer"
            >
              <v-list-item-content>
                <div class="d-flex">
                  <div>
                    <v-checkbox
                      :ripple="false"
                      v-on:click="toggleSelectedAccount(item)"
                      v-model="selectedAccounts"
                      :value="item.id"
                    >
                    </v-checkbox>
                  </div>
                  <div class="flex-grow-1">
                    <company-list-item
                      :hide-action="true"
                      :item="item"
                    ></company-list-item>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>

          <div v-if="!endReached" v-intersect="onEndOfList" class="pa-4">
            <v-progress-circular indeterminate />
          </div>
        </div>
        <div class="d-flex pa-2">
          <div class="flex-grow-1"></div>
          <div>
            <v-btn
              depressed
              small
              color="primary"
              v-on:click="apply"
              :disabled="isApplyDisabled"
            >
              {{ $i18n("common.label.apply", "Apply") }}
            </v-btn>
          </div>
        </div>
      </v-list>
      <template v-slot:activator="{ on }">
        <div class="activator d-flex">
          <div class="flex-grow-1 d-flex align-center" v-on="on">
            <div class="placeholder">
              {{ $i18n("common.label.selectCompanies", "Select Companies") }}
              <template v-if="selectedAccounts.length > 0">
                ({{ selectedAccounts.length }})
              </template>
            </div>
          </div>
          <div class="d-flex align-center">
            <v-btn small v-on:click="onClickClear" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="d-flex align-center pr-2">
            <v-btn small v-on="on" icon>
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
    </v-menu>
  </div>
</template>

<script type="text/babel">
import TokenService from "SC/services/tokenService";
import _findLast from "lodash/findLast";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import _replace from "lodash/replace";
import { mapGetters } from "vuex";
import AccountsApiService from "SC/services/accountsApi";
import _uniq from "lodash/uniq";
import _filter from "lodash/filter";
import _includes from "lodash/includes";
import CompanyListItem from "@/components/forms/account-management/authorized-shippers/add-authorized/list-item";
import _debounce from "lodash/debounce";
import AccountTypes from "@/constants/accountTypes";
import _isEmpty from "lodash/isEmpty";
import _remove from "lodash/remove";

export default {
  name: "components-intercompany_switcher-index",
  watch: {
    selectMenu() {
      if (this.selectMenu === false && this.isDirty) {
        this.apply();
      }
    },
  },
  props: {},
  data() {
    return {
      selectedAccounts: [],
      selectedAccountsItems: [],
      items: [],
      currentPage: 0,
      pageSize: 10,
      isSearching: false,
      selectMenu: false,
      isDirty: false,
      query: "",
      totalItems: 0,
      allIntercompaniesCount: 0,
    };
  },
  methods: {
    refreshSelected() {
      if (this.selectedAccounts.length === 0) {
        this.selectedAccountsItems = [];
        return;
      }

      let paramQuery = {
        query: `id=in=(${this.selectedAccounts.join(",")});tenantId==${
          this.tenantId
        }`,
      };

      return AccountsApiService.getAccounts(0, 10000, null, paramQuery)
        .then((result) => {
          this.selectedAccountsItems = _get(result, "content", []);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    },
    onQueryDebounced: _debounce(function (query) {
      this.currentPage = 0;
      this.items = [];
      this.querySelections(this.query, this.currentPage);
    }, 500),
    onEndOfList() {
      if (this.isSearching) {
        return;
      }

      if (this.endReached) {
        return;
      }

      this.currentPage++;
      this.querySelections(this.query, this.currentPage);
    },
    apply() {
      TokenService.setSpecifiedRegistrationIds(this.selectedAccounts);
      TokenService.setIntercompaniesCount(this.allIntercompaniesCount);
      TokenService.setSpecifiedRegistrationIdsForTenantId(
        this.selectedAccounts,
        this.tenantId
      );

      window.location.reload();
    },

    toggleSelectedAccount(account) {
      if (this.selectedAccounts.indexOf(account.id) > -1) {
        this.selectedAccounts = _remove(this.selectedAccounts, (accountId) => {
          return accountId !== account.id;
        });
      } else {
        this.selectedAccounts.push(account.id);
      }

      this.refreshSelected();
      this.isDirty = true;
    },
    onClickClear() {
      TokenService.setSpecifiedRegistrationIds([]);
      TokenService.setSpecifiedRegistrationIdsForTenantId([], this.tenantId);
      TokenService.setIntercompaniesCount(this.allIntercompaniesCount);
      window.location.reload();
    },
    querySelections(query, page = 0) {
      this.isSearching = true;
      query = _replace(query, this.allowedSearchCharsRegex, "");
      query = _replace(query, / /g, ".*");
      let searchQuery = `name=re=.*${query}.*`;
      let paramQuery = {
        query: `${searchQuery};type=in=(${AccountTypes.SHIPPER},${AccountTypes.AUCTION});tenantId==${this.tenantId}`,
      };

      return AccountsApiService.getAccounts(
        page,
        this.pageSize,
        null,
        paramQuery
      )
        .then((result) => {
          this.totalItems = result.totalElements;
          if (page === 0) {
            this.items = _get(result, "content", []);
          } else {
            this.items = [...this.items, ..._get(result, "content", [])];
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.isSearching = false;
        });
    },
  },
  computed: {
    itemsFiltered() {
      let unique = [];

      return this.items
        .filter((item) => {
          return this.selectedAccounts.indexOf(item.id) === -1;
        })
        .filter((item) => {
          if (unique.indexOf(item.id) > -1) {
            return false;
          }

          unique.push(item.id);
          return true;
        });
    },
    endReached() {
      return this.items.length >= this.totalItems;
    },
    isApplyDisabled() {
      return !this.isDirty;
    },
    allowedSearchCharsRegex() {
      return new RegExp(/[^0-9a-zA-Z_@.() -]/g);
    },
    ...mapGetters("user", [
      "tenantId",
      "userRoles",
      "userName",
      "userData",
      "hasShipperRole",
      "hasAuctionRole",
    ]),
  },
  created() {
    this.selectedAccounts = TokenService.getSpecifiedRegistrationIds() || [];
    this.refreshSelected();
    this.querySelections(this.query).then(() => {
      this.allIntercompaniesCount = TokenService.getIntercompaniesCount();
    });
  },
  mounted() {},
  mixins: [],
  components: {
    CompanyListItem,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tms-company-switcher {
  .activator {
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: rgba(0, 0, 0, 0.42);
    cursor: pointer;

    .placeholder {
      padding: 8px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

.switcher {
  height: 32px !important;
}

.select-all-label {
  font-size: 0.8125rem;
  font-weight: 500;
}

.clear-all {
  position: absolute;
  right: -0.5em;
  top: -0.25em;
  font-size: 1.5em;
}
</style>
