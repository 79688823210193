const mixin = {
  mounted() {
    if (this.$options.name) {
      try {
        this.$el.setAttribute("data-component-name", this.$options.name);
      } catch (e) {}
    }
  },
};

//- export it as a plugin
export default {
  install(Vue, options) {
    Vue.mixin(mixin);
  },
};
