export default {
  RUNBUGGY: "RUNBUGGY",
  TMS: "TMS",
  COMMERCIAL: "SHIPPER",
  SHIPPER: "SHIPPER",
  DEALER: "SHIPPER",
  PRIVATE: "PRIVATE",
  AUCTION: "AUCTION",
  TRANSPORTER: "TRANSPORTER",
  CUSTOMER: "CUSTOMER"
};
