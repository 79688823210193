<template>
  <div class="component">
    <v-dialog v-model="value" persistent max-width="650" class="dialog-container">
      <div class="dialog-content">
        <div class="dialog-card">
          <div style="float: right; margin: 5px">
            <v-btn depressed icon @click="close" v-if="!(mustAccept && hasTransporterAccountOwnerRole)">
              <v-icon small color="#999999">fa-times</v-icon>
            </v-btn>
          </div>
          <div class="header">
            <v-row class="icon-row">
              <v-col cols="12" class="text-center pb-2">
                <v-avatar
                  color="#FFFFFF"
                  size="100"
                  class="avatar-header pa-10"
                >
                  <v-avatar color="orange" size="80">
                    <v-icon large color="white"> mdi-exclamation-thick</v-icon>
                  </v-avatar>
                </v-avatar>
              </v-col>
            </v-row>

            <v-row class="mt-5">
                <v-col cols="12" class="text-center pt-5">
                  <span
                    class="
                      headline
                      font-weight-bold
                      text-center
                    "
                    > 
                      {{ 
                        $i18n(
                          'termsOfService.title',
                          'Terms of Service'
                        )
                      }}
                  </span>
                </v-col>
            </v-row>
          </div>
          <v-card-text>
            <div class="component d-flex flex-column">
              <div class="px-10 pt-4">
                <template v-if="mustAccept && hasTransporterAccountOwnerRole">
                  <p>
                    {{ 
                      $i18n(
                        'termsOfService.message.owner',
                        'To continue, please read and accept our Terms of Service.'
                      )
                    }}
                  </p>
                </template>
                <template v-else>
                  <p>
                    {{ 
                      $i18n(
                        'termsOfService.message.nonOwner',
                        'To continue, the account owner will need to accept our Terms of Service.'
                      )
                    }}
                  </p>
                </template>
              </div>

              <div class="flex-grow-1 pa-8 white">
                <div class="pa-3" v-for="accountType in accountTypes" :key="accountType">
                  <v-btn block href="https://runbuggy.com/terms-full/" target="_blank" v-if="accountType === 'runbuggy'">
                    {{ 
                      $i18n(
                        'termsOfService.link.runbuggy',
                        'RunBuggy Marketplace Terms of Service'
                      )
                    }}
                  </v-btn>
                  <v-btn block href="https://runbuggy.com/hitch-terms-full/" target="_blank" v-if="accountType === 'tms'">
                    {{ 
                      $i18n(
                        'termsOfService.link.hitch',
                        'HITCH Partner Carrier Terms of Service'
                      )
                    }}
                  </v-btn>
                </div>

                <div class="px-10 pt-4" v-if="!(mustAccept && hasTransporterAccountOwnerRole)">
                  <p>
                    {{ 
                      $i18n(
                        'termsOfService.message.additionalInfo',
                        '*You are not the account owner. You will not be able to accept these terms.'
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </v-card-text>

          <div class="d-flex pa-4 border-top" v-if="mustAccept && hasTransporterAccountOwnerRole">
            <div class="flex-grow-1">
              <template v-if="!isLoading">
                <v-btn 
                  outlined
                  class="secondary-btn" 
                  v-on:click="cancel">
                  {{ 
                    $i18n(
                      'common.label.decline',
                      'Decline'
                    )
                  }}
                </v-btn>
              </template>
            </div>
            <div>
              <template v-if="mustAccept">
                <v-btn 
                  depressed
                  class="primary-btn"
                  v-bind:loading="isLoading"
                  v-on:click="accept">
                  {{ 
                    $i18n(
                      'common.label.accept',
                      'Accept'
                    )
                  }}
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  depressed
                  class="primary-btn"
                  v-on:click="close">
                  {{ 
                    $i18n(
                      'common.label.continue',
                      'Continue'
                    )
                  }}
                </v-btn>
              </template>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script type="text/babel">
import OktaService from "@/services/okta";
import IntercomService from "@/services/intercom";
import TokenService from "SC/services/tokenService";
import AccountsService from "SC/services/accountsApi";
import {mapGetters} from "vuex";
import _get from 'lodash/get'
import NotificationConstants from "@/constants/notifications";


export default {
  name: "components-modal_user_roles_changed-index",
  watch: {},
  props: {
    value: undefined
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    setAccept() {
      this.isLoading = true;

      let promises = this.mustAcceptAccounts.map((account) => {
        return AccountsService.acceptTerms(account.id);
      });

      return Promise.all(promises)
          .then(() => {
            return OktaService.renewAccessToken()
          })
          .then(() => {
            window.location.reload();
          })
          .catch((e) => {
            this.$notify({
              type: 'error',
              title: 'Something went wrong',
              text: e.message,
              duration: NotificationConstants.DURATION,
              speed: 1000
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    logout() {
      if (TokenService.hasToken() || TokenService.hasImpersonationToken()) {
        IntercomService.shutdown();
        TokenService.clearAll();
      }

      OktaService.unauthenticate().then(() => {
      });
    },
    cancel() {
      this.logout();
    },
    accept() {
      this.setAccept();
    },
    close() {
      this.$emit("input", false);
    }
  },
  computed: {
    accountTypes() {
      let types = [];

      this.accountsNeedToAcceptTerms.forEach((account) => {
        let type;

        if (account.tenantId === 'runbuggy') {
          type = 'runbuggy';
        } else {
          type = 'tms';
        }

        if (types.indexOf(type) === -1) {
          types.push(type);
        }
      })

      return types;
    },
    mustAccept() {
      return this.mustAcceptAccounts.length > 0 && !this.isImpersonating;
    },
    termsPageSource() {
      return `https://runbuggy.com/hitch-terms-full/`;
    },
    mustAcceptAccounts() {
      return this.accountsNeedToAcceptTerms
          .filter((account) => {
            let isFormOfAdmin = false;

            ['TMS_ADMIN', 'TRIAL_ADMIN', 'ADMIN']
                .forEach((role) => {
                  if (_get(account, 'roles', []).indexOf(role) > -1) {
                    isFormOfAdmin = true;
                  }
                });

            return isFormOfAdmin;
          });
    },
    ...mapGetters("user", [
      "accountsNeedToAcceptTerms",
      "isImpersonating",
      "hasTransporterAccountOwnerRole"
    ]),
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/variables";
@import "../../scss/variables";

.success-login-icon {
  color: $color-COMPLETED;
  font-size: 50px;
}

.iframe-terms {
  border: none;
  width: 100%;
  height: 100%;
  min-height: 600px;
}


.dialog-container {
  overflow: visible;
}

.dialog-content {
  background-color: #ffffff;

  .dialog-card {
    .header {
      padding: 30px 30px 0;
    }
  }
}
.avatar-header {
  position: absolute;
  bottom: calc(100% - 25px);
  left: calc(50% - 40px);
  right: auto;
  top: auto;
}
.icon-row {
  position: relative;
}

</style>
<style lang="scss">
.v-dialog {
  overflow: visible !important;
}
</style>
