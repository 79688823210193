import {Service as CamundaApiService} from './camundaApi'
import Client from "../clients/camundaIntegrationsApi";

export class Service extends CamundaApiService {
    constructor() {
        super();
        this.client = Client;
    }
}

const service = new Service();

export default service;
