import _get from 'lodash/get'
import CamundaAPIClient from '../clients/camundaApi'
import TokenService from '../services/tokenService'
import _each from 'lodash/each'
import Double from "../classes/double";

export class Service {
    constructor() {
        this.client = CamundaAPIClient;
    }

    camundaUrl() {
        return this.client.createPath();
    }

    variableInstanceDataPath(taskId, variableName) {
        let path = window.location.href.split('#').shift();
        let token = TokenService.getToken();


        if (this.client.createPath()[0] === '/') {
            console.log('services.camundaApi.variableInstanceDataPath', 'API path starts from root');
            path = window.location.origin;
        }

        let result = `${path}${this.client.createPath()}/task/${taskId}/variables/${variableName}/data?access_token=${token}`;

        return result;
    }

    deserializeInstanceVariables(variables) {
        let deserialized = {};

        _each(variables, (variable, key) => {
            switch (variable.type) {
                case "Json":
                    variable.value = this.deserializeInstanceVariable(variable.value, variable.type);
                    break;
            }
            deserialized[key] = variable;
        });

        return deserialized;
    }

    deserializeInstanceVariable(value, type) {
        switch (type) {
            case "Json":
                try {
                    value = JSON.parse(value);
                } catch (e) {
                    console.error('services.camundaApi.deserializeInstanceVariables', e);
                }
                break;
        }

        return value;
    }

    encodeVariable(value) {
        let variableValue = {};

        if (value === null) {
            return variableValue;
        }

        switch (typeof value) {
            case 'boolean':
                variableValue.type = 'boolean';
                variableValue.value = value;
                break;
            case 'string':
                variableValue.type = 'string';
                variableValue.value = value;
                break;
            case 'number':
                if (value % 1 === 0) {
                    variableValue.type = 'integer';
                } else {
                    variableValue.type = 'double';
                }

                variableValue.value = value;
                break;
            case 'object':
                if (value instanceof Double) {
                    variableValue.type = 'double';
                    variableValue.value = parseFloat(value);
                } else if (value.hasOwnProperty('fileData')) {
                    variableValue.type = 'file';
                    variableValue.value = value.fileData;
                    variableValue.valueInfo = {
                        filename: _get(value, 'fileName'),
                        mimeType: _get(value, 'fileMimeType')
                    };
                } else if (value.hasOwnProperty('byteArray')) {
                    variableValue.type = 'file';

                    let binary = '';
                    let bytes = value.byteArray;
                    let len = bytes.byteLength;
                    for (let i = 0; i < len; i++) {
                        binary += String.fromCharCode(bytes[i]);
                    }

                    variableValue.value = binary;
                    variableValue.valueInfo = {
                        filename: _get(value, 'fileName'),
                        mimeType: _get(value, 'mime'),
                        encoding: "Base64"
                    };
                } else if (value.hasOwnProperty('base64File')) {
                    variableValue.type = 'file';

                    variableValue.value = _get(value, 'base64File');
                    variableValue.valueInfo = {
                        filename: _get(value, 'fileName'),
                        mimeType: _get(value, 'mime'),
                        encoding: "Base64"
                    };
                } else {
                    variableValue.type = 'json';
                    variableValue.value = JSON.stringify(value);
                }
                break;
        }

        return variableValue;
    }

    getFilters(options) {
        return this.client.getFilters(options)
            .then((res) => {
                return res.data;
            });
    }

    getFilterTasks(filterId, query, params) {
        return this.client.getFilterTasks(filterId, query, params)
            .then((res) => {
                let count = _get(res, 'data.count')
                let tasks = _get(res, 'data._embedded.task', []);

                tasks = tasks.map((task) => {
                    let variables = _get(task, '_embedded.variable', []);
                    variables = this.deserializeInstanceVariables(variables);
                    // task.variables = variables;

                    _each(variables, (variable, index) => {
                        task[variable.name] = variable.value;
                    });

                    return task;
                });

                // return tasks;
                return {
                    tasks,
                    count,
                }
            });
    }

    getTaskVariableData(taskId, variableName) {
        return this.client.getTaskVariableData(taskId, variableName)
            .then((res) => {
                console.log('services.camundaApi.getTaskVariableData', res);
            });
    }

    getTasks(filter) {
        return this.client.getTasks(filter)
            .then((res) => {
                return res.data;
            });
    }

    getFilterTasksCount(filterId, query) {
        return this.client.getFilterTasksCount(filterId, query)
            .then((res) => {
                return res.data;
            });
    }

    getTasksCount(filter) {
        return this.client.getTasksCount(filter)
            .then((res) => {
                return res.data;
            });
    }

    getProcessDefinitionById(processKey) {
        return this.client.getProcessDefinitionById(processKey)
            .then((res) => {
                return res.data;
            });
    }

    getProcessDefinitionByKey(processKey) {
        return this.client.getProcessDefinitionByKey(processKey)
            .then((res) => {
                return res.data;
            });
    }

    getDecisionRequirementDefinitions(key) {
        return this.client.getDecisionRequirementDefinitions(key)
            .then((res) => {
                return res.data;
            });
    }

    getDecisionDefinitions(key) {
        return this.client.getDecisionDefinitions(key)
            .then((res) => {
                return res.data;
            });
    }

    getDecisionRequirementDefinitionXML(id) {
        return this.client.getDecisionRequirementDefinitionXML(id)
            .then((res) => {
                return res.data;
            });
    }

    getDecisionDefinitionXML(id) {
        return this.client.getDecisionDefinitionXML(id)
            .then((res) => {
                return res.data;
            });
    }

    deployDefinition(name, xml) {
        return this.client.deployDefinition(name, xml)
            .then((res) => {
                return res.data;
            });
    }

    removeDeploymentWithID(id) {
        return this.client.removeDeploymentWithID(id)
            .then((res) => {
                return res.data;
            });
    }


    claimTask(taskId, userId) {
        return this.client.claimTask(taskId, userId)
            .then((res) => {
                return res.data;
            });
    }

    unclaimTask(taskId) {
        return this.client.unclaimTask(taskId)
            .then((res) => {
                return res.data;
            });
    }

    getTask(taskId) {
        return this.client.getTask(taskId)
            .then((res) => {
                return res.data;
            });
    }

    createTaskComment(taskId, message) {
        return this.client.createTaskComment(taskId, message)
            .then((res) => {
                return res.data;
            });
    }

    getTaskHistory(taskId) {
        return this.client.getTaskHistory(taskId)
            .then((res) => {
                return res.data;
            });
    }

    getTaskComments(taskId) {
        return this.client.getTaskComments(taskId)
            .then((res) => {
                return res.data;
            });
    }


    updateTask(taskId, data) {
        return this.client.updateTask(taskId, data)
            .then((res) => {
                return res.data;
            });
    }

    getTaskForm(taskId) {
        return this.client.getTaskForm(taskId)
            .then((res) => {
                return res.data;
            });
    }

    getProcessStartFormKey(definitionId) {
        return this.client.getProcessStartFormKey(definitionId)
            .then((res) => {
                return res.data;
            });
    }

    startProcessInstanceId(definitionId, body = {}) {
        return this.client.startProcessInstanceId(definitionId, body)
            .then((res) => {
                return res.data;
            });
    }

    submitFormOnProcessDefinitionById(processId, data) {
        return this.client.submitFormOnProcessDefinitionById(processId, data)
            .then((res) => {
                return res.data;
            });
    }

    startProcessInstanceKey(definitionKey, body = {}) {
        return this.client.startProcessInstanceKey(definitionKey, body)
            .then((res) => {
                return res.data;
            });
    }

    uploadBatchOrderFile(file, templateName, paymentBy, limitPerOrder = 1000) {
        return this.client.uploadBatchOrderFile(file, limitPerOrder, paymentBy, templateName)
            .then((res) => {
                return res.data;
            });
    }

    getDeploymentResources(deploymentId) {
        return this.client.getDeploymentResources(deploymentId)
            .then((res) => {
                return res.data;
            });
    }

    getDeploymentResourceData(deploymentId, resourceId) {
        return this.client.getDeploymentResourceData(deploymentId, resourceId)
            .then((res) => {
                return res.data;
            });
    }

    getProcessInstance(instanceID) {
        return this.client.getProcessInstance(instanceID)
            .then((res) => {
                return res.data;
            });
    }

    getProcessInstanceHistory(instanceID) {
        return this.client.getProcessInstanceHistory(instanceID)
            .then((res) => {
                return res.data;
            });
    }

    getProcessInstanceHistoryCount(params = {}) {
        return this.client.getProcessInstanceHistoryCount(params)
            .then((res) => {
                return res.data;
            });
    }

    getProcessInstanceVariables(instanceID) {
        return this.client.getProcessInstanceVariables(instanceID)
            .then((res) => {
                return res.data;
            });
    }

    getUserOperationLog(instanceID) {
        return this.client.getUserOperationLog(instanceID)
            .then((res) => {
                return res.data;
            });
    }

    getUserOperationLogTask(taskId) {
        return this.client.getUserOperationLogTask(taskId)
            .then((res) => {
                return res.data;
            });
    }

    getProcessInstanceHistoryDetails(instanceID) {
        return this.client.getProcessInstanceHistoryDetails(instanceID)
            .then((res) => {
                return res.data;
            });
    }

    getProcessInstanceTasks(instanceID) {
        return this.client.getProcessInstanceTasks(instanceID)
            .then((res) => {
                return res.data;
            });
    }

    postTaskForm(taskId, body) {
        return this.client.postTaskForm(taskId, body)
            .then((res) => {
                return res.data;
            });
    }

    getProcessInstanceList(query) {
        return this.client.getProcessInstanceList(query)
            .then((res) => {
                return res.data;
            });
    }

    getHistoryProcessInstanceList(query) {
        return this.client.getHistoryProcessInstanceList(query)
            .then((res) => {
                return res.data;
            });
    }

    postMessage(messageName, processInstanceId, processVariables) {
        return this.client.postMessage({
            messageName: messageName,
            processInstanceId: processInstanceId,
            processVariables: processVariables
        })
            .then((res) => {
                return res.data;
            });
    }
}

const service = new Service();
export default service;
