<template>
  <div class="component d-flex py-2">
    <div class="orderButton px-5">
      <a :href="`#/orders/${resourceTypePath}/${transportationOrderId}`" target="_blank">
        {{ referenceNumber }} <v-icon class="ml-3" small>mdi-open-in-new</v-icon>
      </a>
      </div>
  </div>
</template>
<script type="text/babel">
import Vue from "vue";
import _get from "lodash/get";

export default Vue.extend({
  name: "components-batch_operation_status-grid_row_reference_number_renderer-index",
  components: {},
  data() {
    return {};
  },
  computed: {
    transportationOrderId() {
      return _get(this.params, "data.batchItemRequest.resourceId", "");
    },
    referenceNumber() {
      return _get(this.params, "value", "");
    },
    resourceTypePath() {
      return _get(this.params, "data.batchItemRequest.resourceType", "TransportationOrder") === "TransportationOrder" ? "transporter" : "shipper";
    }
  },
  methods: {},
  created() {}
});
</script>
<style lang="scss" scope>
</style>
