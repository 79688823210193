<template>
  <div class="component router-content">
    <toasts />
    <router-view></router-view>
  </div>
</template>

<script type="text/babel">
import Toasts from "@/components/toasts";

export default {
  name: "layouts-workflows-index",
  watch: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {
    Toasts,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "SC/scss/mq-mixins";
@include sm-down {
  .router-content {
    padding-bottom: 80px !important;
  }
}
</style>
