import * as types from "./mutation-types";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import _includes from "lodash/includes";
import _intersection from "lodash/intersection";
import _uniq from "lodash/uniq";
import ROLES from "SC/constants/roles";

// initial state
const state = {
  userId: undefined, // deprecated
  token: undefined,
  isImpersonating: false,
  impersonatorUsername: undefined,
  userData: undefined,
  userTasksConfig: undefined,
  temporaryPassword: undefined,
  authState: {},
  featureAccessKeys: [],
  uamCompanyData: undefined,
  selectedTenant: undefined,
  activeTheme: undefined,
};

// getters
const getters = {
  authState(state) {
    return state.authState;
  },
  token(state) {
    return state.token;
  },
  userData(state) {
    return state.userData;
  },
  userTasksConfig(state) {
    return state.userTasksConfig;
  },
  companyData(state) {
    return _get(state.selectedTenant, "id", "runbuggy") === "runbuggy"
      ? state.uamCompanyData
      : state.selectedTenant;
  },
  uamCompanyData(state) {
    return state.uamCompanyData;
  },
  selectedTenant(state) {
    return state.selectedTenant;
  },
  temporaryPassword(state) {
    return state.temporaryPassword;
  },
  isImpersonating(state) {
    return state.isImpersonating;
  },
  impersonatorUsername(state) {
    return state.impersonatorUsername;
  },
  isTemporaryPasswordSet(state) {
    return _get(state.userData, "isTemporaryPasswordSet", false);
  },
  isDriverOnly(state, getters) {
    let driverRoles = [];
    let nonDriverRoles = [];

    getters.userRoles.forEach((role) => {
      if (
        ["ROLE_TRANSPORTER_TRIAL_DRIVER", "ROLE_TRANSPORTER_DRIVER"].indexOf(
          role
        ) === -1
      ) {
        nonDriverRoles.push(role);
      } else {
        driverRoles.push(role);
      }
    });

    return nonDriverRoles.length === 0 && driverRoles.length > 0;
  },
  userRoles(state) {
    return _get(state.userData, "userRoles", []);
  },
  additionalUserRoles(state) {
    return _get(state.userData, "additionalUserRoles", []);
  },
  userGroups(state) {
    return _get(state.userData, "userGroups", []);
  },
  userMainRole(state, getters) {
    return getters.userRoles[0];
  },
  hasAdminRole(state, getters) {
    return (
      _intersection(
        [
          ROLES.RUNBUGGY_SUPER_ADMIN,
          ROLES.RUNBUGGY_ADMIN,
          ROLES.RUNBUGGY_OPERATIONS,
        ],
        getters.userRoles
      ).length > 0
    );
  },
  hasAnyTmsRole(state, getters) {
    return (
      _intersection([ROLES.TMS_ADMIN, ROLES.TMS_USER], getters.userRoles)
        .length > 0
    );
  },
  hasDealerRole(state, getters) {
    return getters.userRoles.indexOf(ROLES.DEALER) > -1;
  },
  hasAuctionHouseRole(state, getters) {
    return getters.userRoles.indexOf(ROLES.AUCTION_HOUSE) > -1;
  },
  hasDriverRole(state, getters) {
    return getters.userRoles.indexOf(ROLES.DRIVER) > -1;
  },
  hasIntercompanyRole(state, getters) {
    return true;
    // TODO: Anyone can have intercompany / enterprise
    // return getters.userRoles.indexOf(ROLES.INTERCOMPANY) > -1;
  },
  ///===========================================================///
  hasRunbuggyRole(state, getters) {
    return getters.userRoles.some((u) => u.includes("RUNBUGGY"));
  },
  hasTmsRole(state, getters) {
    return getters.userRoles.some((u) => u.includes("TMS"));
  },
  hasShipperRole(state, getters) {
    return getters.userRoles.some((u) => u.includes("SHIPPER"));
  },
  hasPrivateShipperRole(state, getters) {
    return getters.userRoles.some((u) => u.includes("PRIVATE"));
  },
  hasManagerRole(state, getters) {
    return getters.userRoles.some((u) => u.includes("MANAGER"));
  },
  hasAuctionRole(state, getters) {
    return getters.userRoles.some((u) => u.includes("AUCTION"));
  },
  hasTransporterRole(state, getters) {
    return getters.userRoles.some((u) => u.includes("TRANSPORTER"));
  },
  hasTransporterAccountOwnerRole(state, getters) {
    let contacts = _get(getters, "uamCompanyData.contacts", []);
    let primaryContact = contacts.find((contact) => contact.type === "primary");
    return (
      (getters.hasTransporterAdminTrialRole ||
        getters.hasTransporterAdminRole) &&
      primaryContact &&
      _get(primaryContact, "email").toLowerCase() ===
        _get(getters, "userData.email").toLowerCase()
    );
  },
  hasRunbuggySuperAdminRole(state, getters) {
    return getters.userRoles.includes(ROLES.RUNBUGGY_SUPER_ADMIN);
  },
  hasRunbuggyAdminRole(state, getters) {
    return getters.userRoles.includes(ROLES.RUNBUGGY_ADMIN);
  },
  hasRunbuggyOperationsRole(state, getters) {
    return getters.userRoles.includes(ROLES.RUNBUGGY_OPERATIONS);
  },
  hasAuctionAdminRole(state, getters) {
    return getters.userRoles.includes(ROLES.AUCTION_ADMIN);
  },
  hasAuctionAdminTrialRole(state, getters) {
    return getters.userRoles.includes(ROLES.AUCTION_ADMIN_TRIAL);
  },
  hasShipperAdminRole(state, getters) {
    return getters.userRoles.includes(ROLES.SHIPPER_ADMIN);
  },
  hasShipperAdminTrialRole(state, getters) {
    return getters.userRoles.includes(ROLES.SHIPPER_ADMIN_TRIAL);
  },
  hasTransporterAdminRole(state, getters) {
    return getters.userRoles.includes(ROLES.TRANSPORTER_ADMIN);
  },
  hasTransporterAdminTrialRole(state, getters) {
    return getters.userRoles.includes(ROLES.TRANSPORTER_ADMIN_TRIAL);
  },
  hasTransporterDriverRole(state, getters) {
    return getters.userRoles.includes(ROLES.TRANSPORTER_DRIVER);
  },
  hasTransporterDriverTrialRole(state, getters) {
    return getters.userRoles.includes(ROLES.TRANSPORTER_DRIVER_TRIAL);
  },
  hasTransporterBrokerRole(state, getters) {
    return getters.userRoles.includes(ROLES.TRANSPORTER_BROKER);
  },
  hasPricingAdminRole(state, getters) {
    return getters.userRoles.includes(ROLES.TMS_PRICING_ADMIN);
  },
  hasPaymentAdminRole(state, getters) {
    return getters.userGroups.includes(ROLES.PAYMENT_ADMIN);
  },
  hasIntegrationAdminRole(state, getters) {
    return getters.userRoles.includes(ROLES.INTEGRATION_ADMIN);
  },
  hasIntegrationUserRole(state, getters) {
    return getters.userRoles.includes(ROLES.INTEGRATION_USER);
  },
  hasViewConfigAdminRole(state, getters) {
    return getters.userGroups.includes(ROLES.VIEW_CONFIG_ADMIN);
  },
  hasFinanceAdminRole(state, getters) {
    return getters.userGroups.includes(ROLES.FINANCE_ADMIN);
  },
  hasFinanceReviewRole(state, getters) {
    return getters.userGroups.includes(ROLES.FINANCE_REVIEW);
  },
  hasTMSAdminRole(state, getters) {
    return getters.userGroups.includes(ROLES.TMS_ADMIN);
  },
  hasTMSAdminTrialRole(state, getters) {
    return getters.userRoles.includes(ROLES.TMS_ADMIN_TRIAL);
  },
  hasTMSUserRole(state, getters) {
    return getters.userRoles.includes(ROLES.TMS_USER);
  },
  hasTMSHitchRole(state, getters) {
    return getters.userRoles.includes(ROLES.TMS_HITCH);
  },
  hasTMSPricingAdminRole(state, getters) {
    return getters.userRoles.includes(ROLES.TMS_PRICING_ADMIN);
  },
  hasAuctionHouseAdminRole(state, getters) {
    return getters.userRoles.includes(ROLES.AUCTION_ADMIN);
  },
  hasAuctionHouseAdminTrialRole(state, getters) {
    return getters.userRoles.includes(ROLES.AUCTION_ADMIN_TRIAL);
  },
  hasAuctionHouseUserTrialRole(state, getters) {
    return getters.userRoles.includes(ROLES.AUCTION_USER_TRIAL);
  },
  hasShipperUserRole(state, getters) {
    return getters.userRoles.includes(ROLES.SHIPPER_USER);
  },
  hasShipperUserTrialRole(state, getters) {
    return getters.userRoles.includes(ROLES.SHIPPER_USER_TRIAL);
  },
  hasShipperManagerRole(state, getters) {
    return getters.userRoles.includes(ROLES.SHIPPER_MANAGER);
  },
  hasShipperManagerTrialRole(state, getters) {
    return getters.userRoles.includes(ROLES.SHIPPER_MANAGER_TRIAL);
  },
  hasShipperBusinessUnitManagerRole(state, getters) {
    return getters.userRoles.includes(ROLES.SHIPPER_BUSINESS_UNIT_MANAGER);
  },
  hasShipperBusinessUnitManagerTrialRole(state, getters) {
    return getters.userRoles.includes(
      ROLES.SHIPPER_BUSINESS_UNIT_MANAGER_TRIAL
    );
  },
  hasTransporterUserTrialRole(state, getters) {
    return getters.userRoles.includes(ROLES.TRANSPORTER_USER_TRIAL);
  },
  hasTrialRole(state, getters) {
    return getters.userRoles.some((u) => u.includes("TRIAL"));
  },
  hasFeatureShipper(state, getters) {
    return getters.userGroups.some((u) => u.includes("ROLE_SHIPPER"));
  },
  isDefaultAccountTrial(state, getters) {
    let defaultAccount = getters.accounts.filter((account) => account.default);
    defaultAccount = _get(defaultAccount, "0.id")
      ? _get(defaultAccount, "0")
      : _get(state.userData, "accounts.0");
    return _get(defaultAccount, "isTrial", false);
  },
  userSecurityDependentsTimestamp() {
    return _get(state.userData, "securityHash.userDependentsTimestamp");
  },
  userSecurityEntityHash() {
    return _get(state.userData, "securityHash.userEntityHash");
  },
  userId(state) {
    return _get(state.userData, "username");
  },
  firstName(state) {
    return _get(state.userData, "firstName");
  },
  lastName(state) {
    return _get(state.userData, "lastName");
  },
  userName(state) {
    return _get(state.userData, "username");
  },
  fullName(state, getters) {
    return `${getters.firstName} ${getters.lastName}`;
  },
  hasSubAccounts(state, getters) {
    return _get(state.userData, "hasSubAccounts");
  },
  hasMarketPlaceAccount(state) {
    let allAccounts = _get(state, "userData.accounts", []).concat(
      _get(state, "userData.additionalAccounts", [])
    );
    let filterdAccounts = allAccounts.filter(
      (account) =>
        _get(account, "tenantId", "runbuggy").toLowerCase() === "runbuggy"
    );
    return filterdAccounts.length > 0;
  },
  marketPlaceAccounts(state) {
    let allAccounts = _get(state, "userData.accounts", []).concat(
      _get(state, "userData.additionalAccounts", [])
    );
    let filterdAccounts = allAccounts.filter(
      (account) =>
        _get(account, "tenantId", "runbuggy").toLowerCase() === "runbuggy"
    );
    return filterdAccounts;
  },
  marketPlaceShipperAccounts(state) {
    let allAccounts = _get(state, "userData.accounts", []).concat(
      _get(state, "userData.additionalAccounts", [])
    );
    let filterdAccounts = allAccounts.filter(
      (account) =>
        _get(account, "tenantId", "runbuggy").toLowerCase() === "runbuggy" &&
        _includes(["SHIPPER", "AUCTION"], _get(account, "type"))
    );
    return filterdAccounts;
  },
  isAuthenticated(state) {
    return !_isEmpty(state.token);
  },
  isVinlessEnabled(state, getters) {
    return _get(getters.companyData, "status.isVinlessEnabled", false);
  },
  showBidding(state, getters) {
    return _get(getters.companyData, "status.showBidding", false);
  },
  showCustomerFlow(state, getters) {
    return _get(getters.companyData, "status.isShowCustomerListEnabled", false);
  },
  isTransporterAssignmentDisabled(state, getters) {
    return _get(
      getters.companyData,
      "status.isTransporterAssignmentDisabled",
      false
    );
  },
  isLoadboardAssignmentDisabled(state, getters) {
    return _get(
      getters.companyData,
      "status.isLoadboardAssignmentDisabled",
      false
    );
  },
  companyLabels(state, getters) {
    return _get(getters.companyData, "info.labels", {});
  },
  showSuggestedPricing(state, getters) {
    return _get(getters.companyData, "status.isSuggestedPricingEnabled", false);
  },
  userUID(state) {
    return _get(state.userData, "userId");
  },
  email(state) {
    return _get(state.userData, "email");
  },
  featureAccessKeys(state) {
    return _get(state, "featureAccessKeys", []);
  },
  accounts(state) {
    return _get(state, "userData.accounts", []);
  },
  accountsNeedToAcceptTerms(state, getters) {
    return getters.accounts.filter((account) => {
      return account.needToAcceptTerms === true;
    });
  },
  transporterAccounts(state) {
    return _get(state, "userData.accounts", []).filter((account) => {
      return account.type === "TRANSPORTER";
    });
  },
  transporterAccountsWithTenantId(state, getters) {
    return getters.transporterAccounts.filter((account) => {
      return !!account.tenantId;
    });
  },
  transporterAccountsWithoutTenantId(state, getters) {
    return getters.transporterAccounts.filter((account) => {
      return !account.tenantId;
    });
  },
  transporterAccountsRunBuggyMarketplaceOnly(state, getters) {
    return (
      getters.transporterAccountsWithTenantId.length === 0 &&
      getters.transporterAccountsWithoutTenantId.length > 0
    );
  },
  tmsAccount(state) {
    return _get(state, "userData.accounts", []).filter(
      (account) => account.type === "TMS"
    );
  },
  tenantIds(state, getters) {
    return _uniq(
      _get(state, "userData.accounts", []).map((account) => {
        return account.tenantId;
      })
    );
  },
  tenantId(state, getters) {
    return _get(
      state,
      "userData.tenantId",
      _get(
        state,
        "userData.selectedTenantId",
        _get(getters.tmsAccount[0], "id")
      )
    );
  },
  selectedTenantId(state) {
    return _get(state, "userData.selectedTenantId");
  },
  isTmsChildAccount(state, getters) {
    let tenantId = getters.selectedTenantId;
    return tenantId && tenantId !== "runbuggy" ? true : false;
  },
  isTmsContextUser(state, getters) {
    return getters.isTmsChildAccount ||
      getters.hasTmsRole ||
      getters.hasTransporterBrokerRole
      ? true
      : false;
  },
  activeTheme(state) {
    return state.activeTheme;
  },
  roleContext(state, getters) {
    let tenantIds = getters.tenantIds;
    return tenantIds.length === 1 && tenantIds.includes("runbuggy")
      ? "RUNBUGGY"
      : "TMS";
  },
  companyEnableCompanyFilter(state, getters) {
    return _get(getters, "companyData.status.enableCompanyFilter", false);
  },
  companyEnableBulkVinlessOrders(state, getters) {
    return _get(getters, "companyData.status.enableBulkVinlessOrders", false);
  },
  companyEnableShipperFeature(state, getters) {
    return _get(getters, "companyData.status.enableShipperFeature", false);
  },
  taskOrderDetailDefault(state, getters) {
    return _get(getters, "companyData.status.taskOrderDetailDefault", false);
  },
  taskOrderDetailEditModeDefault(state, getters) {
    return _get(
      getters,
      "companyData.status.taskOrderDetailEditModeDefault",
      false
    );
  },
  companyDisableOrderFareFeature(state, getters) {
    return _get(getters, "companyData.status.disableOrderFareFeature", false);
  },
  companyDisableOrderPayoutFeature(state, getters) {
    return _get(getters, "companyData.status.disableOrderPayoutFeature", false);
  },
  companyEnableBrokerFeature(state, getters) {
    return _get(getters, "companyData.status.enableBrokerFeature", false);
  },
  companyEnableSelfClaimFeature() {
    return _get(getters.companyData, "status.enableSelfClaimFeature", false);
  },
  companyTransportationsNavigationLabel(state, getters) {
    return _get(
      getters,
      "companyData.labels.transportationsNavigationLabel",
      false
    );
  },
  companyStripePublishableKey(state, getters) {
    return _get(getters, "companyData.labels.stripePublishableKey", "");
  },
  operationalCountry(state, getters) {
    return _get(getters, "companyData.labels.operationalCountry");
  },
  distanceUnit(state, getters) {
    return _get(getters, "operationalCountry") === "canada"
      ? "common.label.kilometers"
      : "common.label.miles";
  },
  distanceUnitShort(state, getters) {
    return _get(getters, "operationalCountry") === "canada" ? "km" : "mi";
  },
};

// actions
const actions = {
  SetUserData: async ({ commit, state }, userInfo) => {
    await commit(types.USER_DATA, userInfo);
  },
  SetUserTasksConfig: async ({ commit, state }, userTasksConfig) => {
    await commit(types.USER_TASKS_CONFIG, userTasksConfig);
  },
  SetSelectedTenant: async ({ commit, state }, selectedTenant) => {
    await commit(types.SELECTED_TENANT, selectedTenant);
  },
  SetCompanyData: async ({ commit, state }, uamCompanyData) => {
    await commit(types.ACTIVE_COMPANY_INFO, uamCompanyData);
  },
  SetActiveTheme: async ({ commit, state }, activeTheme) => {
    await commit(types.ACTIVE_THEME, activeTheme);
  },
};

// mutations
const mutations = {
  [types.AUTH_STATE](state, authState) {
    state.authState = authState;
  },
  [types.TEMPORARY_PASSWORD](state, temporaryPassword) {
    state.temporaryPassword = temporaryPassword;
  },
  [types.FEATURE_ACCESS_KEYS](state, featureAccessKeys) {
    state.featureAccessKeys = featureAccessKeys;
  },
  [types.USER_DATA](state, userData) {
    let data = userData || {};
    data.userRoles = _get(data, "accounts", []).flatMap((account) => {
      return account.roles.map(
        (role) => `ROLE_${account.type}_${role.replace("FEAT_", "")}`
      );
    });
    data.additionalUserRoles = _get(data, "additionalAccounts", []).flatMap(
      (account) => {
        return account.roles.map(
          (role) => `ROLE_${account.type}_${role.replace("FEAT_", "")}`
        );
      }
    );
    // data.userRoles = data.accounts.flatMap(account => {
    //     const roles = account.roles;
    //     return roles;
    // }).map(role => `ROLE_${role}`);
    state.userData = data;
  },
  [types.USER_TASKS_CONFIG](state, userTasksConfig) {
    state.userTasksConfig = userTasksConfig;
  },
  [types.USER_ID](state, userId) {
    state.userId = userId;
  },
  [types.FIRST_NAME](state, firstName) {
    state.userData.firstName = firstName;
  },
  [types.LAST_NAME](state, lastName) {
    state.userData.lastName = lastName;
  },
  [types.USERNAME](state, username) {
    state.username = username;
  },
  [types.TOKEN](state, token) {
    state.token = token;
  },
  [types.IS_IMPERSONATING](state, boolean) {
    state.isImpersonating = boolean;
  },
  [types.IMPERSONATOR_USERNAME](state, username) {
    state.impersonatorUsername = username;
  },
  [types.SELECTED_TENANT](state, selectedTenant) {
    state.selectedTenant = selectedTenant;
  },
  [types.ACTIVE_COMPANY_INFO](state, uamCompanyData) {
    state.uamCompanyData = uamCompanyData;
  },
  [types.ACTIVE_THEME](state, activeTheme) {
    state.activeTheme = activeTheme;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
