<template>
  <v-navigation-drawer
    dark
    v-model="isNavigationShowing"
    :color="navigationColor"
    v-bind:mini-variant="displayMini"
    mini-variant-width="70"
    width="300"
    app
    overflow
    v-bind:class="{
      collapsed: displayCollapsed,
      overlap: displayOverlap,
    }"
    id="main-navigation"
    @input="transitionend"
  >
    <div class="logo" v-bind:class="{ mini: displayMini }">
      <template @click="navigateToDashboard()" v-if="!displayMini">
        <img src="../../assets/full-logo.svg" />
      </template>
      <v-icon @click="menuToggle" color="navigation_text" dense>{{
        isNavigationExpanded ? "mdi-menu-open" : "mdi-menu"
      }}</v-icon>
    </div>
    <v-divider class="mt-2"></v-divider>
    <v-list flat color="transparent">
      <template v-for="(item, index) in items">
        <template v-if="item.type === ITEM_TYPE_CAMUNDA_FILTER">
          <camunda-filter-item
            v-bind:key="`camunda_filter_${index}`"
            v-on:navigated="onNavigated"
            v-bind:params="item.typeParams"
            v-bind:mini="displayMini"
            v-bind:item="item"
          />
        </template>
        <template
          v-else-if="
            item.type === TASK_MANAGEMENT_FILTER &&
            (hasAdminRole || hasAnyTmsRole)
          "
        >
          <task-management-menu-item
            v-on:navigated="onNavigated"
            v-bind:mini="displayMini"
            v-bind:key="`navigation_item_${index}`"
            v-bind:item="item"
          >
          </task-management-menu-item>
        </template>
        <template v-else>
          <item
            v-on:navigated="onNavigated"
            v-bind:mini="displayMini"
            v-bind:key="`navigation_item_${index}`"
            v-bind:item="item"
          >
          </item>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script type="text/babel">
import _get from "lodash/get";
import NavigationService from "@/services/navigation";
import Item from "./components/item";
import CamundaFilterItem from "./components/camunda-filter-item";
import { mapActions, mapGetters } from "vuex";
import Roles from "SC/constants/roles";
import LocalStorageService from "SC/services/localStorage";
import LocalStorageConstants from "@/constants/localstorage";
import TaskManagementMenuItem from "./components/task-management-menu-item.vue";
import EventBus from "@/services/eventBus";
import EventBusConstants from "@/constants/eventBus";
//import NavigationGeneral from "./navigation-general.json";

const ITEM_TYPE_CAMUNDA_FILTER = "ITEM_TYPE_CAMUNDA_FILTER";
const TASK_MANAGEMENT_FILTER = "TASK_MANAGEMENT_FILTER";
const VIN_ITEM = "VIN_ITEM";

export default {
  name: "components-navigation-index",
  watch: {
    $mq: {
      handler(newValue) {
        if (["xs", "sm", "md"].indexOf(newValue) > -1) {
          this.toggleNavigationExpanded(false);
        }
      },
    },
    isNavigationExpanded: function (newVal) {
      this.isNavigationShowing = newVal;
    },
  },
  props: {},
  data() {
    return {
      ITEM_TYPE_CAMUNDA_FILTER,
      TASK_MANAGEMENT_FILTER,
      VIN_ITEM,
      monoProjectItems: [],
      isRetrieving: false,
      isNavigationShowing: false,
      podsLabel: undefined,
      generalItems: [],
    };
  },
  methods: {
    navigateToDashboard() {
      this.$router.push({ path: "/dashboard" });
    },
    retrieveCamundaOnboardingFilters(menuItem) {
      menuItem.additionalChildren.push({
        label: "Orders",
        icon: '<i class="mdi mdi-order-bool-descending mdi-24px"></i>',
        roles: [
          Roles.RUNBUGGY_SUPER_ADMIN,
          Roles.RUNBUGGY_ADMIN,
          Roles.RUNBUGGY_OPERATIONS,
          Roles.TMS_ADMIN,
          Roles.TMS_USER,
          Roles.SHIPPER_ADMIN,
          Roles.PRIVATE_SHIPPER_ADMIN,
          Roles.AUCTION_ADMIN,
          Roles.AUCTION_USER,
        ],
        route: {
          name: "orders-list-transporter",
        },
      });
    },
    onNavigated(route) {
      if (["xs", "sm", "md"].indexOf(this.$mq) > -1) {
        this.toggleNavigationExpanded(false);
      }
      LocalStorageService.set(
        LocalStorageConstants.LAST_NAVIGATION_ROUTE,
        route
      );
      EventBus.$emit(EventBusConstants.THEME_SETUP);
    },
    retrieveGeneralNavigation() {
      this.isRetrieving = true;
      return Promise.resolve(NavigationGeneral)
      .then(response => {
        this.generalItems = response;
      })
        .catch(() => {})
        .finally(() => {
          this.isRetrieving = false;
        });
    },
    retrieveNavigations() {
      this.isRetrieving = true;
      return NavigationService.retrieveMonoProjectsNavigation()
        .then((items) => {
          this.monoProjectItems = items;
        })
        .catch(() => {})
        .finally(() => {
          this.isRetrieving = false;
        });
    },
    transitionend(event) {
      if (!event && ["xs", "sm", "md"].indexOf(this.$mq) > -1) {
        this.toggleNavigationExpanded(false);
      }
    },
    menuToggle() {
      this.toggleNavigationExpanded();
    },
    ...mapActions("ui", ["toggleNavigationExpanded"]),
  },
  computed: {
    tmsTransportationNavigationlabel() {
      return (this.companyTransportationsNavigationLabel) ? this.companyTransportationsNavigationLabel : "Transportations";
    },
    matchedRouteNames() {
      return this.$route.matched.map((route) => {
        return route.name;
      });
    },
    taskListLabel() {
      return `Tasks ${this.podsLabel ? `(${this.podsLabel})` : ""}`;
    },
    vinListLabel() {
      return `VINS ${this.podsLabel ? `(${this.podsLabel})` : ""}`;
    },
    localItems() {
      return [
        {
          label: "Create an Order",
          icon: '<i class="mdi mdi-plus mdi-24px"></i>',
          color: "primary",
          route: {
            name: "orders-create",
          },
          roles: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.RUNBUGGY_OPERATIONS,
            Roles.TMS_ADMIN,
            Roles.SHIPPER_ADMIN,
            Roles.SHIPPER_USER,
            Roles.PRIVATE_SHIPPER_ADMIN,
            Roles.PRIVATE_SHIPPER_USER,
            Roles.AUCTION_ADMIN,
            Roles.AUCTION_USER,
          ],
          groups: [
            Roles.SHIPPER,
          ]
        },
        {
          label: "Dashboard",
          icon: '<i class="mdi mdi-view-dashboard mdi-24px"></i>',
          route: {
            name: "dashboard",
          },
          roles: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.RUNBUGGY_OPERATIONS,
            Roles.TMS_ADMIN,
            Roles.TMS_ADMIN_TRIAL,
            Roles.TMS_USER,
            Roles.TMS_USER_TRIAL,
            Roles.AUCTION_ADMIN,
            Roles.AUCTION_USER,
            Roles.AUCTION_ADMIN_TRIAL,
            Roles.AUCTION_USER_TRIAL,
            Roles.TRANSPORTER_ADMIN,
            Roles.TRANSPORTER_USER,
            Roles.TRANSPORTER_DRIVER,
            Roles.TRANSPORTER_ADMIN_TRIAL,
            Roles.TRANSPORTER_USER_TRIAL,
            Roles.TRANSPORTER_DRIVER_TRIAL,
            Roles.SHIPPER_ADMIN,
            Roles.SHIPPER_USER,
            Roles.SHIPPER_MANAGER,
            Roles.SHIPPER_BUSINESS_UNIT_MANAGER,
            Roles.SHIPPER_ADMIN_TRIAL,
            Roles.SHIPPER_USER_TRIAL,
            Roles.SHIPPER_MANAGER_TRIAL,
            Roles.SHIPPER_BUSINESS_UNIT_MANAGER_TRIAL,
            Roles.PRIVATE_SHIPPER_ADMIN,
            Roles.PRIVATE_SHIPPER_USER,
            Roles.PRIVATE_SHIPPER_ADMIN_TRIAL,
            Roles.PRIVATE_SHIPPER_USER_TRIAL,
          ],
          groups: [
            Roles.SHIPPER,
          ]
        },
          ...(this.isMessagingSpaV3FeatureEnabled ? [{
          label: "Messages",
          icon: '<i class="mdi mdi-chat mdi-24px"></i>',
          route: {
            name: "messages",
          },
          roles: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.RUNBUGGY_OPERATIONS,
            Roles.TMS_ADMIN,
            Roles.TMS_ADMIN_TRIAL,
            Roles.TMS_USER,
            Roles.TMS_USER_TRIAL,
            Roles.AUCTION_ADMIN,
            Roles.AUCTION_USER,
            Roles.AUCTION_ADMIN_TRIAL,
            Roles.AUCTION_USER_TRIAL,
            Roles.TRANSPORTER_ADMIN,
            Roles.TRANSPORTER_USER,
            Roles.TRANSPORTER_DRIVER,
            Roles.TRANSPORTER_ADMIN_TRIAL,
            Roles.TRANSPORTER_USER_TRIAL,
            Roles.TRANSPORTER_DRIVER_TRIAL,
            Roles.SHIPPER_ADMIN,
            Roles.SHIPPER_USER,
            Roles.SHIPPER_MANAGER,
            Roles.SHIPPER_BUSINESS_UNIT_MANAGER,
            Roles.SHIPPER_ADMIN_TRIAL,
            Roles.SHIPPER_USER_TRIAL,
            Roles.SHIPPER_MANAGER_TRIAL,
            Roles.SHIPPER_BUSINESS_UNIT_MANAGER_TRIAL,
            Roles.PRIVATE_SHIPPER_ADMIN,
            Roles.PRIVATE_SHIPPER_USER,
            Roles.PRIVATE_SHIPPER_ADMIN_TRIAL,
            Roles.PRIVATE_SHIPPER_USER_TRIAL,
          ],
          groups: [
            Roles.SHIPPER,
          ]
        }] : []),
        {
          label: "Orders",
          icon: this.hasAnyTmsRole
            ? '<i class="mdi mdi-package-variant mdi-24px"></i>'
            : '<i class="mdi mdi-order-bool-descending mdi-24px"></i>',
          roles: [
            Roles.SHIPPER_ADMIN,
            Roles.SHIPPER_USER,
            Roles.SHIPPER_MANAGER,
            Roles.SHIPPER_BUSINESS_UNIT_MANAGER,
            Roles.SHIPPER_ADMIN_TRIAL,
            Roles.SHIPPER_USER_TRIAL,
            Roles.SHIPPER_MANAGER_TRIAL,
            Roles.SHIPPER_BUSINESS_UNIT_MANAGER_TRIAL,
            Roles.PRIVATE_SHIPPER_ADMIN,
            Roles.PRIVATE_SHIPPER_USER,
            Roles.PRIVATE_SHIPPER_ADMIN_TRIAL,
            Roles.PRIVATE_SHIPPER_USER_TRIAL,
            Roles.AUCTION_ADMIN,
            Roles.AUCTION_USER,
            Roles.AUCTION_ADMIN_TRIAL,
            Roles.AUCTION_USER_TRIAL,
            Roles.TMS_ADMIN,
            Roles.TMS_USER,
          ],
          groups: [
            Roles.SHIPPER,
            Roles.SO_VIEW,
          ],
          route: {
            name: "orders-list-shipper",
          },

          isActive: () => {
            if (
              this.$route.name === "orders-view" &&
              this.$route.params.perspective === "shipper"
            ) {
              return true;
            }

            return this.matchedRouteNames.indexOf("orders-list-shipper") > -1;
          },
        },
        {
          label: this.hasAnyTmsRole ? this.tmsTransportationNavigationlabel : "Orders",
          icon: this.hasAnyTmsRole
            ? '<i class="mdi mdi-tow-truck mdi-24px"></i>'
            : '<i class="mdi mdi-order-bool-descending mdi-24px"></i>',
          roles: [
            Roles.TRANSPORTER_ADMIN,
            Roles.TRANSPORTER_USER,
            Roles.TRANSPORTER_DRIVER,
            Roles.TRANSPORTER_ADMIN_TRIAL,
            Roles.TRANSPORTER_USER_TRIAL,
            Roles.TRANSPORTER_DRIVER_TRIAL,
            Roles.TMS_ADMIN,
            Roles.TMS_USER,
          ],
          groups: [
            Roles.TRANSPORTER,
            Roles.TO_VIEW,
          ],
          route: {
            name: "orders-list-transporter",
          },
          isActive: () => {
            if (
              this.$route.name === "orders-view" &&
              this.$route.params.perspective === "transporter"
            ) {
              return true;
            }

            return (
              this.matchedRouteNames.indexOf("orders-list-transporter") > -1
            );
          },
        },
        {
          label: "Vins",
          icon: '<i class="mdi mdi-car-multiple mdi-24px"></i>',
          roles: [
            Roles.SHIPPER_ADMIN,
            Roles.SHIPPER_USER,
            Roles.SHIPPER_MANAGER,
            Roles.SHIPPER_BUSINESS_UNIT_MANAGER,
            Roles.SHIPPER_ADMIN_TRIAL,
            Roles.SHIPPER_USER_TRIAL,
            Roles.SHIPPER_MANAGER_TRIAL,
            Roles.SHIPPER_BUSINESS_UNIT_MANAGER_TRIAL,
            Roles.AUCTION_ADMIN,
            Roles.AUCTION_USER,
            Roles.AUCTION_ADMIN_TRIAL,
            Roles.AUCTION_USER_TRIAL,
          ],
          hideForRoles: [Roles.TMS_ADMIN, Roles.TMS_USER],
          route: {
            name: "orders-list-shipper-vins",
          },
        },
        {
          label: "Vins",
          icon: '<i class="mdi mdi-car-multiple mdi-24px"></i>',
          roles: [Roles.TRANSPORTER_BROKER],
          hideForRoles: [Roles.TMS_ADMIN, Roles.TMS_USER],
          route: {
            name: "orders-list-transporter-vins",
          },
        },
        {
          label: "Driver Locations",
          icon: '<i class="mdi mdi-map-search mdi-24px"></i>',
          featureAccess:
            this.featureAccessKeys.indexOf(
              "transporter-driver_location_map-view"
            ) > -1,
          roles: [
            Roles.TRANSPORTER_ADMIN,
            Roles.TMS_ADMIN,
          ],
          groups: [
            Roles.TRANSPORTER,
            Roles.TO_VIEW,
          ],
          route: {
            name: "transporter-driver_location_map-view",
          },
          isActive: () => {
            if (this.$route.name === "transporter-driver_location_map-view") {
              return true;
            }

            return (
              this.matchedRouteNames.indexOf(
                "transporter-driver_location_map-view"
              ) > -1
            );
          },
        },
        {
          label: "Orders",
          groupKey: "order-lists",
          roles: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.RUNBUGGY_OPERATIONS,
          ],
          children: [
            {
              label: this.hasAnyTmsRole ? this.tmsTransportationNavigationlabel : "Transporter",
              icon: '<i class="mdi mdi-tow-truck mdi-24px"></i>',
              route: {
                name: "orders-list-transporter",
              },
              isActive: () => {
                if (
                  this.$route.name === "orders-view" &&
                  this.$route.params.perspective === "transporter"
                ) {
                  return true;
                }

                return (
                  this.matchedRouteNames.indexOf("orders-list-transporter") > -1
                );
              },
            },
            {
              label: this.hasAnyTmsRole ? "Orders" : "Shipper",
              icon: '<i class="mdi mdi-package-variant mdi-24px"></i>',
              route: {
                name: "orders-list-shipper",
              },
              isActive: () => {
                if (
                  this.$route.name === "orders-view" &&
                  this.$route.params.perspective === "shipper"
                ) {
                  return true;
                }

                return (
                  this.matchedRouteNames.indexOf("orders-list-shipper") > -1
                );
              },
            },
          ],
        },
        {
          label: "Administrator Views",
          groupKey: "manage",
          roles: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.RUNBUGGY_OPERATIONS,
          ],
          children: [
            {
              label: "Transport Order",
              icon: '<i class="mdi mdi-truck-delivery mdi-24px"></i>',
              route: {
                name: "admin/orders/perspective",
                params: {
                  perspective: "transporter",
                },
              },
              isActive: () => {
                if (
                  this.$route.name === "admin/orders/perspective" &&
                  this.$route.params.perspective === "transporter"
                ) {
                  return true;
                }
              },
            },
            {
              label: "VINs",
              icon: '<i class="mdi mdi-car-multiple mdi-24px"></i>',
              route: {
                name: "admin/orders/vehicles",
                params: {
                  perspective: "vehicles",
                },
              }
            },
            {
              label: "Invoicing",
              icon: '<i class="mdi mdi-finance mdi-24px"></i>',
              route: {
                name: "admin/orders/invoicing",
                params: {
                  perspective: "invoicing",
                },
              },
            },
            {
              label: "Driver Locations",
              icon: '<i class="mdi mdi-map-search mdi-24px"></i>',
              route: {
                name: "admin-driver_location_map-view",
                params: {
                  perspective: "driver-location-map",
                },
              },
            },
          ],
        },
        {
          label: this.taskListLabel,
          groupKey: "task-lists",
          featureAccess: true,
          roles: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.RUNBUGGY_OPERATIONS,
            Roles.TMS_ADMIN,
          ],
          groups: [
            Roles.TASK_MANAGEMENT,
          ],
          type: TASK_MANAGEMENT_FILTER,
        },
        {
          label: this.vinListLabel,
          groupKey: "vin-lists",
          roles: [Roles.TMS_ADMIN],
          type: TASK_MANAGEMENT_FILTER,
          view: VIN_ITEM,
        },
        {
          label: "Onboarding Tasks",
          roles: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.RUNBUGGY_OPERATIONS,
          ],
          type: ITEM_TYPE_CAMUNDA_FILTER,
          typeParams: {
            params: {
              processKey: "onboarding",
            },
            query: {
              "show-as-table": true,
            },
          },
        },
        {
          label: "Integrations Tasks",
          roles: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.RUNBUGGY_OPERATIONS,
          ],
          type: ITEM_TYPE_CAMUNDA_FILTER,
          typeParams: {
            params: {
              processKey: "integrations",
            },
            query: {
              "show-as-table": true,
            },
          },
        },
        {
          label: "Orders Tasks",
          roles: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.RUNBUGGY_OPERATIONS,
          ],
          type: ITEM_TYPE_CAMUNDA_FILTER,
          typeParams: {
            params: {
              processKey: "orders-workflow",
            },
            query: {
              "show-as-table": true,
            },
          },
        },
        {
          label: "Reports",
          groupKey: "reports",
          roles: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.RUNBUGGY_OPERATIONS,
            Roles.PRIVATE_SHIPPER_ADMIN,
            Roles.SHIPPER_ADMIN,
            Roles.SHIPPER_USER,
            Roles.SHIPPER_MANAGER,
            Roles.SHIPPER_BUSINESS_UNIT_MANAGER,
            Roles.AUCTION_ADMIN,
            Roles.AUCTION_USER,
            Roles.TRANSPORTER,
            Roles.TRANSPORTER_ADMIN,
            Roles.TRANSPORTER_USER,
            Roles.TMS_ADMIN,
          ],
          hideForRoles: [Roles.TMS_USER],
          children: [
            {
              label: "Payouts",
              icon: '<i class="mdi mdi-account-cash-outline mdi-24px"></i>',
              route: {
                name: "reports-payouts",
              },
              roles: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
                Roles.TRANSPORTER_ADMIN,
                Roles.TRANSPORTER_USER,
                Roles.TMS_ADMIN,
              ],
            },
            {
              label: "Payments",
              icon: '<i class="mdi mdi-cash-check mdi-24px"></i>',
              route: {
                name: "reports-payments",
              },
              roles: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
                Roles.TRANSPORTER_ADMIN,
                Roles.TRANSPORTER_USER,
                Roles.TMS_ADMIN,
              ],
            },
            {
              label: "Charges",
              icon: '<i class="mdi mdi-currency-usd mdi-24px"></i>',
              route: {
                name: "reports-charges",
              },
              roles: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
                Roles.SHIPPER_ADMIN,
                Roles.SHIPPER_USER,
                Roles.SHIPPER_MANAGER,
                Roles.SHIPPER_BUSINESS_UNIT_MANAGER,
                Roles.AUCTION_ADMIN,
                Roles.AUCTION_USER,
              ],
              hideForRoles: [Roles.TMS_ADMIN],
            },
            {
              label: "Invoices",
              icon: '<i class="mdi mdi-receipt-text-check mdi-24px"></i>',
              route: {
                name: "reports-invoices",
              },
              roles: [Roles.TMS_ADMIN],
            },
          ],
        },
        {
          label: "Settings",
          groupKey: "settings",
          roles: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.TMS_ADMIN,
            Roles.SYSTEM_SETTINGS,
          ],
          children: [
          {
              label: "Notification Template Definitions",
              icon: '<i class="mdi mdi-file-table-outline mdi-24px"></i></i>',
              route: {
                name: "notification-template-definitions",
              },
              roles: [Roles.RUNBUGGY_SUPER_ADMIN, Roles.NOTIFICATIONS_ADMIN],
              groups: [Roles.NOTIFICATIONS_ADMIN],
            },
            {
              label: "Notification Templates",
              icon: '<i class="mdi mdi-file-table-outline mdi-24px"></i></i>',
              route: {
                name: "notification-templates",
              },
              roles: [Roles.RUNBUGGY_SUPER_ADMIN, Roles.NOTIFICATIONS_ADMIN],
              groups: [Roles.NOTIFICATIONS_ADMIN],
            },
            {
              label: "Rationales",
              icon: '<i class="mdi mdi-strategy mdi-24px"></i>',
              route: {
                name: "admin/rationales",
              },
              roles: [Roles.RUNBUGGY_SUPER_ADMIN],
            },
            {
              label: "Preferred / Dedicated Rules",
              icon: '<i class="mdi mdi-pencil-ruler mdi-24px"></i>',
              route: {
                name: "admin/preferred-dedicated-rules",
              },
              roles: [Roles.RUNBUGGY_SUPER_ADMIN, Roles.RUNBUGGY_ADMIN],
            },
            {
              label: "Timers",
              icon: '<i class="mdi mdi-timer-outline mdi-24px"></i>',
              route: {
                name: "dd/Timers",
              },
              roles: [Roles.RUNBUGGY_SUPER_ADMIN],
            },
            {
              label: "Assignment Rules",
              icon: '<i class="mdi mdi-account-star-outline mdi-24px"></i>',
              route: {
                name: "dd/AssignmentRules",
              },
              roles: [Roles.RUNBUGGY_SUPER_ADMIN, Roles.RUNBUGGY_ADMIN],
            },
            {
              label: "Approval Rules",
              icon: '<i class="mdi mdi-stamper mdi-24px"></i>',
              route: {
                name: "drd/ApprovalDefinitions",
              },
              roles: [Roles.RUNBUGGY_SUPER_ADMIN],
            },
            {
              label: "Optimization Rules",
              icon: '<i class="mdi mdi-head-cog-outline mdi-24px"></i>',
              route: {
                name: "dd/OptimizationRules",
              },
              roles: [Roles.RUNBUGGY_SUPER_ADMIN],
            },
            {
              label: "Shipper Order Rules",
              icon: '<i class="mdi mdi-ship-wheel mdi-24px"></i>',
              route: {
                name: "drd/ShipperOrderRules",
              },
              roles: [Roles.RUNBUGGY_SUPER_ADMIN, Roles.RUNBUGGY_ADMIN],
            },
            {
              label: "Transporter Access Rules",
              icon: '<i class="mdi mdi-shield-lock-outline mdi-24px"></i>',
              route: {
                name: "admin/TransporterAccessRules",
              },
              groups: [Roles.ACCESS_RULE_ADMIN],
            },
            {
              label: this.$i18n(
                "admin.settings.genericTasksDefinitions.title",
                "Generic Tasks Definitions"
              ),
              icon: '<i class="mdi mdi-ballot-outline mdi-24px"></i>',
              route: {
                name: "admin/GenericTasksDefinitions",
              },
              roles: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.VIEW_CONFIG_ADMIN,
                Roles.TMS_ADMIN,
              ],
              groups: [Roles.VIEW_CONFIG_ADMIN],
            },
          ],
        },
      ];
    },
    items() {
      let items = [];
      //items = items.concat(this.generalItems);
      items = items.concat(this.localItems);
      items = items.concat(this.monoProjectItems);

      if (this.hasAnyTmsRole && !this.isShipperFeatureEnable) {
        items = items.filter(
          (item) => _get(item, "route.name") !== "orders-list-shipper"
        );
      }

      return items;
    },
    displayCollapsed() {
      if (["xs", "sm", "md"].indexOf(this.$mq) > -1) {
        return !this.isNavigationExpanded;
      }

      return false;
    },
    displayMini() {
      if (["xs", "sm", "md"].indexOf(this.$mq) > -1) {
        return false;
      }
      this.isNavigationShowing = true;
      return !this.isNavigationExpanded;
    },
    displayOverlap() {
      return ["xs", "sm", "md"].indexOf(this.$mq) > -1;
    },
    navigationColor() {
      return this.$vuetify.theme.themes.light.navigation_bg;
    },
    isShipperFeatureEnable() {
      return _get(this.companyData, "status.enableShipperFeature", false);
    },
    ...mapGetters("ui", {
      isNavigationExpanded: "isNavigationExpanded",
    }),
    ...mapGetters("user", {
      featureAccessKeys: "featureAccessKeys",
      hasAdminRole: "hasAdminRole",
      hasTMSAdminRole: "hasTMSAdminRole",
      hasAnyTmsRole: "hasAnyTmsRole",
      companyData: "companyData",
      companyTransportationsNavigationLabel: "companyTransportationsNavigationLabel",
    }),
  },
  created() {
    EventBus.$on(EventBusConstants.PODS_LABEL, (name) => {
      this.podsLabel = name;
    });
  },
  async mounted() {
    await this.retrieveNavigations();
    //await this.retrieveGeneralNavigation();
  },
  beforeDestroy() {},
  mixins: [],
  components: {
    Item,
    CamundaFilterItem,
    TaskManagementMenuItem,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.overlap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100px;

  &.collapsed {
    width: 0px !important;
    overflow: hidden;
  }
}

.logo {
  padding: 0.75rem 10px;
  display: flex;
  transition: all 500ms ease-in-out;
  justify-content: space-between;
  cursor: pointer;

  &.mini {
    align-items: center;
    justify-content: center;

    img {
      max-width: 40px !important;
    }
  }

  img {
    max-height: 36px;
  }
}

.v-list {
  font-family: "Montserrat", sans-serif;
  padding: 0;
}
</style>
<style lang="scss">
.v-navigation-drawer--mini-variant {
  .list-group.expanded {
    .children {
      .v-list-item {
        margin-left: 0px !important;
      }
    }
  }
}
</style>
