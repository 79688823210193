import _get from "lodash/get";
import store from "store";

const ISSUER = _get(window, "CONFIG.okta.issuer");
const IDP = _get(window, "CONFIG.okta.idp");
const CLIENT_ID = _get(window, "CONFIG.okta.clientId");
const USE_INTERACTION_CODE_FLOW = true;
const OKTA_TESTING_DISABLEHTTPSCHECK = true;

let config = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  idp: IDP,
  redirectUri: window.BASE_URL,
  postLogoutRedirectUri: window.BASE_URL,
  scopes: ["openid", "profile", "email", "runbuggy_user"],
  responseMode: "fragment",
  pkce: true,
  useInteractionCodeFlow: USE_INTERACTION_CODE_FLOW,
  testing: {
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  tokenManager: {
    autoRenew: false,
    expireEarlySeconds: 300,
    syncStorage: true, // sync all tabs
    storageKey: `${_get(window, "CONFIG.localStorage.namespace")}_okta-token-storage`,
  },
};

if (store.get("CUSTOM_OKTA_CONFIG")) {
  let customConfig = store.get("CUSTOM_OKTA_CONFIG");
  console.log("Custom config", customConfig);
  Object.assign(config, customConfig);
}

config = {
  oidc: config,
};

console.log("OKTA CONFIG", config);

export default config;
