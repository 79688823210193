const JAVASCRIPT_CHANNEL = window.RunbuggyAppJavascriptChannel;
const KEYS = {
    'OKTA_AUTHENTICATION_TOKENS': 'OKTA_AUTHENTICATION_TOKENS',
    'BEYOND_IDENTITY_FAILED': 'BEYOND_IDENTITY_FAILED'
};
const Service = {
    KEYS,
    isMobileContext() {
        if (JAVASCRIPT_CHANNEL) {
            console.log('MobileAppService: in mobile context');
            return true;
        }

        return false;
    },
    sendMessage(key, message) {
        console.log('MobileAppService:', 'sendMessage', {
            key,
            message
        })

        if (!this.isMobileContext()) {
            return;
        }

        JAVASCRIPT_CHANNEL.postMessage(JSON.stringify({
            key,
            message
        }));
    }
};

export default Service;
