import * as FeatureExplanationMutationTypes from "@/store/modules/feature-explanation/mutation-types";

import vueStore from "@/store/index";
import store from "store";
import FeatureExplanation from "@/components/feature-explanation";

const FeatureExplanationKeys = "FeatureExplanationKeys";

const rememberDismissal = function (key) {
  let onceKeys = store.get(FeatureExplanationKeys) || [];

  if (onceKeys.indexOf(key) > -1) {
    return;
  }

  onceKeys.push(key);
  store.set(FeatureExplanationKeys, onceKeys);
};

const Internationalization = {
  install(Vue, options) {
    Vue.component("feature-explanation", FeatureExplanation);

    Vue.prototype.$featureExplanationShow = function (
      key,
      dismissOthers = true,
      once = false
    ) {
      let onceKeys = store.get(FeatureExplanationKeys) || [];

      if (onceKeys.indexOf(key) > -1) {
        return;
      }

      if (dismissOthers) {
        vueStore.commit(
          `featureExplanation/${FeatureExplanationMutationTypes.CLEAR_ACTIVE_KEYS}`,
          key
        );
      }
      vueStore.commit(
        `featureExplanation/${FeatureExplanationMutationTypes.ADD_ACTIVE_KEY}`,
        key
      );
    };

    Vue.prototype.$featureExplanationDismiss = function (
      key,
      remember = false
    ) {
      vueStore.commit(
        `featureExplanation/${FeatureExplanationMutationTypes.REMOVE_ACTIVE_KEY}`,
        key
      );

      if (remember) {
        rememberDismissal(key);
      }
    };
  },
};

export default Internationalization;
