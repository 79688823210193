import * as types from "./mutation-types";

// initial state
const state = {
  isInIFrame: false,
  transparent: false,
};

// getters
const getters = {
  isInIFrame(state) {
    return state.isInIFrame;
  },
  transparent(state) {
    return state.transparent;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  [types.IS_IN_IFRAME](state, bool) {
    state.isInIFrame = bool;
  },
  [types.TRANSPARENT](state, bool) {
    state.transparent = bool;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
