import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import LocalStorageService from './localStorage'
import LocalStorageConstants from '../constants/localstorage'
import jwt_decode from "jwt-decode";

const Service = {
    setImpersonationToken(token) {
        return LocalStorageService.set(LocalStorageConstants.IMPERSONATION_TOKEN, token);
    },
    setImpersonatedByUsername(username) {
        return LocalStorageService.set(LocalStorageConstants.IMPERSONATION_BY_USERNAME, username);
    },
    setImpersonationUsername(username) {
        return LocalStorageService.set(LocalStorageConstants.IMPERSONATION_USERNAME, username);
    },
    setSpecifiedRegistrationId(specifiedRegistrationId) {
        return LocalStorageService.set(LocalStorageConstants.SPECIFIED_REGISTRATION_ID, specifiedRegistrationId);
    },
    setSpecifiedRegistrationIds(specifiedRegistrationIds) {
        return LocalStorageService.set(LocalStorageConstants.SPECIFIED_REGISTRATION_IDS, specifiedRegistrationIds);
    },
    setSpecifiedRegistrationIdsForTenantId(selectedAccounts, tenantId) {
        let specifiedRegistrationIdsForTenantIdsAll = this.getSpecifiedRegistrationIdsForTenantIdsAll();
        specifiedRegistrationIdsForTenantIdsAll[tenantId] = selectedAccounts;
        return LocalStorageService.set(LocalStorageConstants.SPECIFIED_REGISTRATION_IDS_FOR_TENANT_IDS, specifiedRegistrationIdsForTenantIdsAll);
    },
    getSpecifiedRegistrationIdsForTenantIdsAll() {
        return LocalStorageService.get(LocalStorageConstants.SPECIFIED_REGISTRATION_IDS_FOR_TENANT_IDS, {});
    },
    getSpecifiedRegistrationIdsForTenantId(tenantId) {
        return _get(this.getSpecifiedRegistrationIdsForTenantIdsAll(), tenantId, []);
    },
    setIntercompaniesCount(count) {
        LocalStorageService.set(LocalStorageConstants.INTERCOMPANY_TOTAL, count);
    },
    getIntercompaniesCount() {
        return parseInt(LocalStorageService.get(LocalStorageConstants.INTERCOMPANY_TOTAL));
    },
    setIntercompanyName(intercompanyName) {
        return LocalStorageService.set(LocalStorageConstants.INTERCOMPANY_NAME, intercompanyName);
    },
    setToken(token) {
        this.setJWTPayload(token);

        return LocalStorageService.set(LocalStorageConstants.USER_TOKEN, token);
    },
    setJWTPayload(token) {
        let decodedJWTToken;
        try {
            decodedJWTToken = jwt_decode(token);
        } catch (e) {
            // not a valid JWT token
            return;
        }

        return LocalStorageService.set(LocalStorageConstants.JWT_PAYLOAD, decodedJWTToken);
    },
    getJWTPayload() {
        let result = LocalStorageService.get(LocalStorageConstants.JWT_PAYLOAD);
        return result;
    },
    isTokenJWT() {
        return !_isEmpty(this.getJWTPayload());
    },
    isJWTExpired() {
        let expiration = _get(this.getJWTPayload(), 'exp');
        if (!expiration) {
            return false;
        }

        return expiration > new Date().getTime();
    },
    hasToken() {
        return !_isEmpty(this.getToken());
    },
    hasImpersonatedByUsername() {
        return !_isEmpty(this.getImpersonatedByUsername());
    },
    hasImpersonationToken() {
        return !_isEmpty(this.getImpersonationToken());
    },
    hasSpecifiedRegistrationId() {
        return !_isEmpty(this.getSpecifiedRegistrationId());
    },
    hasSpecifiedRegistrationIds() {
        return !_isEmpty(this.getSpecifiedRegistrationIds());
    },
    hasIntercompanyName() {
        return !_isEmpty(this.getIntercompanyName());
    },
    getToken() {
        if (this.isTokenJWT() && this.isJWTExpired()) {
            this.clearToken();
            this.clearJWTPayload();

            console.log('JWT expired');
            return;
        }

        return LocalStorageService.get(LocalStorageConstants.USER_TOKEN);
    },
    getImpersonationToken() {
        return LocalStorageService.get(LocalStorageConstants.IMPERSONATION_TOKEN);
    },
    getImpersonatedByUsername() {
        return LocalStorageService.get(LocalStorageConstants.IMPERSONATION_BY_USERNAME);
    },
    getImpersonationUsername() {
        return LocalStorageService.get(LocalStorageConstants.IMPERSONATION_USERNAME);
    },
    getSpecifiedRegistrationId() {
        return LocalStorageService.get(LocalStorageConstants.SPECIFIED_REGISTRATION_ID);
    },
    getSpecifiedRegistrationIds() {
        return LocalStorageService.get(LocalStorageConstants.SPECIFIED_REGISTRATION_IDS);
    },
    getIntercompanyName() {
        return LocalStorageService.get(LocalStorageConstants.INTERCOMPANY_NAME);
    },
    clearJWTPayload() {
        return LocalStorageService.remove(LocalStorageConstants.JWT_PAYLOAD);
    },
    clearToken() {
        return LocalStorageService.remove(LocalStorageConstants.USER_TOKEN);
    },
    clearImpersonationToken() {
        return LocalStorageService.remove(LocalStorageConstants.IMPERSONATION_TOKEN);
    },
    clearImpersonatedByUsername() {
        return LocalStorageService.remove(LocalStorageConstants.IMPERSONATION_BY_USERNAME);
    },
    clearImpersonationUsername() {
        return LocalStorageService.remove(LocalStorageConstants.IMPERSONATION_USERNAME);
    },
    clearSpecifiedRegistrationId() {
        return LocalStorageService.remove(LocalStorageConstants.SPECIFIED_REGISTRATION_ID);
    },
    clearSpecifiedRegistrationIds() {
        return LocalStorageService.remove(LocalStorageConstants.SPECIFIED_REGISTRATION_IDS);
    },
    clearIntercompanyName() {
        return LocalStorageService.remove(LocalStorageConstants.INTERCOMPANY_NAME);
    },
    clearCompliantStatus() {
        return LocalStorageService.remove(LocalStorageConstants.ACCOUNT_COMPLIANCE);
    },
    clearAll() {
        this.clearSpecifiedRegistrationId();
        this.clearSpecifiedRegistrationIds();
        this.clearToken();
        this.clearJWTPayload();
        this.clearImpersonationToken();
        this.clearImpersonationUsername();
        this.clearImpersonatedByUsername();
        this.clearIntercompanyName();
        this.clearCompliantStatus();
    }
};

export default Service;
