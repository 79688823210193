const COLORS = {
  DARK_BLUE: "#1485b8",
  BLUE: "#28aae3",
  LIGHT_BLUE: "#e2f1f6",
  GREY_BLUE: "#e9f4f9",
  GRAY: "#7f7d7d",
  RED: "#ff6464",

  // NEW COLORS
  BLUE_GREY: "#848fa1",
  DARK_SKY_BLUE: "#2aaae3",
  AZURE: "#06b0f2",
  CTA: "#29aae3",
  MAIN_TEXT_NAV: "#344563",
  ACTIVE_STATE: "#1387ba",
  CANCEL_ERROR: "#9e0931",
  FIELD_ERROR: "#cc0000",
};

export default COLORS;
