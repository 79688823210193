<template>
  <v-list-item
    class="position-relative"
    :style="{ 'margin-left': `${8 * depth}px` }"
  >
    <v-tooltip :fixed="false" right v-bind:disabled="!mini">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on:click="onClick"
          :x-small="hasChildren"
          v-on="on"
          v-bind:color="buttonColor"
          v-bind:input-value="isActive"
          active-class="text-white"
          class="button"
          v-bind:class="{
            expanded: !mini,
            'group-expanded': groupExpanded,
            overline: hasChildren,
            'text-color': !isOrderCreate,
          }"
          block
          depressed
        >
          <span v-if="item.icon" v-html="item.icon"></span>

          <template v-if="!mini">
            <span class="ml-2">{{ item.label }}</span>
          </template>

          <!-- <v-divider v-if="hasChildren && mini"></v-divider> -->
          <template v-if="hasChildren && !mini">
            <div class="expand-icon">
              <template v-if="groupExpanded">
                <v-icon>mdi-chevron-up</v-icon>
              </template>
              <template v-else>
                <v-icon>mdi-chevron-down</v-icon>
              </template>
            </div>
          </template>
        </v-btn>
      </template>
      <span>{{ item.label }}</span>
    </v-tooltip>
  </v-list-item>
</template>

<script type="text/babel">
import _get from "lodash/get";
import _has from "lodash/has";
import * as designTokens from "DT";

export default {
  name: "components-navigation-button",
  watch: {},
  props: {
    hasChildren: undefined,
    mini: {
      default() {
        return false;
      }
    },
    depth: {
      default() {
        return 0;
      }
    },
    groupExpanded: undefined,
    item: {
      required: true
    }
  },
  data() {
    return {
      designTokens
    };
  },
  methods: {
    emitNavigated() {
      this.$emit("navigated", this.route);
    },
    onClick() {
      if (this.hasRoute) {
        this.$router.push(this.route);
        this.emitNavigated();
      }

      this.$emit("click");
    }
  },
  computed: {
    matchedRouteNames() {
      return this.$route.matched.map((route) => {
        return route.name;
      });
    },
    buttonColor() {
      if (this.isActive) {
        return this.$vuetify.theme.themes.light.navigation_bg;
      }

      if (this.route && this.route.name === "orders-create") {
        return this.$vuetify.theme.themes.light.primary;
      }

      return "transparent";
    },
    isOrderCreate() {
      return this.route && this.route.name === "orders-create";
    },
    isActive() {
      if (this.item.isActive) {
        return this.item.isActive();
      }

      if (!this.hasRoute) {
        return false;
      }

      return this.routeParsed.route.fullPath === this.$route.path;
    },
    routeParsed() {
      return this.$router.resolve(this.route);
    },
    route() {
      return _get(this.item, "route");
    },
    hasRoute() {
      return _has(this.item, "route");
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../../scss/variables";

.v-list-item {
  padding: 0 0.5rem;
}

.overline {
  opacity: 0.6;
}

.text-color {
  color: var(--v-navigation_text-base) !important;
}

.button {
  &.group-expanded {
  }

  &.expanded {
    justify-content: left;

    .expanded-icon {
      right: 5px;
    }
  }

  .expand-icon {
    font-size: 0.8em;
    position: absolute;
    right: -5px;
    opacity: 0.5;
  }
}
</style>
