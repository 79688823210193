<template>
  <v-card>
    <v-card-title class="text-h5">
      {{ $i18n("common.label.grantAccess", "Grant 24 Hour Access") }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="form">
        <div class="d-flex align-center">
          <v-text-field
            :label="emailLabel"
            outlined
            dense
            :rules="rules.email"
            v-model="emailAddress"
          ></v-text-field>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" depressed @click="closeModal()">{{
        $i18n("taskManagement.dialogs.addTag.cancelBtn", "Cancel")
      }}</v-btn>
      <v-btn
        color="primary"
        depressed
        @click="submitSupport()"
        :disabled="emptyEmail"
        >{{ $i18n("common.label.submitForApproval", "Submit") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script type="text/babel">
import AccountsApiService from "SC/services/accountsApi";
import NotificationConstants from "@/constants/notifications";
import _get from "lodash/get";

export default {
  name: "top_bar-components-enable_support_dialog",
  watch: {},
  props: {
    accountId: undefined,
  },
  data() {
    return {
      emailAddress: undefined,
      rules: {
        email: [
          (value) => !!value || "Enter Email.",
          (value) => {
            if (!value || value.trim() === "") {
              return true;
            }
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return (
              pattern.test(value) ||
              this.$i18n("common.label.error.invalidEmail", "Invalid e-mail.")
            );
          },
        ],
      },
      form: false,
    };
  },
  methods: {
    closeModal() {
      this.emailAddress = undefined;
      this.$emit("close");
    },
    submitSupport() {
      const params = {
        expireHours: 24,
      };
      const encodedEmail = encodeURIComponent(this.emailAddress);
      AccountsApiService.enableSupportByEmail(encodedEmail, params)
        .then(() => {
          this.showNotification(
            "success",
            this.$i18n("common.label.permissionGranted", "Permission granted"),
            this.$i18n(
              "common.label.permissionGrantedTo",
              `You have granted support permissions to ${this.emailAddress}`,
              { user: this.emailAddress }
            )
          );
        })
        .catch((error) => {
          this.showErrorNotification(error);
        })
        .finally(() => {
          this.closeModal();
        });
    },
    showErrorNotification(error) {
      let status = _get(error, "response.status");
      let message = _get(error, "response.data.message");
      if (status && message) {
        this.showNotification(
          "error",
          this.$i18n("common.label.error", "Error") + " " + status,
          message
        );
      } else {
        this.showNotification(
          "error",
          this.$i18n("common.label.error", "Error"),
          error
        );
      }
    },
    showNotification(type, title, message) {
      this.$notify({
        type: type,
        title: title,
        text: message,
        duration: NotificationConstants.DURATION,
        speed: 1000,
      });
    },
  },
  computed: {
    emailLabel() {
      return this.$i18n(
        "accountManagement.basicInfo.email.label",
        "Email Address"
      );
    },
    emptyEmail() {
      return !this.emailAddress || this.emailAddress === "" || !this.form;
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
