export default {
  DOCUMENTS: {
    CERTIFICATE_OF_INSURANCE: "CertificateOfInsuranceDocumentPolicy",
    CERTIFICATE_OF_OPERATING_AUTH:
      "CertificateOfOperatingAuthorityDocumentPolicy",
    CERTIFICATE_OF_W9: "CertificateOfW9DocumentPolicy",
  },
  PAYMENTS: {
    STRIPE_BANK_ACCOUNT_PAYMENT: "StripeBankAccountPaymentPolicy",
    STRIPE_CARD_PAYMENT: "StripeCardPaymentPolicy",
  },
};
