import * as types from "./mutation-types";

// initial state
const state = {
  filterUserId: undefined,
  variableQuery: undefined,
  sorting: undefined,
  assigneeQuery: undefined,
};

// getters
const getters = {
  filterIsNotEmpty(state, getters) {
    return (
      getters.filterQueryList.hasOwnProperty("processVariables") &&
      getters.filterQueryList.processVariables.length > 0
    );
  },
  filterQuery(state, getters) {
    let query = {};

    if (getters.filterUserId === -1) {
      query["unassigned"] = true;
    } else if (getters.filterUserId) {
      query["assignee"] = getters.filterUserId;
    }

    return query;
  },
  filterQueryList(state, getters) {
    let query = {};

    if (getters.filterUserId === -1) {
      query["unassigned"] = true;
    } else if (getters.filterUserId) {
      query["assignee"] = getters.filterUserId;
    }

    if (state.variableQuery) {
      query["processVariables"] = state.variableQuery;
    }

    if (state.sorting) {
      query["sorting"] = state.sorting;
    }

    if (getters.filterUserId === -1) {
      query["unassigned"] = true;
    } else if (getters.filterUserId) {
      query["assignee"] = getters.filterUserId;
    } else if (state.assigneeQuery) {
      query["assigneeLike"] = `%${state.assigneeQuery}%`;
    }

    return query;
  },
  filterUserId(state) {
    return state.filterUserId;
  },
  variableQuery(state) {
    return state.variableQuery;
  },
  sorting(state) {
    return state.sorting;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  [types.ASSIGNEE_QUERY](state, assigneeQuery) {
    state.assigneeQuery = assigneeQuery;
  },
  [types.FILTER_USER_ID](state, filterUserId) {
    state.filterUserId = filterUserId;
  },
  [types.VARIABLE_QUERY](state, variableQuery) {
    state.variableQuery = variableQuery;
  },
  [types.SORTING](state, sorting) {
    state.sorting = sorting;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
