<template>
  <div>
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      z-index="1001"
      :close-on-content-click="false"
      v-model="showNotifications"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="mr-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
          @click="onNotificationsButtonClicked"
        >
          <v-badge v-if="unseenCount > 0" color="secondary" overlap bordered>
            <template v-slot:badge>
              <span>{{ unseenCount }}</span>
            </template>

            <slot></slot>
          </v-badge>
          <div v-else>
            <slot></slot>
          </div>
        </v-btn>
      </template>
      <v-card max-width="550" class="mx-auto">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          {{ $i18n("common.label.markAllRead", "Mark all read") }}
          <v-btn icon @click="markAllSeen">
            <i class="mdi mdi-email-open mdi-24px"></i>
          </v-btn>
        </v-toolbar>

        <v-tabs-items v-model="tab">
          <!-- New -->
          <notification-tab-item
            :notifications="newNotifications"
            :title="title"
            v-on:view-item-clicked="onViewItemClicked"
            v-on:notification-seen="onNewNotificationSeen"
          >
          </notification-tab-item>
          <!-- All -->
          <notification-tab-item
            :notifications="allNotifications"
            :title="title"
            v-on:view-item-clicked="onViewItemClicked"
            v-on:notification-seen="onAllNotificationSeen"
          >
          </notification-tab-item>
        </v-tabs-items>

        <v-tabs v-model="tab" right icons-and-text>
          <v-tab>
            {{ $i18n("common.label.new", "New") }}
            <v-icon>mdi mdi-email</v-icon>
          </v-tab>
          <v-tab @click="onAllTabClicked">
            {{ $i18n("common.label.all", "All") }}
            <v-icon>mdi mdi-email-open</v-icon>
          </v-tab>
        </v-tabs>
      </v-card>
    </v-menu>
  </div>
</template>

<script type="text/babel">
import COLORS from "@/constants/colors";
import _filter from "lodash/filter";
import NotificationsAPIService from "SC/services/notificationsApi";
import moment from "moment";
import NotificationTabItem from "@/components/top-bar/components/notification-display/tab-item";

const notificationIconMap = {
  TransportationOrder_VEHICLES_SIGNATURE_DELIVERED:
    "fas fa-check-circle green--text",
  TransportationOrder_VEHICLES_CLAIMED:
    "fas fa-check-double green--text text--darken-4",
  TransportationOrder_VEHICLES_UNCLAIMED: "far fa-minus-square orange--text",
  TransportationOrder_DRIVER_ASSIGNED: "far fa-address-card blue--text",
  TransportationOrder_VEHICLES_SIGNATURE_PICKEDUP:
    "fas fa-shipping-fast green--text text--darken-4",
  TransportationOrder_DRIVER_ACCEPTED:
    "far fa-handshake green--text text--darken-4",
  TransportationOrder_DRIVER_REJECTED: "fas fa-exclamation orange--text",
  TransportationOrder_PAYOUT_COMPLETE:
    "fas fa-file-invoice-dollar green--text text--darken-4",

  OrderTransportationEvent_VEHICLES_SIGNATURE_DELIVERED:
    "fas fa-check-circle green--text",
  OrderTransportationEvent_VEHICLES_CLAIMED:
    "fas fa-check-double green--text text--darken-4",
  OrderTransportationEvent_VEHICLES_UNCLAIMED:
    "far fa-minus-square orange--text",
  OrderTransportationEvent_VEHICLES_SIGNATURE_PICKEDUP:
    "fas fa-shipping-fast green--text text--darken-4",
  OrderTransportationEvent_DRIVER_ACCEPTED:
    "far fa-handshake green--text text--darken-4",
  OrderTransportationEvent_PICKUP_ETA_UPDATED:
    "far fa-clock green--text text--darken-4",
  OrderTransportationEvent_DROPOFF_ETA_UPDATED:
    "far fa-clock green--text text--darken-4",

  Order_CANCELED: "mdi mdi-nacel red--text",
  Charge_SUCCESS: "mdi mdi-receipt green--text text--darken-4",
  Message_POSTED: "mdi mdi-comment-multiple blue--text text--darken-3",
};

export default {
  name: "components-top_bar_notification_display-index",
  watch: {},
  props: {
    title: {
      required: true,
      type: String,
      default: undefined,
    },
    typesFilter: {
      required: false,
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      COLORS,
      newNotifications: [],
      allNotifications: [],
      showNotifications: false,
      tab: null,
      fetchingAllNotifications: false,
      setNewNotificationsTimeout: undefined,
    };
  },
  methods: {
    markAllSeen() {
      NotificationsAPIService.markAllSeen(this.typesFilter);
      this.newNotifications = [];
    },
    setNewNotifications() {
      NotificationsAPIService.getNotifications(0, 100, this.typesFilter, false)
        .then((result) => {
          this.newNotifications = result.data.content.map(function (val) {
            let key = val.type + "_" + val.event;

            let icon = null;
            if (notificationIconMap[key] !== undefined) {
              icon = notificationIconMap[key];
            }

            return {
              id: val.id,
              type: val.type,
              event: val.event,
              sourceId: val.sourceId,
              title: val.content.title,
              text: val.content.text,
              dt: moment(val.dt).fromNow(),
              userSeen: val.userSeen,
              icon: icon,
            };
          });
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.setNewNotificationsTimeout = setTimeout(() => {
            this.setNewNotifications();
          }, 20000);
        });
    },
    setAllNotifications: function () {
      this.fetchingAllNotifications = true;
      NotificationsAPIService.getNotifications(0, 100, this.typesFilter)
        .then((result) => {
          this.allNotifications = result.data.content.map(function (val) {
            let key = val.type + "_" + val.event;

            let icon = null;
            if (notificationIconMap[key] !== undefined) {
              icon = notificationIconMap[key];
            }

            return {
              id: val.id,
              type: val.type,
              event: val.event,
              sourceId: val.sourceId,
              title: val.content.title,
              text: val.content.text,
              dt: moment(val.dt).fromNow(),
              userSeen: val.userSeen,
              icon: icon,
            };
          });
          this.fetchingAllNotifications = false;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.fetchingAllNotifications = false;
        });
    },
    onAllTabClicked() {
      this.setAllNotifications();
    },
    onNotificationsButtonClicked() {
      if (this.tab === 1) {
        this.setAllNotifications();
      }
    },
    onViewItemClicked() {
      this.showNotifications = false;
    },
    onNewNotificationSeen(notification) {
      if (notification.userSeen) {
        return;
      }

      NotificationsAPIService.markNotificationSeen(notification.id);

      this.newNotifications = this.newNotifications.filter(
        (item) => item.id !== notification.id
      );
    },
    onAllNotificationSeen(notification) {
      if (notification.userSeen) {
        return;
      }

      NotificationsAPIService.markNotificationSeen(notification.id);

      this.allNotifications.forEach((val, index) => {
        if (val.id === notification.id) {
          this.allNotifications[index].userSeen = true;
        }
      });
    },
  },
  computed: {
    unseenCount() {
      return _filter(this.newNotifications, function (notification) {
        return notification.userSeen === false;
      }).length;
    },
    seenStyle() {
      return "font-weight-bold";
    },
  },
  created() {},
  mounted() {
    this.setNewNotifications();
    this.setAllNotifications();
  },
  beforeDestroy() {
    if (this.setNewNotificationsTimeout) {
      clearTimeout(this.setNewNotificationsTimeout);
    }
  },
  components: {
    NotificationTabItem,
  },
  mixins: [],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../../../scss/variables";

.notification-list {
  height: 550px;
  overflow-y: auto;
}

.empty-list {
  height: 550px;
}
</style>
