import _get from 'lodash/get'
import store from 'store'

const Service = {
    set(key, value) {
        store.set(this.namespacedKey(key), value);
    },
    get(key, fallback) {
        let value = store.get(this.namespacedKey(key), fallback);
        return value !== undefined ? value : fallback;
    },
    remove(key) {
        store.remove(this.namespacedKey(key));
    },
    namespacedKey(key) {
        let parts = [];
        let namespace = _get(window, 'CONFIG.localStorage.namespace');

        if (namespace) {
            parts.push(namespace);
        }
        parts.push(key);
        return parts.join('/');
    }
};

export default Service;
