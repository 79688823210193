<template>
  <v-card>
    <v-card-title>{{
      operation === "reset"
        ? $i18n("taskManagement.dialogs.reset.title", "Reset View To Default")
        : $i18n("taskManagement.dialogs.delete.title", "Delete View")
    }}</v-card-title>

    <div class="pl-12 pr-12">
      <v-card-text
        >{{
          operation === "reset"
            ? $i18n(
                "taskManagement.dialogs.reset.message",
                "Are you sure you want to reset all the filters and layout in "
              )
            : $i18n(
                "taskManagement.dialogs.delete.message",
                "Are you sure you want to delete"
              )
        }}
        <strong>{{ `${view.label}?` }}</strong></v-card-text
      >
    </div>
    <v-card-actions class="pr-12 pb-12">
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="closeModal()">{{
        $i18n("taskManagement.dialogs.addTag.cancelBtn", "Cancel")
      }}</v-btn>
      <v-btn color="primary" depressed @click="deleteView()">{{
        operation === "reset"
          ? $i18n("common.label.reset", "Reset")
          : $i18n("common.label.delete", "Delete")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script type="text/babel">
import RunBuggyTasksApiService from "SC/services/runBuggyTasksApi";
import NotificationConstants from "@/constants/notifications";

export default {
  name: "add-tag-dialog",
  watch: {},
  props: {
    view: undefined,
    operation: false
  },
  data: () => ({}),
  methods: {
    closeModal(refresh = false) {
      this.$emit("close-delete-view-dialog", refresh);
    },
    deleteView() {
      let promise;
      if (this.operation === "reset") {
        promise = RunBuggyTasksApiService.deleteViewByUser(
          this.view.route.params.id
        );
      } else {
        promise = RunBuggyTasksApiService.deleteView(this.view.route.params.id);
      }
      promise
        .then((res) => {
          this.showNotification(
            "View action",
            `The view has been ${
              this.operation === "reset" ? "reset" : "delete"
            } successfully`,
            "success"
          );
          this.closeModal(true);
        })
        .catch((error) => {
          console.log("error", error);
          this.showNotification(
            "View action",
            "There was an error, please try again!",
            "error"
          );
        });
    },
    showNotification(title, message, type) {
      this.$notify({
        type: type,
        title: title,
        text: message,
        duration: NotificationConstants.DURATION,
        speed: 1000
      });
    }
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.add-tag-modal-btn {
  padding: 0 8px !important;
  min-width: 24px !important;
}

.v-card__text {
  padding-left: 0;
}
</style>
