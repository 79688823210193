const TenantRoute = {
  install (Vue, options) {
    Vue.prototype.$tenantRoute = function (
      tenantId,
      routeParams
    ) {
      let path = this.$router.resolve(routeParams).route.path
      const resolved = this.$router.resolve({
        query: {
          redirect: path
        },
        name: 'ensure-tenant-redirect',
        params: {
          tenantId: tenantId
        }
      })
      return resolved.route;
    }
  },
}

export default TenantRoute
