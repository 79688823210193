<template>
  <div class="component">
    <v-dialog v-model="value" persistent max-width="650" class="dialog-container">
      <div class="dialog-content">
        <div class="dialog-card">
          <div class="header">
            <v-row class="icon-row">
              <v-col cols="12" class="text-center pb-2">
                <v-avatar
                  color="#FFFFFF"
                  size="100"
                  class="avatar-header pa-10"
                >
                  <v-avatar color="orange" size="80">
                    <v-icon large color="white"> mdi-exclamation-thick</v-icon>
                  </v-avatar>
                </v-avatar>
              </v-col>
            </v-row>

            <v-row class="mt-5">
                <v-col cols="12" class="text-center pt-5">
                  <span
                    class="
                      headline
                      font-weight-bold
                      text-center
                    "
                    > 
                      {{
                        $i18n(
                          "user.tokenChanged.title", 
                          "Something Has Changed"
                        )
                      }}
                  </span>
                </v-col>
            </v-row>
          </div>
          <v-card-text>
            <div class="component d-flex flex-column">
              <div class="px-10 pt-4">
                  <p>
                    {{
                      $i18n(
                        "user.tokenChanged.body",
                        "In order to continue using the application you need to log back in."
                      )
                    }}
                  </p>
              </div>

              <div class="flex-grow-1 pa-8 white">
                <div class="pa-3">
                  <v-btn color="primary" block v-on:click="confirm">
                    {{ 
                      $i18n(
                        'common.label.confirm',
                        'Confirm'
                      )
                    }}
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script type="text/babel">
import OktaService from "@/services/okta";
import TokenService from "SC/services/tokenService";

export default {
  name: "components-modal_token_changed-index",
  watch: {},
  props: {
    value: undefined
  },
  data() {
    return {};
  },
  methods: {
    logout() {
      if (TokenService.hasToken() || TokenService.hasImpersonationToken()) {
        TokenService.clearAll();
      }
      OktaService.unauthenticate(window.location.href).then(() => {});
    },
    confirm() {
      this.logout();
    }
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/variables";
@import "../../scss/variables";

.dialog-container {
  overflow: visible;
}

.dialog-content {
  background-color: #ffffff;

  .dialog-card {
    .header {
      padding: 30px 30px 0;
    }
  }
}
.avatar-header {
  position: absolute;
  bottom: calc(100% - 25px);
  left: calc(50% - 40px);
  right: auto;
  top: auto;
}
.icon-row {
  position: relative;
}

</style>
<style lang="scss">
.v-dialog {
  overflow: visible !important;
}
</style>
