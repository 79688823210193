<template>
  <div class="d-flex flex-grow-1 flex-column">
    <div v-if="!hideAuthenticatedHeader">
      <top-bar class="top-bar"></top-bar>
    </div>
    <navigation v-if="!hideNavigation" class="navigation-drawer"></navigation>

    <v-content class="router-content">
      <toasts />
      <router-view></router-view>
    </v-content>
    <footer-bar v-if="!hideFooterBar"></footer-bar>
  </div>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
import TopBar from "@/components/top-bar";
import Navigation from "@/components/navigation";
import FooterBar from "@/components/footer-bar";
import Toasts from "@/components/toasts";
import MobileAppService from '@/services/mobileApp'
import LocalStorageService from "SC/services/localStorage";
import LocalStorageConstants from "@/constants/localstorage";

export default {
  name: "layouts-authenticated-index",
  watch: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    hideFooterBar() {
      return LocalStorageService.get(LocalStorageConstants.MOBILE_EMBEDDED_LAYOUT);
    },
    hideAuthenticatedHeader() {
      return this.isInIFrame || this.$route.meta["hideAuthenticatedHeader"] || LocalStorageService.get(LocalStorageConstants.MOBILE_EMBEDDED_LAYOUT);
    },
    hideNavigation() {
      if (this.isDriverOnly && !this.isImpersonating) {
        return true;
      }

      return this.$route.meta["hideNavigation"];
    },
    ...mapGetters("app", ["isInIFrame"]),
    ...mapGetters("user", ["userRoles", "isDriverOnly", "isImpersonating"])
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {
    Navigation,
    TopBar,
    FooterBar,
    Toasts
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../scss/variables";
@import "SC/scss/mq-mixins";
@include sm-down {
  .router-content {
    padding-bottom: 80px !important;
  }
}
</style>
