import TokenService from "../services/tokenService";
import axios from "axios";
import _get from "lodash/get";

const Client = {
    axiosClient: axios.create(),
    // address to address / flat fare
    createNewAddressToAddress(addressToAddress) {
        let path = `${_get(window, 'CONFIG.pricing.api.path')}/addresses`;
        return this.post(path, addressToAddress);
    },
    getAllAddressToAddresses(page=1, size=20) {
        let path = `${_get(window, 'CONFIG.pricing.api.path')}/addresses`;
        let query = [];
        query.push(`page=${page}`);
        query.push(`size=${size}`);
        path += `?${query.join('&')}`;
        
        return this.get(path);
    },
    updateAddressToAddressWithID(id, addressToAddress) {
        let path = `${_get(window, 'CONFIG.pricing.api.path')}/addresses/${id}`;
        return this.put(path, addressToAddress);
    },
    removeAddressToAddressWithID(id) {
        let path = `${_get(window, 'CONFIG.pricing.api.path')}/addresses/${id}`;
        return this.delete(path);
    },

    // features / zone surcharges
    createNewFeature(feature) {
        let path = `${_get(window, 'CONFIG.pricing.api.path')}/features`;
        return this.post(path, feature);
    },
    getAllFeatures(page=1, size=20) {
        let path = `${_get(window, 'CONFIG.pricing.api.path')}/features`;
        let query = [];
        query.push(`page=${page}`);
        query.push(`size=${size}`);
        path += `?${query.join('&')}`;
        return this.get(path);
    },
    updateFeatureWithID(id, feature) {
        let path = `${_get(window, 'CONFIG.pricing.api.path')}/features/${id}`;
        return this.put(path, feature);
    },
    removeFeatureWithID(id) {
        let path = `${_get(window, 'CONFIG.pricing.api.path')}/features/${id}`;
        return this.delete(path);
    },

    // prices
    getPrice(body) {
        let path = `${_get(window, 'CONFIG.pricing.api.path')}/prices`;
        return this.post(path, body);
    },

    // services
    createNewService(service) {
        let path = `${_get(window, 'CONFIG.pricing.api.path')}/services`;
        return this.post(path, service);
    },
    getAllServices(sort){
        let path = `${_get(window, 'CONFIG.pricing.api.path')}/services`;
        if(sort) path += `?sort=${sort.join(',')}`;
        return this.get(path);
    },
    updateServiceWithID(id, service) {
        let path = `${_get(window, 'CONFIG.pricing.api.path')}/services/${id}`;
        return this.put(path, service);
    },
    removeServiceWithID(id) {
        let path = `${_get(window, 'CONFIG.pricing.api.path')}/services/${id}`;
        return this.delete(path);
    },

    // common calls with auth token
    get(path) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        return Client.axiosClient.get(path, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params
        });
    },
    put(path, data) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        return Client.axiosClient.put(path, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params
        });
    },
    delete(path) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }
      
        return Client.axiosClient.delete(path, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params
        });
    },
    post(path, data) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        return Client.axiosClient.post(path, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params
        });
    }
};

export default Client;
