// import {OktaAuth} from "@okta/okta-auth-js";
import {OktaAuth} from "@/../libraries/okta-auth-js.umd";
import config from "@/okta";
import SessionStorageConstants from "@/constants/sessionStorage";
import EventBusConstants from "@/constants/eventBus";
import EventBus from "@/services/eventBus";


import MobileAppService from '@/services/mobileApp'
import LocalStorageService from "SC/services/localStorage";
import TokenService from "SC/services/tokenService";
import LocalstorageConstants from "@/constants/localstorage";
import _get from 'lodash/get';
import _set from 'lodash/set';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';

const LOCAL_STORAGE_OKTA_POST_AUTH_REDIRECT =
  "LOCAL_STORAGE_OKTA_POST_AUTH_REDIRECT";
const LOCAL_STORAGE_OKTA_GRANTOR_TOKEN = "LOCAL_STORAGE_OKTA_GRANTOR_TOKEN";
const OKTA_PROXIED = 'OKTA_PROXIED';

const service = {
  _authClient: undefined,
  _vueStore: undefined,
  completeSignupTimeout: undefined,
  onRenewCallback: undefined,
  renewAccessTokenWithAttemptsPromise: undefined,
  configuration: {
    ...config.oidc
  },
  async init(vueStore) {
    this.clearTokenRenewalLock();

    let proxied = LocalStorageService.get(OKTA_PROXIED);
    if (proxied) {
      this.configuration.idp = _get(window, "CONFIG.oktaProxy.idp");
      this.configuration.issuer = _get(window, "CONFIG.oktaProxy.issuer");
      this.configuration.clientId = _get(window, "CONFIG.oktaProxy.clientId");
    } else {
      this.configuration.idp = _get(window, "CONFIG.okta.idp");
      this.configuration.issuer = _get(window, "CONFIG.okta.issuer");
      this.configuration.clientId = _get(window, "CONFIG.okta.clientId");
    }

    this.configuration = {
      ...this.configuration,
      transformAuthState: (oktaAuth, authState) => {
        return this.transformAuthState(oktaAuth, authState);
      },
    };

    console.log('Okta service: init');
    console.log('Okta service: config', this.configuration);

    this._vueStore = vueStore;
    this._authClient = new OktaAuth(this.configuration);

    let promise = Promise.resolve();

    console.log('Okta service: _authClient', this._authClient);

    if (this._authClient.isLoginRedirect()) {
      console.log('Okta service: isLoginRedirect/isImpersonating');

      promise = promise
          .then(() => {
            return this._authClient.token.parseFromUrl();
          })
          .then(async (result) => {
            if (_has(result, "tokens.accessToken.claims.runbuggy_user")) {
              console.log('Okta service: isLoginRedirect', 'setTokens', result);
              this._authClient.tokenManager.setTokens(_get(result, 'tokens', result));
              let redirect = LocalStorageService.get(
                  LOCAL_STORAGE_OKTA_POST_AUTH_REDIRECT
              );
              if (redirect) {
                window.location.href = redirect;
                LocalStorageService.remove(LOCAL_STORAGE_OKTA_POST_AUTH_REDIRECT);
                sessionStorage.setItem(SessionStorageConstants.ACCOUNT_MANAGEMENT_COMPLETE_SIGNUP,"true");
              }
            }else{
              this.clearLocalSession();
              throw new Error("Authentication Error - please try to login again. If Authentication continues to fail Call RunBuggy Support");
            }
          })
          .catch((e) => {
            let authErrorSource;

            if(window.sessionStorage.getItem(SessionStorageConstants.BEYOND_IDENTITY_AUTH_ATTEMPT) === "true") {
              authErrorSource = 'beyond_identity';
              console.log('Okta service: isLoginRedirect', 'was trying BYNDID auth', window.sessionStorage.getItem(SessionStorageConstants.BEYOND_IDENTITY_AUTH_ATTEMPT));
            }
            window.location.hash = `#/login?auth_error_message=${_get(e, "message", e.toString())}&auth_error_source=${authErrorSource}`;
          });
    }

    let preExistingTokens = await this.getTokens();
    if (window.location.hash.indexOf('byndid_auto_login=true') > -1 && !_isEmpty(preExistingTokens)) {
      TokenService.clearAll();
      let postLogoutRedirectUri = `${window.location.origin}${window.location.pathname}#/login?byndid_auto_login=true`;
      return this.unauthenticate(postLogoutRedirectUri);
    }

    return promise
      .then(async () => {
        console.log('Okta service:', 'Client start');
        this._authClient.start();
      })
      .then(() => {

        /*
        NOTE:
        this._authClient.authStateManager.updateAuthState()
        ^ this might throw an exception that is not caught properly within okta's library causing the user to be stuck.
        To prevent the user from being stuck we force a reload if this call is not completed within 2 seconds.
          */
        console.log('Okta service:', 'Setting updateAuthStateTimeout');
        let updateAuthStateTimeout = setTimeout(() => {
          console.log('Okta service:', 'Executing updateAuthStateTimeout');
          window.location.reload();
        }, 2000);

        return this._authClient.authStateManager
            .updateAuthState()
            .then((authState) => {
              console.log('OktaService: authState update', authState);
              this._vueStore.commit("user/AUTH_STATE", authState);
            })
            .catch((e) => {
              console.log('Okta service:', 'updateAuthState error', e);
              window.location.reload();
            })
            .finally(() => {
              clearTimeout(updateAuthStateTimeout);
              console.log('Okta service:', 'Clearing updateAuthStateTimeout');
            });
      })
      .then(async () => {

        let authTokenDetails;
        console.log('Okta service:', '_authClient.isAuthenticated()');
        if (await this._authClient.isAuthenticated()) {
          authTokenDetails = {
            tokens: await this.getTokens()
          };

          if (!_has(authTokenDetails, "tokens.accessToken.claims.runbuggy_user.grantor")){
            //Set LAST_LOGGED_IN_USER_NAME if not impersonating
            LocalStorageService.set(LocalstorageConstants.LAST_LOGGED_IN_USER_NAME, _get(authTokenDetails, 'tokens.accessToken.claims.runbuggy_user.username'));
          }
        }

        if (authTokenDetails && MobileAppService.isMobileContext()) {
          MobileAppService.sendMessage(MobileAppService.KEYS.OKTA_AUTHENTICATION_TOKENS, authTokenDetails);
        }

        this._authClient.authStateManager.subscribe((authState) => {
          console.log('OktaService: authState subscribe', authState);
          this._vueStore.commit("user/AUTH_STATE", authState);
        });

        this._authClient.tokenManager.on('error', (err) => {
          console.group('Okta Error Event');
          console.log('Error: ', (err));
          console.groupEnd();
          this.clearLocalSession();
          window.location.reload();
        });

        this._authClient.tokenManager.on('expired', (key, newToken, oldToken) => {
          console.group('Okta Expired Event');
          console.log('key: ', key);
          console.log('oldToken: ', oldToken);
          console.log('newToken: ', newToken);
          console.groupEnd();

          if(key === 'accessToken') {
            this.renewAccessToken();
          }
        });
        
        this._authClient.tokenManager.on("renewed", (key, newToken, oldToken) => {
          console.group('Okta Renewed Event');
          console.log('key: ', key);
          console.log('oldToken: ', oldToken);
          console.log('newToken: ', newToken);
          console.groupEnd();

          if (!_has(newToken, "claims.runbuggy_user")) {
            return this.unauthenticate();
          }

          if (this.onRenewCallback) {
            this.onRenewCallback(key, newToken, oldToken);
          }
        });
      });
  },
  async renewAccessTokenWithAttempts(totalAttempts = 3) {
    if (this.renewAccessTokenWithAttemptsPromise) {
      console.log('OKTA Service: renewAccessTokenWithAttempts', 'already running');
      return this.renewAccessTokenWithAttemptsPromise;
    }

    console.log('OKTA Service: renewAccessTokenWithAttempts', 'started');
    let promise = Promise.resolve();
    let attempts = 0;
    let success;

    for (let i = 0; i < totalAttempts; i++) {
      promise = promise.then(() => {
        console.log('OKTA Service: renewAccessTokenWithAttempts', attempts, totalAttempts, success);

        if (success) {
          return;
        }
        attempts++;

        return this.renewAccessToken()
            .then(() => {
              success = true;
            })
            .catch((e) => {
              if (attempts === totalAttempts) {
                throw new Error(e);
              }
            });
      });
    }

    promise = promise.finally(() => {
      this.renewAccessTokenWithAttemptsPromise = undefined;
    });
    this.renewAccessTokenWithAttemptsPromise = promise;
    return promise;
  },
  async transformAuthState(oktaAuth, authState) {
    console.log("transformAuthState: auth state changed");
    console.log('transformAuthState: oktaAuth');
    console.log(oktaAuth);
    console.log('transformAuthState: authState');
    console.log(authState);
    if(authState.accessToken) {
      authState.isAuthenticated = true;
    }

    console.log('transformAuthState: isAuthenticated', authState.isAuthenticated);

    return authState;
  },
  async revertToGrantorToken() {
    try {
      const originalTokens = JSON.parse(
          LocalStorageService.get(LOCAL_STORAGE_OKTA_GRANTOR_TOKEN)
      );
      this._authClient.tokenManager.setTokens(originalTokens);
    } catch (e) {
      console.log("revertToGrantorToken:", e);
    }
    LocalStorageService.remove(LOCAL_STORAGE_OKTA_GRANTOR_TOKEN);
  },
  async getTenantWithoutPrompt(tenantId) {
    console.log('Okta Tenant: getWithoutPrompt', tenantId);
    LocalStorageService.set(
      LOCAL_STORAGE_OKTA_POST_AUTH_REDIRECT,
       window.location.href
    );
    let params = {
      runbuggyTenantId: tenantId,
      idp: this.configuration.idp,
    };
    let impersonationEmail = LocalStorageService.get("IMPERSONATION_USERNAME");
    if(impersonationEmail){
      _set(params, "runbuggyImpersonate", impersonationEmail);
    }
    return this._authClient.token.getWithoutPrompt(params);
  },
  getTenantWitRedidect(tenantId) {
    console.log('Okta Tenant: getWithRedirect', tenantId);
    let params = {
      runbuggyTenantId: tenantId,
      idp: this.configuration.idp,
      responseType: "id_token",
    };
    let impersonationEmail = LocalStorageService.get("IMPERSONATION_USERNAME");
    if(impersonationEmail){
      _set(params, "runbuggyImpersonate", impersonationEmail);
    }
    return this._authClient.token.getWithRedirect(params)
    .catch((e) => {
      console.log('Okta Tenant: getWithRedirect error', e);
    })
  },
  async setTenantToken(tenantId) {
    console.group('Okta Tenant: ', tenantId);
    return this.getTokens()
        .then((result) => {
          let currentToken = result;
          if (!_has(currentToken, "accessToken.claims.runbuggy_user.grantor")) {
            console.log("Okta Tenant-grantorToken: ", currentToken);
            LocalStorageService.set(
                LOCAL_STORAGE_OKTA_GRANTOR_TOKEN,
                JSON.stringify(currentToken)
            );
          }
        })
        .then(async () => {
          //POP-UP BLOCKER MUST BE DISABLED FOR THIS TO WORK
          const tenantToken = await this.getTenantWithoutPrompt(tenantId);
          console.log("Okta Tenant-newToken: ", tenantToken);
          if (_has(tenantToken, "tokens.accessToken.claims.runbuggy_user")) {
            this.setTokens(tenantToken);
          }
          else{
            throw new Error("Authentication Error - please try again. If Authentication continues to fail Call RunBuggy Support");
          }
        })
        .catch((e) => {
          console.warn('Okta Tenant: error =>', e);
          console.log('Okta Tenant: failed to set tokens', _get(e, "message", e.toString()));
          //ATTEMPT getWithRedirect IF getWithoutPrompt FAILS
          this.getTenantWitRedidect(tenantId);
        })
        .finally(()=>{
          console.groupEnd();
        });
  },
  async getImpersonationWithoutPrompt(userEmail) {
    console.log('Okta Impersonation: getWithoutPrompt', userEmail);
    LocalStorageService.set(
      LOCAL_STORAGE_OKTA_POST_AUTH_REDIRECT,
       window.location.href
    );
    return this._authClient.token.getWithoutPrompt({
      runbuggyImpersonate: userEmail,
      idp: this.configuration.idp,
    })
  },
  getImpersonationWitRedidect(userEmail) {
    console.log('Okta Impersonation: getWithRedirect', userEmail);
    return this._authClient.token.getWithRedirect({
      runbuggyImpersonate: userEmail,
      idp: this.configuration.idp,
      responseType: "id_token",
      state: "impersonation",
    })
    .catch((e) => {
      console.log('Okta Impersonation: getWithRedirect error', e);
    })
  },
  async setImpersonationToken(userEmail) {
    console.group('Okta Impersonation: ', userEmail);
    return this.getTokens()
        .then((result) => {
          let currentToken = result;
          if (!_has(currentToken, "accessToken.claims.runbuggy_user.grantor")) {
            console.log("Okta Impersonation-grantorToken: ", currentToken);
            LocalStorageService.set(
                LOCAL_STORAGE_OKTA_GRANTOR_TOKEN,
                JSON.stringify(currentToken)
            );
          }
        })
        .then(async () => {
          //POP-UP BLOCKER MUST BE DISABLED FOR THIS TO WORK
          const impersonationToken = await this.getImpersonationWithoutPrompt(userEmail);
          console.log("Okta Impersonation-newToken: ", impersonationToken);
          if (_has(impersonationToken, "tokens.accessToken.claims.runbuggy_user")) {
            this.setTokens(impersonationToken);
          }
          else{
            throw new Error("Authentication Error - please try to login again. If Authentication continues to fail Call RunBuggy Support");
          }
        })
        .catch((e) => {
          console.warn('Okta Impersonation: error =>', e);
          console.log('Okta Impersonation: failed to set impersonation tokens', _get(e, "message", e.toString()));
          //ATTEMPT getWithRedirect IF getWithoutPrompt FAILS
          this.getImpersonationWitRedidect(userEmail);
        })
        .finally(()=>{
          console.groupEnd();
        });
  },
  decodeToken(token) {
    return this._authClient.token.decode(token);
  },
  setTokens(tokens) {
    console.log('Okta service: setTokens');
    tokens = _get(tokens, 'tokens', tokens);
    let previousAccessToken;

    return this._authClient.tokenManager.getTokens()
        .then((result) => {
          previousAccessToken = _get(result, 'accessToken.accessToken');
        })
        .catch((e) => {

        })
        .then(() => {
          if (previousAccessToken === _get(tokens, 'accessToken.accessToken')) {
            return console.log('Okta Service: setTokens', 'Token is identical');
          }
          this._authClient.tokenManager.setTokens(tokens);
          LocalStorageService.set(LocalstorageConstants.USER_TOKEN, _get(tokens, 'accessToken.accessToken'));
        });
  },
  async renewAllTokensWithRedirect() {
    return this._authClient.token.getWithRedirect({
      idp: this.configuration.idp,
      responseType: "id_token",
    });
  },
  async renewAllTokensWithoutPrompt() {
    const tokens = await this._authClient.token.getWithoutPrompt({
      prompt: 'none',
      idp: this.configuration.idp,
    });
    this.setTokens(tokens);
  },
  async getTokens() {
    console.log('Okta service: getTokens');

    return this._authClient.tokenManager.getTokens().then((result) => {
      console.log("getTokens:", result);
      return result;
    })
    .catch((e) => {
      console.log('Okta service: getTokens', e);
      window.location.reload();
    })
  },
  async getUserInfo() {
    console.log('Okta service: getUserInfo');
    return this._authClient.token.getUserInfo().then((result) => {
      console.log("getUserInfo:", result);
      return result;
    })
    .catch((e) => {
      console.log('Okta service: getUserInfo', e);
      window.location.reload();
    })
  },
  async renewAllTokens() {
    console.log('Okta service: renewAllTokens');

    return this.renewAccessToken()
      .then((results) => {
        console.log('Okta service: renewAllTokens', "results", results);
        return this._authClient.authStateManager.updateAuthState();
      })
      .catch((err) => {
        console.log("Okta service: renewAllTokens; could not renew token", err);
      });
  },

  async renewAccessToken() {
    if (this.hasTokenRenewalLock()) {
      console.log('Okta service: Token renewal lock in place, skipping renewAccessToken');
      return;
    }

    this.setTokenRenewalLock();
    return this._authClient.tokenManager
      .renew("accessToken")
      .then((freshToken) => {
        console.log("new token", freshToken);
        // manage freshToken
      })
      .finally(() => {
        this.clearTokenRenewalLock();
      })
  },
  async renewIdToken() {
    console.log("renewing id token");
    return this.getTokens()
      .then(({ accessToken, idToken }) => {
        console.log("renewIdToken", accessToken, idToken);
        return this._authClient.tokenManager.renew("idToken"); //idToken
      })
      .then((freshToken) => {
        console.log("new token", freshToken);
        // manage freshToken
      })
      .catch((err) => {
        console.log('Okta service: renewIdToken', 'could not renew token"', err);
        // handle OAuthError
      });
  },
  async getAuthState() {
    return this._authClient.authStateManager.getAuthState();
  },
  getTransaction() {
    if (!this._authClient.tx.exists()) {
      return Promise.reject("No transaction");
    }

    return this._authClient.tx
      .resume()
      .then((transaction) => {
        console.log("current transaction status:", transaction.status);
        return transaction;
      })
      .catch((err) => {
        console.error("Okta service: getTransaction: ", err);
      });
  },
  verifyRecoveryToken(recoveryToken) {
    return this._authClient.verifyRecoveryToken({
      recoveryToken: recoveryToken,
    });
  },
  authenticateWithTransaction(transaction) {
    LocalStorageService.set(
        LOCAL_STORAGE_OKTA_POST_AUTH_REDIRECT,
        window.location.href
    );
    console.log("authenticateWithTransaction-getWithRedirect");
    return this._authClient.token.getWithRedirect({
      sessionToken: transaction.sessionToken,
      responseType: "id_token",
    });
  },
  async unauthenticate(postLogoutRedirectUri) {
    await this.clearLocalSession();
    return this._authClient.signOut({
      postLogoutRedirectUri
    });
  },
  forgotPassword(username, factorType = "email") {
    return this._authClient
      .forgotPassword({
        username: username,
        factorType: factorType,
      })
      .then((transaction) => {
        console.log("transaction", transaction);
        return transaction;
      });
  },
  authenticateIdp() {
    console.log("authenticateIdp: signInWithRedirect");
    return this._authClient
      .signInWithRedirect({
        idp: this.configuration.idp,
        prompt: 'login',
        redirectUri: window.BASE_URL,
        originalUri: window.BASE_URL
      })
      .catch((err) => {
        console.log("authenticateIdp: signInWithRedirect error", err);
      });
  },
  authenticate(username, password, sendFingerprint) {
    return this._authClient
      .signInWithCredentials({
        username: username,
        password: password,
        sendFingerprint: sendFingerprint,
      })
      .then((transaction) => {
        switch (transaction.status) {
          case "SUCCESS":
            this.authenticateWithTransaction(transaction);
            break;
          case "MFA_REQUIRED":
            return transaction;
          default:
            throw "We cannot handle the " + transaction.status + " status";
        }
      });
  },
  setTokenRenewalLock() {
    console.log('Okta service: Setting token renewal lock');
    return LocalStorageService.set(LocalstorageConstants.OKTA_TOKEN_RENEWAL_LOCK, true);
  },
  hasTokenRenewalLock() {
    return LocalStorageService.get(LocalstorageConstants.OKTA_TOKEN_RENEWAL_LOCK);
  },
  clearTokenRenewalLock() {
    console.log('Okta service: Clearing token renewal lock');
    return LocalStorageService.remove(LocalstorageConstants.OKTA_TOKEN_RENEWAL_LOCK);
  },
  async clearLocalSession() {
    LocalStorageService.remove(LOCAL_STORAGE_OKTA_GRANTOR_TOKEN);
    LocalStorageService.remove(LOCAL_STORAGE_OKTA_POST_AUTH_REDIRECT);
    return this._authClient.tokenManager.clear();
  }
};

export default service;
