<template>
  <div class="component toasts">
    <notifications
      classes="vue-notifications"
      position="top right"
      :duration="-1"
    >
      <template slot="body" slot-scope="props">
        <div
          class="vue-notification-template vue-notifications white-notification"
          v-bind:class="[props.item.type]"
          v-on:click="onNotificationClick(props)"
        >
          <v-icon class="ml-auto close-btn" @click="onNotificationClick(props)"
            >mdi-close</v-icon
          >
          <div
            class="notification-title d-flex"
            v-html="props.item.title"
          ></div>
          <div class="notification-content" v-html="props.item.text"></div>
        </div>
      </template>
    </notifications>

    <notifications
      classes="vue-notifications"
      position="right top"
      group="full-width"
      v-bind:duration="-1"
      style="top: 60px"
    >
      <template slot="body" slot-scope="props">
        <div
          class="vue-notification-template vue-notifications white-notification"
          v-bind:class="[props.item.type]"
          v-on:click="onNotificationClick(props)"
        >
          <div class="notification-title" v-html="props.item.title"></div>
          <div class="notification-content" v-html="props.item.text"></div>
        </div>
      </template>
    </notifications>
    <notifications
      classes="vue-notifications"
      position="top right"
      group="top-right"
      :duration="-1"
      width="400px"
    >
      <template slot="body" slot-scope="props">
        <div
          class="vue-notification-template vue-notifications white-notification"
          v-bind:class="[props.item.type]"
          v-on:click="onNotificationClick(props)"
        >
          <div class="d-flex align-center">
            <v-icon class="huge-icon mr-4">mdi-check-circle</v-icon>
            <div class="d-flex flex-column justify-center">
              <div class="notification-title" v-html="props.item.title"></div>
              <div class="notification-content" v-html="props.item.text"></div>
            </div>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script type="text/babel">
import _get from "lodash/get";
import { mapGetters } from "vuex";

export default {
  name: "components-toasts-index",
  watch: {},
  props: {},
  data() {
    return {};
  },
  methods: {
    onNotificationClick(props) {
      let callback = _get(props, "item.data.callback");

      if (callback) {
        Promise.resolve()
          .then(() => {
            return callback();
          })
          .finally(() => {
            props.close();
          });
      } else {
        props.close();
      }
    }
  },
  computed: {
    style() {
      let left = 90;

      if (["xs", "sm"].indexOf(this.$mq) > -1 || this.isNavigationExpanded) {
        left = 310;
      }

      return {
        left: `${left}px`
      };
    },
    ...mapGetters("ui", {
      isNavigationExpanded: "isNavigationExpanded"
    })
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.toasts {
  z-index: 5000;
  position: relative;
  .vue-notification-group {
    top: 50px !important;
  }
}
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>

<style lang="scss"></style>
