import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from "vue";
import VueMq from "vue-mq";
import VueMeta from "vue-meta";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import TokenService from "SC/services/tokenService";
import InternationalizationPlugin from "@/plugins/i18n";
import TenantRoute from "@/plugins/tenantRoute";
import IDPlugin from "@/plugins/id";
import FeatureExplanationPlugin from "@/plugins/featureExplanation";
import AutoAppendComponentNamePlugin from "@/plugins/autoAppendComponentName";
import RunBuggyLoggerPlugin from "@/plugins/logger";
import vuetify from "./plugins/vuetify";
import VueSVGIcon from "vue-svgicon";
import Notifications from "vue-notification";
import VueSanitize from "vue-sanitize";
import { VTooltip } from "v-tooltip";
import _get from "lodash/get";
import BugsnagService from "SC/services/bugsnag";
import OktaService from "@/services/okta";
import { MessagingService } from "@/services/messagingService";
import PortalVue from "portal-vue";
import LocalStorageService from "SC/services/localStorage";
import LocalStorageConstants from "@/constants/localstorage";
import axios from 'axios';

import AccountsApiClient from "SC/clients/accountsApi";
import CamundaIntegrationsApi from "SC/clients/camundaIntegrationsApi";
import CamundaOnboardingApi from "SC/clients/camundaOnboardingApi";
import CamundaOrdersApi from "SC/clients/camundaOrdersApi";
import CamundaPricingApi from "SC/clients/camundaPricingApi";
import IntegrationApi from "SC/clients/integrationApi";
import LabelDefinitionsApi from "SC/clients/labelDefinitionsApi";
import NotificationsApi from "SC/clients/notificationsApi";
import OnboardingApi from "SC/clients/onboardingApi";
import PricingApi from "SC/clients/pricingApi";
import RunBuggyAnalyticsApi from "SC/clients/runBuggyAnalyticsApi";
import RunBuggyApi from "SC/clients/runBuggyApi";
import RunBuggyNgApi from "SC/clients/runBuggyNgApi";
import RunBuggyOrdersApi from "SC/clients/runBuggyOrdersApi";
import RunBuggyTasksApi from "SC/clients/runBuggyTasksApi";
import LoadScript from 'vue-plugin-load-script';
import unleashClient from '@/plugins/unleash';

import EventBusConstants from "@/constants/eventBus";
import EventBus from "@/services/eventBus";

import LocaleHelpers from "@/mixins/localeHelpers";
import UnleashMixins from "@/mixins/unleash";

const axiosClient = axios.create();
const impersonationUsername = TokenService.getImpersonationUsername();
// attach interceptors
axiosClient.interceptors.response.use((response) => {
  return response;
}, (error) => {
  console.log('INTERCEPTOR error res', error);
  let status = _get(error, 'response.status');
  switch (status) {
    case 401:
      if (LocalStorageService.get(LocalStorageConstants.LIMITED_LIFETIME_TOKEN)) {
        return;
      }
      
      if (OktaService.hasTokenRenewalLock()) {
        console.log('INTERCEPTOR: Okta service: Token renewal lock in place, skipping renewAccessToken');
        return;
      }

      OktaService.renewAccessTokenWithAttempts()
        .then(() => {
          if(TokenService.hasImpersonationToken() || impersonationUsername) {
            OktaService.setImpersonationToken(impersonationUsername);
          }
        })
        .catch(() => {
          OktaService.unauthenticate(window.location.href).then(() => {});
        });
      break;
  }
  return Promise.reject(error);
});

[
  AccountsApiClient,
  CamundaIntegrationsApi,
  CamundaOnboardingApi,
  CamundaOrdersApi,
  CamundaPricingApi,
  IntegrationApi,
  LabelDefinitionsApi,
  NotificationsApi,
  OnboardingApi,
  PricingApi,
  RunBuggyAnalyticsApi,
  RunBuggyApi,
  RunBuggyNgApi,
  RunBuggyOrdersApi,
  RunBuggyTasksApi
].forEach((client) => {
  client.axiosClient = axiosClient;
});

function getParamMap() {
  let paramMap = {};

  let parts = window.location.href.split("?");
  if (!parts[1]) {
    return paramMap;
  }

  parts[1].split("&").forEach((param) => {
    let parts = param.split("=");
    paramMap[parts[0]] = decodeURIComponent(parts[1]);
  });

  return paramMap;
}

function setTokenFromURL() {
  let token;

  if (paramMap.hasOwnProperty("token")) {
    token = paramMap['token'];
  } else if(paramMap.hasOwnProperty("access_token")) {
    token = paramMap['access_token'];
  }

  LocalStorageService.set(LocalStorageConstants.LIMITED_LIFETIME_TOKEN, true);
  TokenService.setToken(token);
  let filteredParams = [];
  Object.keys(paramMap).forEach((key) => {
    if (key === "token") {
      return;
    }

    let value = paramMap[key];
    filteredParams.push(`${key}=${value}`);
  });

  let parts = window.location.href.split("?");
  window.location.href = `${parts[0]}?${filteredParams.join("&")}`;
  return Promise.resolve();
}

let paramMap = getParamMap();
let promise;

if (paramMap.hasOwnProperty("token") || paramMap.hasOwnProperty("access_token")) {
  promise = setTokenFromURL();
} else {
  LocalStorageService.remove(LocalStorageConstants.LIMITED_LIFETIME_TOKEN);
  promise = OktaService.init(store);
}

promise.then(() => {
  if (_get(window, "CONFIG.bugsnag.key")) {
    BugsnagService.init(
      _get(window, "CONFIG.bugsnag.key"),
      Vue,
      _get(window, "VERSION.ui")
    );
  } else {
    console.log("Bugsnag: No key");
  }

  if (!unleashClient) {
    console.error('Unleash client could not be initialized due to missing configuration.');
  } else {
    // Make unleashClient available in all Vue components
    Vue.prototype.$unleashClient = unleashClient;
  }

  Vue.directive("tooltip", VTooltip);
  Vue.mixin(LocaleHelpers);
  Vue.mixin(UnleashMixins);

  Vue.use(VueSVGIcon, { tagName: "svgicon" });
  Vue.use(Notifications);
  Vue.use(VueSanitize);
  Vue.use(AutoAppendComponentNamePlugin);
  Vue.use(InternationalizationPlugin);
  Vue.use(TenantRoute);
  Vue.use(FeatureExplanationPlugin);
  Vue.use(RunBuggyLoggerPlugin);
  Vue.use(PortalVue);
  Vue.use(LoadScript);
  Vue.use(IDPlugin);

  Vue.config.productionTip = false;

  Vue.use(VueMeta, {});

  Vue.use(VueMq, {
    breakpoints: {
      xs: 600,
      sm: 960,
      md: 1264,
      lg: 1904,
      xl: Infinity,
    },
    defaultBreakpoint: "md",
  });

  Vue.config.ignoredElements = [
    /wc-\w*/,
    /rb-\w*/
  ];

  function setLayoutFromUrl() {
    let paramMap = getParamMap();

    if (!paramMap.hasOwnProperty('MOBILE_EMBEDDED_LAYOUT')) {
        return;
    }

    if (paramMap['MOBILE_EMBEDDED_LAYOUT'] === '1') {
        LocalStorageService.set(LocalStorageConstants.MOBILE_EMBEDDED_LAYOUT, true);
    } else if (paramMap['MOBILE_EMBEDDED_LAYOUT'] === '0') {
        LocalStorageService.set(LocalStorageConstants.MOBILE_EMBEDDED_LAYOUT, false);
    }
  }

  setLayoutFromUrl();

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
    beforeCreate() {
      // Ensure unleashClient is started before the app
      this.$unleashClient?.start(); 
    },
  }).$mount("#app");

  OktaService.onRenewCallback = (key, newToken, oldToken) => {
    switch (key) {
      case "accessToken":
        MessagingService.reconnect(newToken.accessToken).then(() => {
          console.log("Messaging service reconnected");
        });
        break;
    }
  };
});
