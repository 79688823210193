<template>
  <v-list-item
    class="position-relative"
    v-bind:id="
      item.processKey && item.label
        ? `side-menu-item-${item.processKey}-${item.label.split(' ').join('-')}`
        : undefined
    "
  >
    <v-tooltip
      :fixed="false"
      right
      v-bind:disabled="!mini && item.label.length < 20"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on:click="onClick"
          v-on="on"
          v-bind:color="buttonColor"
          v-bind:input-value="isActive"
          active-class="text-white"
          class="button d-flex"
          v-bind:class="{ expanded: !mini, 'group-expanded': groupExpanded }"
          block
          depressed
        >
          <div class="color-box" v-bind:style="colorBox"></div>

          <template v-if="!mini">
            <span class="ml-2 navigation-text-overflow">{{ item.label }}</span>
          </template>

          <v-badge
            right
            color="secondary"
            class="count-badge"
            v-if="count > 0 && !mini"
          >
            <template v-slot:badge>
              <span>{{ count }}</span>
            </template>
          </v-badge>

          <template v-if="hasChildren">
            <div class="expand-icon">
              <template v-if="groupExpanded">
                <v-icon>mdi-chevron-up</v-icon>
              </template>
              <template v-else>
                <v-icon>mdi-chevron-down</v-icon>
              </template>
            </div>
          </template>
        </v-btn>
      </template>
      <span>{{ item.label }}</span>
    </v-tooltip>
  </v-list-item>
</template>

<script type="text/babel">
import _get from "lodash/get";
import _has from "lodash/has";
import * as designTokens from "DT";
import CamundaIntegrationsApiService from "SC/services/camundaIntegrationsApi";
import CamundaOnboardingApiService from "SC/services/camundaOnboardingApi";
import CamundaOrdersApiService from "SC/services/camundaOrdersApi";
import CamundaPricingApiService from "SC/services/camundaPricingApi";

export default {
  name: "components-navigation-button",
  watch: {},
  props: {
    hasChildren: undefined,
    mini: {
      default() {
        return false;
      }
    },
    depth: {
      default() {
        return 0;
      }
    },
    groupExpanded: undefined,
    item: {
      required: true
    }
  },
  data() {
    return {
      designTokens,
      count: 0
    };
  },
  methods: {
    emitNavigated() {
      this.$emit("navigated", this.route);
    },
    onClick() {
      if (this.hasRoute) {
        this.$router.push(this.route);
        this.emitNavigated();
      }

      this.$emit("click");
    },
    setCount() {
      return this.camundaApiService
        .getFilterTasksCount(this.filter.id)
        .then((res) => {
          this.count = res.count;
        })
        .catch((e) => {
          console.log("Could not retrieve count", e);
        });
    }
  },
  computed: {
    camundaApiService() {
      let serviceKey = _get(this.item, "processKey");
      switch (serviceKey) {
        case "pricing":
          return CamundaPricingApiService;
        case "integrations":
          return CamundaIntegrationsApiService;
        case "orders-workflow":
        case "orders":
          return CamundaOrdersApiService;
        case "onboarding":
        case "default":
        default:
          return CamundaOnboardingApiService;
      }
    },
    colorBox() {
      return {
        backgroundColor: this.color ? this.color : "white"
      };
    },
    color() {
      return _get(this.filter, "properties.color");
    },
    filter() {
      return this.item.filter;
    },
    buttonColor() {
      if (this.isActive) {
        return designTokens.DARK_BLUE_2;
      }

      return "transparent";
    },
    isActive() {
      if (this.item.isActive) {
        return this.item.isActive();
      }

      if (!this.hasRoute) {
        return false;
      }

      return this.routeParsed.route.fullPath === this.$route.fullPath;
    },
    routeParsed() {
      return this.$router.resolve(this.route);
    },
    route() {
      return _get(this.item, "route");
    },
    hasRoute() {
      return _has(this.item, "route");
    }
  },
  created() {},
  mounted() {
    this.setCount();
  },
  beforeDestroy() {},
  mixins: [],
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../../scss/variables";

.button {
  .color-box {
    display: inline-block;
    width: 10px;
    height: 10px;
  }

  &.group-expanded {
  }

  &.expanded {
    justify-content: left;

    .expanded-icon {
      right: 5px;
    }
  }

  .count-badge {
    position: absolute;
    top: 0.875rem;
    right: 1.5rem;
  }

  .expand-icon {
    font-size: 0.8em;
    position: absolute;
    right: -5px;
    opacity: 0.5;
  }
}

.navigation-text-overflow {
  color: var(--v-navigation_text-base);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 170px;
}
</style>
