<template>
  <v-dialog v-model="value" persistent max-width="650">
    <v-card v-if="value">
      <v-card-title
        class="headline d-flex justify-center pt-8"
        id="loginSuccessfulHeader"
      >
        <div class="col-sm-12 d-flex justify-center">
          <v-icon class="success-login-icon"> mdi-exclamation-thick</v-icon>
        </div>
        <div>
          {{
            $i18n(
              "user.becameInactive.title",
              "Your account has been deactivated"
            )
          }}
        </div>
      </v-card-title>

      <v-card-text class="text-center">
        <div class="my-5">
          <p>
            {{
              $i18n(
                "user.becameInactive.body",
                "Please reach out to RunBuggy support to get this cleared up as soon as possible."
              )
            }}
          </p>
        </div>
        <v-btn color="primary" block v-on:click="confirm">Confirm</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script type="text/babel">
import OktaService from "@/services/okta";
import IntercomService from "@/services/intercom";
import TokenService from "SC/services/tokenService";

export default {
  name: "components-modal_user_became_inactive-index",
  watch: {
    value(newValue) {
      if (newValue) {
        //this.logout();
      }
    }
  },
  props: {
    value: undefined
  },
  data() {
    return {};
  },
  methods: {
    logout() {
      if (TokenService.hasToken() || TokenService.hasImpersonationToken()) {
        IntercomService.shutdown();
        TokenService.clearAll();
      }

      OktaService.unauthenticate().then(() => {});
    },
    confirm() {
      this.logout();
    }
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/variables";
@import "../../scss/variables";

.success-login-icon {
  color: $color-COMPLETED;
  font-size: 50px;
}

.selections-container {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 50px;
  padding-right: 50px;
}

.selection-content-section {
  width: 360px;
}

.selection-go-action-section {
  width: 60px;
}

.selection-card {
  border: 2.5px solid $colorLightGray;
  border-radius: 5px;
  padding: 10px 15px;
  opacity: 0.8;
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}

.selection-card:hover {
  cursor: pointer;
  border: 2.5px solid var(--v-primary-base);
  background-color: $colorLightGray;
  transition: 0.6s;
  opacity: 1;

  .go-to-view-icon {
    visibility: visible;
    opacity: 1;
    transition: 0.8s ease-out;
  }
}

.selection-header {
  font-size: 15px;
  font-weight: 600;
}

.selection-subheader {
  font-size: 13px;
  color: $colorBlueGrey;
}

.active-selection {
  border: 2.5px solid var(--v-primary-base);
  transition: 0.4s;
}

.go-to-view-icon {
  color: $color-WHITE !important;
  background-color: var(--v-primary-base) !important;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
</style>
