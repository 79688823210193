import TokenService from "../services/tokenService";
import axios from "axios";
import _get from "lodash/get";
import _isNumber from 'lodash/isNumber'

const Client = {
  axiosClient: axios.create(),
  getDocuments(page, size, params = {}) {
      let path = `${_get(window, 'CONFIG.onboarding.api.path')}/documents`;
      let query = [];

      if (_isNumber(page)) {
          query.push(`page=${page}`)
      }

      if (size) {
          query.push(`size=${size}`)
      }

      Object.keys(params).forEach((key) => {
          let value = params[key];
          query.push(`${key}=${value}`);
      });

      if (query.length > 0) {
          path += `?${query.join('&')}`;
      }
      return this.get(path);
  },

  getDocumentsByAccountId(accountId, page, size, params = {}) {
      let path = `${_get(window, 'CONFIG.onboarding.api.path')}/documents?query=accountId==${accountId}`;
      let query = [];

      if (_isNumber(page)) {
          query.push(`page=${page}`)
      }

      if (size) {
          query.push(`size=${size}`)
      }

      Object.keys(params).forEach((key) => {
          let value = params[key];
          query.push(`${key}=${value}`);
      });

      if (query.length > 0) {
          path += `&${query.join('&')}`;
      }
      return this.get(path);
  },

  getDocument(documentId) {
      let path = `${_get(window, 'CONFIG.onboarding.api.path')}/documents/${documentId}`;
      return this.get(path);
  },

  createDocument(data) {
      let path = `${_get(window, 'CONFIG.onboarding.api.path')}/documents`;
      return this.post(path, data);
  },

  downloadDocument(documentId, params = {}) {
      let path = `${_get(window, 'CONFIG.onboarding.api.path')}/documents/${documentId}/download`;
      let query = [];
      Object.keys(params).forEach((key) => {
        let value = params[key];
        query.push(`${key}=${value}`);
      });
      if (query.length > 0) {
        path += `?${query.join('&')}`;
      }
      return this.get(path);
  },

  deleteDocument(documentId) {
      let path = `${_get(window, 'CONFIG.onboarding.api.path')}/documents/${documentId}`;
      return this.delete(path);
  },

  getAccountPolicies(accountId) {
    let path = `${_get(window, 'CONFIG.onboarding.api.path')}/account-policies/${accountId}`;
    return this.get(path);
  },

  addBankAccount(accountId, data) {
    let path = `${_get(window, 'CONFIG.onboarding.api.path')}/accounts/${accountId}/bank-accounts`;
    return this.post(path, data);
  },

  updateBankAccount(accountId, bankAccountId, data) {
    let path = `${_get(window, 'CONFIG.onboarding.api.path')}/accounts/${accountId}/bank-accounts/${bankAccountId}`;
    return this.patch(path, data);
  },

  deleteBankAccount(accountId, bankAccountId) {
    let path = `${_get(window, 'CONFIG.onboarding.api.path')}/accounts/${accountId}/bank-accounts/${bankAccountId}`;
    return this.delete(path);
  },

  getBankAccount(accountId, bankAccountType) {
    let path = `${_get(window, 'CONFIG.onboarding.api.path')}/accounts/${accountId}/bank-accounts/${bankAccountType}`;
    return this.get(path);
  },

  getBankAccounts(accountId) {
    let path = `${_get(window, 'CONFIG.onboarding.api.path')}/accounts/${accountId}/bank-accounts`;
    return this.get(path);
  },

  getStripeBankAccount(accountId, bankAccountId) {
    let path = `${_get(window, 'CONFIG.onboarding.api.path')}/stripe/accounts/${accountId}/bank-account/${bankAccountId}`;
    return this.get(path);
  },

  verifyStripeBankAccount(accountId, bankAccountId, data) {
    let path = `${_get(window, 'CONFIG.onboarding.api.path')}/stripe/accounts/${accountId}/bank-account/${bankAccountId}/verify`;
    return this.post(path, data);
  },

  getStripeCard(accountId, cardId) {
    let path = `${_get(window, 'CONFIG.onboarding.api.path')}/stripe/accounts/${accountId}/card/${cardId}`;
    return this.get(path);
  },

  connectOnboarding(accountId, params) {
    let path = `${_get(window, 'CONFIG.onboarding.api.path')}/stripe/connect-onboarding/${accountId}`;
    let query = []
    Object.keys(params).forEach((key) => {
        let value = params[key];
        query.push(`${key}=${value}`);
    });

    if (query.length > 0) {
        path += `?${query.join('&')}`;
    }
    return this.get(path);
  },

  connectUpdateProfile(accountId, params) {
    let path = `${_get(window, 'CONFIG.onboarding.api.path')}/stripe/connect-profile/${accountId}`;
    let query = []
    Object.keys(params).forEach((key) => {
        let value = params[key];
        query.push(`${key}=${value}`);
    });

    if (query.length > 0) {
        path += `?${query.join('&')}`;
    }
    return this.get(path);
  },

  getInvoiceAccounts(accountId) {
    let path = `${_get(window, 'CONFIG.onboarding.api.path')}/accounts/${accountId}/invoice-accounts`;
    return this.get(path);
  },

  saveInvoiceAccount(accountId, data) {
    let path = `${_get(window, 'CONFIG.onboarding.api.path')}/accounts/${accountId}/invoice-accounts`;
    return this.post(path, data);
  },

  deleteInvoiceAccount(accountId, invoiceId) {
    let path = `${_get(window, 'CONFIG.onboarding.api.path')}/accounts/${accountId}/invoice-accounts/${invoiceId}`;
    return this.delete(path);
  },
  
  // common calls with auth token
  get(path) {
      let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
      let params = {};
      if (TokenService.hasImpersonatedByUsername()) {
          params.byAdmin = TokenService.getImpersonatedByUsername();
      }

      return Client.axiosClient.get(path, {
          headers: {
              'Authorization': `Bearer ${token}`
          },
          params
      });
  },

  getFile(path) {
      let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
      let params = { access_token: token };

      if (TokenService.hasImpersonatedByUsername()) {
        params.byAdmin = TokenService.getImpersonatedByUsername();
      }

      return Client.axiosClient.get(path, { params });
  },

  post(path, data) {
      let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
      let params = {};
      if (TokenService.hasImpersonatedByUsername()) {
          params['byAdmin'] = TokenService.getImpersonatedByUsername();
      }

      return Client.axiosClient.post(path, data, {
          headers: {
              'Authorization': `Bearer ${token}`
          },
          params
      });
  },

  patch(path, data) {
      let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
      let params = {};
      if (TokenService.hasImpersonatedByUsername()) {
          params['byAdmin'] = TokenService.getImpersonatedByUsername();
      }

      return Client.axiosClient.patch(path, data, {
          headers: {
              'Authorization': `Bearer ${token}`
          },
          params
      });
  },

  put(path, data) {
     let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
     let params = {};
     if (TokenService.hasImpersonatedByUsername()) {
         params['byAdmin'] = TokenService.getImpersonatedByUsername();
     }

     return Client.axiosClient.put(path, data, {
         headers: {
             'Authorization': `Bearer ${token}`
         },
         params
     });
  },

  delete(path) {
      let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
      let params = {};
      if (TokenService.hasImpersonatedByUsername()) {
          params['byAdmin'] = TokenService.getImpersonatedByUsername();
      }

      return Client.axiosClient.delete(path, {
          headers: {
              'Authorization': `Bearer ${token}`
          },
          params
      });
  }
};

export default Client;
