<template>
  <div>
    <template v-if="shouldShow">
      <template v-if="!hasChildren">
        <item-button
          v-bind:id="
            item.label
              ? `side-menu-item-${item.label.split(' ').join('-')}`
              : undefined
          "
          v-on:click="onClick"
          v-bind:mini="mini"
          v-bind:depth="depth"
          v-bind:item="item"
          v-on:navigated="emitNavigated"
        ></item-button>
      </template>
      <template v-else>
        <div class="list-group" v-bind:class="{ expanded: groupExpanded }">
          <item-button
            v-bind:id="
              item.label
                ? `side-menu-item-${item.label.split(' ').join('-')}`
                : undefined
            "
            v-on:click="onClick"
            v-bind:mini="mini"
            v-bind:depth="depth"
            v-bind:item="item"
            v-bind:group-expanded="groupExpanded"
            v-bind:has-children="hasChildren"
            v-on:navigated="emitNavigated"
            v-if="!mini"
          ></item-button>
          <div class="children" v-if="groupExpanded">
            <template v-for="(child, index) in children">
              <template v-if="child.type === ITEM_TYPE_CAMUNDA_FILTER">
                <camunda-filter-item
                  v-bind:key="`camunda_filter_${index}`"
                  v-on:navigated="emitNavigated"
                  v-bind:params="child.typeParams"
                  v-bind:mini="mini"
                  v-bind:item="child"
                  v-bind:depth="depth + 1"
                />
              </template>
              <template v-else-if="child.type === TASK_MANAGEMENT_FILTER">
                <task-management-menu-item
                  v-bind:key="`navigation_filter_${index}`"
                  v-on:navigated="emitNavigated"
                  v-bind:params="child.typeParams"
                  v-bind:mini="mini"
                  v-bind:item="child"
                  v-bind:depth="depth + 1"
                />
              </template>
              <template v-else>
                <components-navigation-item
                  v-on:navigated="emitNavigated"
                  v-bind:mini="mini"
                  v-bind:depth="depth + 1"
                  v-bind:key="
                    item.label
                      ? `navigation_item_${item.label}_child_${index}`
                      : undefined
                  "
                  v-bind:item="child"
                >
                </components-navigation-item>
              </template>
            </template>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script type="text/babel">
import _get from "lodash/get";
import { mapActions, mapGetters } from "vuex";
import ItemButton from "./button";
import CamundaFilterItem from "./camunda-filter-item";
import TaskManagementMenuItem from "./task-management-menu-item";
const ITEM_TYPE_CAMUNDA_FILTER = "ITEM_TYPE_CAMUNDA_FILTER";
const TASK_MANAGEMENT_FILTER = "TASK_MANAGEMENT_FILTER";

export default {
  name: "components-navigation-item",
  watch: {},
  props: {
    mini: {
      default() {
        return false;
      },
    },
    roles: undefined,
    depth: {
      default() {
        return 0;
      },
    },
    item: {
      required: true,
    },
  },
  data() {
    return {
      additionalChildren: [],
      ITEM_TYPE_CAMUNDA_FILTER,
      TASK_MANAGEMENT_FILTER,
    };
  },
  methods: {
    emitNavigated(route) {
      this.$emit("navigated", route);
    },
    onClick() {
      if (this.item.callback) {
        this.item.callback(this);
        console.log("Item has callback");
      } else if (this.hasChildren && this.groupKey) {
        this.toggleGroupExpanded(this.groupKey);
      }
    },
    ...mapActions("ui", ["toggleGroupExpanded"]),
  },
  computed: {
    groupKey() {
      return _get(this.item, "groupKey");
    },
    groupExpanded() {
      return this.uiCollapsedGroupKeys.indexOf(this.groupKey) === -1;
    },
    shouldShow() {
      let shouldShow = true;
      let hasPositiveMatch = false;
      if (this.item.featureAccess === false) {
        shouldShow = false;
      }

      if (this.item.roles && !hasPositiveMatch) {
        let matches = this.item.roles.filter(
          (value) => -1 !== this.userRoles.indexOf(value)
        );
        shouldShow = matches.length > 0;

        if (shouldShow) {
          hasPositiveMatch = true;
        }
      }

      if (this.item.groups && !hasPositiveMatch) {
        let matches = this.item.groups.filter(
          (value) => -1 !== this.userGroups.indexOf(value)
        );
        shouldShow = matches.length > 0;

        if (shouldShow) {
          hasPositiveMatch = true;
        }
      }

      if (this.item.hideForRoles) {
        let matches = this.item.hideForRoles.filter(
          (value) => -1 !== this.userRoles.indexOf(value)
        );
        shouldShow = (matches.length > 0) ? false : shouldShow;
      }
      
      if(_get(this.item, "route.name") === "reports-invoices" && this.hasAnyTmsRole && !this.showInvoicesReport) {
        shouldShow = false;
      }

      return shouldShow;
    },
    hasChildren() {
      return this.children.length > 0;
    },
    children() {
      let allChildren = _get(this.item, "children", []);
      allChildren = allChildren.concat(this.additionalChildren);
      return allChildren;
    },
    ...mapGetters("user", ["userRoles", "userGroups", "companyData", "hasAnyTmsRole"]),
    ...mapGetters("ui", {
      uiCollapsedGroupKeys: "collapsedGroupKeys",
    }),
    showInvoicesReport() {
      return _get(this.companyData, "status.showInvoicesReport", false);
    },
  },
  created() {
    if (this.hasChildren && !this.item.groupKey) {
      throw new Error("Navigation item with children must have a group key");
    }
  },
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {
    CamundaFilterItem,
    TaskManagementMenuItem,
    ItemButton,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.list-group {
  position: relative;
  border-top: 1px solid rgba(#fff, 0.15);

  &.expanded {
    background: rgba(#fff, 0.0125);
  }
}
</style>
