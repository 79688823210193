<template>
  <div class="component item-container my-3" v-on:click="$emit('click')">
    <div class="d-flex">
      <div class="mr-3 px-5">
        <name-avatar v-bind:name="_get(item, 'name', '')"></name-avatar>
      </div>
      <div class="flex-grow-1 d-flex flex-column">
        <div class="font-weight-bold mb-2 d-flex align-center">
          <div class="flex-grow-1"> {{_get(item, 'name', '')}} </div>
          <div>
            <v-chip
              color="primary"
              class="mr-2 pa-2"
              label
              x-small
              >
              {{ _get(item, 'type', '--') }}
            </v-chip>
          </div>
        </div>
        <div class="mb-2">{{_get(item, 'address', '--')}}</div>
        <div style="color: #aaa">{{contactInfo}}</div>
      </div>

      <div class="d-flex justify-right align-center action" v-if="!hideAction">
        <div class="text-right">
          <v-icon color="primary">mdi-chevron-right</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import NameAvatar from "@/components/name-avatar";
import _get from "lodash/get";

export default {
  name: "components-forms-account_management-authorized_shippers-add_authorized-list_item",
  watch: {},
  props: {
    hideAction: undefined,
    item: {
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    _get
  },
  computed: {
    contactInfo() {
      return `${_get(this.item, 'contacts.0.name', '-')} - ${_get(this.item, 'contacts.0.email', '-')} / ${_get(this.item, 'contacts.0.phone', '-')}`;
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {
    NameAvatar
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.action {
  padding: 0 1em;
  border-left: 1px solid rgb(247, 247, 247);
}
.item-container {
  font-size: 13px;
}
</style>
