import { mapGetters } from "vuex";

const mixin = {
  props: {},
  methods: {
    getKilometersFromMiles(val) {
      if (isNaN(val)) return;
      return parseFloat(val * 1.609344);
    },
    getLocaleDistance(miles) {
      return this.isMetricSystem
        ? this.getKilometersFromMiles(miles).toFixed(0)
        : parseFloat(miles).toFixed(0);
    },
    getFormatedLocaleDistance(miles) {
      if (isNaN(miles)) return;
      let val = this.isMetricSystem
        ? this.getKilometersFromMiles(miles)
        : parseFloat(miles);
      return new Intl.NumberFormat(this._locale, {
        style: "unit",
        unit: this._distanceUnit,
        maximumFractionDigits: 0,
      }).format(val);
    },
    getFormatedLocaleDistanceLong(miles) {
      if (isNaN(miles)) return;
      let val = this.isMetricSystem
        ? this.getKilometersFromMiles(miles)
        : parseFloat(miles);
      return new Intl.NumberFormat(this._locale, {
        style: "unit",
        unit: this._distanceUnit,
        unitDisplay: "long",
        maximumFractionDigits: 0,
      }).format(val);
    },
    formatLocaleCurrency(val) {
      if (isNaN(val)) return;
      return new Intl.NumberFormat(this._locale, {
        style: "currency",
        currency: this._currency,
      }).format(val);
    },
    formatLocaleCurrencyNoDecimals(val) {
      if (isNaN(val)) return;
      return new Intl.NumberFormat(this._locale, {
        style: "currency",
        currency: this._currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(val);
    },
    getLocaleCurrencySymbol() {
      return (0)
        .toLocaleString(this._locale, {
          style: "currency",
          currency: this._currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, "")
        .trim();
    },
  },
  computed: {
    navigatorLanguage() {
      return navigator.language;
    },
    _locale() {
      if (this.operationalCountry === "canada") {
        return this.navigatorLanguage || "en-CA";
      } else {
        return this.navigatorLanguage || "en-US";
      }
    },
    _currency() {
      if (this.operationalCountry === "canada") {
        return "CAD";
      } else {
        return "USD";
      }
    },
    _distanceUnit() {
      if (this.operationalCountry === "canada") {
        return "kilometer";
      } else {
        return "mile";
      }
    },
    isMetricSystem() {
      return this.operationalCountry === "canada" ? true : false;
    },
    ...mapGetters("user", ["operationalCountry"]),
  },
};

export default mixin;
