import {Service as CamundaApiService} from './camundaApi'
import Client from "../clients/camundaPricingApi";

export class Service extends CamundaApiService {

    constructor() {
        super();
        this.client = Client;
    }

    getDecisionRequirementDefinitions(key){
        return this.client.getDecisionRequirementDefinitions(key)
            .then((res) => {
                return res.data;
            });
    }

    getDecisionDefinitions(key){
        return this.client.getDecisionDefinitions(key)
            .then((res) => {
                return res.data;
            });
    }

    getDecisionRequirementDefinitionXML(id){
        return this.client.getDecisionRequirementDefinitionXML(id)
            .then((res) => {
                return res.data;
            });
    }

    getDecisionDefinitionXML(id){
        return this.client.getDecisionDefinitionXML(id)
            .then((res) => {
                return res.data;
            });
    }

    deployDefinition(name, xml){
        return this.client.deployDefinition(name, xml)
            .then((res) => {
                return res.data;
            });
    }

    removeDeploymentWithID(id) {
        return this.client.removeDeploymentWithID(id)
            .then((res) => {
                return res.data;
            });
    }

}

const service = new Service();

export default service;
