// mutation-types.js
export const USER_ID = "USER_ID";
export const FIRST_NAME = "FIRST_NAME";
export const LAST_NAME = "LAST_NAME";
export const USERNAME = "USERNAME";
export const TOKEN = "TOKEN";
export const USER_DATA = "USER_DATA";
export const USER_TASKS_CONFIG = "USER_TASKS_CONFIG";
export const IS_IMPERSONATING = "IS_IMPERSONATING";
export const IMPERSONATOR_USERNAME = "IMPERSONATOR_USERNAME";
export const TEMPORARY_PASSWORD = "TEMPORARY_PASSWORD";
export const AUTH_STATE = "AUTH_STATE";
export const FEATURE_ACCESS_KEYS = "FEATURE_ACCESS_KEYS";
export const TRUSTED_DEVICE = "TRUSTED_BROWSER";
export const ACTIVE_COMPANY_INFO = "ACTIVE_COMPANY_INFO";
export const SELECTED_TENANT = "SELECTED_TENANT";
export const ACTIVE_THEME = "ACTIVE_THEME";
