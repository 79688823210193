import * as types from "./mutation-types";

// initial state
const state = {
  listItemMapMarkerClickIndex: undefined,
  listItemMapMarkerHoverIndex: undefined,
  listScrollPosition: undefined,
};

// getters
const getters = {
  listItemMapMarkerHoverIndex(state) {
    return state.listItemMapMarkerHoverIndex;
  },
  listItemMapMarkerClickIndex(state) {
    return state.listItemMapMarkerClickIndex;
  },
  listScrollPosition(state) {
    return state.listScrollPosition;
  },
};

// actions
const actions = {
  setListItemMapMarkerClickIndex($store, index) {
    $store.commit(types.LIST_ITEM_MAP_MARKER_CLICK_INDEX, index);
  },
  setListItemMapMarkerHoverIndex($store, index) {
    $store.commit(types.LIST_ITEM_MAP_MARKER_HOVER_INDEX, index);
  },
  setListScrollPosition($store, position) {
    $store.commit(types.LIST_SCROLL_POSITION, position);
  },
};

// mutations
const mutations = {
  [types.LIST_ITEM_MAP_MARKER_CLICK_INDEX](state, payload) {
    if (typeof payload !== "object") {
      payload = { index: payload };
    }
    state.listItemMapMarkerClickIndex = payload;
  },
  [types.LIST_ITEM_MAP_MARKER_HOVER_INDEX](state, payload) {
    if (typeof payload !== "object") {
      payload = { index: payload };
    }
    state.listItemMapMarkerHoverIndex = payload;
  },
  [types.LIST_SCROLL_POSITION](state, payload) {
    if (typeof payload !== "object") {
      payload = { offsetTop: payload };
    }
    state.listScrollPosition = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
