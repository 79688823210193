import { io } from "socket.io-client";
import _get from "lodash/get";

const MessagingService = (() => {
  return {
    socket: undefined,
    connected: false,
    eventHistory: [],
    callbacks: {},
    connectPromise: undefined,
    init() {},
    addCallback(eventName, callback) {

      if (!this.callbacks[eventName]) {
        this.callbacks[eventName] = [];
      }

      if (this.callbacks[eventName].indexOf(callback) > -1) {
        console.log("Messaging service:", "Callback already added");
        return;
      }

      this.callbacks[eventName].push(callback);
    },
    flushCallbacks() {
      this.callbacks = {};
    },
    handleEvent(eventName, args) {
      console.log(
        "Socket:",
        "Incoming event:",
        eventName,
        args,
        JSON.stringify(args)
      );

      this.eventHistory.push({
        eventName,
        args,
      });

      _get(this.callbacks, eventName, []).forEach((callback) => {
        console.log(
          "Socket:",
          "Callbacks",
          "Callback found",
          eventName,
          callback
        );
        callback(args);
      });
    },
    onError(error) {
      console.log("Socket:", "Error:", error);
    },
    onDisconnect() {
      console.log("Socket:", "Disconnected");
      this.connected = false;
    },
    resetSocketListeners() {
      console.log("Socket:", "Resetting socket listeners");
      ["connect", "error", "connect_error", "disconnect"].forEach(
        (eventName) => {
          this.socket.off(eventName);
        }
      );
      this.socket.offAny();

      this.socket.on("error", this.onError);
      this.socket.on("disconnect", this.onDisconnect);
      this.socket.onAny((eventName, args) => {
        this.handleEvent(eventName, args);
      });
    },
    connect(jwt) {
      console.log("Socket:", "Connecting", jwt);

      if (this.socket) {
        console.log("Socket:", "Already connected");
        return Promise.resolve();
      }

      return new Promise((resolve, reject) => {
        let host = window.location.host;
        let pathName = window.location.pathname;
        let protocol = "ws://";
        if (location.protocol === "https:") {
          protocol = "wss://";
        }

        let url = `${protocol}${host}`;
        let path = `${pathName}${_get(window, "CONFIG.messaging.api.path")}`;

        console.log("Socket:", "Connecting url", url);
        console.log("Socket:", "Connecting path", path);

        try {
          this.socket = io(url, {
            path: path,
            auth: { token: jwt },
          });
        } catch (e) {
          console.log("Socket:", e);
          reject(e);
        }
        this.resetSocketListeners();

        resolve();
      });
    },
    onConnect() {
      console.log("Socket:", "Connected");
      this.connected = true;
    },
    onConnectError(e) {
      console.log("Socket:", "Connection error:", e);
      this.socket = undefined;
    },
    flushAll() {
      this.eventHistory = [];
      this.flushCallbacks();
    },
    reconnect(jwt) {
      console.log("Socket:", "Reconnecting", jwt);
      this.disconnect();
      return this.connect(jwt);
    },
    disconnect() {
      this.flushAll();

      if (!this.socket) {
        console.log("Socket:", "Not connected");
        return;
      }

      this.socket.disconnect();
      this.socket = undefined;
    },
  };
})();

export { MessagingService };
