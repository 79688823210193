import Handlebars from 'handlebars/dist/handlebars.min.js';
import Translations from "@/i18n/us_en/translations";

export const i18n = function (
  key,
  fallback = undefined,
  params = {},
  fallbackParamsOverride = undefined
) {
  let translation = Translations[key];
  
  if (!fallbackParamsOverride) {
    fallbackParamsOverride = params;
  }

  if (translation) {
    let template = Handlebars.compile(translation);
    return template(params);
  }
  
  if (fallback) {
    let template = Handlebars.compile(fallback);
    return template(fallbackParamsOverride);
  }

  return key;
};

const Internationalization = {
  install(Vue, options) {
    Vue.prototype.$i18n = i18n
  },
};

export default Internationalization;
