import axios from "axios/index";
import FileService from '../services/fileService'
import TokenService from '../services/tokenService'
import CamundaApiService from '../services/camundaApi'
import _get from 'lodash/get'


export class Client {
    createPath() {
        let endpoint = _get(window, 'CONFIG.camunda.api.routeEndpoint');

        if (!endpoint) {
            endpoint = _get(window, 'CONFIG.camunda.api.defaultEndpoint');
        }

        let parts = [
            _get(window, 'CONFIG.camunda.api.prefix'),
            '/',
            endpoint,
            _get(window, 'CONFIG.camunda.api.suffix')
        ];

        return parts.join('');
    }

    getTaskVariableData(taskId, variableName) {
        let path = CamundaApiService.variableInstanceDataPath(taskId, variableName);
        return this.get(path);
    }

    getFilters(options = {}) {
        let path = `${this.createPath()}/filter`;
        return this.get(path, options);
    }

    getFilterTasks(filterId, query, params) {
        let path = `${this.createPath()}/filter/${filterId}/list`;

        return this.post(path, query, {
            params,
            'headers': {
                'Accept': 'application/hal+json, application/json'
            }
        });
    }

    getFilterTasksCount(filterId, query) {
        let path = `${this.createPath()}/filter/${filterId}/count`;
        return this.post(path, query);
    }

    claimTask(taskId, userId) {
        let path = `${this.createPath()}/task/${taskId}/claim`;
        return this.post(path, {
            userId: userId
        });
    }

    createTaskComment(taskId, message) {
        let path = `${this.createPath()}/task/${taskId}/comment/create`;
        return this.post(path, {
            message: message
        });
    }

    unclaimTask(taskId) {
        let path = `${this.createPath()}/task/${taskId}/unclaim`;
        return this.post(path);
    }

    getTasks(filter) {
        let path = `${this.createPath()}/task`;
        return this.get(path, {
            params: filter
        });
    }

    getTasksCount(filter) {
        let path = `${this.createPath()}/task/count`;
        return this.get(path, {
            params: filter
        });
    }

    getTask(taskId) {
        let path = `${this.createPath()}/task/${taskId}`;
        return this.get(path);
    }

    getTaskHistory(taskid) {
        let path = `${this.createPath()}/history/task-id/${taskid}`;
        return this.get(path);
    }

    getTaskComments(taskId) {
        let path = `${this.createPath()}/task/${taskId}/comment`;
        return this.get(path);
    }


    updateTask(taskId, data) {
        let path = `${this.createPath()}/task/${taskId}`;
        return this.put(path, data);
    }

    getTaskForm(taskId) {
        let path = `${this.createPath()}/task/${taskId}/deployed-form`;
        return this.get(path);
    }

    submitFormOnProcessDefinitionById(processId, data) {
        let path = this.createPath().replace('onboarding', 'orders-workflow');
        path = `${path}/process-definition/${processId}/submit-form`;
        return this.post(path, data);
    }

    getProcessDefinitionById(processId) {
        let path = `${this.createPath()}/process-definition/${processId}`;
        return this.get(path);
    }

    getProcessDefinitionByKey(processKey) {
        let path = `${this.createPath()}/process-definition/key/${processKey}`;
        return this.get(path);
    }

    getDecisionRequirementDefinitions(key) {
        let path = `${this.createPath()}/decision-requirements-definition?sortBy=version&sortOrder=desc`;
        if(key) {
            path += `&key=${key}`;
        }
        return this.get(path);
    }

    getDecisionDefinitions(key) {
        let path = `${this.createPath()}/decision-definition?sortBy=version&sortOrder=desc`;
        if(key) {
            path += `&key=${key}`;
        }
        return this.get(path);
    }

    getDecisionRequirementDefinitionXML(id) {
        let path = `${this.createPath()}/decision-requirements-definition/${id}/xml`;
        return this.get(path);
    }

    getDecisionDefinitionXML(id) {
        let path = `${this.createPath()}/decision-definition/${id}/xml`;
        return this.get(path);
    }

    deployDefinition(name, xml) {
        let path = `${this.createPath()}/deployment/create`;
        const form = new FormData();
        form.append("enable-duplicate-filtering", "false");
        form.append("deploy-changed-only", "false");
        form.append("data", new Blob([xml], {type: "text/xml"}), name);
        return this.post(path, form);
    }

    removeDeploymentWithID(id) {
        let path = `${this.createPath()}/deployment/${id}`;
        return this.delete(path);
    }

    getDeploymentResources(deploymentId) {
        let path = `${this.createPath()}/deployment/${deploymentId}/resources`;
        return this.get(path);
    }

    getDeploymentResourceData(deploymentId, resourceId) {
        let path = `${this.createPath()}/deployment/${deploymentId}/resources/${resourceId}/data`;
        return this.get(path);
    }

    startProcessInstanceId(definitionId, body = {}) {
        let path = `${this.createPath()}/process-definition/key/${definitionId}/start`;
        return this.post(path, {});
    }

    getProcessInstance(instanceID) {
        let path = `${this.createPath()}/process-instance/${instanceID}`;
        return this.get(path);
    }

    getProcessInstanceHistory(instanceID) {
        let path = `${this.createPath()}/history/process-instance/${instanceID}`;
        return this.get(path);
    }

    getProcessInstanceHistoryCount(params = {}) {
        let path = `${this.createPath()}/history/process-instance/count`;
        return this.get(path, params);
    }

    getProcessInstanceVariables(instanceID) {
        let path = `${this.createPath()}/process-instance/${instanceID}/variables?deserializeValues=false`;
        return this.get(path);
    }

    getUserOperationLog(instanceID) {
        let path = `${this.createPath()}/history/user-operation?processInstanceId=${instanceID}`;
        return this.get(path);
    }

    getUserOperationLogTask(taskId) {
        let path = `${this.createPath()}/history/user-operation?taskId=${taskId}`;
        return this.get(path);
    }

    getProcessInstanceHistoryDetails(instanceID) {
        let path = `${this.createPath()}/history/detail?processInstanceId=${instanceID}`;
        return this.get(path);
    }

    getProcessInstanceTasks(instanceID, active = 'true') {
        let path = `${this.createPath()}/task?processInstanceId=${instanceID}&active=${active}&sortBy=priority&sortOrder=desc`;
        return this.get(path);
    }

    startProcessInstanceKey(definitionKey, body = {}) {
        let path = `${this.createPath()}/process-definition/key/${definitionKey}/start`;
        return this.post(path, body);
    }

    getProcessStartFormKey(definitionId) {
        let path = `${this.createPath()}/process-definition/${definitionId}/startForm`;
        return this.get(path);
    }

    getProcessInstanceActivityInstances(instanceID) {
        let path = `${this.createPath()}/process-instance/${instanceID}/activity-instances`;
        return this.get(path);
    }

    getProcessInstanceList(query) {
        let path = `${this.createPath()}/process-instance`;
        return this.post(path, query);
    }

    getHistoryProcessInstanceList(query) {
        let path = `${this.createPath()}/history/process-instance`;
        return this.post(path, query);
    }

    postTaskForm(taskId, body) {
        let path = `${this.createPath()}/task/${taskId}/submit-form`;
        return this.post(path, body);
    }

    postMessage(body) {
        let path = `${this.createPath()}/message`;
        return this.post(path, body);
    }

    uploadBatchOrderFile(file, limitPerOrder, paymentBy, templateName) {
        let path = `${this.createPath()}/process-definition/key/UploadFiles/submit-form`;

        return FileService.convertFileToString(file)
            .then((fileString) => {
                return this.post(path, {
                    variables: {
                        "bulkOrdersFile": {
                            "value": fileString,
                            "type": "File",
                            "valueInfo": {
                                "filename": file.name,
                                "mimeType": file.type ? file.type : ''
                            }
                        },
                        "limitPerOrder": {
                            "value": limitPerOrder,
                            "type": "Integer"
                        },
                        "paymentBy": {
                            "value": paymentBy,
                            "type": "String"
                        },
                        "templateName": {
                            "value": templateName,
                            "type": "String"
                        }
                    }
                });
            });
    }

    get(path, options = {}) {
        if (_get(window, 'CONFIG.camunda.enableAuth')) {
            let headers = options.headers || {};
            headers['Authorization'] = 'Basic YW5vbnltb3VzOg==';

            let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }

            options.headers = headers;
        }


        return this.axiosClient.get(path, options);
    }

    post(path, data, options = {}) {
        if (_get(window, 'CONFIG.camunda.enableAuth')) {
            let headers = options.headers || {};
            headers['Authorization'] = 'Basic YW5vbnltb3VzOg==';

            let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }

            options.headers = headers;
        }

        return this.axiosClient.post(path, data, options);
    }

    put(path, data, options = {}) {
        if (_get(window, 'CONFIG.camunda.enableAuth')) {
            let headers = options.headers || {};
            headers['Authorization'] = 'Basic YW5vbnltb3VzOg==';

            let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }

            options.headers = headers;
        }

        return this.axiosClient.put(path, data, options);
    }

    delete(path, options = {}) {
        if (_get(window, 'CONFIG.camunda.enableAuth')) {
            let headers = options.headers || {};
            headers['Authorization'] = 'Basic YW5vbnltb3VzOg==';

            let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }

            options.headers = headers;
        }

        return this.axiosClient.delete(path, options);
    }

}

const client = new Client();
client.axiosClient = axios.create();

export default client;
