<template>
  <div class="d-inline-block">
    <span
      v-if="showIcon"
      class="info-icon"
      v-bind:class="{ 'inverse-icon': inverseIcon }"
      v-on:mouseover="onmouseoverIcon"
      v-on:mouseout="onmouseoutIcon"
    >
      <v-icon>information</v-icon>
    </span>
    <div
      class="component feature-explanation shadow-lg"
      v-if="isActive || mouseoverIcon"
    >
      <div class="triangle"></div>
      <slot name="content"></slot>
      <div class="text-right pt-2" v-if="isActive">
        <v-btn
          small
          color="white"
          style="font-size: 12px"
          v-on:click="onDismiss"
          >Okay</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import * as FeatureExplanationMutationTypes from "@/store/modules/feature-explanation/mutation-types";

import { mapGetters } from "vuex";

export default {
  name: "components-feature_explanation-index",
  watch: {},
  props: {
    dismissPermanently: {
      default() {
        return true;
      },
    },
    inverseIcon: undefined,
    showIcon: undefined,
    id: undefined,
  },
  data() {
    return {
      mouseoverIcon: false,
    };
  },
  methods: {
    onmouseoverIcon() {
      this.mouseoverIcon = true;
    },
    onmouseoutIcon() {
      this.mouseoverIcon = false;
    },
    onDismiss() {
      this.$featureExplanationDismiss(this.id, this.dismissPermanently);
    },
  },
  computed: {
    isActive() {
      return this.activeKeys.indexOf(this.id) > -1;
    },
    ...mapGetters("featureExplanation", ["activeKeys"]),
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgb(130, 177, 255);
  position: absolute;
  top: -10px;
  left: 100px;
}

.info-icon {
  cursor: help;
  position: relative;
  z-index: 1;
  width: 22px;
  overflow: hidden;
  display: inline-block;
  height: 22px;
  top: 2px;

  > .v-icon {
    width: 20px;
    position: absolute;
    left: 64px;
    font-size: 18px;
    top: 0;
    color: rgba(0, 0, 0, 0.25) !important;
  }

  &:hover {
    > .v-icon {
      color: rgba(0, 0, 0, 0.5) !important;
    }
  }

  &.inverse-icon {
    > .v-icon {
      color: rgba(255, 255, 255, 0.25) !important;
    }

    &:hover {
      > .v-icon {
        color: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }
}

.feature-explanation {
  line-break: auto;
  text-align: left;
  background-color: rgb(130, 177, 255);
  max-width: 200px;
  min-width: 150px;
  padding: 1em;
  color: white;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  border: solid 1px white;
  position: absolute;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: -100px;
  z-index: 2;
  overflow-wrap: break-word;
  word-break: normal;
  white-space: normal;
  text-transform: none;
}
</style>
