const unleashMixin = {
  computed: {
    isHitchPaymentFeatureEnabled() {
      return this.$unleashClient?.isEnabled("spav2.hitch.payment.processor");
    },
    isMarketplaceFinancialReviewFeatureEnabled() {
      return this.$unleashClient?.isEnabled(
        "spav2.marketplace.financial.review"
      );
    },
    isMarketplaceAdminSortByFeatureEnabled() {
      return this.$unleashClient?.isEnabled("spav2.marketplace.admin.sortBy");
    },
    /**
     * Checks if the "spav2.tms.transporter.multi-vin-search" feature is enabled.
     *
     * @return {boolean} True if the feature is enabled, false otherwise.
     */
    isTmsTransporterMultiVinSearchFeatureEnabled() {
      return this.$unleashClient?.isEnabled(
        "spav2.tms.transporter.multi-vin-search"
      );
    },
    isNotificationsSidebarFeatureEnabled() {
      return this.$unleashClient?.isEnabled(
        "spav2.tasks.notifications-sidebar"
      );
    },
    isEditVehicleMeasurementsFeatureEnabled() {
      return this.$unleashClient?.isEnabled(
        "spav2.vehicles.edit-vehicle-measurements"
      );
    },
    isEditVehicleTypeFeatureEnabled() {
      return this.$unleashClient?.isEnabled("spav2.vehicles.edit-vehicle-type");
    },
    isMessagingSpaV3FeatureEnabled() {
      return this.$unleashClient?.isEnabled("spav2.messaging.spav3");
    },
  },
};

export default unleashMixin;
