/*jshint esversion: 6 */

import _get from "lodash/get";
import _has from "lodash/has";

const Service = {
  init() {
    return new Promise((resolve, reject) => {
      if (_has(window, "OneSignal")) {
        console.log("OneSignal already initialized");
      }

      const oneSignalAppId = _get(window, "CONFIG.onesignal.app_id");
      const oneSignalSafariWebId = _get(
        window,
        "CONFIG.onesignal.safari_web_id"
      );

      if (!oneSignalAppId) {
        return reject("No OneSignal app id provided");
      }

      (function () {
        window.OneSignal = window.OneSignal || [];

        window.OneSignal.push(() => {
          window.OneSignal.init({
            appId: oneSignalAppId,
            safari_web_id: oneSignalSafariWebId,
            // Depends if you're testing or not
            allowLocalhostAsSecureOrigin:
              window.location.hostname === "localhost",
          });
        });

        resolve();
      })();
    });
  },
};

export default Service;
