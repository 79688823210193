import {Client as CamundaApiClient} from './camundaApi'
import _get from 'lodash/get'
import axios from "axios";

export class Client extends CamundaApiClient {
    createPath() {
        let prefix = _get(window, 'CONFIG.camunda.api.prefix');

        let path = `${prefix}/integrations`;
        return `${path}/rest/engine/default`;
    }
}

const client = new Client();
client.axiosClient = axios.create();

export default client;
