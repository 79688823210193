import _get from "lodash/get";
import _has from "lodash/has";

const Service = {
  init() {
    return new Promise((resolve, reject) => {
      if (_has(window, "Intercom")) {
        console.log("Intercom already initialized");
        return resolve();
      }

      let intercomAppId = _get(window, "CONFIG.intercom.app_id");

      if (!intercomAppId) {
        return reject("No intercom app id provided");
      }

      (function () {
        let w = window;
        let ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
        } else {
          let d = document;
          let i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          let l = function () {
            let s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/" + intercomAppId;
            let x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
            resolve();
          };
          l();
        }
      })();
    });
  },
  boot(username, role, intercom_user_hash) {
    return this.init().then(() => {
      let config = {
        ..._get(window, "CONFIG.intercom", {}),
        username,
        role,
        intercom_user_hash,
      };
      window.Intercom("boot", config);
    });
  },
  shutdown() {
    if (_has(window, "Intercom")) {
      window.Intercom("shutdown");
    }
  },
};

export default Service;
