<template>
  <div v-if="isLoading" class="text-center pt-0">
    <p class="overline font-weight-regular text-center">
      {{
        $i18n(
          "accountManagement.companyInfo.compliance.loading.title",
          "Checking company compliance!"
        )
      }}
    </p>
    <p>
      <v-progress-linear
        indeterminate
        striped
        color="primary"
        height="5"
      ></v-progress-linear>
    </p>
  </div>
  <div class="component" v-else>
    <!-- RUNBUGGY ADMIN NON COMPLIANT ALERT -->
    <template v-if="isRunbuggyAdminView">
      <v-alert
        outlined
        type="error"
        text
        class="alertCompliance"
        v-model="showAlert"
        transition="scroll-x-transition"
      >
        <div class="d-flex">
          <div class="flex-gorw-1">
            {{
              $i18n(
                "accountManagement.companyInfo.compliance.alertTitle",
                "This account is out of compliance"
              )
            }}
            <ul class="mt-3">
              <li v-if="missingDocumentsString">
                <strong>Missing Document(s):</strong>
                {{ missingDocumentsString }}
              </li>
              <li v-if="pendingDocumentsString">
                <strong>Document(s) Pending Approval:</strong>
                {{ pendingDocumentsString }}
              </li>
              <li v-if="missingPaymentsString || pendingPaymentsString">
                <strong>Payment Info:</strong>
                {{
                  $i18n(
                    "accountManagement.companyInfo.compliance.admin.paymentInfo",
                    "Bank account setup has not been completed"
                  )
                }}
              </li>
            </ul>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn icon @click="showAlert = false">
              <v-icon color="error" size="medium">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </v-alert>
    </template>

    <template v-else>
      <!-- NON COMPLIANT DIALOG CARD -->
      <v-card flat v-if="!isCompliant">
        <v-card-title
          class="headline d-flex justify-center"
          id="cardTitlePolicyCompliance"
        >
          <div class="col-sm-12 d-flex justify-center">
            <v-icon large color="error"> mdi-alert-circle-outline </v-icon>
          </div>
          <div class="subtitle-1 font-weight-medium">
            {{
              $i18n(
                "accountManagement.companyInfo.compliance.dialog.noncompliant.title",
                "This account is currently out of compliance"
              )
            }}
          </div>
        </v-card-title>
        <v-card-text>
          <div class="caption text-center pb-8">
            {{
              $i18n(
                "accountManagement.companyInfo.compliance.dialog.noncompliant.subtitle",
                "The following information are missing. Your access to RunBuggy will be limited until all the missing information are adequately provided."
              )
            }}
          </div>
          <!-- MISSING & PENDING DOCUMENTS FOR DIALOG -->
          <v-card
            outlined
            class="cardInfoBox"
            v-if="missingDocuments.length > 0 || pendingDocuments.length > 0"
          >
            <v-card-title class="d-flex flex-column justify-center pt-8">
              <v-avatar size="25" class="avatar-section" color="blue lighten-5">
                <v-icon small class="avatar-icon" color="blue">
                  mdi-file-upload-outline
                </v-icon>
              </v-avatar>
              <div class="subtitle-2 font-weight-medium">
                {{
                  $i18n(
                    "accountManagement.companyInfo.compliance.dialog.noncompliant.documents.title",
                    "Non Compliant Document(s)"
                  )
                }}
              </div>
            </v-card-title>
            <v-card-text class="d-flex flex-column justify-center caption">
              <div class="font-weight-medium mx-auto">
                {{
                  $i18n(
                    "accountManagement.companyInfo.compliance.dialog.noncompliant.documents.subtitle",
                    "Add the following documents to continue using RunBuggy"
                  )
                }}
              </div>
              <div class="mx-auto">
                <ul v-if="missingDocuments.length > 0">
                  <li v-for="item in missingDocuments" :key="`key-${item}`">
                    {{ item }} <strong>(Missing)</strong>
                  </li>
                </ul>
                <ul v-if="pendingDocuments.length > 0">
                  <li v-for="item in pendingDocuments" :key="`key-${item}`">
                    {{ item }} <strong>(Pending Approval)</strong>
                  </li>
                </ul>
              </div>
            </v-card-text>
            <v-card-actions
              class="d-flex justify-center pb-8"
              v-if="!hideActionButton"
            >
              <v-btn
                depressed
                class="primary-btn"
                id="btnUploadDocument"
                text
                @click="goToDocumentManagement()"
              >
                {{
                  $i18n(
                    "accountManagement.companyInfo.compliance.dialog.noncompliant.documents.button",
                    "Upload Documents"
                  )
                }}
              </v-btn>
            </v-card-actions>
          </v-card>

          <!-- MISSING & PENDING PAYMENT INFO FOR DIALOG -->
          <v-card
            outlined
            class="cardInfoBox mt-10"
            v-if="missingPayments.length > 0 || pendingPayments.length > 0"
          >
            <v-card-title class="d-flex flex-column justify-center pt-8">
              <v-avatar size="25" class="avatar-section" color="blue lighten-5">
                <v-icon small class="avatar-icon" color="blue">
                  mdi-credit-card-settings-outline
                </v-icon>
              </v-avatar>
              <div class="subtitle-2 font-weight-medium">
                {{
                  $i18n(
                    "accountManagement.companyInfo.compliance.dialog.noncompliant.payments.title",
                    "Non Compliant Payment Information"
                  )
                }}
              </div>
            </v-card-title>
            <v-card-text class="d-flex flex-column justify-center caption">
              <div class="mx-auto text-center">
                <span v-for="item in missingPayments" :key="`key-${item}`"
                  >{{ item }} <strong>(Missing)</strong></span
                >
                <span v-for="item in pendingPayments" :key="`key-${item}`"
                  >{{ item }} <strong>(Pending Completion)</strong></span
                >
              </div>
            </v-card-text>
            <v-card-actions
              class="d-flex justify-center pb-8"
              v-if="!hideActionButton"
            >
              <v-btn
                depressed
                class="primary-btn"
                id="btnAddPaymentInfo"
                text
                @click="goToPaymentManagement()"
              >
                {{
                  $i18n(
                    "accountManagement.companyInfo.compliance.dialog.noncompliant.payments.button",
                    "Add Payment Info"
                  )
                }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>

      <!-- FULLY COMPLIANT DIALOG CARD -->
      <v-card flat v-else>
        <v-card-title
          class="headline d-flex justify-center"
          id="cardTitlePolicyCompliance"
        >
          <div class="col-sm-12 d-flex justify-center">
            <v-icon large color="primary"> mdi-check-circle-outline </v-icon>
          </div>
          <div class="subtitle-1 font-weight-medium">
            {{
              $i18n(
                "accountManagement.companyInfo.compliance.dialog.compliant.title",
                "This account is fully compliant"
              )
            }}
          </div>
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <div class="caption text-center">
            {{
              $i18n(
                "accountManagement.companyInfo.compliance.dialog.compliant.subtitle",
                "The following information are missing. Your access to RunBuggy will be limited until all the missing information are adequately provided."
              )
            }}
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-8">
          <v-btn
            depressed
            class="primary-btn"
            id="btnContinue"
            text
            @click="continueClick()"
          >
            {{
              $i18n(
                "accountManagement.companyInfo.compliance.dialog.compliant.button",
                "Continue with RunBuggy"
              )
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </div>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
import _get from "lodash/get";
import _includes from "lodash/includes";

import AccountPolicies from "@/constants/accountPolicies";
import NotificationConstants from "@/constants/notifications";
import OnboardingApiService from "SC/services/onboardingApi";

const documentPolicies = [
  AccountPolicies.DOCUMENTS.CERTIFICATE_OF_INSURANCE,
  AccountPolicies.DOCUMENTS.CERTIFICATE_OF_OPERATING_AUTH,
  AccountPolicies.DOCUMENTS.CERTIFICATE_OF_W9
];
const paymentPolicies = [
  AccountPolicies.PAYMENTS.STRIPE_BANK_ACCOUNT_PAYMENT,
  AccountPolicies.PAYMENTS.STRIPE_CARD_PAYMENT
];
export default {
  name: "components-account-management-policies_compliance-index",
  watch: {
    isCompliant(newValue) {
      this.$emit("input", newValue);
    },
    accountPoliciesUpdates(newValue) {
      if (newValue) {
        this.checkOnMissingInfo(this.newValue);
      }
    }
  },
  props: {
    value: {
      default() {
        return true;
      }
    },
    accountId: {
      default() {
        return undefined;
      }
    },
    isRunbuggyAdminView: {
      default() {
        return true;
      }
    },
    accountPoliciesUpdates: {
      default() {
        return undefined;
      }
    }
  },
  data() {
    return {
      componentTimeouts: [],
      missingViewsInfo: [],
      pendingViewsInfo: [],
      isLoading: false,
      compliance: [],
      isCompliant: undefined,
      showAlert: false,

      pendingDocuments: [],
      pendingPayments: [],
      missingDocuments: [],
      missingPayments: [],

      pendingDocumentsString: undefined,
      pendingPaymentsString: undefined,
      missingDocumentsString: undefined,
      missingPaymentsString: undefined
    };
  },
  methods: {
    getAccountPoliciesInfo() {
      if (this.accountPoliciesUpdates) {
        this.checkOnMissingInfo(this.accountPoliciesUpdates);
      } else {
        this.isLoading = true;
        let accountPolicies = {};
        OnboardingApiService.getAccountPolicies(this.accountId)
          .then((response) => {
            accountPolicies = _get(response, "policies", {});
            Object.entries(accountPolicies).some(([key, value]) => {
              if (_get(value, "status") === "UNKNOWN") {
                this.componentTimeouts.push(
                  setTimeout(() => this.getAccountPoliciesInfo(), 2000)
                );
                return true;
              } else {
                this.isLoading = false;
                this.checkOnMissingInfo(accountPolicies);
              }
            });
          })
          .catch((error) => {
            this.showErrorNotification(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    getCompliantKeyValue(key) {
      let value = undefined;
      switch (key) {
        case AccountPolicies.DOCUMENTS.CERTIFICATE_OF_INSURANCE:
          value = this.$i18n(
            "accountManagement.documents.title.insurance",
            "Certificate of Insurance"
          );
          break;
        case AccountPolicies.DOCUMENTS.CERTIFICATE_OF_OPERATING_AUTH:
          value = this.$i18n(
            "accountManagement.documents.title.auth",
            "Certificate of Operating Authority"
          );
          break;
        case AccountPolicies.DOCUMENTS.CERTIFICATE_OF_W9:
          value = this.$i18n(
            "accountManagement.documents.title.w9",
            "Provide Certificate of W9 Document"
          );
          break;
        case AccountPolicies.PAYMENTS.STRIPE_BANK_ACCOUNT_PAYMENT:
          value = this.$i18n(
            "accountManagement.bankAccount.pending",
            "Bank Account Details. Please Make Sure You Completed The Stripe Account Setup"
          );
          break;
        case AccountPolicies.PAYMENTS.STRIPE_CARD_PAYMENT:
          value = this.$i18n(
            "accountManagement.creditCard.title",
            "Credit Card Details"
          );
          break;
      }
      return value;
    },
    checkOnMissingInfo(accountPolicies) {
      if (accountPolicies) {
        this.missingDocuments = [];
        this.missingPayments = [];
        this.pendingDocuments = [];
        this.pendingPayments = [];

        let noCompliantCount = 0;
        Object.entries(accountPolicies).forEach(([key, value]) => {
          if (_get(value, "status") === "NONCOMPLIANT") {
            noCompliantCount++;
            if (_get(value, "requirements[0].name")) {
              if (_includes(documentPolicies, key)) {
                this.missingDocuments.push(this.getCompliantKeyValue(key));
              } else if (_includes(paymentPolicies, key)) {
                this.missingPayments.push(this.getCompliantKeyValue(key));
              }
            } else {
              if (_includes(documentPolicies, key)) {
                this.pendingDocuments.push(this.getCompliantKeyValue(key));
              } else if (_includes(paymentPolicies, key)) {
                this.pendingPayments.push(this.getCompliantKeyValue(key));
              }
            }
          }
        });
        if (noCompliantCount > 0) {
          this.isCompliant = false;
          this.showAlert = true;
        } else this.isCompliant = true;

        this.pendingDocumentsString =
          this.pendingDocuments.length > 0
            ? this.pendingDocuments.join(", ")
            : undefined;
        this.pendingPaymentsString =
          this.pendingPayments.length > 0
            ? this.pendingPayments.join(", ")
            : undefined;
        this.missingDocumentsString =
          this.missingDocuments.length > 0
            ? this.missingDocuments.join(", ")
            : undefined;
        this.missingPaymentsString =
          this.missingPayments.length > 0
            ? this.missingPayments.join(", ")
            : undefined;
      }
    },
    goToDocumentManagement() {
      this.$router.push({ name: "user-account-management-documents" });
      this.$emit("close");
    },
    goToPaymentManagement() {
      this.$router.push({ name: "user-account-management-payments" });
      this.$emit("close");
    },
    goToAccountManagement() {
      this.$router.push({ name: "user-account-management" });
      this.$emit("close");
    },
    continueClick() {
      this.$emit("close");
    },
    showErrorNotification(error) {
      if (
        error.response &&
        error.response.status &&
        error.response.statusText
      ) {
        this.showNotification(
          "error",
          this.$i18n("common.label.error", "Error") +
            " " +
            error.response.status,
          error.response.statusText
        );
      } else {
        this.showNotification(
          "error",
          this.$i18n("common.label.error", "Error"),
          error
        );
      }
    },
    showNotification(type, title, message) {
      this.$notify({
        type: type,
        title: title,
        text: message,
        duration: NotificationConstants.DURATION,
        speed: 1000
      });
    }
  },
  computed: {
    hideActionButton() {
      return (
        !this.hasAdminRole &&
        this.userRoles.some(
          (userRole) => userRole.includes("USER") && !userRole.includes("ADMIN")
        )
      );
    },
    ...mapGetters("user", ["userRoles", "hasAdminRole"])
  },
  created() {
    if (this.accountId) {
      this.getAccountPoliciesInfo();
    }
  },
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "DT/index";

.cardInfoBox {
  border: 1px solid $BLUE-GRAY-2;
  border-radius: $SPACE-XS;
  background: rgba(236, 240, 249, 0.2);
}
</style>
