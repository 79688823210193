<template>
  <div>
    <v-btn
      class="mr-2 status-container position-relative"
      min-width="0"
      text
      id="status-btn"
      @click="isDialogOpenState = true"
    >
      <v-badge
        color="secondary"
        class="status-container--badge v-badge--bordered bounce"
        v-if="count > 0"
        bodered
      >
        <template v-slot:badge>
          <span>{{ count }}</span>
        </template>
      </v-badge>
      <v-icon>mdi-clipboard-check-multiple-outline</v-icon>
    </v-btn>
    <generic-dialog
      v-model="isDialogOpenState"
      :width="`750`"
      :max-width="`90%`"
      transition="dialog-bottom-transition"
      :title="$i18n(`common.label.batchOperationsStatus`, `Batch Operations Status`)"
      :expand="true"
    >
      <template v-slot:dialog-content>
        <div style="height: 4px">
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="isLoadingData"
          ></v-progress-linear>
        </div>
        

        <v-card-text class="pa-5 status-container--card--text h-100 flex-grow-1">
          <template v-if="batchOperationList.length > 0" >
            <v-list two-line nav class="pa-0">
              <template v-for="(item, index) in batchOperationList">
                <status-item
                  :key="`status_item-${index}-${item.id}`"
                  :id="`status_item-${index}-${item.id}`"
                  :item="item"
                  :current-batch-operation-id="currentBatchOperationId"
                >
                </status-item>
                <v-divider
                    :key="`status_divider-${index}`"
                    v-if="index + 1 < batchOperationList.length"
                ></v-divider>
              </template>
            </v-list>
          </template>
          <template v-else>
            <div
                v-if="!batchOperationList.length"
                class="d-flex flex-column justify-center align-center text-center"
            >
              <v-icon class="no-icon">mdi-playlist-remove</v-icon>
              <p class="text-center font-15-500">
                {{
                  $i18n(
                      "common.label.noBatchOperation",
                      "No batch operation to show at the moment"
                  )
                }}
              </p>
            </div>
          </template>
        </v-card-text>
      </template>
    </generic-dialog>
  </div>
</template>

<script type="text/babel">
import GenericDialog from "@/components/generic-dialog";
import StatusItem from "./item";
import LoadingSpinner from "SC/components/loading-spinner";
import _get from "lodash/get";
import _sortBy from "lodash/sortBy";
import RunbuggyOrdersApi from "SC/services/runBuggyOrdersApi";
import EventBus from "@/services/eventBus";
import EventBusConstants from "@/constants/eventBus";

const STATUS = {
  PENDING: "PENDING",
  PROCESSING: "PROCESSING",
  COMPLETED: "COMPLETED",
  ERROR: "ERROR",
}
export default {
  name: "components-batch_operation_status-index",
  watch: {
    isDialogOpenState: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue === false) {
          this.clearTimeout();
        } else {
          this.getStatus();
        }
      },
    },
  },
  props: {},
  data() {
    return {
      STATUS,
      isDialogOpenState: false,
      isLoadingData: false,
      polling: null,
      batchOperationList: [],
      getTimeout: undefined,
      getTimeoutDuration: 2000,
      getStatusPromise: undefined,
      currentBatchOperationId: undefined,
      shouldPollLookup: false,
      maxPollingCount: 10,
      pollCount: 0,
      batchOperationListSize: 10,
      expanded: false,
      width: "800",
      dialogWidth: "800",
      dialogHeight: "60vh"
    };
  },
  methods: {
    resizeDialog() {
      this.dialogWidth = this.expanded ? this.width : '1050px';
      this.expanded = !this.expanded;
    },
    clearTimeout() {
      if(this.getTimeout) {
        clearTimeout(this.getTimeout);
      }

      this.getTimeout = undefined;
    },
    getStatus() {
      if(this.getStatusPromise) {
        return this.getStatusPromise;
      }

      this.isLoadingData = true;

      this.getStatusPromise = this.getBatchOperations()
          .then((result) => {
            result = _sortBy(_get(result, "content", []), [
              "startDate",
            ]).reverse();
            this.batchOperationList = result;

            if(this.shouldPollLookup && _get(this, "currentItemStatus", "").toLowerCase() === "completed" || _get(this, "currentItemStatus", "").toLowerCase() === "error") {
              EventBus.$emit(EventBusConstants.BATCH_OPERATION_RELOAD_TABLE);
            }
            else {
              if (this.pendingItems.length > 0 && this.shouldPollLookup) {
                this.getTimeout = setTimeout(() => {
                  this.getStatus();
                }, this.getTimeoutDuration);
              }
            }
          })
          .catch((e) => {
            this.error = e;
            this.batchOperationList = [];
          })
          .finally(() => {
            this.isLoadingData = false;
            this.getStatusPromise = undefined;
          })

      return this.getStatusPromise;
    },
    getBatchOperations() {
      return RunbuggyOrdersApi.getBatchOperations(`size=${this.batchOperationListSize}&sort=startDate,desc`);
    },
  },
  computed: {
    pendingItems() {
      return this.batchOperationList.filter((item) => {
        return [
            'COMPLETED',
            'ERROR',
        ].indexOf(item.status) === -1;
      });
    },
    currentItem() {
      return this.batchOperationList.find((item) => {
        return item.id === this.currentBatchOperationId;
      });
    },
    currentItemStatus(){
      return _get(this.currentItem, "status");
    },
    count() {
      if (!this.batchOperationList || !this.batchOperationList.length) {
        return 0;
      }
      return this.batchOperationList.filter((item) => {
        return item.status === STATUS.PROCESSING;
      }).length;
    },
  },
  mounted() {},
  created() {
    this.getStatus();
    EventBus.$on(EventBusConstants.BATCH_OPERATION_SENT, (id) => {
      this.currentBatchOperationId = id;
      this.isDialogOpenState = true;
      this.shouldPollLookup = true;
      this.getStatus();
    });
  },
  beforeDestroy() {
    this.clearTimeout();
  },
  mixins: [],
  components: {
    GenericDialog,
    StatusItem,
    LoadingSpinner,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../scss/variables";

.status-container {
  .v-icon {
    color: #000;
  }
  &--badge {
    position: absolute;
    right: 4px;
    top: 8px;
    animation-duration: 2s;
    animation-iteration-count: 3;
  }

  &--card {
    max-height: 450px;
    display: flex !important;
    flex-direction: column;

    &--text {
      overflow-y: auto;
      gap: 20px;
      flex: 1;
      min-width: 550px;
    }
  }
}

.no-icon {
  color: var(--v-primary-base);
  font-size: 50px;
}

.bounce {
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-5px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-3px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
</style>

<style lang="scss">
.status-container--card {
  .action-btn {
    background: transparent !important;
    border-color: #fff !important;
  }
}
</style>
