import axios from 'axios'
import TokenService from "../services/tokenService";
import _get from "lodash/get";

const CancelToken = axios.CancelToken;

const Client = {
    axiosClient: axios.create(),
    _requestId: 0,
    cancellationSources: {},

    getLabelDefinitions() {
      let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/label-definitions`;
      return this.get(path);
    },
    createLabelDefinition(data) {
      let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/label-definitions/label`;
      return this.post(path, data);
    },
    updateLabelDefinition(id, data) {
      let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/label-definitions/${id}`;
      return this.put(path, data);
    },
    deleteLabelDefinitions(id) {
      let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/label-defintions/${id}`;
      return this.delete(path);
    },

    get(path) {
      let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
      let params = {};
      if (TokenService.hasImpersonatedByUsername()) {
          params['byAdmin'] = TokenService.getImpersonatedByUsername();
      }

      let headers = {
          'Authorization': `Bearer ${token}`
      }

      if (TokenService.hasSpecifiedRegistrationId() && TokenService.hasIntercompanyName()) {
          headers['X-Intercompany-ID'] = TokenService.getSpecifiedRegistrationId();
          headers['X-Intercompany-Name'] = TokenService.getIntercompanyName();
      }

      if (TokenService.hasSpecifiedRegistrationIds()) {
          headers['X-Intercompany-Read-IDs'] = TokenService.getSpecifiedRegistrationIds().join(',');
      }

      const cancellationSource = CancelToken.source();
      const requestId = this._requestId++;
      this.cancellationSources[requestId] = {
          path: path,
          cancellationSource
      };

      return Client.axiosClient.get(path, {
          headers,
          params,
          cancelToken: cancellationSource.token
      }).finally(() => {
          delete this.cancellationSources[requestId];
      });
    },
    post(path, data) {
      let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
      let params = {};
      if (TokenService.hasImpersonatedByUsername()) {
          params['byAdmin'] = TokenService.getImpersonatedByUsername();
      }

      let headers = {
          'Authorization': `Bearer ${token}`
      }

      if (TokenService.hasSpecifiedRegistrationId() && TokenService.hasIntercompanyName()) {
          headers['X-Intercompany-ID'] = TokenService.getSpecifiedRegistrationId();
          headers['X-Intercompany-Name'] = TokenService.getIntercompanyName();
      }

      if (TokenService.hasSpecifiedRegistrationIds()) {
          headers['X-Intercompany-Read-IDs'] = TokenService.getSpecifiedRegistrationIds().join(',');
      }

      const cancellationSource = CancelToken.source();
      const requestId = this._requestId++;
      this.cancellationSources[requestId] = {
          path: path,
          cancellationSource,
          cancelToken: cancellationSource.token
      };

      return Client.axiosClient.post(path, data, {
          headers,
          params,
          cancelToken: cancellationSource.token
      })
          .finally(() => {
              delete this.cancellationSources[requestId];
          });
    },
    put(path, data) {
      let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
      let params = {};
      if (TokenService.hasImpersonatedByUsername()) {
          params['byAdmin'] = TokenService.getImpersonatedByUsername();
      }

      let headers = {
          'Authorization': `Bearer ${token}`
      }

      if (TokenService.hasSpecifiedRegistrationId() && TokenService.hasIntercompanyName()) {
          headers['X-Intercompany-ID'] = TokenService.getSpecifiedRegistrationId();
          headers['X-Intercompany-Name'] = TokenService.getIntercompanyName();
      }

      if (TokenService.hasSpecifiedRegistrationIds()) {
          headers['X-Intercompany-Read-IDs'] = TokenService.getSpecifiedRegistrationIds().join(',');
      }

      const cancellationSource = CancelToken.source();
      const requestId = this._requestId++;
      this.cancellationSources[requestId] = {
          path: path,
          cancellationSource,
          cancelToken: cancellationSource.token
      };

      return Client.axiosClient.put(path, data, {
          headers,
          params,
          cancelToken: cancellationSource.token
      }).finally(() => {
          delete this.cancellationSources[requestId];
      });
    },
    patch(path, data) {
      let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
      let params = {};

      if (TokenService.hasImpersonatedByUsername()) {
          params['byAdmin'] = TokenService.getImpersonatedByUsername();
      }

      let headers = {
          'Authorization': `Bearer ${token}`
      }

      if (TokenService.hasSpecifiedRegistrationId() && TokenService.hasIntercompanyName()) {
          headers['X-Intercompany-ID'] = TokenService.getSpecifiedRegistrationId();
          headers['X-Intercompany-Name'] = TokenService.getIntercompanyName();
      }

      if (TokenService.hasSpecifiedRegistrationIds()) {
          headers['X-Intercompany-Read-IDs'] = TokenService.getSpecifiedRegistrationIds().join(',');
      }

      const cancellationSource = CancelToken.source();
      const requestId = this._requestId++;
      this.cancellationSources[requestId] = {
          path: path,
          cancellationSource,
          cancelToken: cancellationSource.token
      };

      return Client.axiosClient.patch(path, data, {
          headers,
          params,
          cancelToken: cancellationSource.token
      }).finally(() => {
          delete this.cancellationSources[requestId];
      });
    },
    delete(path) {
      let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
      let params = {};

      if (TokenService.hasImpersonatedByUsername()) {
          params['byAdmin'] = TokenService.getImpersonatedByUsername();
      }

      let headers = {
          'Authorization': `Bearer ${token}`
      }

      if (TokenService.hasSpecifiedRegistrationId() && TokenService.hasIntercompanyName()) {
          headers['X-Intercompany-ID'] = TokenService.getSpecifiedRegistrationId();
          headers['X-Intercompany-Name'] = TokenService.getIntercompanyName();
      }

      if (TokenService.hasSpecifiedRegistrationIds()) {
          headers['X-Intercompany-Read-IDs'] = TokenService.getSpecifiedRegistrationIds().join(',');
      }

      const cancellationSource = CancelToken.source();
      const requestId = this._requestId++;
      this.cancellationSources[requestId] = {
          path: path,
          cancellationSource,
          cancelToken: cancellationSource.token
      };

      return Client.axiosClient.delete(path, {
          headers,
          params,
          cancelToken: cancellationSource.token
      }).finally(() => {
          delete this.cancellationSources[requestId];
      });
    }
};

export default Client;
