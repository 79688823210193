import axios from "axios";
import _get from "lodash/get";

const Service = {
  retrieveMonoProjectsNavigation() {
    let monoProjects = _get(window, "CONFIG.monoProjects", []);
    let promises = [];

    monoProjects.forEach((monoProject) => {
      let jsonPath = monoProject.navigationPath;
      let promise = axios
        .get(jsonPath)
        .then((result) => {
          return result.data;
        })
        .catch((e) => {
          console.error("Failed to retrieve navigation for", jsonPath, e);
        });

      promises.push(promise);
    });

    return Promise.all(promises).then((results) => {
      let navigations = [];
      results.forEach((result) => {
        if(result){
          result.forEach((navigation) => {
            navigations.push(navigation);
          });
        }
      });
      return navigations;
    });
  },
};

export default Service;
