var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component intercompany-switcher"},[(_vm.isRetrieving)?[_c('v-progress-circular',{attrs:{"indeterminate":""}})]:(_vm.items.length > 1)?[(_vm.asListItem)?[_c('v-list-item',[_c('v-autocomplete',{attrs:{"items":_vm.items,"loading":_vm.isRetrieving,"clearable":"","hide-no-data":"","outlined":"","multiple":"","label":"Intercompanies","hide-details":"","dense":""},on:{"click:clear":function($event){$event.preventDefault();return _vm.onClickClear.apply(null, arguments)},"change":_vm.setIntercompanies},model:{value:(_vm.currentIntercompanies),callback:function ($$v) {_vm.currentIntercompanies=$$v},expression:"currentIntercompanies"}})],1)]:[_c('div',{staticClass:"position-relative"},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},on:{"input":_vm.onMenuOpenChange},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"switcher",attrs:{"depressed":"","outlined":""}},'v-btn',attrs,false),on),[_c('div',{staticClass:"position-relative"},[(
                    _vm.currentIntercompanies && _vm.currentIntercompanies.length > 0
                  )?[_c('div',{staticClass:"intercompany-count d-flex justify-center align-center"},[_c('div',[_vm._v(_vm._s(_vm.currentIntercompanies.length))])])]:_vm._e(),_c('span',{staticClass:"d-inline-block",class:{
                    'pl-2':
                      _vm.currentIntercompanies &&
                      _vm.currentIntercompanies.length > 0,
                  }},[_vm._v(_vm._s(_vm.intercompanyMenuLabel))])],2)])]}}]),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},[_c('v-list',[(_vm.items.length > 1)?_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"pa-4"},[_c('v-checkbox',{staticClass:"ma-0",attrs:{"label":_vm.$i18n('common.label.all', 'All'),"dense":"","hide-details":""},on:{"change":_vm.toggleAllCompaniesSelection},model:{value:(_vm.checkAllIntercompanies),callback:function ($$v) {_vm.checkAllIntercompanies=$$v},expression:"checkAllIntercompanies"}})],1)],1):_vm._e(),_c('div',{staticClass:"overflow-auto",staticStyle:{"max-height":"400px"}},_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:`list-item-${index}`,staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"pa-4"},[_c('v-checkbox',{staticClass:"ma-0",attrs:{"value":item.value,"label":item.text,"dense":"","hide-details":""},on:{"change":_vm.checkIfAllCompaniesAreSelected},model:{value:(_vm.currentIntercompanies),callback:function ($$v) {_vm.currentIntercompanies=$$v},expression:"currentIntercompanies"}})],1)],1)}),1),_c('div',{staticClass:"d-flex border-top pt-2 px-2"},[_c('div',{staticClass:"flex-grow-1 pa-1"},[_c('v-btn',{staticClass:"secondary-btn",attrs:{"block":"","small":"","depressed":"","outlined":""},on:{"click":_vm.closeIntercompanyMenu}},[_vm._v(" "+_vm._s(_vm.$i18n("common.label.cancel", "Cancel"))+" ")])],1),_c('div',{staticClass:"flex-grow-1 pa-1"},[_c('v-btn',{attrs:{"block":"","small":"","depressed":"","color":"primary","id":"apply_set_intercompanies"},on:{"click":_vm.setIntercompanies}},[_vm._v(" "+_vm._s(_vm.$i18n("common.label.apply", "Apply"))+" ")])],1)])],1)],1)],1)]]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }