/*jshint esversion: 6 */

import BugsnagService from "SC/services/bugsnag";

const LOG_STYLE = {
  log: "color: #5c677d",
  debug: "color: #4E89CC",
  error: "color: #FF6B6B",
  warn: "color: #FFE66D",
  info: "color: #2ec4b6",
};

export const log = function (
  message,
  data,
  type = "log",
  logToBugsnag = false
) {
  const fullMessage = data ? `RB LOG: ${message}` : `RB ${type}: ${message}`;

  if (data) {
    console.group(`%c ${fullMessage}`, LOG_STYLE[type]);
    console[type](data);
    console.groupEnd();
  } else {
    console[type](`%c ${fullMessage}`, LOG_STYLE[type]);
  }

  // Bugsnag integration
  if (logToBugsnag) {
    BugsnagService.notify(fullMessage);
  }
};

const RunBuggyLogger = {
  install(Vue, options) {
    Vue.prototype.$log = log;
  },
};

export default RunBuggyLogger;
