import * as types from "./mutation-types";

// initial state
const state = {
  activeTab: 0,
  activitiesFilter: {
    grouping: []
  }
};
const mutations = {
  setActiveTab(state, tab) {
    state.activeTab = tab;
  },
  setActivitiesFilter(state, filter) {
    state.activitiesFilter = filter;
  },
};
const actions= {
  setActiveTab({ commit }, tab) {
    commit("setActiveTab", tab);
  },
  setActivitiesFilter({ commit }, filter) {
    commit("setActivitiesFilter", filter);
  },
};
const getters= {
  activeTab: (state) => state.activeTab,
  activitiesFilter: (state) => state.activitiesFilter,
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
