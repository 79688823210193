import Vue from "vue";
import Vuetify from "vuetify/lib";

import * as designTokens from "DT";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: designTokens.BLUE_2,
        secondary: designTokens.ORANGE,
        accent: designTokens.GREEN_3,
        error: designTokens.RED,
        green: designTokens.GREEN_2,
        navigation_bg: designTokens.DARK_BLUE_1,
        navigation_text: designTokens.WHITE
      },
    },
    options: {
      customProperties: true
    }
  }
});
