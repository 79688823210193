import TokenService from "../services/tokenService";
import axios from "axios";
import _isNumber from 'lodash/isNumber';
import _get from "lodash/get";

const Client = {
    axiosClient: axios.create(),
    createNewAddress(address) {
        let path = `${_get(window, 'CONFIG.integrations.api.path')}/addresses`;
        return this.post(path, address);
    },
    getAllAddresses(page, size) {
        let path = `${_get(window, 'CONFIG.integrations.api.path')}/addresses`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`)
        }

        if (size) {
            query.push(`size=${size}`)
        }

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }

        return this.get(path);
    },
    updateAddressForId(id, address) {
        let path = `${_get(window, 'CONFIG.integrations.api.path')}/addresses/${id}`;
        return this.patch(path, address);
    },
    removeAddressForId(id) {
        let path = `${_get(window, 'CONFIG.integrations.api.path')}/addresses/${id}`;
        return this.delete(path);
    },

    createNewAddressPrice(addressToAddressPrice) {
        let path = `${_get(window, 'CONFIG.integrations.api.path')}/address-price`;
        return this.post(path, addressToAddressPrice);
    },
    getAllAddressPrice(page, size) {
        let path = `${_get(window, 'CONFIG.integrations.api.path')}/address-price`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`)
        }

        if (size) {
            query.push(`size=${size}`)
        }

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }

        return this.get(path);
    },
    updateAddressPriceForId(id, addressToAddressPrice) {
        let path = `${_get(window, 'CONFIG.integrations.api.path')}/address-price/${id}`;
        return this.put(path, addressToAddressPrice);
    },
    removeAddressPriceForId(id) {
        let path = `${_get(window, 'CONFIG.integrations.api.path')}/address-price/${id}`;
        return this.delete(path);
    },

    get(path) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }
        return Client.axiosClient.get(path, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params
        });
    },
    put(path, data) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }
        return Client.axiosClient.put(path, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params
        });
    },
    patch(path, data) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }
        return Client.axiosClient.patch(path, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params
        });
    },
    delete(path) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }
        return Client.axiosClient.delete(path, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params
        });
    },
    post(path, data) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }
        return Client.axiosClient.post(path, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params
        });
    },
    getAllGeoFeatures(page, size) {
        let path = `${_get(window, 'CONFIG.integrations.api.path')}/geo-features`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`)
        }

        if (size) {
            query.push(`size=${size}`)
        }

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }

        return this.get(path);
    },
    createNewGeoFeature(geoFeature) {
        let path = `${_get(window, 'CONFIG.integrations.api.path')}/geo-features`;
        return this.post(path, geoFeature);
    },
    updateGeoFeature(id, geoFeature) {
        let path = `${_get(window, 'CONFIG.integrations.api.path')}/geo-features/${id}`;
        return this.put(path, geoFeature);
    },
    removeGeoFeature(id) {
        let path = `${_get(window, 'CONFIG.integrations.api.path')}/geo-features/${id}`;
        return this.delete(path);
    },
};

export default Client;
