export default {
  ACCOUNT_MANAGEMENT_ACTIVE_SELECTION: "ACCOUNT_MANAGEMENT_ACTIVE_SELECTION",
  ACCOUNT_MANAGEMENT_EXPIRING_DOC: "ACCOUNT_MANAGEMENT_EXPIRING_DOC",
  ACCOUNT_MANAGEMENT_POLICY_INFO: "ACCOUNT_MANAGEMENT_POLICY_INFO",
  ACCOUNT_MANAGEMENT_POLICY_STATE: "ACCOUNT_MANAGEMENT_POLICY_STATE",
  COLUMN_STACK_UPDATED: "COLUMN_STACK_UPDATED",
  MESSAGING_ACCOUNT_POLICY_CHANGED: "MESSAGING_ACCOUNT_POLICY_CHANGED",
  OKTA_PASSWORD_POLICIES_STATUS: "OKTA_PASSWORD_POLICIES_STATUS",
  PODS_UPDATED: "PODS_UPDATED",
  PODS_LABEL: "PODS_LABEL",
  REFRESH_TABLE: "REFRESH_TABLE",
  SHOW_NEW_TASK: "SHOW_NEW_TASK",
  SHOW_ORDER_NOTE: "SHOW_ORDER_NOTE",
  SHOW_ORDER_PUBLIC_NOTE: "SHOW_ORDER_PUBLIC_NOTE",
  TASK_LIST_TASK_COMPLETED: "TASK_LIST_TASK_COMPLETED",
  TASK_LIST_UPDATED: "TASK_LIST_UPDATED",
  TASK_VIEW_RESETTED: "TASK_VIEW_RESETTED",
  TOGGLE_DETAILS_VIEW: "TOGGLE_DETAILS_VIEW",
  TOGGLE_CONTACTS_VIEW: "TOGGLE_CONTACTS_VIEW",
  TOGGLE_NOTIFICATIONS_VIEW: "TOGGLE_NOTIFICATIONS_VIEW",
  THEME_SETUP: "THEME_SETUP",
  UAM_ACTIVE_ACCOUNT_CHANGED: "UAM_ACTIVE_ACCOUNT_CHANGED",
  UAM_COMPANY_INFO_UPDATED: "UAM_COMPANY_INFO_UPDATED",
  UAM_DRIVER_UPDATED: "UAM_DRIVER_UPDATED",
  UAM_COMPANY_LIST_UPDATED: "UAM_COMPANY_LIST_UPDATED",
  UAM_ACTIVE_COMPANY_CHANGED: "UAM_ACTIVE_COMPANY_CHANGED",
  UAM_DRIVER_BULK_UPLOAD: "UAM_DRIVER_BULK_UPLOAD",
  UAM_DRIVER_BULK_UPLOAD_SAVED: "UAM_DRIVER_BULK_UPLOAD_SAVED",
  UAM_PAYMENT_INFO_UPDATED: "UAM_PAYMENT_INFO_UPDATED",
  SHOW_ASSIGN_TRANSPORTER: "SHOW_ASSIGN_TRANSPORTER",
  SELECTED_TENANT_CHANGED: "SELECTED_TENANT_CHANGED",
  GRID_DATA_EXPORT_SENT: "GRID_DATA_EXPORT_SENT",
  UAM_TOKEN_CHANGED: "UAM_TOKEN_CHANGED",
  BATCH_OPERATION_SENT: "BATCH_OPERATION_SENT",
  BATCH_OPERATION_RELOAD_TABLE: "BATCH_OPERATION_RELOAD_TABLE",
};
