import {Client as CamundaApiClient} from './camundaApi';
import _get from "lodash/get";
import axios from "axios";

export class Client extends CamundaApiClient {
    createPath() {
        const prefix = _get(window, 'CONFIG.camunda.api.prefix');
        
        let endpoint = _get(window, 'CONFIG.camunda.api.routeEndpoint');

        if (!endpoint) {
            endpoint = _get(window, 'CONFIG.camunda.api.defaultEndpoint');
        }
        const path = `${prefix}/${endpoint}`;

        return `${path}/rest/engine/default`;
    }
}

const client = new Client();
client.axiosClient = axios.create();

export default client;
