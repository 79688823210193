import * as types from "./mutation-types";

// initial state
const state = {
  counts: undefined,
  labels: undefined,
};

// getters
const getters = {
  viewsCounts(state) {
    return state.counts;
  },
  viewLabels(state) {
    return state.labels;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  [types.VIEWS_COUNT](state, countsData) {
    state.counts = countsData;
  },
  [types.VIEWS_LABELS](state, labelsData) {
    state.labels = labelsData;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
