<template>
  <div>
    <template v-if="shouldShow">
      <div class="list-group" v-bind:class="{ expanded: groupExpanded }">
        <item-button
          v-on:click="onClick"
          v-bind:mini="mini"
          v-bind:depth="depth"
          v-bind:item="item"
          v-bind:group-expanded="groupExpanded"
          v-bind:has-children="true"
          v-on:navigated="emitNavigated"
          v-if="!mini"
          :id="
            item.label
              ? `side-menu-task-item-${item.label.split(' ').join('-')}`
              : undefined
          "
        ></item-button>
        <div class="children" v-if="groupExpanded">
          <template v-if="isLoadingFilters">
            <v-progress-circular
              class="ml-8"
              indeterminate
              width="2"
              size="16"
            ></v-progress-circular>
          </template>
          <template v-else>
            <div v-for="(child, index) in children" :key="index">
              <task-management-filter-item-button
                v-bind:mini="mini"
                v-bind:depth="depth + 1"
                v-bind:item="child"
                v-on:navigated="emitNavigated"
                v-on:refresh-menu="onRefreshMenu"
                :id="
                  child.label
                    ? `side-menu-task-item-${child.label.split(' ').join('-')}`
                    : undefined
                "
              ></task-management-filter-item-button>
            </div>
            <div v-if="!children.length && !mini" class="pa-6 pl-12 pt-0">
              <small><em>No tasks</em></small>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script type="text/babel">
import _get from "lodash/get";
import _find from "lodash/find";
import _sortBy from "lodash/sortBy";
import { mapActions, mapGetters } from "vuex";
import * as TasksMutationTypes from "@/store/modules/tasks/mutation-types";
import ItemButton from "./button";
import Item from "./item";
import LocalStorageService from "SC/services/localStorage";
import LocalStorageKeys from "@/constants/localstorage";

import RunBuggyTasksApiService from "SC/services/runBuggyTasksApi";

import TaskManagementFilterItemButton from "./task-management-filter-item-button";

import EventBus from "@/services/eventBus";
import EventBusConstants from "@/constants/eventBus";

const TASK_MANAGEMENT_FILTER = "TASK_MANAGEMENT_FILTER";
const VIN_ITEM = "VIN_ITEM";

export default {
  name: "components-navigation-camunda_filter_item",
  watch: {},
  props: {
    mini: {
      default() {
        return false;
      },
    },
    roles: undefined,
    depth: {
      default() {
        return 0;
      },
    },
    params: {
      default() {
        return 0;
      },
    },
    item: {
      required: true,
    },
  },
  data() {
    return {
      VIN_ITEM,
      additionalChildren: [],
      filters: [],
      counts: [],
      isLoadingFilters: true,
      groupExpanded: false,
      TASK_MANAGEMENT_FILTER,
    };
  },
  methods: {
    setEventListeners() {
      EventBus.$on(EventBusConstants.TASK_LIST_UPDATED, () => {
        this.onRefreshMenu();
      });
    },
    emitNavigated(route) {
      this.$emit("navigated", route);
    },
    onClick() {
      this.groupExpanded = !this.groupExpanded;
      if (this.filters.length > 0) {
        return;
      }

      this.getFilters();
    },
    onRefreshMenu(reset = undefined) {
      this.getFilters();
      if (reset) {
        EventBus.$emit(EventBusConstants.TASK_VIEW_RESETTED);
      }
    },
    getFilters() {
      this.isLoadingFilters = true;
      this.filters = [];
      let resource = "task";
      if (this.viewType === VIN_ITEM) {
        resource = "vehicleTransferOrder";
      }

      if (
        this.viewType !== VIN_ITEM ||
        (this.viewType === VIN_ITEM && this.isTmsContextUser)
      ) {
        const params = {
          addCount: true,
        };
        return RunBuggyTasksApiService.getViews(resource, params)
          .then((res) => {
            this.filters = res;
            this.isLoadingFilters = false;
          })
          .catch((error) => {
            this.filters = [];
            this.isLoadingFilters = false;
            console.error(error);
          });
      } else {
        this.isLoadingFilters = false;
      }
    },
    canResetView(view) {
      return view.overriddenId && view.overriddenId !== "";
    },
    canDeleteView(view) {
      return view.created && view.created.byUser === this.userData.userName;
    },
    getPodsFiltersFromLocalStorage() {
      let podsData = LocalStorageService.get(this.podsLocalStorageKey);
      return podsData;
    },
    buildRoute(filter) {
      if (this.viewType === VIN_ITEM) {
        return {
          name: "vin-list-view",
          params: {
            id: filter.overriddenId ? filter.overriddenId : filter.id,
            vin_id: "null",
          },
        };
      } else {
        return {
          name: "task-list-view",
          params: {
            id: filter.overriddenId ? filter.overriddenId : filter.id,
            child_id: "null",
            business_key: "null",
            task_id: "null",
          },
        };
      }
    },
  },
  computed: {
    viewType() {
      return _get(this.item, "view");
    },
    groupKey() {
      return _get(this.item, "groupKey");
    },
    podsLocalStorageKey() {
      return `${LocalStorageKeys.PODS_TASK_GROUPS}_${this.userId}`;
    },
    shouldShow() {
      let shouldShow = true;
      if (this.item.roles) {
        let matches = this.item.roles.filter(
          (value) => -1 !== this.userRoles.indexOf(value)
        );
        return matches.length > 0;
      }

      return shouldShow;
    },
    children() {
      return _sortBy(this.filters, "priority").map((filter) => {
        return {
          label: filter.name ? filter.name : "No label",
          color: filter.color,
          count: filter.count,
          route: this.buildRoute(filter),
          canReset: this.canResetView(filter),
          canDelete: this.canDeleteView(filter),
          isActive: () => {
            return (
              this.$route.params.id ===
              (filter.overriddenId ? filter.overriddenId : filter.id)
            );
          },
          type: TASK_MANAGEMENT_FILTER,
        };
      });
    },
    hasChildren() {
      return this.children.length > 0;
    },
    ...mapGetters("user", [
      "userRoles",
      "userData",
      "userId",
      "isTmsContextUser",
    ]),
    ...mapGetters("ui", {
      uiCollapsedGroupKeys: "collapsedGroupKeys",
    }),
  },
  created() {
    if (this.hasChildren && !this.item.groupKey) {
      throw new Error("Navigation item with children must have a group key");
    }
  },
  mounted() {
    setTimeout(() => {
      this.onClick();
    }, 1000);
    this.setEventListeners();
  },
  beforeDestroy() {},
  mixins: [],
  components: {
    TaskManagementFilterItemButton,
    ItemButton,
    Item,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.list-group {
  position: relative;
  border-top: 1px solid rgba(#fff, 0.15);

  &.expanded {
    background: rgba(#fff, 0.0125);
  }
}
</style>
