import axios from 'axios'
import TokenService from '../services/tokenService'
import _get from "lodash/get";

const Client = {
    axiosClient: axios.create(),
    async oauth2Token(data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ngApi.path')}/oauth2/token`;
        return this.post(path, data);
    },

    get(path, query = {}) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();

        let params = query;
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        if (TokenService.hasSpecifiedRegistrationId()) {
            params['specifiedRegistrationId'] = TokenService.getSpecifiedRegistrationId();
        }

        let headers = {
            'Authorization': `Bearer ${token}`
        }

        return Client.axiosClient.get(path, {
            headers,
            params
        });
    },
    post(path, data) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();

        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        if (TokenService.hasSpecifiedRegistrationId()) {
            params['specifiedRegistrationId'] = TokenService.getSpecifiedRegistrationId();
        }

        let headers = {
            'Authorization': `Bearer ${token}`
        }

        return Client.axiosClient.post(path, data, {
            headers,
            params
        });
    }
};

export default Client;
