import {Client as CamundaApiClient} from './camundaApi'
import _get from "lodash/get";
import axios from "axios";

export class Client extends CamundaApiClient {

    createPath() {
        let prefix = _get(window, 'CONFIG.camunda.api.prefix');
        let path = `${prefix}/pricing`;
        return `${path}/rest/engine/default`;
    }

    getDecisionRequirementDefinitions(key) {
        let path = `${this.createPath()}/decision-requirements-definition?sortBy=version&sortOrder=desc`;
        if(key) {
            path += `&key=${key}`;
        }
        return this.get(path);
    }

    getDecisionDefinitions(key) {
        let path = `${this.createPath()}/decision-definition?sortBy=version&sortOrder=desc`;
        if(key) {
            path += `&key=${key}`;
        }
        return this.get(path);
    }

    getDecisionRequirementDefinitionXML(id) {
        let path = `${this.createPath()}/decision-requirements-definition/${id}/xml`;
        return this.get(path);
    }

    getDecisionDefinitionXML(id) {
        let path = `${this.createPath()}/decision-definition/${id}/xml`;
        return this.get(path);
    }

    deployDefinition(name, xml) {
        let path = `${this.createPath()}/deployment/create`;
        const form = new FormData();
        form.append("enable-duplicate-filtering", "false");
        form.append("deploy-changed-only", "false");
        form.append("data", new Blob([xml], {type: "text/xml"}), name);
        return this.post(path, form);
    }

    removeDeploymentWithID(id) {
        let path = `${this.createPath()}/deployment/${id}`;
        return this.delete(path);
    }

}

const client = new Client();
client.axiosClient = axios.create();

export default client;
