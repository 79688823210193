<template>
  <div class="d-flex h-100">
    <toasts />
    <div class="left-column shadow-lg">
      <router-view></router-view>
    </div>
    <div class="d-none d-sm-block flex-grow-1 right-column">
      <div class="truck-with-cars-container">
        <img src="../../assets/images/login/truck-with-cars.png" />
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import COLORS from "@/constants/colors";
import Toasts from "@/components/toasts";

export default {
  name: "layouts-login-index",
  watch: {},
  props: {},
  data() {
    return {
      COLORS,
    };
  },
  methods: {},
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {
    Toasts,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../scss/variables";
@import "SC/scss/mq-mixins";

.logo {
  margin-bottom: 50px;
}

.left-column {
  width: 100%;
  max-width: 600px;

  position: relative;
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  .truck-with-cars-container {
    overflow: hidden;

    img {
      width: 800px;
      margin-left: 50px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

.right-column {
  background-image: url("../../assets/images/login/background.jpg");
  background-size: cover;
  position: relative;

  .truck-with-cars-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    img {
      position: absolute;
      bottom: 0;
      left: 200px;

      @media (min-width: 2300px) {
        right: -100px;
        left: auto;
      }
    }
  }
}

.form-container {
  display: inline-block;
  width: 80%;
  max-width: 530px;
  background: white;
}

.button-sign-up {
  background: white;
  color: $colorCTA;

  strong {
    padding-left: 5px;
    color: $colorMainTextNav;
  }
}

.login-button {
  justify-content: left !important;
  color: white;

  .icon {
    position: absolute;
    right: 0;
  }
}

@include sm-down {
  .left-column {
    min-width: 100%;
    max-width: 100%;
    padding-bottom: 80px !important;
  }
  
}
</style>
