<template>
  <v-dialog v-model="isDialogOpenState" persistent max-width="650">
    <v-card>
      <v-card-title
        class="headline d-flex justify-center pt-8"
        id="documentExpiringHeader"
      >
        <div class="col-sm-12 d-flex justify-center">
          <v-icon class="warning-document-icon warning--text">
            mdi-file-clock-outline
          </v-icon>
        </div>
        <div v-if="daysLeft === 0" class="modal-header">
          {{
            $i18n(
              "accountManagement.documents.expiringToday",
              "Document Expires Today"
            )
          }}
        </div>
        <div v-else class="modal-header">
          {{
            $i18n("accountManagement.documents.expiring", "Document Expires In")
          }}
          {{ daysLeft }}
          <span v-if="daysLeft === 1"
            >{{ $i18n("common.label.day", "Day") }}
          </span>
          <span v-else> {{ $i18n("common.label.days", "Days") }} </span>
        </div>
      </v-card-title>
      <div class="col-sm-12 selections-container" id="selectionsContainer">
        <div
          class="row mb-10 selection-card d-flex"
          v-ripple
          id="uploadSection"
          @click="goToDocumentManagement"
        >
          <div
            class="col-sm-2 d-flex justify-center align-center"
            id="uploadSectionIcon"
          >
            <v-avatar class="avatar-section" color="blue lighten-5">
              <v-icon class="avatar-icon" color="blue">
                mdi-file-upload-outline
              </v-icon>
            </v-avatar>
          </div>
          <div class="col-sm-10 d-flex">
            <div class="selection-content-section" id="uploadContentSection">
              <div class="col-sm-12 selection-header" id="uploadHeader">
                {{
                  $i18n(
                    "accountManagement.documents.uploadNew",
                    "Upload a New Document"
                  )
                }}
              </div>
              <div class="col-sm-12 selection-subheader" id="uploadSubheader">
                {{
                  $i18n(
                    "accountManagement.documents.uploadNewMsg",
                    "Your access to All of the RunBuggy App features will not be limited, if a new document is provided."
                  )
                }}
              </div>
            </div>
            <div
              class="selection-go-action-section justify-end align-center"
              id="uploadGoAction"
            >
              <v-slide-x-transition>
                <v-btn
                  class="go-to-view-icon"
                  id="goToDocumentManagementBtn"
                  v-tooltip="$i18n('common.label.go', 'Go')"
                  @click="goToDocumentManagement"
                  icon
                >
                  <v-icon> mdi-arrow-right </v-icon>
                </v-btn>
              </v-slide-x-transition>
            </div>
          </div>
        </div>
        <div
          class="row mb-8 selection-card d-flex"
          v-ripple
          id="continueSection"
          @click="closeDialog"
        >
          <div
            class="col-sm-2 d-flex justify-center align-center"
            id="continueSectionIcon"
          >
            <v-avatar class="avatar-section" color="light-blue lighten-5">
              <img class="logo-img avatar-icon" src="../../assets/logo.svg" />
            </v-avatar>
          </div>
          <div class="col-sm-10 d-flex">
            <div class="selection-content-section" id="continueContentSection">
              <div class="col-sm-12 selection-header" id="continueHeader">
                {{
                  $i18n(
                    "accountManagement.documents.continue",
                    "Continue with RunBuggy"
                  )
                }}
              </div>
              <div class="col-sm-12 selection-subheader" id="continueSubheader">
                {{
                  $i18n(
                    "accountManagement.documents.continueMsg",
                    "Your access to All of the RunBuggy App features will be limited, if document expires and no new document is provided."
                  )
                }}
              </div>
            </div>
            <div
              class="selection-go-action-section justify-end align-center"
              id="continueAction"
            >
              <v-slide-x-transition>
                <v-btn
                  class="go-to-view-icon"
                  id="closeDialogBtn"
                  v-tooltip="$i18n('common.label.go', 'Go')"
                  @click="closeDialog"
                  icon
                  outlined
                >
                  <v-icon> mdi-arrow-right </v-icon>
                </v-btn>
              </v-slide-x-transition>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script type="text/babel">
export default {
  name: "components-document_expiring-index",
  watch: {},
  props: {
    isDialogOpen: {
      default() {
        return false;
      }
    },
    daysLeft: {
      default() {
        return 0;
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    goToDocumentManagement() {
      this.$router.push({ name: "user-account-management-documents" });
      this.$emit("modal-close");
    },
    closeDialog() {
      this.$emit("modal-close");
    }
  },
  computed: {
    isDialogOpenState() {
      return this.isDialogOpen;
    }
  },
  created() {},
  mounted() {},
  mixins: [],
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/variables";
@import "../../scss/variables";

.warning-document-icon {
  font-size: 50px;
}

.avatar-section {
  width: 60px !important;
  height: 60px !important;

  .avatar-icon {
    width: 50px;
    height: 50px;
    font-size: 28px;
  }
}

.selections-container {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 50px;
  padding-right: 50px;
}

.selection-content-section {
  width: 360px;
}

.selection-go-action-section {
  display: flex;
  width: 60px;
}

.selection-card {
  border: 2.5px solid $colorLightGray;
  border-radius: 5px;
  padding: 10px 15px;
  opacity: 0.8;
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}

.selection-card:hover {
  cursor: pointer;
  border: 2.5px solid var(--v-primary-base);
  background-color: $colorLightGray;
  transition: 0.6s;
  opacity: 1;

  .go-to-view-icon {
    visibility: visible;
    opacity: 1;
    transition: 0.8s ease-out;
  }
}

.selection-header {
  font-size: 15px;
  font-weight: 600;
}

.selection-subheader {
  font-size: 13px;
  color: $colorBlueGrey;
}

.active-selection {
  border: 2.5px solid var(--v-primary-base);
  transition: 0.4s;
}

.go-to-view-icon {
  color: $color-WHITE !important;
  background-color: var(--v-primary-base) !important;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

@media screen and (max-width: 600px) {
  .selections-container {
    padding: 5px 30px;
  }
  .selection-card {
    padding: 10px;
    margin-bottom: 10px;
  }
  .selection-header {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
  }
  .modal-header {
    font-size: 17px;
  }
  .selection-subheader {
    font-size: 12px;
    text-align: center;
  }
  .selection-go-action-section {
    display: none;
  }
  .warning-document-icon {
    font-size: 40px;
  }
}
</style>
