// import _find from 'lodash/find';
import _forIn from "lodash/forIn";
import _get from "lodash/get";
import _has from "lodash/has";
import _intersectionBy from "lodash/intersectionBy";

import * as types from "./mutation-types";

// initial state
const getInitialState = () => {
  return {
    hasRetrievedOrder: false,
    isRetrievingOrder: false,
    retrievingOrderId: undefined,
    retrievingMessage: undefined,
    retrievalError: undefined,

    /**
     * The shipper tied to this particular perspective
     */
    shipperOrder: undefined,
    shipperTransporterOrders: [],
    shipperVehicleTransferOrders: [],

    /**
     * The transporter tied to this particular perspective
     */
    transporterOrder: undefined,
    transporterShipperOrders: [],
    transporterVehicleTransferOrders: [],

    /**
     * The vehicle tied to this particular perspective
     */
    vehicleTransferOrder: undefined,

    allVehicleTransferOrders: [],
    /**
     * When retrieving VTOs for Shipper and Transporter, no reason to call the same VTO multiple times
     */
    sharedVehicleTransferOrders: {},
    expandedOrder: undefined,

    orderTasks: [],
  };
};

const state = getInitialState();

// getters
const getters = {
  /**
   *
   * @param {*} state
   */
  overallOrder(state) {
    return state;
  },
  shipperOrderVTOCount(state) {
    return _get(state.shipperOrder, "vehicleTransferOrders", []).length;
  },
  transporterOrderVTOCount(state) {
    return _get(state.transporterOrder, "vehicleTransferOrders", []).length;
  },
  sharedVTOCount(state) {
    return Object.keys(state.sharedVehicleTransferOrders).length;
  },
  hasRetrievalError(state) {
    return !!state.retrievalError;
  },
  orderTasks(state) {
    return state.orderTasks;
  },
};

import RunBuggyOrdersApiService from "SC/services/runBuggyOrdersApi";
import RunBuggyOrdersApi from "SC/clients/runBuggyOrdersApi";

// actions
const actions = {
  retrieveOrderTasks($store) {
    let { state } = $store;

    let transportationOrderId = state.expandedOrder.id;

    $store.commit(types.ORDER_TASKS, []);

    return RunBuggyOrdersApi.getTransportationOrderTasks(transportationOrderId)
      .then((result) => {
        let tasks = _get(result, "data", []);
        $store.commit(types.ORDER_TASKS, tasks);
      })
      .catch(() => {
        // Simply no tasks available
      });
  },
  retrieveOrder($store, { perspective, id, ...rest }) {
    let { state } = $store;

    if (state.isRetrievingOrder) {
      // TODO: Cancel the current load request
      return;
    }

    $store.commit(types.IS_RETRIEVING_ORDER, true);
    $store.commit(types.RETRIEVING_ORDER_ID, id);

    switch (perspective) {
      case "SO":
      case "shipper":
        RunBuggyOrdersApiService.getShipperOrder(id).then((res) => {
          console.log("store shipper", res);
        });

        break;
      default:
        $store.commit(types.RETRIEVING_MESSAGE, "Retrieving VTO");

        const isVTO = perspective === "VTO" || perspective === "vehicles";

        const call = isVTO
          ? RunBuggyOrdersApiService.getExpandedOrder(id)
          : RunBuggyOrdersApiService.getTransportationOrderByIdFull(id);

        call
          .then((result) => {
            const type = isVTO
              ? types.VEHICLE_TRANSFER_ORDER
              : types.TRANSPORTER_ORDER;
            $store.commit(type, result);
            console.log("Here:", result);
          })
          // .then(() => {
          //     return RunBuggyOrdersApiService.getVehicleTransferOrder(id);
          // })
          .then((res) => {
            // Error out if order not found
            if (!_has(res, "id")) {
              throw "Invalid vehicle transfer order";
            }

            $store.commit(types.VEHICLE_TRANSFER_ORDER, res);

            let promises = [];

            // TODO: Move photos/gate passes to it's own then block before SO/TO
            $store.commit(
              types.RETRIEVING_MESSAGE,
              "Retrieving Photos/Gate Passes"
            );

            promises.push(
              RunBuggyOrdersApiService.getVehicleTransferOrderPhotos(id).then(
                (res) => {
                  if (!_has(res, "id")) {
                    // TODO: Handle
                    // throw 'Invalid transporter order';
                    return;
                  }

                  $store.commit(types.VEHICLE_TRANSFER_ORDER_PHOTOS, res);

                  return res;
                }
              )
            );

            promises.push(
              RunBuggyOrdersApiService.getVehicleTransferOrderGatePasses(
                id
              ).then((res) => {
                if (!_has(res, "id")) {
                  // TODO: Handle
                  // throw 'Invalid transporter order';
                  return;
                }

                $store.commit(types.VEHICLE_TRANSFER_ORDER_GATEPASSES, res);

                return res;
              })
            );

            $store.commit(types.RETRIEVING_MESSAGE, "Retrieving SO/TO");

            let shipperOrderId = _get(res, "orderId");
            if (shipperOrderId) {
              promises.push(
                RunBuggyOrdersApiService.getShipperOrder(shipperOrderId).then(
                  (res) => {
                    if (!_has(res, "id")) {
                      // TODO: Handle
                      // throw 'Invalid shipper order';
                      return;
                    }

                    $store.commit(types.SHIPPER_ORDER, res);

                    return res;
                  }
                )
              );
            }

            // let transporterOrderId = _get(res, 'transporter.id');
            // TODO: VTO response should return the TO id, which it currently doesn't.
            let transporterOrderId = _get(rest, "_additional.cell.data.id");

            if (transporterOrderId) {
              promises.push(
                RunBuggyOrdersApiService.getTransporterOrder(
                  transporterOrderId
                ).then((res) => {
                  if (!_has(res, "id")) {
                    // TODO: Handle
                    // throw 'Invalid transporter order';
                    return;
                  }

                  $store.commit(types.TRANSPORTER_ORDER, res);

                  return res;
                })
              );
            }

            return Promise.all(promises);
          })
          .then((allRes) => {
            $store.commit(types.RETRIEVING_MESSAGE, "Analyzing VTO");

            // NOTE: This is remaining here until a true definition of states vs. timeline is hammered out.
            // The READY or created state isn't populated, so we will populate it.
            // if(_has(state, 'vehicleTransferOrder.states.ready') === false) {
            //     // states is null when first created, and _get doesn't default null to { }.
            //     let states = _get(state, 'vehicleTransferOrder.states', { }) || { };

            //     states.ready = {
            //         time: _get(state, 'vehicleTransferOrder.created.date'),
            //     };

            //     let vehicleVTO = _get(state, 'vehicleTransferOrder');

            //     vehicleVTO.states = states;

            //     $store.commit(types.VEHICLE_TRANSFER_ORDER, vehicleVTO);
            // }

            // Process shared vehicle transfer orders...
            // Populate SHARED with id placeholders, then fetch all and update accordingly

            let shipperVTOs = _get(
              state.shipperOrder,
              "vehicleTransferOrders",
              []
            );
            let transporterVTOs = _get(
              state.transporterOrder,
              "vehicleTransferOrders",
              []
            );

            let allVTOs = {};

            // We're keying these now... it'll help avoid looping through an array.
            [shipperVTOs, transporterVTOs].forEach((perspectiveVTOs) => {
              perspectiveVTOs.forEach((vto) => {
                allVTOs[vto.id] = vto;
              });
            });

            // Add the loaded VTO
            allVTOs[state.vehicleTransferOrder.id] = state.vehicleTransferOrder;

            // No need to run a pointless process if either is empty.
            if (shipperVTOs.length && transporterVTOs.length) {
              let sharedVTOs = [];

              _intersectionBy(shipperVTOs, transporterVTOs, "id").forEach(
                (sharedVTO) => {
                  sharedVTOs.push(sharedVTO.id);
                }
              );

              $store.commit(types.SHARED_VEHICLE_TRANSFER_ORDERS, sharedVTOs);
            }

            $store.commit(types.RETRIEVING_MESSAGE, "Finishing up...");

            // Let's go get all the VTOs now...
            let promises = [];

            _forIn(allVTOs, (vto, id) => {
              // No need to re-pull anything that already exists...
              if (vto.referenceNumber) {
                return;
              }

              promises.push(
                RunBuggyOrdersApiService.getVehicleTransferOrder(id).then(
                  (res) => {
                    if (!_has(res, "id")) {
                      // TODO: Handle
                      // throw 'Invalid vehicle transfer order';
                      return;
                    }

                    allVTOs[id] = res;

                    return res;
                  }
                )
              );
            });

            return Promise.all(promises).then((allRes) => {
              $store.commit(types.ALL_VEHICLE_TRANSFER_ORDERS, allVTOs);

              $store.commit(types.RETRIEVING_MESSAGE, "All done!");

              return allRes;
            });
          })

          // Retrieve task actions
          .then(() => {
            $store.dispatch("retrieveOrderTasks");
          })

          .catch((error) => {
            $store.commit(types.RETRIEVAL_ERROR, error);
          })
          .finally(() => {
            $store.commit(
              types.HAS_RETRIEVED_ORDER,
              !!state.vehicleTransferOrder ||
                !!state.shipperOrder ||
                !!state.transporterOrder
            );
            $store.commit(types.IS_RETRIEVING_ORDER, false);
            // console.log('VTO Details', JSON.parse(JSON.stringify({
            //     vehicleTransferOrder: state.vehicleTransferOrder,
            //     shipperOrder: state.shipperOrder,
            //     transporterOrder: state.transporterOrder
            // })));
          });

        break;
      // default:
      // TODO: Handle error
    }
  },
  resetPerspective($store) {
    $store.commit(types.RESET_PERSPECTIVE);
  },
};

// mutations
const mutations = {
  [types.RESET_PERSPECTIVE]() {
    Object.assign(state, getInitialState());
  },
  [types.HAS_RETRIEVED_ORDER](state, payload) {
    state.hasRetrievedOrder = payload;
  },
  [types.IS_RETRIEVING_ORDER](state, payload) {
    state.isRetrievingOrder = payload;
  },
  [types.RETRIEVING_ORDER_ID](state, payload) {
    state.retrievingOrderId = payload;
  },
  [types.RETRIEVING_MESSAGE](state, payload) {
    state.retrievingMessage = payload;
  },
  [types.RETRIEVAL_ERROR](state, payload) {
    state.retrievalError = payload;
  },
  [types.SHIPPER_ORDER](state, payload) {
    state.shipperOrder = payload;
  },
  [types.SHIPPER_TRANSPORTER_ORDERS](state, payload) {
    state.shipperOrder = payload;
  },
  [types.SHIPPER_VEHICLE_TRANSFER_ORDERS](state, payload) {
    state.shipperOrder = payload;
  },
  [types.TRANSPORTER_ORDER](state, payload) {
    state.transporterOrder = payload;
  },
  [types.TRANSPORTER_SHIPPER_ORDERS](state, payload) {
    state.shipperOrder = payload;
  },
  [types.TRANSPORTER_VEHICLE_TRANSFER_ORDERS](state, payload) {
    state.shipperOrder = payload;
  },
  [types.VEHICLE_TRANSFER_ORDER](state, payload) {
    state.vehicleTransferOrder = payload;
  },
  [types.VEHICLE_TRANSFER_ORDER_PHOTOS](state, payload) {
    state.vehicleTransferOrder.photos = payload;
  },
  [types.VEHICLE_TRANSFER_ORDER_GATEPASSES](state, payload) {
    state.vehicleTransferOrder.gatePasses = payload;
  },
  [types.ALL_VEHICLE_TRANSFER_ORDERS](state, payload) {
    state.allVehicleTransferOrders = payload;
  },
  [types.SHARED_VEHICLE_TRANSFER_ORDERS](state, payload) {
    state.sharedVehicleTransferOrders = payload;
  },
  [types.EXPANDED_ORDER](state, expandedOrder) {
    state.expandedOrder = expandedOrder;
  },
  [types.ORDER_TASKS](state, orderTasks) {
    state.orderTasks = orderTasks;
  },
};

const namespace = "perspective";

export const allState = Reflect.ownKeys(state);
export const allGetters = Reflect.ownKeys(getters);
export const allActions = Reflect.ownKeys(actions);
export const allMutations = Reflect.ownKeys(mutations);

export default {
  namespaced: typeof namespace !== "undefined",
  namespace,
  state,
  getters,
  actions,
  mutations,
};
