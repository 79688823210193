<template>
  <v-list-item class="export-status-item ma-0" :key="item.id">
    <v-list-item-avatar>
      <div class="align-self-center">
        <template v-if="isDownloadReady">
          <v-icon v-if="seen" color="grey"
            >mdi-file-document-check-outline</v-icon
          >
          <v-icon v-else color="success"
            >mdi-file-document-check-outline</v-icon
          >
        </template>
        <v-icon v-else-if="isDownloadFailed" color="error">mdi-file-document-alert-outline</v-icon>
        <v-icon v-else color="warning">mdi-file-clock-outline</v-icon>
        
      </div>
    </v-list-item-avatar>
    <v-list-item-content
      class="mr-2"
      v-bind:class="{ 'font-weight-bold': !item.userSeen }"
    >
      <v-list-item-title class="export-label font-14-700">
        {{ label }}
      </v-list-item-title>
      <v-list-item-subtitle
        class="export-date font-13-500 color-grey-500 mt-2"
      >
        {{ date }} <template v-if="isDownloadReady">/ {{ size }}</template>
      </v-list-item-subtitle>
      <v-list-item-subtitle class="mt-2" v-if="isDownloadFailed">
        <div class="font-13-500 color-red-500">{{ error }}</div>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <div v-if="!isDownloadReady && !isDownloadFailed">
        <loading-spinner :size="25"></loading-spinner>
      </div>
      <div v-else class="d-flex">
        <v-btn
          v-if="!isDownloadFailed"
          color="primary"
          class="action-button download-export-btn"
          id="download_export_btn"
          outlined
          @click="onDownloadExport(item.id)"
          text
        >
          <v-icon class="mr-5"> mdi-folder-download </v-icon>
          {{
            $i18n("accountManagement.documents.download.tooltip", "Download")
          }}
        </v-btn>
        <v-btn
          class="action-button ml-5"
          color="error"
          outlined
          id="delete_export_btn"
          @click="onDeleteExport(item.id)"
          text
          v-bind:disabled="isDeleting"
          v-bind:loading="isDeleting"
        >
          <v-icon class="mr-5"> mdi-trash-can </v-icon>
          {{ $i18n("common.label.delete", "Delete") }}
        </v-btn>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script type="text/babel">
import LoadingSpinner from "SC/components/loading-spinner";

import _get from "lodash/get";
import moment from "moment";
import "moment-timezone";
import RunbuggyOrdersApi from "SC/services/runBuggyOrdersApi";
import NotificationConstants from "@/constants/notifications";

export default {
  name: "components-export-status-index",
  watch: {},
  props: {
    item: undefined,
  },
  data() {
    return {
      isDeleting: false
    };
  },
  methods: {
    onDeleteExport(exportId) {
      this.isDeleting = true;

      RunbuggyOrdersApi.deleteExportFile(exportId)
        .then((res) => {
          this.$emit("refresh-list");
          this.showNotification(
            this.$i18n("common.label.success", "Success"),
            this.$i18n(
              "common.label.exportHistoryDeleted",
              "Export file deleted successfully"
            ),
            "success"
          );
        })
        .catch((error) => {
          this.showNotification(
            this.$i18n("common.label.error", "Error") +
              " " +
              error.response.status,
            error.response.statusText,
            "error"
          );
        })
          .finally(() => {
            this.isDeleting = false;
          })
    },
    onDownloadExport(exportId) {
      RunbuggyOrdersApi.downloadExportFile(exportId).then((res) => {
        let data = _get(res, "data");

        let contentDisposition = _get(res, "headers.content-disposition");
        let filename = contentDisposition.split(";")[2].split('"')[1];

        const anchor = document.createElement("a");
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(data);
        anchor.target = "_blank";
        anchor.download = filename;
        anchor.click();
      });
    },
    showNotification(title, message, type) {
      this.$notify({
        type: type,
        title: title,
        text: message,
        duration: NotificationConstants.DURATION,
        speed: 1000,
      });
    },
    formatDateTime(dateTime) {
      let timezone = "America/Los_Angeles";
      try {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (e) {
        //
      }
      if (dateTime) {
        return moment(dateTime).tz(timezone).format("MM/DD/YYYY hh:mm A");
      } else {
        return "";
      }
    },
    humanFileSize(size) {
      var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "kB", "MB", "GB", "TB"][i]
      );
    },
  },
  computed: {
    label() {
      return _get(this.item, "name", "");
    },
    isDownloadReady() {
      return this.status === "COMPLETED";
    },
    isDownloadFailed() {
      return this.status === "FAILED";
    },
    status() {
      return _get(this.item, "status", "CREATED");
    },
    error() {
      return _get(this.item, "error", "");
    },
    date() {
      const createdDate = _get(this.item, "created.date");
      if (!createdDate) return;
      const createdDateFormatted = this.formatDateTime(createdDate);
      return `${this.$i18n(
        "gridManagement.filter.createTime",
        "Created"
      )} - ${createdDateFormatted}`;
    },
    size() {
      return this.humanFileSize(_get(this.item, "file.size", 0));
    },
    seen() {
      return _get(this.item, "downloaded", true);
    },
  },
  mounted() {},
  created() {},
  beforeDestroy: function () {},
  mixins: [],
  components: {
    LoadingSpinner,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.export-data {
  margin-right: 60px;
  margin-left: 10px;
}

.item-status {
  margin-left: auto;
}

.seen-container {
  width: 8px;
}

.not-seen {
  top: 0 !important;
}
.action-button {
  min-width: 40px !important;
  // padding: 0 !important
}
.v-list-item__icon:first-child {
  margin-right: 20px;
}

.v-list-item__title {
  white-space: normal !important;
}

.v-list-item__subtitle {
  white-space: normal !important;
}
</style>
