import axios from 'axios'
import TokenService from '../services/tokenService'
import _get from "lodash/get";

const Client = {
    axiosClient: axios.create(),
    async authenticate(email, password) {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/login`;
        return Client.axiosClient.post(path, {
            username: email,
            password: password
        });
    },

    async authenticateAsUser(username) {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/admin/authenticateAsUser`;
        let token = TokenService.getToken();

        return Client.axiosClient.post(path, {
            username: username
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    },

    async updatePassword(newPassword, oldPassword) {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/user/updatePassword`;
        return this.post(path, {
            newPassword: newPassword,
            oldPassword: oldPassword
        });
    },

    async sendPasswordResetLink(username) {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/user/sendPasswordResetLink`;

        return Client.axiosClient.get(path, {
            params: {
                userName: username
            }
        });
    },

    async getLoggedInUserData() {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/user/getLoggedInUserData`;
        return this.get(path);
    },

    async getUsersByRole(role, offset = 0, limit = 1000) {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/company/allRegisteredUsers`;

        let query = [
            `offset=${offset}`,
            `limit=${limit}`,
        ]

        path = `${path}?${query.join('&')}`;

        return this.post(path, {
            role: role
        });
    },

    async allRegisteredUsers(payload, offset = 0, limit = 1000) {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/company/allRegisteredUsers`;

        let query = [
            `offset=${offset}`,
            `limit=${limit}`,
        ];

        path = `${path}?${query.join('&')}`;

        return this.post(path, payload);
    },

    async searchUserNames(username) {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/admin/searchUserNames`;
        let token = TokenService.getToken();

        return Client.axiosClient.post(path, {
            username: username
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    },

    async getDriversByTransporter() {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/driver/byTransporter`;
        return this.post(path);
    },

    async getAuthorizedCompanies(specifiedRegistrationId) {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/company/getCompaniesWithMeInGoodStanding`;
        let query = [];
        if (specifiedRegistrationId) {
            query.push(`specifiedRegistrationId=${specifiedRegistrationId}`)
        }

        if (query.length > 0) {
            path += `?${query.join('&')}`
        }

        return this.get(path);
    },

    async getAllAuthorizedCompaniesByUsername(username) {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/company/getAllAuthorizedCompaniesByUsername`;
        return this.get(path, {
            params: {
                username: username
            }
        });
        //return this.get(path, username);
    },

    async getOrdersByQuery(query) {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/order/getAllOrdersByFilters`;
        return this.post(path, query);
    },

    async legacyLogout() {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/logout`;
        return this.post(path, {});
    },

    async getOrderDetails(orderId) {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/order/getOrderDetails?orderId=${orderId}`;
        return this.get(path);
    },

    async findCompanies(query, entityTypes = [], limit = 10) {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/company/allCompanies?limit=${limit}`;
        return this.post(path, {
            companyName: query,
            RBEntityType: entityTypes.join(',')
        });
    },

    async getReasonsForCancelingOrder() {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/order/getReasonsForCancelingOrder`;
        return this.get(path);
    },

    async getCompanies(query) {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/company/getCompanies`;
        return this.get(path, query);
    },

    async getUserDocumentDetails(payload) {
        let path = `${_get(window, 'CONFIG.runbuggy.api.path')}/document/getUserDocumentDetails`;
        return this.post(path, payload);
    },

    get(path, query = {}) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();

        let params = query;
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        if (TokenService.hasSpecifiedRegistrationId()) {
            params['specifiedRegistrationId'] = TokenService.getSpecifiedRegistrationId();
        }

        let headers = {
            'Authorization': `Bearer ${token}`
        }

        return Client.axiosClient.get(path, {
            headers,
            params
        });
    },
    post(path, data) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();

        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        if (TokenService.hasSpecifiedRegistrationId()) {
            params['specifiedRegistrationId'] = TokenService.getSpecifiedRegistrationId();
        }

        let headers = {
            'Authorization': `Bearer ${token}`
        }

        return Client.axiosClient.post(path, data, {
            headers,
            params
        });
    }
};

export default Client;
