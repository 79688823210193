<template>
  <v-dialog v-model="value" persistent max-width="650">
    <v-card>
      <v-card-title
          class="headline d-flex justify-center pt-8"
          id="loginSuccessfulHeader"
      >
        <div class="col-sm-12 d-flex justify-center">
          <v-icon class="success-login-icon"> mdi-exclamation-thick</v-icon>
        </div>
        <div v-html="title" class="text-center">

        </div>
      </v-card-title>

      <v-card-text class="text-center">
        <div class="my-5">
          <p>
            {{ message }}
          </p>
        </div>

      </v-card-text>

      <v-divider/>

      <v-card-text>
        <div class="d-flex text-left pt-8">
          <div class="flex-grow-1">
            <v-btn outlined class="secondary-btn" v-on:click="stay">
              {{ stayText }}
            </v-btn>
          </div>
          <div>
            <v-btn depressed color="primary" v-on:click="reload">
              {{ switchText }}
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script type="text/babel">
import OktaService from '@/services/okta'
import EventBus from '@/services/eventBus'
import EventBusConstants from '@/constants/eventBus'
import TokenService from 'SC/services/tokenService'
import { mapGetters } from 'vuex'

export default {
  name: 'components-modal_user_roles_changed-index',
  watch: {},
  props: {
    previousTenantId: undefined,
    newTenantId: undefined,
    value: undefined
  },
  data () {
    return {}
  },
  methods: {
    async stay () {
      return OktaService.setTenantToken(this.previousTenantId)
          .then((res) => {
            EventBus.$emit(EventBusConstants.SELECTED_TENANT_CHANGED, this.previousTenantId)
            TokenService.clearSpecifiedRegistrationIds()
            window.location.reload()
          })
    },
    async reload () {
      window.location.reload()
    }
  },
  computed: {
    switchToRunBuggy () {
      return this.newTenantId === 'runbuggy'
    },
    fromRunBuggy () {
      return this.previousTenantId === 'runbuggy'
    },
    stayText() {
      if (this.switchToRunBuggy && !this.fromRunBuggy) {
        return 'Stay in HITCH Account';
      } else if (!this.switchToRunBuggy && !this.fromRunBuggy) {
        return 'Stay in HITCH Account';
      } else if (!this.switchToRunBuggy && this.fromRunBuggy) {
        return 'Stay in RunBuggy Account';
      } else {
        return 'Stay';
      }
    },
    switchText() {
      if (this.switchToRunBuggy && !this.fromRunBuggy) {
        return 'Switch to RunBuggy Account';
      } else if (!this.switchToRunBuggy && !this.fromRunBuggy) {
        return 'Switch to HITCH Account';
      } else if (!this.switchToRunBuggy && this.fromRunBuggy) {
        return 'Switch to HITCH Account';
      } else {
        return 'Switch';
      }
    },
    title () {
      if (this.switchToRunBuggy && !this.fromRunBuggy) {
        return 'You have switched to your RunBuggy account';
      } else if (!this.switchToRunBuggy && !this.fromRunBuggy) {
        return 'You have switched HITCH accounts';
      } else if (!this.switchToRunBuggy && this.fromRunBuggy) {
        return 'You have switched to your HITCH account';
      } else {
        return 'You have switched accounts';
      }
    },
    message () {
      if (this.switchToRunBuggy && !this.fromRunBuggy) {
        return 'This page is no longer valid with your RunBuggy account. Do you want to switch back to your HITCH account?';
      } else if (!this.switchToRunBuggy && !this.fromRunBuggy) {
        return 'This page is no longer valid with your HITCH account. Do you want to switch back to your other HITCH account?';
      } else if (!this.switchToRunBuggy && this.fromRunBuggy) {
        return 'This page is no longer valid with your HITCH account. Do you want to switch back to your RunBuggy account?';
      } else {
        return 'You have switched accounts';
      }
    },
    ...mapGetters('user', [
      'selectedTenantId'
    ])
  },
  created () {},
  mounted () {},
  beforeDestroy () {},
  mixins: [],
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/variables";
@import "../../scss/variables";

.success-login-icon {
  color: $color-COMPLETED;
  font-size: 50px;
}

.selections-container {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 50px;
  padding-right: 50px;
}

.selection-content-section {
  width: 360px;
}

.selection-go-action-section {
  width: 60px;
}

.selection-card {
  border: 2.5px solid $colorLightGray;
  border-radius: 5px;
  padding: 10px 15px;
  opacity: 0.8;
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}

.selection-card:hover {
  cursor: pointer;
  border: 2.5px solid var(--v-primary-base);
  background-color: $colorLightGray;
  transition: 0.6s;
  opacity: 1;

  .go-to-view-icon {
    visibility: visible;
    opacity: 1;
    transition: 0.8s ease-out;
  }
}

.selection-header {
  font-size: 15px;
  font-weight: 600;
}

.selection-subheader {
  font-size: 13px;
  color: $colorBlueGrey;
}

.active-selection {
  border: 2.5px solid var(--v-primary-base);
  transition: 0.4s;
}

.go-to-view-icon {
  color: $color-WHITE !important;
  background-color: var(--v-primary-base) !important;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
</style>
