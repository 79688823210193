<template>
  <v-footer class="app-footer" inset app>
    <small
      >&copy; Copyright {{ new Date().getFullYear() }} RunBuggy OMI Inc. All
      Rights Reserved<span> — </span
      ><a href="https://runbuggy.com/privacy/" target="_blank" class="mr-5"
        >Privacy Policy</a
      >
      <a href="https://runbuggy.com/terms/" target="_blank"
        >Terms of Service</a
      ></small
    >

    <v-spacer></v-spacer>
    <small>{{ appVersion }}</small>
  </v-footer>
</template>

<script type="text/babel">
import _get from "lodash/get";
export default {
  name: "components-footer-index",
  watch: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    appVersion() {
      return _get(window, "VERSION.ui");
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "SC/scss/mq-mixins";

.app-footer {
  z-index:10;
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@include sm-down {
  span,
  a {
    display: none;
  }
}
</style>
