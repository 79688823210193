<template>
  <div
    class="component name-avatar d-flex justify-center align-center"
    v-bind:style="style"
  >
    <template v-for="(initial, index) in initials">
      <span class="initial" v-bind:key="index">{{ initial }}</span>
    </template>
  </div>
</template>

<script type="text/babel">
export default {
  name: "components-name_avatar-index",
  watch: {},
  props: {
    name: {
      required: true
    },
    size: {
      default() {
        return "3";
      }
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    style() {
      return {
        "background-color": this.backgroundColor,
        "--avatar-size": `${this.size}em`,
      };
    },
    backgroundColor() {
      let index = this.initials[0].toLowerCase().charCodeAt(0) - 97;
      let value = [
        "#29b1e9",
        "#249fd1",
        "#208dba",
        "#1c7ba3",
        "#186a8b",
        "#145874",
        "#10465d",
        "#0c3545",
        "#08232e",
        "#3eb8eb",
        "#53c0ed",
        "#69c8ef",
        "#F44336",
        "#db3c30",
        "#c3352b",
        "#aa2e25",
        "#922820",
        "#7a211b",
        "#611a15",
        "#491410",
        "#f5554a",
        "#f6685e",
        "#f77b72",
        "#f88e86",
        "#f9a19a",
        "#fab3ae"
      ][index];

      if (!value) {
        value = "#F44336";
      }

      return value;
    },
    initials() {
      let initials = this.name.split(" ").map((part) => {
        return part[0];
      });
      return initials.length > 2 ? initials.slice(0, 2) : initials;
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.initial {
  font-weight: bold;
  font-size: 1em;
  color: white;
}

.name-avatar {
  display: inline-block;
  background: red;
  border-radius: 50%;
  width: var(--avatar-size);
  height: var(--avatar-size);
}
</style>
