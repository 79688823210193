<template>
  <div class="component batch-status-item-container">
    <v-list-item class="status-item ma-0" :key="item.id">
      <v-list-item-avatar>
        <div class="align-self-center">
          <v-icon 
            v-if="isCompleted" 
            color="success">
            mdi-clipboard-check-multiple-outline
          </v-icon>
          <v-icon 
            v-else-if="isFailed" 
            color="error">
            mdi-clipboard-off-outline
          </v-icon>
          <v-icon 
            v-else 
            color="warning">
            mdi-clipboard-clock-outline
          </v-icon>
          
        </div>
      </v-list-item-avatar>
      <v-list-item-content
        class="mr-2 font-weight-bold"
      >
        <v-list-item-title class="item-label font-14-700 text-capitalize">
          {{ batchOperation.replaceAll("_", " ").toLowerCase() }} - {{ batchOperationDescription.replaceAll("_", " ").toLowerCase() }}
        </v-list-item-title>
        <v-list-item-subtitle
          class="item-date font-13-500 color-grey-500 mt-2"
        >
          {{ startDate }} <template v-if="endDate">/ {{ endDate }}</template>
        </v-list-item-subtitle>
        <v-list-item-subtitle
          class="item-created font-12-400 color-grey-500 mt-2 align-self-center"
          v-if="createdUserString || createdDateString"
        >

          <template v-if="createdUserString">
            <v-icon small class="mr-2">mdi-account-circle</v-icon>
            <strong>{{ $i18n("common.label.processedBy", "Processed By") }}:</strong> {{ createdUserString }}
          </template>
          <template v-if="createdDateString">
            <v-icon small class="mr-2 ml-10">mdi-clock</v-icon>
            <strong>{{ $i18n("common.label.processedOn", "Processed On") }}:</strong> {{ createdDateString }}
          </template>
        </v-list-item-subtitle>
        <template v-if="showCurrentSummary && !showDetail">
          <v-list-item-subtitle class="mt-2 font-13-500">
            <v-chip label small class="mr-3" v-if="summaryPendingCount > 0">{{ $i18n("common.label.pending", "Pending") }}: {{ summaryPendingCount }}</v-chip>
            <v-chip label small class="mr-3" v-if="summaryProcessingCount > 0" color="warning">{{ $i18n("common.label.processing", "Processing") }}: {{ summaryProcessingCount }}</v-chip>
            <v-chip label small class="mr-3" v-if="summaryCompletedCount > 0" color="success">{{ $i18n("common.label.completed", "Completed") }}: {{ summaryCompletedCount }}</v-chip>
            <v-chip label small class="mr-3" v-if="summaryErrorCount > 0" color="error">{{ $i18n("common.label.error", "Error") }}: {{ summaryErrorCount }}</v-chip>
          </v-list-item-subtitle>
        </template>
        <template v-else>
          <v-list-item-subtitle class="mt-2 font-13-500">
            <v-chip label small class="mr-3" v-if="pendingCount > 0">{{ $i18n("common.label.pending", "Pending") }}: {{ pendingCount }}</v-chip>
            <v-chip label small class="mr-3" v-if="processingCount > 0" color="warning">{{ $i18n("common.label.processing", "Processing") }}: {{ processingCount }}</v-chip>
            <v-chip label small class="mr-3" v-if="completedCount > 0" color="success">{{ $i18n("common.label.completed", "Completed") }}: {{ completedCount }}</v-chip>
            <v-chip label small class="mr-3" v-if="errorCount > 0" color="error">{{ $i18n("common.label.error", "Error") }}: {{ errorCount }}</v-chip>
          </v-list-item-subtitle>
        </template>
        <v-list-item-subtitle class="mt-2" v-if="isFailed">
          <div class="font-13-500 color-red-500">{{ error }}</div>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <div v-if="!isCompleted && !isFailed">
          <loading-spinner :size="25"></loading-spinner>
        </div>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="showDetail=!showDetail" icon>
              <v-icon>{{showDetail?'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
            </v-btn>
          </template>
          <span>
            {{ $i18n("common.label.showBatchItems", "Show Batch Items") }}
          </span>
        </v-tooltip>
      </v-list-item-action>
    </v-list-item>
    <v-expand-transition>
      <div class="mb-5 h-100" v-if="showDetail">
        <div v-if="isLoading">
          <v-skeleton-loader
            loading
            type="list-item, list-item, list-item, list-item"
          >
          </v-skeleton-loader>
        </div>
        <ag-grid-vue
          v-else
          class="ag-theme-balham ag-grid-batch-operation-items"
          id="batchOperationItems"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="batchOperationItems"
          :sideBar="sideBarDefinition"
          :gridOptions="gridOptions"
        >
        </ag-grid-vue>
      </div>
    </v-expand-transition>
  </div>
</template>

<script type="text/babel">
import LoadingSpinner from "SC/components/loading-spinner";
import RowStatusRenderer from "./grid-row-batch-item-status-renderer";
import RowReferenceNumberRenderer from "./grid-row-reference-number-renderer";

import _get from "lodash/get";
import _filter from "lodash/filter";
import moment from "moment";
import "moment-timezone";
import RunbuggyOrdersApi from "SC/services/runBuggyOrdersApi";
import NotificationConstants from "@/constants/notifications";
import { AgGridVue } from "ag-grid-vue";
// agGrid enterprise license
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(`${_get(window, "CONFIG.agGrid.key")}`);
import { dateFilterComparator } from "@/helpers/ag-grid";
import EventBus from "@/services/eventBus";
import EventBusConstants from "@/constants/eventBus";


export default {
  name: "components-batch_operation_status-item-index",
  watch: {
    showDetail(newValue) {
      if(newValue) {
        this.getBatchOperationItems();
      }
    },
    currentBatchOperationId: {
      immediate: true,
      handler(newValue) {
        if(this.showCurrentSummary) {
          this.getBatchOperationSummary(newValue);
        }
      }
    }
  },
  props: {
    item: undefined,
    currentBatchOperationId: undefined,
  },
  data() {
    return {
      isLoading: false,
      batchOperationItems: [],
      showDetail: false,
      dateFormat: "MM/DD/YYYY hh:mm A",
      gridOptions: undefined,
      defaultColDef: undefined,
      columnDefs: undefined,
      rowData: undefined,
      itemSummary: undefined,
      batchOperationCompletedDone: false,
      getSummaryPromise: undefined,
      getTimeout: undefined,
      getTimeoutDuration: 1000,
    };
  },
  methods: {
    formatDate(timestamp) {
      return moment(timestamp).format(this.dateFormat);
    },
    getBatchOperationSummary(id) {
      RunbuggyOrdersApi.getBatchOperationSummary(id)
        .then((response) => {
          this.itemSummary = response;

          if (!this.batchOperationCompletedDone) {
            this.getTimeout = setTimeout(() => {
              this.batchOperationCompletedDone = this.batchOperationCompleted;
              this.getBatchOperationSummary(id);
              EventBus.$emit(EventBusConstants.BATCH_OPERATION_RELOAD_TABLE);
            }, this.getTimeoutDuration);
          }
        })
        .catch((e) => {
          this.itemSummary = undefined;
        });
    },
    clearTimeout() {
      if(this.getTimeout) {
        clearTimeout(this.getTimeout);
      }

      this.getTimeout = undefined;
    },
    async getBatchOperationItems() {
      this.batchOperationItems = [];
      let allBatchOperationItems = [];
      this.isLoading = true;
      let page = 0;
      let size = 200;
      
      while (true) {
        let query = `batchId==${this.batchId}&page=${page}&size=${size}`;
        const response = await RunbuggyOrdersApi.getBatchOperationItems(query)
          .catch((e) => {
            this.batchOperationItems = [];
            this.isLoading = false;
          });
        
        let data = _get(response, "content", []);
        if (!data.length) {
          break;
        }
        allBatchOperationItems = [...allBatchOperationItems, ...data];
        this.batchOperationItems = allBatchOperationItems;
        this.isLoading = false;
        page++;

        if (data.length < size) {
          break;
        }
      }
    },
    showNotification(title, message, type) {
      this.$notify({
        type: type,
        title: title,
        text: message,
        duration: NotificationConstants.DURATION,
        speed: 1000,
      });
    },
    formatDateTime(dateTime) {
      let timezone = "America/Los_Angeles";
      try {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (e) {
        //
      }
      if (dateTime) {
        return moment(dateTime).tz(timezone).format("MM/DD/YYYY hh:mm A");
      } else {
        return "";
      }
    },
  },
  computed: {
    batchOperationCompleted() {
      let completedStatus = ["ERROR", "COMPLETED"]
      return this.summaryPendingCount >= 0 && this.summaryProcessingCount <= 0 && (completedStatus.includes(this.summaryStatus.toUpperCase()));
    },
    showCurrentSummary() {
      return this.currentBatchOperationId === _get(this.item, "id");
    },
    sideBarDefinition() {
      return {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
            },
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
            maxWidth: 500,
          },
        ],
      };
    },
    batchId() {
      return _get(this.item, "id");
    },
    pendingCount() {
      return _filter(this.batchOperationItems, (item => {
        return _get(item, "status").toLowerCase() === "pending"
      })).length || 0;
    },
    processingCount() {
      return _filter(this.batchOperationItems, (item => {
        return _get(item, "status").toLowerCase() === "processing"
      })).length || 0;
    },
    completedCount() {
      return _filter(this.batchOperationItems, (item => {
        return _get(item, "status").toLowerCase() === "completed"
      })).length || 0;
    },
    errorCount() {
      return _filter(this.batchOperationItems, (item => {
        return _get(item, "status").toLowerCase() === "error"
      })).length || 0;
    },
    summaryPendingCount() {
      return _get(this.itemSummary, "pending", 0);
    },
    summaryProcessingCount() {
      return _get(this.itemSummary, "processing", 0);
    },
    summaryCompletedCount() {
      return _get(this.itemSummary, "completed", 0);
    },
    summaryErrorCount() {
      return _get(this.itemSummary, "error", 0);
    },
    summaryStatus() {
      return _get(this.itemSummary, "status", "");
    },
    batchOperationDescription() {
      return _get(this.item, "description") || this.batchType;
    },
    batchOperation() {
      return _get(this.item, "batchOperation", "") || "";
    },
    batchType() {
      return _get(this.item, "batchType", "") || "";
    },
    label() {
      return _get(this.item, "name", "") || "";
    },
    isCompleted() {
      return this.status === "COMPLETED";
    },
    isFailed() {
      return this.status === "ERROR";
    },
    status() {
      return _get(this.item, "status", "CREATED");
    },
    error() {
      return _get(this.item, "error", "");
    },
    startDate() {
      const startDate = _get(this.item, "startDate");
      if (!startDate) return;
      const startDateFormatted = this.formatDateTime(startDate);
      return `${this.$i18n(
        "common.label.startTime",
        "Start Time: "
      )} - ${startDateFormatted}`;
    },
    endDate() {
      const endDate = _get(this.item, "endDate");
      if (!endDate) return;
      const endDateFormatted = this.formatDateTime(endDate);
      return `${this.$i18n(
        "common.label.endTime",
        "End Time: "
      )} - ${endDateFormatted}`;
    },
    createdUserString() {
      let created = _get(this.item, "created");
      return (created) ? `${created.byUserFullName} (${created.byUser})` : undefined;
    },
    createdDateString() {
      let created = _get(this.item, "created");
      return (created) ? `${this.formatDateTime(created.date)}` : undefined;
    }
  },
  mounted() {},
  created() {
    this.gridOptions = {
      animateRows: true,
      enableCellTextSelection: true,
      suppressContextMenu: false,
      frameworkComponents: {
        rowStatusRenderer: RowStatusRenderer,
        rowReferenceNumberRenderer: RowReferenceNumberRenderer
      },
      suppressRowClickSelection: true,
      rowHeight: 35,
      rowGroupPanelShow: "always",
      groupDefaultExpanded: 1,
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "right",
          },
        ],
      },
    };

    this.defaultColDef = {
      minWidth: 50,
      resizable: true,
      filter: true,
      sortable: true,
    };

    this.columnDefs = [
      {
        headerName: this.$i18n("common.label.referenceNumber", "Reference Number"),
        field: "batchItemRequest.referenceNumber",
        colId: "batchItemRequest.referenceNumber",
        pinned: "left",
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        cellRenderer: "rowReferenceNumberRenderer",
      },
      {
        headerName: this.$i18n("common.label.startDate", "Start Date"),
        field: "startDate",
        colId: "startDate",
        width: 150,
        sort: "desc",
        filter: "agDateColumnFilter",
        enableRowGroup: false,
        filterParams: {
          comparator: dateFilterComparator,
          inRangeInclusive: true,
        },
        valueGetter: (params) => {
          return moment(_get(params, "data.startDate")).format(
            "MM/DD/YYYY h:mm A"
          );
        },
      },
      {
        headerName: this.$i18n("common.label.endDate", "End Date"),
        field: "endDate",
        colId: "endDate",
        width: 150,
        sort: "desc",
        filter: "agDateColumnFilter",
        enableRowGroup: false,
        filterParams: {
          comparator: dateFilterComparator,
          inRangeInclusive: true,
        },
        valueGetter: (params) => {
          return moment(_get(params, "data.startDate")).format(
            "MM/DD/YYYY h:mm A"
          );
        },
      },
      {
        headerName: this.$i18n("common.label.status", "Status"),
        field: "status",
        colId: "status",
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        cellRenderer: "rowStatusRenderer",
        width: 80,
      },
      {
        headerName: this.$i18n("common.label.statusDetail", "Status Detail"),
        field: "statusDetail",
        colId: "statusDetail",
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        width: 350,
      },
    ];
  },
  beforeDestroy() {
    this.clearTimeout();
  },
  mixins: [],
  components: {
    AgGridVue,
    LoadingSpinner,
    RowStatusRenderer,
    RowReferenceNumberRenderer
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.batch-status-item-container {
  .item-status {
    margin-left: auto;
  }

  .seen-container {
    width: 8px;
  }

  .not-seen {
    top: 0 !important;
  }
  .action-button {
    min-width: 40px !important;
    // padding: 0 !important
  }
  .v-list-item__icon:first-child {
    margin-right: 20px;
  }

  .v-list-item__title {
    white-space: normal !important;
  }

  .v-list-item__subtitle {
    white-space: normal !important;
  }

  .ag-grid-batch-operation-items {
    width: 100%;
    height: 65vh;
  }

}
</style>
