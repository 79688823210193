export default {
    USER_TOKEN: "USER_TOKEN",
    IMPERSONATION_BY_USERNAME: "IMPERSONATION_BY_USERNAME",
    IMPERSONATION_TOKEN: "IMPERSONATION_TOKEN",
    IMPERSONATION_USERNAME: "IMPERSONATION_USERNAME",
    SPECIFIED_REGISTRATION_ID: "SPECIFIED_REGISTRATION_ID",
    SPECIFIED_REGISTRATION_IDS: "SPECIFIED_REGISTRATION_IDS",
    SPECIFIED_REGISTRATION_IDS_FOR_TENANT_IDS: "SPECIFIED_REGISTRATION_IDS_FOR_TENANT_IDS",
    INTERCOMPANY_NAME: "INTERCOMPANY_NAME",
    INTERCOMPANY_TOTAL: "INTERCOMPANY_TOTAL",
    JWT_PAYLOAD: "JWT_PAYLOAD",
    MOBILE_EMBEDDED_LAYOUT: "MOBILE_EMBEDDED_LAYOUT"
}