<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      id="pods_menu"
      :close-on-content-click="false"
      bottom
      :offset-y="true"
      :nudge-top="-10"
      :min-width="350"
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          outlined
          v-on="on"
          color="grey"
          class="default-btn"
          dense
          id="group_by_pod_btn"
        >
          {{ labelToShow }}
          <v-icon right dark>
            {{ quickFilterIcon }}
          </v-icon>
        </v-btn>
      </template>
      <v-card class="pa-5 pods-list-container">
        <v-treeview
          :items="pods"
          selected-color="primary"
          :selection-type="'independent'"
          class="pods-tree"
          activatable
          hoverable
          :active="this.podsSelected"
          @update:active="getActiveValue"
          id="pods_tree_viewer"
        ></v-treeview>

        <v-card-actions
          class="border-top pt-10 px-0 d-flex align-center justify-end"
        >
          <v-btn
            outlined
            color="primary"
            :disabled="this.podsSelected.length === 0"
            @click="clearPods"
            >Reset</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script type="text/babel">
import EventBus from "@/services/eventBus";
import EventBusConstants from "@/constants/eventBus";
export default {
  name: "pod-filtering",
  watch: {
    selected: {
      handler(newValue) {
        this.podsSelected = newValue;
      }
    }
  },
  props: {
    pods: {
      default() {
        return [];
      }
    },
    selected: []
  },
  data() {
    return {
      podsSelected: [],
      menu: false
    };
  },
  methods: {
    getActiveValue(value) {
      this.podsSelected = value;
      this.applyPodFilter();
    },
    applyPodFilter() {
      this.$emit("pods-updated", this.podsSelected);
      this.menu = false;
    },
    clearPods() {
      this.clearTree();
      this.applyPodFilter();
      EventBus.$emit(EventBusConstants.PODS_LABEL, "");
    },
    clearTree() {
      this.podsSelected = [];
    }
  },
  computed: {
    quickFilterIcon() {
      return this.menu ? "mdi-chevron-up" : "mdi-chevron-down";
    },
    labelToShow() {
      return this.podsSelected.length > 0 ? this.correctItemName : "POD Group";
    },
    correctItemName() {
      let name = "";
      this.pods.forEach((_pod, podIndex) => {
        if (_pod.id === this.podsSelected[0]) {
          name = _pod.name;
        }
        _pod.children.forEach((child, childIndex) => {
          if (child.id === this.podsSelected[0]) {
            name = `${_pod.name} / ${child.name}`;
            if (name.length >= 30) {
              name = `${name.substring(0, 22)}...`;
            }
          }
          if (
            podIndex === this.pods.length - 1 &&
            childIndex === _pod.children.length - 1
          ) {
            return name;
          }
        });
      });
      EventBus.$emit(EventBusConstants.PODS_LABEL, name);
      return name;
    }
  },
  created() {
    this.podsSelected = this.selected;
  },
  mounted() {},
  beforeDestroy() {},
  mixins: [],
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.pods-tree {
  .v-treeview-node--leaf,
  .v-treeview-node__root {
    cursor: pointer;
  }
}

.default-btn {
  height: 32px !important;
}

.pods-tree {
  max-height: 520px;
  overflow-y: auto;
}
</style>
