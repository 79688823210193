import { UnleashClient } from 'unleash-proxy-client';
import _get from "lodash/get";

const config = {
  url: _get(window, "CONFIG.unleash.url", ""),
  clientKey: _get(window, "CONFIG.unleash.clientKey", ""),
  appName: _get(window, "CONFIG.unleash.appName", ""),
};

function validateConfig(config) {
  const missingKeys = Object.entries(config)
    .filter(([, value]) => !value)
    .map(([key]) => key);

  if (missingKeys.length > 0) {
    throw new Error(`Missing Unleash configuration keys: ${missingKeys.join(', ')}`);
  }
}

let unleashClient = null;

try {
  validateConfig(config);

  unleashClient = new UnleashClient({
    url: config.url,
    clientKey: config.clientKey,
    appName: config.appName,
    environment: config.environment,
  });

  unleashClient.start();
} catch (error) {
  console.error(error.message);
}

export default unleashClient;