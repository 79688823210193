export default {
  ON_HOLD: "ON_HOLD",
  AVAILABLE: "AVAILABLE",
  AVAILABLE_PRIORITY: "AVAILABLE_PRIORITY",
  ATTACHED: "ATTACHED",
  CLAIMED: "CLAIMED",
  DRIVER_ASSIGNED: "DRIVER_ASSIGNED",
  DRIVER_ACCEPTED: "DRIVER_ACCEPTED",
  DRIVER_ARRIVED: "DRIVER_ARRIVED",
  VEHICLES_PICKEDUP: "VEHICLES_PICKEDUP",
  VEHICLES_DELIVERED: "VEHICLES_DELIVERED",
  DRIVER_REJECTED: "DRIVER_REJECTED",
  VEHICLES_CLAIMED: "VEHICLES_CLAIMED",
  VEHICLES_UNCLAIMED: "VEHICLES_UNCLAIMED",
  VEHICLES_SIGNATURE_PICKEDUP: "VEHICLES_SIGNATURE_PICKEDUP",
  VEHICLES_SIGNATURE_DELIVERED: "VEHICLES_SIGNATURE_DELIVERED",
  CANCELED: "CANCELED",
  DETACHED: "DETACHED",
  COMPLETED: "COMPLETED",
  PAYOUT_PROCESSING: "PAYOUT_PROCESSING",
  PAYOUT_COMPLETE: "PAYOUT_COMPLETE",
  PROVIDED_ETA_DROPOFF: "PROVIDED_ETA_DROPOFF",
  ACCEPTED: "ACCEPTED",
  ASSIGNED: "ASSIGNED",
  CREATED: "CREATED",
  DRAFT: "DRAFT",
  DRAFT_CREATED: "DRAFT_CREATED",
  READY: "READY",
  PICKED_UP: "PICKED_UP",
  COMPLETE: "COMPLETE",
  MARKETPLACE_PLACED: "MARKETPLACE_PLACED",
  MARKETPLACE_AVAILABLE: "MARKETPLACE_AVAILABLE",
  MARKETPLACE_CLAIMED: "MARKETPLACE_CLAIMED",
  MARKETPLACE_IN_TRANSIT: "MARKETPLACE_IN_TRANSIT",
  MARKETPLACE_ON_HOLD: "MARKETPLACE_ON_HOLD",
  MARKETPLACE_CANCELED: "MARKETPLACE_CANCELED",
  BIDDING: "BIDDING",
  BIDDING_COMPLETED: "BIDDING_COMPLETED",
  IN_FINANCIAL_REVIEW: "IN_FINANCIAL_REVIEW",
  REVIEWER_REJECTED: "REVIEWER_REJECTED",
  VEHICLES_DELIVERY_VERIFIED: "VEHICLES_DELIVERY_VERIFIED",
};
