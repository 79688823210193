import RunBuggyOrdersAPIClient from '../clients/runBuggyOrdersApi'
import _get from "lodash/get";

const Service = {
    get cancellationSources() {
        return RunBuggyOrdersAPIClient.cancellationSources;
    },

    async getFeatureAccessForKey(key) {
        return RunBuggyOrdersAPIClient.getFeatureAccessForKey(key)
            .then((res) => {
                return res.data;
            })
    },

    async getFeatureAccessForKeys(keys) {
        return RunBuggyOrdersAPIClient.getFeatureAccessForKeys(keys)
            .then((res) => {
                return res.data;
            })
    },

    async getMargins() {
        return RunBuggyOrdersAPIClient.getMargins()
            .then((res) => {
                return res.data;
            })
    },

    async getDriverDetails(query) {
        return RunBuggyOrdersAPIClient.getDriverDetails(query)
            .then((res) => {
                return res.data;
            })
    },

    async tenantsProvision(data) {
        return RunBuggyOrdersAPIClient.tenantsProvision(data)
            .then((res) => {
                return res.data;
            })
    },

    async getDriverLocationsByEmail(email) {
        return RunBuggyOrdersAPIClient.getDriverLocationsByEmail(email)
            .then((res) => {
                return res.data;
            })
    },

    async getDriverLocations(query) {
        return RunBuggyOrdersAPIClient.getDriverLocations(query)
            .then((res) => {
                return res.data;
            })
    },

    async getDriverLocationsNearOrderId(orderId, maxDistanceMiles) {
        return RunBuggyOrdersAPIClient.getDriverLocationsNearOrderId(orderId, maxDistanceMiles)
            .then((res) => {
                return res.data;
            })
    },

    async getDefaultServices() {
        return RunBuggyOrdersAPIClient.getDefaultServices()
            .then((res) => {
                return res.data;
            })
    },

    async updateInspectionPoints(inspectionPointsData, transportationOrderId, taskId) {
        return RunBuggyOrdersAPIClient.updateInspectionPoints(inspectionPointsData, transportationOrderId, taskId)
            .then((res) => {
                return res.data;
            })
    },

    async attachImageToVehicleTransferOrder(file, transportationOrderId, inspectionPointKey, inspectionId, taskId, vtoId, vin, description, geolocation, isStartInspection, labels, isClaim) {
        return RunBuggyOrdersAPIClient.attachImageToVehicleTransferOrder(file, transportationOrderId, inspectionPointKey, inspectionId, taskId, vtoId, vin, description, geolocation, isStartInspection, labels, isClaim)
            .then((res) => {
                return res.data;
            })
    },

    async attachDocumentToVehicleTransferOrder(file, transportationOrderId, inspectionPointKey, inspectionId, taskId, vtoId, vin, description, geolocation, isStartInspection, labels, isClaim) {
        return RunBuggyOrdersAPIClient.attachDocumentToVehicleTransferOrder(file, transportationOrderId, inspectionPointKey, inspectionId, taskId, vtoId, vin, description, geolocation, isStartInspection, labels, isClaim)
            .then((res) => {
                return res.data;
            })
    },

    async createGatePass(file) {
        return RunBuggyOrdersAPIClient.createGatePass(file)
            .then((res) => {
                return res.data;
            })
    },

    async getOrders() {
        return RunBuggyOrdersAPIClient.getOrders()
            .then((res) => {
                return res.data;
            })
    },

    async getOrderByIdFull(id) {
        return RunBuggyOrdersAPIClient.getOrderByIdFull(id)
            .then((res) => {
                return res.data;
            })
    },

    async closeOrder(orderId, actionVariables) {
        return RunBuggyOrdersAPIClient.closeOrder(orderId, actionVariables)
            .then((res) => {
                return res.data;
            })
    },

    async payoutOrder(orderId, actionVariables) {
        return RunBuggyOrdersAPIClient.payoutOrder(orderId, actionVariables)
            .then((res) => {
                return res.data;
            })
    },

    async getOrderById(id) {
        return RunBuggyOrdersAPIClient.getOrderById(id)
            .then((res) => {
                return res.data;
            })
    },

    async createOrder(data) {
        return RunBuggyOrdersAPIClient.createOrder(data)
            .then((res) => {
                return res;
            })
    },

    async patchOrder(id, data) {
        return RunBuggyOrdersAPIClient.patchOrder(id, data)
            .then((res) => {
                return res;
            })
    },

    async createBulkOrder(data) {
        return RunBuggyOrdersAPIClient.createBulkOrder(data)
            .then((res) => {
                return res;
            })
    },

    async updateDraftVTO(id, data) {
        return RunBuggyOrdersAPIClient.updateDraftVTO(id, data)
            .then((res) => {
                return res;
            })
    },

    async deleteDraftVTOBulk(ids) {
        return RunBuggyOrdersAPIClient.deleteDraftVTOBulk(ids)
            .then((res) => {
                return res;
            })
    },

    async patchTransportationOrderPayout(id, data) {
        return RunBuggyOrdersAPIClient.patchTransportationOrderPayout(id, data)
            .then((res) => {
                return res;
            })
    },

    async patchTransportationOrderPayoutProperty(id, data) {
        return RunBuggyOrdersAPIClient.patchTransportationOrderPayoutProperty(id, data)
            .then((res) => {
                return res;
            })
    },

    async patchOrderFare(id, data) {
        return RunBuggyOrdersAPIClient.patchOrderFare(id, data)
            .then((res) => {
                return res;
            })
    },

    async updateOrderFare(id, data) {
        return RunBuggyOrdersAPIClient.updateOrderFare(id, data)
            .then((res) => {
                return res;
            })
    },

    async createOrderDraft(data) {
        return RunBuggyOrdersAPIClient.createOrderDraft(data)
            .then((res) => {
                return res;
            })
    },
    async updateOrderDraft(id, data) {
        return RunBuggyOrdersAPIClient.updateOrderDraft(id, data)
            .then((res) => {
                return res;
            })
    },
    async getOrderDraftById(id) {
        return RunBuggyOrdersAPIClient.getOrderDraftById(id)
            .then((res) => {
                return res.data;
            })
    },
    async quoteOrder(data) {
        return RunBuggyOrdersAPIClient.quoteOrder(data)
            .then((res) => {
                return res.data;
            })
    },
    async customerQuoteOrderDraft(data, id) {
        return RunBuggyOrdersAPIClient.customerQuoteOrderDraft(data, id)
            .then((res) => {
                return res.data;
            })
    },
    async transporterQuoteOrderDraft(data, id) {
        return RunBuggyOrdersAPIClient.transporterQuoteOrderDraft(data, id)
            .then((res) => {
                return res.data;
            })
    },
    async customerQuoteOrder(data, id) {
        return RunBuggyOrdersAPIClient.customerQuoteOrder(data, id)
            .then((res) => {
                return res.data;
            })
    },
    async transporterQuoteOrder(data, id) {
        return RunBuggyOrdersAPIClient.transporterQuoteOrder(data, id)
            .then((res) => {
                return res.data;
            })
    },
    async replaceOrder(id, data) {
        return RunBuggyOrdersAPIClient.replaceOrder(id, data)
            .then((res) => {
                return res.data;
            })
    },

    async cancelOrder(id, data) {
        return RunBuggyOrdersAPIClient.cancelOrder(id, data)
            .then((res) => {
                return res;
            })
    },

    async getExportsList() {
        return RunBuggyOrdersAPIClient.getExportsList()
        .then((res) => {
            return res;
        })
    },

    async downloadExportFile(id) {
        return RunBuggyOrdersAPIClient.downloadExportFile(id)
        .then((res) => {
            return res;
        })
    },

    async deleteExportFile(id) {
        return RunBuggyOrdersAPIClient.deleteExportFile(id)
        .then((res) => {
            return res;
        })
    },

    createOrderChangeRequestTask(id, data) {
        return RunBuggyOrdersAPIClient.createOrderChangeRequestTask(id, data)
            .then((res) => {
                return res.data;
            })
    },

    createTransportationOrderChangeRequestTask(id, data) {
        return RunBuggyOrdersAPIClient.createTransportationOrderChangeRequestTask(id, data)
            .then((res) => {
                return res.data;
            })
    },

    changeVehicleFromTransportationOrderVTO(data, id, vtoId) {
        return RunBuggyOrdersAPIClient.changeVehicleFromTransportationOrderVTO(data, id, vtoId)
            .then((res) => {
                return res.data;
            })
    },

    async getTransportationOrderById(id) {
        return RunBuggyOrdersAPIClient.getTransportationOrderById(id)
            .then((res) => {
                return res.data;
            })
    },

    async updateTransportationOrderAdditionalProperties(id, data) {
        return RunBuggyOrdersAPIClient.updateTransportationOrderAdditionalProperties(id, data)
            .then((res) => {
                return res.data;
            })
    },

    async getOrderMessages(orderId) {
        return RunBuggyOrdersAPIClient.getOrderMessages(orderId)
            .then((res) => {
                return res.data;
            })
    },

    getMessage(messageId, sort) {
        return RunBuggyOrdersAPIClient.getMessage(messageId, sort);
    },

    async getTransportationOrderMessages(transportationOrderId, orderId, sort) {
        return RunBuggyOrdersAPIClient.getTransportationOrderMessages(transportationOrderId, orderId, sort)
            .then((res) => {
                return res.data;
            })
    },

    async createOrderMessage(orderId, message, transportationOrderId) {
        return RunBuggyOrdersAPIClient.createOrderMessage(orderId, message, transportationOrderId)
            .then((res) => {
                return res.data;
            })
    },

    async createTransportationOrderMessage(transportationOrderId, orderId, message) {
        return RunBuggyOrdersAPIClient.createTransportationOrderMessage(transportationOrderId, orderId, message)
            .then((res) => {
                return res.data;
            })
    },

    async getTransportationOrderByIdFull(id) {
        return RunBuggyOrdersAPIClient.getTransportationOrderByIdFull(id)
            .then((res) => {
                return res.data;
            })
    },
    async getTransportationOrderByIdActivityLogs(id) {
        return RunBuggyOrdersAPIClient.getTransportationOrderActivityLogs(id)
            .then((res) => {
                return res.data;
            })
    },

    async getVehicleTransferOrderById(id) {
        return RunBuggyOrdersAPIClient.getVehicleTransferOrderById(id)
            .then((res) => {
                return res.data;
            })
    },

    async getVehicleTransferOrderPhotosById(id) {
        return RunBuggyOrdersAPIClient.getVehicleTransferOrderPhotosById(id)
            .then((res) => {
                return res.data;
            })
    },

    async getVehicleTransferOrderPhotoByPhotoId(vtoId, photoId, size) {
        return RunBuggyOrdersAPIClient.getVehicleTransferOrderPhotoByPhotoId(vtoId, photoId, size)
            .then((res) => {
                return res;
            })
    },

    async getVehicleTransferOrderGatePassesById(id) {
        return RunBuggyOrdersAPIClient.getVehicleTransferOrderGatePassesById(id)
            .then((res) => {
                return res.data;
            })
    },

    async getVehicleTransferOrder(id) {
        return RunBuggyOrdersAPIClient.getVehicleTransferOrder(id)
            .then((res) => {
                return res.data;
            })
    },

    async getVehicleTransferOrderFull(id) {
        return RunBuggyOrdersAPIClient.getVehicleTransferOrderFull(id)
            .then((res) => {
                return res.data;
            })
    },

    async getExpandedOrders(page, size, params = {}) {
        return RunBuggyOrdersAPIClient.getExpandedOrders(page, size, params)
            .then((res) => {
                return res.data;
            })
    },

    async getExpandedOrder(id) {
        return RunBuggyOrdersAPIClient.getExpandedOrder(id)
            .then((res) => {
                return res.data;
            })
    },

    getVehicleTransferOrderGatePasses(id) {
        return RunBuggyOrdersAPIClient.getVehicleTransferOrderGatePasses(id)
            .then((res) => {
                return res.data;
            })
    },
    getVehicleTransferOrderGatePass(id, passId) {
        return RunBuggyOrdersAPIClient.getVehicleTransferOrderGatePass(id, passId)
            .then((res) => {
                return res;
            })
    },
    getVehicleTransferOrderPhotos(id) {
        return RunBuggyOrdersAPIClient.getVehicleTransferOrderPhotos(id)
            .then((res) => {
                return res.data;
            })
    },
    getVehicleTransferOrderPhoto(id, photoId) {
        return RunBuggyOrdersAPIClient.getVehicleTransferOrderPhoto(id, photoId)
            .then((res) => {
                return res.data;
            })
    },
    getVehicleTransferOrderPhotosForTask(id, taskName) {
        return RunBuggyOrdersAPIClient.getVehicleTransferOrderPhotosForTask(id, taskName)
            .then((res) => {
                return res.data;
            })
    },
    getVehicleTransferOrderInspectionReports(vehicleTransferOrderId) {
        return RunBuggyOrdersAPIClient.getVehicleTransferOrderInspectionReports(vehicleTransferOrderId)
            .then((res) => {
                return res.data;
            })

    },
    getVehicleTransferOrderInspectionReport(vehicleTransferOrderId, inspectionId) {
        return RunBuggyOrdersAPIClient.getVehicleTransferOrderInspectionReport(vehicleTransferOrderId, inspectionId)
            .then((res) => {
                return res.data;
            })
    },
    getVehicleTransferOrderInspectionReportForTask(id, taskName) {
        return RunBuggyOrdersAPIClient.getVehicleTransferOrderInspectionReportForTask(id, taskName)
            .then((res) => {
                return res.data;
            })
    },
    async getTransportationOrderBillOfLadingDocument(transportationOrderId, billOfLadingId, htmlVersion) {
        return RunBuggyOrdersAPIClient.getTransportationOrderBillOfLadingDocument(transportationOrderId, billOfLadingId, htmlVersion)
            .then((res) => {
                return res;
            })
    },
    async getTransportationOrderBillOfLadingDocumentPreview(transportationOrderId, htmlVersion) {
        return RunBuggyOrdersAPIClient.getTransportationOrderBillOfLadingDocumentPreview(transportationOrderId, htmlVersion)
            .then((res) => {
                return res;
            })
    },
    async downloadFile(uri) {
        return RunBuggyOrdersAPIClient.downloadFile(uri)
            .then((res) => {
                return res;
            })
    },
    async getShipperOrder(id) {
        return RunBuggyOrdersAPIClient.getShipperOrder(id)
            .then((res) => {
                return res.data;
            })
    },
    async getShipperOrderFull(id) {
        return RunBuggyOrdersAPIClient.getShipperOrderFull(id)
            .then((res) => {
                return res.data;
            })
    },
    async getTransporterOrder(id) {
        return RunBuggyOrdersAPIClient.getTransporterOrder(id)
            .then((res) => {
                return res.data;
            })
    },


    async getOrdersPaginated() {
        return RunBuggyOrdersAPIClient.getOrdersPaginated()
            .then((res) => {
                return res.data;
            })
    },

    async getVehicleTransferOrders(page, size) {
        return RunBuggyOrdersAPIClient.getVehicleTransferOrders(page, size)
            .then((res) => {
                return res.data;
            })
    },

    async getShipperOrders(page, size) {
        return RunBuggyOrdersAPIClient.getShipperOrders(page, size)
            .then((res) => {
                return res.data;
            })
    },

    async getVehicleTransferOrdersByCompanyId(companyId) {
        return RunBuggyOrdersAPIClient.getVehicleTransferOrdersByCompanyId(companyId)
            .then((res) => {
                return res.data;
            })
    },

    async getTransportationOrders(page, size) {
        return RunBuggyOrdersAPIClient.getTransportationOrders(page, size)
            .then((res) => {
                return res.data;
            })
    },

    async getTransportationOrdersFull(page, size, params) {
        return RunBuggyOrdersAPIClient.getTransportationOrdersFull(page, size, params)
            .then((res) => {
                return res.data;
            })
    },

    async getTransportationOrdersRsql(page, size, params) {
        return RunBuggyOrdersAPIClient.getTransportationOrdersRsql(page, size, params)
            .then((res) => {
                return res.data;
            })
    },

    async getOrdersFSearch(data) {
        return RunBuggyOrdersAPIClient.getOrdersFSearch(data)
            .then((res) => {
                return res.data;
            })
    },

    async getShipperOrdersFull(page, size, params) {
        return RunBuggyOrdersAPIClient.getShipperOrdersFull(page, size, params)
            .then((res) => {
                return res.data;
            })
    },

    async getVehicleTransfersOrdersFull(page, size, params) {
        return RunBuggyOrdersAPIClient.getVehicleTransfersOrdersFull(page, size, params)
            .then((res) => {
                return res.data;
            })
    },

    async getPaymentsFull(page, size, sort, params, rql) {
        return RunBuggyOrdersAPIClient.getPaymentsFull(page, size, sort, params, rql)
            .then((res) => {
                return res.data;
            })
    },

    async getPaymentsSummary(params, rql) {
        return RunBuggyOrdersAPIClient.getPaymentsSummary(params, rql)
            .then((res) => {
                return res.data;
            })
    },

    async getChargesFull(page, size, sort, params, rql) {
        return RunBuggyOrdersAPIClient.getChargesFull(page, size, sort, params, rql)
            .then((res) => {
                return res.data;
            })
    },

    async getChargesSummary(params, rql) {
        return RunBuggyOrdersAPIClient.getChargesSummary(params, rql)
            .then((res) => {
                return res.data;
            })
    },

    async getPayoutsFull(page, size, sort, params, rql) {
        return RunBuggyOrdersAPIClient.getPayoutsFull(page, size, sort, params, rql)
            .then((res) => {
                return res.data;
            })
    },

    async getPayoutsSummary(params, rql) {
        return RunBuggyOrdersAPIClient.getPayoutsSummary(params, rql)
            .then((res) => {
                return res.data;
            })
    },

    async getTransportationOrder(id) {
        return RunBuggyOrdersAPIClient.getTransportationOrder(id)
            .then((res) => {
                return res.data;
            })
    },

    async getVehicleByVin(vin) {
        return RunBuggyOrdersAPIClient.getVehicleByVin(vin)
            .then((res) => {
                return res.data;
            })
    },

    // campaigns
    async createNewCampaign(campaign) {
        return RunBuggyOrdersAPIClient.createNewCampaign(campaign)
            .then((res) => {
                return res.data;
            })
    },
    async getAllCampaigns(size, params = undefined) {
        return RunBuggyOrdersAPIClient.getAllCampaigns(size, params)
            .then((res) => {
                let data = _get(res, 'data');

                if (!data) {
                    return;
                }

                return _get(data, 'content');
            })
    },
    async getAllMobilityStatuses() {
        return RunBuggyOrdersAPIClient.getAllMobilityStatuses()
            .then((res) => {
                return res.data;
            })
    },
    async updateCampaignWithID(id, campaign) {
        return RunBuggyOrdersAPIClient.updateCampaignWithID(id, campaign)
            .then((res) => {
                return res;
            })
    },
    async removeCampaignWithID(id) {
        return RunBuggyOrdersAPIClient.removeCampaignWithID(id)
            .then((res) => {
                return res.data;
            })
    },

    async driversSummary(query) {
        return RunBuggyOrdersAPIClient.driversSummary(query)
            .then((res) => {
                return res.data;
            })
    },

    async getTask(taskId) {
        return RunBuggyOrdersAPIClient.getTask(taskId)
            .then((res) => {
                return res.data;
            })
    },

    async getTasksConfigurations() {
        return RunBuggyOrdersAPIClient.getTasksConfigurations()
            .then((res) => {
                return res.data;
            })
    },

    async getTaskConfiguration(id) {
        return RunBuggyOrdersAPIClient.getTaskConfiguration(id)
            .then((res) => {
                return res.data;
            })
    },

    async getTasksActiveConfiguration() {
        return RunBuggyOrdersAPIClient.getTasksActiveConfiguration()
            .then((res) => {
                return res.data;
            })
    },

    async createTaskConfiguration(data) {
        return RunBuggyOrdersAPIClient.createTaskConfiguration(data)
            .then((res) => {
                return res.data;
            })
    },

    async updateTaskConfiguration(id, data) {
        return RunBuggyOrdersAPIClient.updateTaskConfiguration(id, data)
            .then((res) => {
                return res.data;
            })
    },

    async removeTaskConfiguration(id) {
        return RunBuggyOrdersAPIClient.removeTaskConfiguration(id)
            .then((res) => {
                return res.data;
            })
    },

    async getTaskConfigurationVariableGroups() {
        return RunBuggyOrdersAPIClient.getTaskConfigurationVariableGroups()
            .then((res) => {
                return res.data;
            })
    },

    async getTaskConfigurationVariableGroup(id) {
        return RunBuggyOrdersAPIClient.getTaskConfigurationVariableGroup(id)
            .then((res) => {
                return res.data;
            })
    },
    async createTaskConfigurationVariableGroup(data) {
        return RunBuggyOrdersAPIClient.createTaskConfigurationVariableGroup(data)
            .then((res) => {
                return res.data;
            })
    },

    async updateTaskConfigurationVariableGroup(id, data) {
        return RunBuggyOrdersAPIClient.updateTaskConfigurationVariableGroup(id, data)
            .then((res) => {
                return res.data;
            })
    },

    async removeTaskConfigurationVariableGroup(id) {
        return RunBuggyOrdersAPIClient.removeTaskConfigurationVariableGroup(id)
            .then((res) => {
                return res.data;
            })
    },

    async getOrderGenericTasks(orderId) {
        return RunBuggyOrdersAPIClient.getOrderGenericTasks(orderId)
            .then((res) => {
                return res.data;
            })
    },

    async getOrderGenericTaskSubtasks(orderId, taskKey) {
        return RunBuggyOrdersAPIClient.getOrderGenericTaskSubtasks(orderId, taskKey)
            .then((res) => {
                return res.data;
            })
    },

    async createOrderGenericTask(orderId, taskKey, data) {
        return RunBuggyOrdersAPIClient.createOrderGenericTask(orderId, taskKey, data)
            .then((res) => {
                return res.data;
            })
    },

    async createOrderGenericTaskSubtask(orderId, taskKey, taskType, subTaskType, data) {
        return RunBuggyOrdersAPIClient.createOrderGenericTaskSubtask(orderId, taskKey, taskType, subTaskType, data)
            .then((res) => {
                return res.data;
            })
    },

    async createOrderGenericTaskAndSubtasks(orderId, taskKey, taskType, data) {
        return RunBuggyOrdersAPIClient.createOrderGenericTaskAndSubtasks(orderId, taskKey, taskType, data)
            .then((res) => {
                return res.data;
            })
    },

    async createOrderPayoutChangeTask(orderId) {
        return RunBuggyOrdersAPIClient.createOrderPayoutChangeTask(orderId)
            .then((res) => {
                return res.data;
            })
    },

    async createTransportationOrderPayoutChangeTask(transportationOrderId) {
        return RunBuggyOrdersAPIClient.createTransportationOrderPayoutChangeTask(transportationOrderId)
            .then((res) => {
                return res.data;
            })
    },

    async completeTransportationOrderTask(taskId, actionName, actionVariables) {
        return RunBuggyOrdersAPIClient.completeTransportationOrderTask(taskId, actionName, actionVariables)
            .then((res) => {
                return res.data;
            })
    },

    async completeTask(taskId, actionName, actionVariables) {
        return RunBuggyOrdersAPIClient.completeTask(taskId, actionName, actionVariables)
            .then((res) => {
                return res.data;
            })
    },

    async completeOrderTask(taskId, actionName, actionVariables) {
        return RunBuggyOrdersAPIClient.completeOrderTask(taskId, actionName, actionVariables)
            .then((res) => {
                return res.data;
            })
    },

    async completeOrderTaskDraft(taskId, actionName, actionVariables) {
        return RunBuggyOrdersAPIClient.completeOrderTaskDraft(taskId, actionName, actionVariables)
            .then((res) => {
                return res.data;
            })
    },

    async createOrderNote(id, data, perspective) {
        return RunBuggyOrdersAPIClient.createOrderNote(id, data, perspective)
            .then((res) => {
                res.data
            })
    },

    async updateOrderNote(id, data, perspective, commentId) {
        return RunBuggyOrdersAPIClient.updateOrderNote(id, data, perspective, commentId)
            .then((res) => {
                res.data
            })
    },

    async getShareLink(id, role, data) {
        return RunBuggyOrdersAPIClient.getShareLink(id, role, data)
            .then((res) => {
                return res.data;
            })
    },

    createRationale(data) {
        return RunBuggyOrdersAPIClient.createRationale(data);
    },
    patchRationale(id, data) {
        return RunBuggyOrdersAPIClient.patchRationale(id, data);
    },

    deleteRationale(id) {
        return RunBuggyOrdersAPIClient.deleteRationale(id);
    },
    // Tasks

    updateTransportationOrderTask(taskId, data) {
        return RunBuggyOrdersAPIClient.updateTransportationOrderTask(taskId, data)
            .then((res) => {
                return res.data;
            })
    },
    updateOrderTask(taskId, data) {
        return RunBuggyOrdersAPIClient.updateOrderTask(taskId, data)
            .then((res) => {
                return res.data;
            })
    },
    updateTask(taskId, data) {
        return RunBuggyOrdersAPIClient.updateTask(taskId, data)
            .then((res) => {
                return res.data;
            })
    },
    getTransportationOrderTaskComments(transportationOrderId, taskId) {
        return RunBuggyOrdersAPIClient.getTransportationOrderTaskComments(transportationOrderId, taskId)
            .then((res) => {
                return res.data;
            })
    },
    createTransportationOrderTaskComment(transportationOrderId, taskId, message) {
        return RunBuggyOrdersAPIClient.createTransportationOrderTaskComment(transportationOrderId, taskId, message)
            .then((res) => {
                return res.data;
            })
    },
    getTaskComments(taskId) {
        return RunBuggyOrdersAPIClient.getTaskComments(taskId)
            .then((res) => {
                return res.data;
            })
    },
    createTaskComment(orderId, taskId, message) {
        return RunBuggyOrdersAPIClient.createTaskComment(orderId, taskId, message)
            .then((res) => {
                return res.data;
            })
    },
    createOrderTaskComment(orderId, taskId, message) {
        return RunBuggyOrdersAPIClient.createOrderTaskComment(orderId, taskId, message)
            .then((res) => {
                return res.data;
            })
    },
    createTransportationOrderTaskTask(transportationOrderId, data) {
        return RunBuggyOrdersAPIClient.createTransportationOrderTaskTask(transportationOrderId, data)
            .then((res) => {
                return res.data;
            })
    },
    createOrderTaskTask(orderId, data) {
        return RunBuggyOrdersAPIClient.createOrderTaskTask(orderId, data)
            .then((res) => {
                return res.data;
            })
    },

    getTransportationOrderTasks(taskId) {
        return RunBuggyOrdersAPIClient.getTransportationOrderTasks(taskId)
            .then((res) => {
                return res.data;
            })
    },

    getRationales(group) {
        return RunBuggyOrdersAPIClient.getRationales(group)
            .then((res) => {
                return res.data;
            })
    },

    getStatistics(type, statistic, params = {}) {
        return RunBuggyOrdersAPIClient.getStatistics(type, statistic, params)
            .then((res) => {
                return res.data;
            })
    },

    getDriverStatistics(username) {
        return RunBuggyOrdersAPIClient.getDriverStatistics(username)
            .then((res) => {
                return res.data;
            })
    },

    getDriverCompletedOrdersCount(username, params = {}) {
        return RunBuggyOrdersAPIClient.getDriverCompletedOrdersCount(username, params)
            .then((res) => {
                return res.data;
            })
    },

    createTransportationOrderFromVehicleTransferOrders(vehicleTransferOrderIDs) {
        return RunBuggyOrdersAPIClient.createTransportationOrderFromVehicleTransferOrders(vehicleTransferOrderIDs)
            .then((res) => {
                return res.data;
            })
    },

    detachVehicleTransferOrder(id) {
        return RunBuggyOrdersAPIClient.detachVehicleTransferOrder(id)
            .then((res) => {
                return res.data;
            })
    },

    appendVehiclesInTpOrder(transportationOrderId, vehicleTransferOrderIDs) {
        return RunBuggyOrdersAPIClient.appendVehiclesInTpOrder(transportationOrderId, vehicleTransferOrderIDs)
            .then((res) => {
                return res.data;
            })
    },

    getSwappableVehicleTransferOrdersForTransportationOrderId(transportationOrderId) {
        return RunBuggyOrdersAPIClient.getSwappableVehicleTransferOrdersForTransportationOrderId(transportationOrderId)
            .then((res) => {
                return res.data;
            })
    },

    preferredTransporterRulesFindAll() {
        return RunBuggyOrdersAPIClient.preferredTransporterRulesFindAll()
            .then((res) => {
                return res.data;
            })
    },
    preferredTransporterRulesCreate(body) {
        return RunBuggyOrdersAPIClient.preferredTransporterRulesCreate(body)
            .then((res) => {
                return res.data;
            })
    },
    preferredTransporterRulesGetById(id) {
        return RunBuggyOrdersAPIClient.preferredTransporterRulesGetById(id)
            .then((res) => {
                return res.data;
            })
    },
    preferredTransporterRulesUpdateById(id, body) {
        return RunBuggyOrdersAPIClient.preferredTransporterRulesUpdateById(id, body)
            .then((res) => {
                return res.data;
            })
    },
    preferredTransporterRulesDeleteById(id) {
        return RunBuggyOrdersAPIClient.preferredTransporterRulesDeleteById(id)
            .then((res) => {
                return res.data;
            })
    },

    async getTransportationOrderEtas(id, type = 'pickup') {
        return RunBuggyOrdersAPIClient.getTransportationOrderEtas(id, type)
            .then((res) => {
                return res.data;
            })
    },

    async postTableDataExport(data, timezone = undefined) {
        return RunBuggyOrdersAPIClient.postTableDataExport(data, timezone)
            .then((res) => {
                return res.data;
            })
    },

    async postOrdersBatchOperation(type, operation, data) {
        return RunBuggyOrdersAPIClient.postOrdersBatchOperation(type, operation, data)
            .then((res) => {
                return res.data;
            })
    },

    async getBatchOperationSummary(id) {
        return RunBuggyOrdersAPIClient.getBatchOperationSummary(id)
            .then((res) => {
                return res.data;
            })
    },

    async getBatchOperations(query) {
        return RunBuggyOrdersAPIClient.getBatchOperations(query)
            .then((res) => {
                return res.data;
            })
    },

    async getBatchOperationItems(query) {
        return RunBuggyOrdersAPIClient.getBatchOperationItems(query)
            .then((res) => {
                return res.data;
            })
    },

    async getFilterValuesFromServer(data, type) {
        return RunBuggyOrdersAPIClient.getFilterValuesFromServer(data, type)
            .then((res) => {
                return res.data;
            })
    },

    async getLoadBoards() {
        return RunBuggyOrdersAPIClient.getLoadBoards()
            .then((res) => {
                return res.data;
            })
    },

    async getCancelLoadBoard(orderId) {
        return RunBuggyOrdersAPIClient.getCancelLoadBoard(orderId)
            .then((res) => {
                return res.data;
            })
    },


    async startClaimFlow(orderId) {
        return RunBuggyOrdersAPIClient.startClaimFlow(orderId)
            .then((res) => {
                return res.data;
            })
    },

    async claimStartInspection(claimId, taskId, vtoId) {
        return RunBuggyOrdersAPIClient.claimStartInspection(claimId, taskId, vtoId)
            .then((res) => {
                return res.data;
            })
    },

    async claimCompleteInspection(transportationOrderId, taskId, vtoId, inspectionId) {
        return RunBuggyOrdersAPIClient.claimCompleteInspection(transportationOrderId, taskId, vtoId, inspectionId)
            .then((res) => {
                return res.data;
            })
    },


};

export default Service;
