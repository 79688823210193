<template>
  <v-menu
    bottom
    left
    offset-y
    origin="top right"
    transition="scale-transition"
    z-index="1001"
    :close-on-content-click="false"
    v-model="showExportStatus"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        class="mr-2 export-status-container position-relative"
        min-width="0"
        text
        v-bind="attrs"
        v-on="on"
        id="export-status-btn"
      >
        <v-badge
          color="secondary"
          class="export-status-container--badge v-badge--bordered bounce"
          v-if="count > 0"
          bodered
        >
          <template v-slot:badge>
            <span>{{ count }}</span>
          </template>
        </v-badge>
        <v-icon>mdi-table-arrow-down</v-icon>
      </v-btn>
    </template>
    <v-card
      min-width="720"
      max-width="550"
      class="mx-auto export-status-container--card"
    >
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title
          class="items-center w-100"
          style="display: flex !important"
        >
          <p class="mb-0">
            {{ $i18n("common.label.exportDataStatus", "Export Data Status") }}
            <loading-spinner color="white" :size="20" v-if="isLoadingData"></loading-spinner>
          </p>
          <v-btn
            color="white"
            id="minimize_export_btn"
            outlined
            @click="showExportStatus = false"
            text
            class="ml-auto minimize-btn"
          >
            <v-icon class="mr-5"> mdi-minus </v-icon>
            {{ $i18n("common.label.minimize", "Minimize") }}
          </v-btn>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text class="pa-5 export-status-container--card--text">
        <template v-if="!exportList">

        </template>
        <template v-else-if="exportList.length > 0" >
          <v-list two-line nav class="pa-0">
            <template v-for="(status, index) in exportList">
              <export-status-item
                  :key="`export_status_item-${index}`"
                  :item="status"
                  @refresh-list="getExportStatus()"
              >
              </export-status-item>
              <v-divider
                  :key="`export_status_divider-${index}`"
                  v-if="index + 1 < exportList.length"
              ></v-divider>
            </template>
          </v-list>
        </template>
        <template v-else>
          <div
              v-if="!exportList.length"
              class="d-flex flex-column justify-center align-center text-center"
          >
            <v-icon class="no-export-icon">mdi-playlist-remove</v-icon>
            <p class="text-center font-15-500">
              {{
                $i18n(
                    "common.label.noExports",
                    "No exports to show at the moment"
                )
              }}
            </p>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script type="text/babel">
import ExportStatusItem from "./export-status-item";
import LoadingSpinner from "SC/components/loading-spinner";
import _get from "lodash/get";
import _sortBy from "lodash/sortBy";
import RunbuggyOrdersApi from "SC/services/runBuggyOrdersApi";
import EventBus from "@/services/eventBus";
import EventBusConstants from "@/constants/eventBus";

export default {
  name: "components-export-status-index",
  watch: {
    showExportStatus: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue === false) {
          this.clearExportsTimeout();
        } else {
          this.getExportStatus();
        }
      },
    },
  },
  props: {},
  data() {
    return {
      showExportStatus: false,
      isLoadingData: false,
      polling: null,
      exportList: undefined,
      getExportsTimeout: undefined,
      getExportsTimeoutDuration: 2000
    };
  },
  methods: {
    clearExportsTimeout() {
      if(this.getExportsTimeout) {
        clearTimeout(this.getExportsTimeout);
      }

      this.getExportsTimeout = undefined;
    },
    getExportStatus() {
      if(this.getExportStatusPromise) {
        return this.getExportStatusPromise;
      }

      this.isLoadingData = true;

      this.getExportStatusPromise = this.getExports()
          .then((result) => {
            result = _sortBy(_get(result, "data", []), [
              "created.date",
            ]).reverse();
            this.exportList = result;

            if (this.pendingItems.length > 0) {
              this.getExportsTimeout = setTimeout(() => {
                this.getExportStatus();
              }, this.getExportsTimeoutDuration);
            }
          })
          .catch((e) => {
            this.error = e;
            this.exportList = [];
          })
          .finally(() => {
            this.isLoadingData = false;
            this.getExportStatusPromise = undefined;
          })

      return this.getExportStatusPromise;
    },
    getExports() {
      return RunbuggyOrdersApi.getExportsList();
    },
  },
  computed: {
    pendingItems() {
      return this.exportList.filter((item) => {
        return [
            'COMPLETED',
            'FAILED',
        ].indexOf(item.status) === -1;
      });
    },
    count() {
      if (!this.exportList || !this.exportList.length) {
        return 0;
      }
      return this.exportList.filter((_item) => {
        return _item.downloaded === false;
      }).length;
    },
  },
  mounted() {},
  created() {
    this.getExportStatus();

    EventBus.$on(EventBusConstants.GRID_DATA_EXPORT_SENT, () => {
      this.showExportStatus = true;
      this.getExportStatus()
    });
  },
  beforeDestroy() {
    this.clearExportsTimeout();
  },
  mixins: [],
  components: {
    ExportStatusItem,
    LoadingSpinner,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../scss/variables";

.export-status-container {
  .v-icon {
    color: #000;
  }
  &--badge {
    position: absolute;
    right: 4px;
    top: 8px;
    animation-duration: 2s;
    animation-iteration-count: 3;
  }

  &--card {
    max-height: 450px;
    display: flex !important;
    flex-direction: column;

    &--text {
      overflow-y: auto;
      gap: 20px;
      flex: 1;
      min-width: 550px;
    }
  }
}

.no-export-icon {
  color: var(--v-primary-base);
  font-size: 50px;
}

.bounce {
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-5px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-3px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
</style>

<style lang="scss">
.export-status-container--card {
  .minimize-btn {
    background: transparent !important;
    border-color: #fff !important;
  }
}
</style>
