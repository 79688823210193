<template>
  <div
    id="app"
    v-bind:class="{ transparent: appTransparent }"
    :style="cssProps"
  >
    <v-app
      v-bind:class="{
        transparent: appTransparent,
        'new-update': isUpdateAvailable,
      }"
    >
      <v-alert
        class="update-alert black--text"
        :value="isUpdateAvailable"
        color="#bad879"
        dense
        icon="mdi-application"
        prominent
      >
        <v-row align="center">
          <v-col class="grow"> A new version is available! </v-col>
          <v-col class="shrink">
            <v-btn color="grey darken-4" dark @click="reloadWindow()"
              >Update Application</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
      <v-overlay v-bind:z-index="1000" v-bind:value="showOverlay"></v-overlay>
      <template v-if="isSettingUserData">
        <div class="d-flex h-100 justify-center align-center">
          <div>
            <loading-spinner text="Logging you in"></loading-spinner>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="routeMetaLayout">
          <component v-bind:is="routeMetaLayout"></component>
        </template>
        <template v-else-if="authState.isAuthenticated">
          <authenticated-layout></authenticated-layout>
        </template>
        <template v-else>
          <unauthenticated-layout></unauthenticated-layout>
        </template>
      </template>

      <modal-terms-acceptance v-model="needsToAcceptTerms" />
      <template v-if="!needsToAcceptTerms">
        <modal-user-roles-changed v-model="userRolesHaveChanged" />
        <modal-user-tenant-changed
          v-model="userTenantHasChanged"
          v-bind:new-tenant-id="newTenantId"
          v-bind:previous-tenant-id="previousTenantId"
        />
        <modal-user-became-inactive v-model="userBecameInactive" />
        <modal-token-changed v-model="userTokenChanged" />
      </template>
    </v-app>
  </div>
</template>

<script type="text/babel">
import OneSignalService from "@/services/onesignal";
import TokenService from "SC/services/tokenService";
import BugsnagService from "SC/services/bugsnag";
//import IntercomService from "@/services/intercom";
//import inContact from "@/services/incontact";
import GoogleAnalyticsService from "@/services/googleAnalytics";
import * as AppMutationTypes from "@/store/modules/app/mutation-types";
import { mapActions, mapGetters } from "vuex";
import WorkflowsLayout from "@/layouts/workflows";
import LoginLayout from "@/layouts/login";
import BlankLayout from "@/layouts/blank";
import AuthenticatedLayout from "@/layouts/authenticated";
import UnauthenticatedLayout from "@/layouts/unauthenticated";
import ParseURLQuery from "@/mixins/parseURLQuery";
import _get from "lodash/get";
import _has from "lodash/has";
import _difference from "lodash/difference";
import _uniq from "lodash/uniq";
import _filter from "lodash/filter";
import _includes from "lodash/includes";

import LAYOUTS from "@/constants/layouts";
import NotificationConstants from "@/constants/notifications";
import LoadingSpinner from "SC/components/loading-spinner";
import axios from "axios";

import LocalStorageService from "SC/services/localStorage";
import LocalStorageConstants from "@/constants/localstorage";
import { MessagingService } from "@/services/messagingService";
import ModalUserRolesChanged from "@/components/modal-user-roles-changed";
import ModalUserTenantChanged from "@/components/modal-user-tenant-changed";
import ModalUserBecameInactive from "@/components/modal-user-became-inactive";
import ModalTermsAcceptance from "@/components/modal-terms-acceptance";
import ModalTokenChanged from "@/components/modal-token-changed";

import EventBusConstants from "@/constants/eventBus";
import EventBus from "@/services/eventBus";
import AccountsApiService from "SC/services/accountsApi";

import RunBuggyOrdersApiService from "SC/services/runBuggyOrdersApi";
import * as UserMutationTypes from "@/store/modules/user/mutation-types";
import AccountTypes from "@/constants/accountTypes";
import jwt_decode from "jwt-decode";

export default {
  name: "app",
  watch: {
    userData: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          GoogleAnalyticsService.setUserId(_get(this.userData, "username"));
          BugsnagService.setUser(this.userData);
          OneSignalService.init();
        } else {
          GoogleAnalyticsService.unsetUserId();
        }
      },
    },
    $route: {
      immediate: true,
      handler(to, from) {
        this.setTitle(this.extractChainMeta("title"));

        if (_get(this.$route.query, "transparent", false)) {
          this.$store.commit(`app/${AppMutationTypes.TRANSPARENT}`, true);
          document.querySelector("body").style.background = "transparent";
        }
      },
    },
    authState: {
      deep: true,
      handler(newValue) {
        console.log("authState changed", newValue);

        if (newValue.isAuthenticated) {
          this.setTokenOnState();
          this.setUserData();
          this.setUnleashUserContext();
          this.setFeatureAccess();
          this.setTrustedBeyondIdentity();
          this.determineTermsCompliance();
          console.log("newValue.accessToken", newValue.accessToken.accessToken);
          MessagingService.connect(newValue.accessToken.accessToken).then(
            () => {
              MessagingService.addCallback(
                "user-changed",
                this.onMessagingUserChangedEvent
              );
              MessagingService.addCallback(
                "accountpolicy-changed",
                this.onMessagingAccountPolicyChangedEvent
              );
            }
          );
        } else {
          MessagingService.disconnect();
        }
      },
      immediate: true,
    },
    isAuthenticated: {
      handler(newValue, oldValue) {
        if (!oldValue && newValue) {
          let redirect = _get(window, "CONFIG.login.defaultRedirect");

          let storedLastNavigation = LocalStorageService.get(
            LocalStorageConstants.LAST_NAVIGATION_ROUTE
          );
          if (storedLastNavigation) {
            redirect = storedLastNavigation;
          } else if (this.queryParamRedirect) {
            redirect = this.queryParamRedirect;
          }
          console.debug("redirect to", redirect);
          this.$router.push(redirect);
        }
      },
    },
  },
  props: {},
  metaInfo: {
    titleTemplate: "%s",
  },
  data() {
    return {
      previousTenantId: undefined,
      newTenantId: undefined,
      needsToAcceptTerms: false,
      userTenantHasChanged: false,
      userRolesHaveChanged: false,
      userBecameInactive: false,
      userTokenChanged: false,
      isSettingUserData: false,
      currentVersion: undefined,
      versionCheckTimeout: undefined,
      versionCheckTimeoutDuration: 60000,
      isUpdateAvailable: false,
      beyondIdentityDevices: undefined,
      activeTheme: undefined,
    };
  },
  methods: {
    onStorageChange(event) {
      switch (event.key) {
        case LocalStorageService.namespacedKey(
          LocalStorageConstants.USER_TOKEN
        ):
          this.evaluateStoredToken();
          break;
      }
    },
    evaluateStoredToken() {
      const storedToken = LocalStorageService.get(
        LocalStorageConstants.USER_TOKEN
      );
      if (!storedToken || !this.token) {
        return;
      }

      if (storedToken !== this.token) {
        const storedTokenDecoded = jwt_decode(storedToken);
        const localTokenDecoded = jwt_decode(this.token);

        const storedTokenTenantId =
          storedTokenDecoded?.runbuggy_user?.selectedTenantId;
        const localTokenTenantId =
          localTokenDecoded?.runbuggy_user?.selectedTenantId;

        // storedTokenTenantId is not set when the user is using TPP
        if (storedTokenTenantId && storedTokenTenantId !== localTokenTenantId) {
          this.userTenantHasChanged = true;
          this.previousTenantId = localTokenTenantId;
          this.newTenantId = storedTokenTenantId;
        } else {
          this.userTenantHasChanged = false;
        }
      }
    },
    determineTermsCompliance() {
      this.needsToAcceptTerms = this.accountsNeedToAcceptTerms.length > 0;
    },
    setFeatureAccess() {
      console.log("setFeatureAccess", "start");
      return RunBuggyOrdersApiService.getFeatureAccessForKeys([
        "new_feature",
        "feature_access_rights",
        "order_create_pickup-dropoff-address_enable-canada",
      ]).then((res) => {
        console.log("setFeatureAccess", res);
        this.$store.commit(
          `user/${UserMutationTypes.FEATURE_ACCESS_KEYS}`,
          res
        );
      });
    },
    onMessagingUserChangedEvent({ event, eventId, user }) {
      let mappedAccounts = {};
      _get(this.userData, "accounts", []).forEach((account) => {
        if (!mappedAccounts[account.id] && account.id) {
          mappedAccounts[account.id] = account;
        }
      });

      let roles = _get(user, "accounts", []).map((account) => {
        let mappedAccount = mappedAccounts[account.id];
        let isTrial = false;

        if (mappedAccount) {
          isTrial = mappedAccount.isTrial;
        }

        let roleParts = ["ROLE", account.type];
        if (isTrial) {
          roleParts.push("TRIAL");
        }

        roleParts.push(account.role);
        return roleParts.join("_");
      });
      let differenceA = _difference(roles, this.userRoles);
      let differenceB = _difference(this.userRoles, roles);

      let changedA =
        this.userSecurityDependentsTimestamp !==
        _get(user, "securityUserDependentsTimestamp");
      let changedB =
        this.userSecurityEntityHash !== _get(user, "securityUserHash");
      if (changedA || changedB) {
        this.userRolesHaveChanged = true;
      }

      if (_get(user, "status.isDeactivated") === true) {
        this.userBecameInactive = true;
      }
    },
    onMessagingAccountPolicyChangedEvent({
      accountId,
      event,
      eventId,
      policies,
    }) {
      let params = {
        accountId: accountId,
        event: event,
        eventId: eventId,
        policies: policies,
      };
      EventBus.$emit(
        EventBusConstants.MESSAGING_ACCOUNT_POLICY_CHANGED,
        params
      );
    },
    ...mapActions("ui", ["v1ShowLeftDrawerToggle"]),
    extractChainMeta(property, ifNotFoundValue) {
      let _chain = this.$route.matched.slice();
      _chain.reverse();

      let _meta = _chain.find((link) => _has(link, `meta.${property}`));

      return _get(_meta, `meta.${property}`, ifNotFoundValue);
    },
    setTitle(title) {
      document.title = title
        ? title + " - " + CONFIG.runbuggy.title
        : CONFIG.runbuggy.title;
    },
    setIntercompanyDefaults() {
      let promise = Promise.resolve();

      promise = promise.then(() => {
        let paramQuery = {
          query: `type=in=(${AccountTypes.SHIPPER},${AccountTypes.AUCTION})`,
        };
        return AccountsApiService.getAccounts(0, 1, null, paramQuery).then(
          (res) => {
            TokenService.setIntercompaniesCount(res.totalElements);
            return;
          }
        );
      });

      if (TokenService.getSpecifiedRegistrationIds()) {
        return promise;
      }

      let interCompanies = [];
      if (this.selectedTenantId === "runbuggy") {
        let accounts = _filter(_get(this.userData, "accounts", []), (item) =>
          _includes(["SHIPPER", "AUCTION"], item.type)
        );
        let mappedAccounts = accounts.map((item) => {
          return _get(item, "id");
        });
        let subAccounts = [];
        accounts.forEach((account) => {
          if (_get(account, "type") === "SHIPPER") {
            let thisSubAccounts = _get(account, "subAccounts", []);
            subAccounts = [...subAccounts, ...thisSubAccounts];
          }
        });

        interCompanies = _uniq([...mappedAccounts, ...subAccounts]);
      }

      return promise.then(() => {
        if (this.tenantId) {
          let previouslyRecordedIntercompanies =
            TokenService.getSpecifiedRegistrationIdsForTenantId(this.tenantId);
          if (previouslyRecordedIntercompanies.length > 0) {
            interCompanies = previouslyRecordedIntercompanies;
          }
        }

        return new Promise((resolve) => {
          if (interCompanies.length > 0) {
            TokenService.setSpecifiedRegistrationIds(interCompanies);
          }
          resolve(interCompanies);
        });
      });
    },
    async setUserData() {
      this.isSettingUserData = true;
      let userInfo = _get(
        this.authState,
        "accessToken.claims.runbuggy_user",
        {}
      );
      userInfo.userGroups = _get(
        this.authState,
        "accessToken.claims.runbuggy_groups",
        []
      );
      await this.$store.dispatch("user/SetUserData", userInfo);

      let promises = [this.setUserTasksConfig()];

      if (!this.isDriverOnly) {
        if (_get(userInfo, "selectedTenantId", "runbuggy") !== "runbuggy") {
          promises.push(
            this.setSelectedTenant(_get(userInfo, "selectedTenantId"))
          );
        }
        promises.push(this.setDefaultCompanyInfo(this.defaultAccountId));
      }

      promises.push(this.setIntercompanyDefaults());

      return Promise.all(promises)
        .then(() => {})
        .catch((e) => {
          console.log("Failed to set user data");
        })
        .finally(() => {
          this.isSettingUserData = false;
        });
    },
    setUnleashUserContext() {
      return this.$unleashClient?.setContextField(
        "userId",
        _get(this.userData, "username")
      );
    },
    setUserTasksConfig() {
      return RunBuggyOrdersApiService.getTasksActiveConfiguration()
        .then((response) => {
          let userTasksConfig = response || {};
          this.$store.dispatch("user/SetUserTasksConfig", userTasksConfig);
        })
        .catch((error) => console.log(error));
    },
    setDefaultCompanyInfo(accountId) {
      return AccountsApiService.getAccount(accountId)
        .then((response) => {
          this.$store.commit("user/ACTIVE_COMPANY_INFO", response);
          if (_get(response, "tenantId", "runbuggy") === "runbuggy") {
            this.activeTheme = _get(response, "info.themeColors");
            if (this.activeTheme) {
              this.$store.dispatch("user/SetActiveTheme", this.activeTheme);
              this.applyActiveTheme(this.activeTheme);
            }
          }
          EventBus.$emit(EventBusConstants.UAM_ACTIVE_COMPANY_CHANGED);
        })
        .catch((error) => console.log(error));
    },
    setSelectedTenant(tenantId) {
      return AccountsApiService.getAccount(tenantId)
        .then((response) => {
          this.$store.commit("user/SELECTED_TENANT", response);
          this.activeTheme = _get(response, "info.themeColors");
          if (this.activeTheme) {
            this.$store.dispatch("user/SetActiveTheme", this.activeTheme);
            this.applyActiveTheme(this.activeTheme);
          }
        })
        .catch((error) => console.log(error));
    },
    async setTokenOnState() {
      let token = _get(this.authState, "accessToken.accessToken");
      if (_has(this.authState, "accessToken.claims.runbuggy_user.grantor")) {
        this.$store.commit(`user/${UserMutationTypes.IS_IMPERSONATING}`, true);
        let impersonatorUsername = _get(
          this.authState,
          "accessToken.claims.runbuggy_user.grantor.username"
        );
        this.$store.commit(
          `user/${UserMutationTypes.IMPERSONATOR_USERNAME}`,
          impersonatorUsername
        );
        if (token) {
          let impersonationUsername = _get(
            this.authState,
            "accessToken.claims.runbuggy_user.username"
          );
          TokenService.setImpersonationToken(token);
          TokenService.setImpersonationUsername(impersonationUsername);
        }
      } else if (token) {
        TokenService.clearImpersonationToken();
        TokenService.clearImpersonationUsername();
        this.$store.commit(`user/${UserMutationTypes.TOKEN}`, token);
        TokenService.setToken(token);
      }
    },
    async setTrustedBeyondIdentity() {
      let trustedDevice =
        LocalStorageService.get(LocalStorageConstants.TRUSTED_DEVICE) || false;
      let byndidAuthInitiator = window.sessionStorage.getItem(
        "BYNDID_AUTH_INITIATOR"
      );

      window.sessionStorage.removeItem("BYNDID_AUTH_INITIATOR");
      await this.getListBeyondIdentityDevices();
      this.beyondIdentityDevices.sort(
        (a, b) => new Date(a.date_last_seen) - new Date(b.date_last_seen)
      );
      let beyondIdentityDevice = this.beyondIdentityDevices.pop();
      let deviceId = new Array(_get(beyondIdentityDevice, "id"));

      if (!trustedDevice) {
        if (byndidAuthInitiator) {
          console.log("BYNDID:", "is auth initiator");
          console.log("BYNDID:", "removing", deviceId);
          await AccountsApiService.removeBeyondIdentityDevices(deviceId)
            .then((response) => {
              LocalStorageService.remove(
                LocalStorageConstants.LAST_LOGGED_IN_USER_NAME
              );
              this.getListBeyondIdentityDevices();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log("BYNDID:", "is not auth initiator");
        }
      } else {
        LocalStorageService.set(
          LocalStorageConstants.TRUSTED_DEVICE_ID,
          deviceId
        );
      }
    },
    async getListBeyondIdentityDevices() {
      return AccountsApiService.listBeyondIdentityDevices()
        .then((response) => {
          this.beyondIdentityDevices = _get(response, "devices", []);
        })
        .catch((error) => {
          this.beyondIdentityDevices = [];
          console.log(error);
        });
    },
    reloadWindow() {
      // true forces a download the page contents again.
      window.location.reload(true);
    },
    versionCheck() {
      let file = `../version/version.txt?rand=${new Date().getTime()}`;
      let path = `${window.location.href.split("#")[0]}/${file}`;

      return axios
        .get(path)
        .then((res) => {
          if (!this.currentVersion) {
            this.currentVersion = res.data;
          }

          if (this.currentVersion !== res.data) {
            this.isUpdateAvailable = true;
          } else {
            this.versionCheckTimeout = setTimeout(() => {
              this.versionCheck();
            }, this.versionCheckTimeoutDuration);
          }
        })
        .catch(() => {
          this.versionCheckTimeout = setTimeout(() => {
            this.versionCheck();
          }, this.versionCheckTimeoutDuration);
        })
        .finally(() => {});
    },
    resetTheme() {
      this.$vuetify.theme.themes.light.navigation_bg = "";
      this.$vuetify.theme.themes.light.navigation_text = "";
      this.$vuetify.theme.themes.light.primary = "";
      this.$vuetify.theme.themes.light.secondary = "";
      this.$vuetify.theme.themes.light.accent = "";
    },
    applyActiveTheme(activeTheme) {
      this.$vuetify.theme.themes.light.navigation_bg = _get(
        activeTheme,
        "navigationColor"
      );
      this.$vuetify.theme.themes.light.navigation_text = _get(
        activeTheme,
        "navigationTextColor"
      );
      this.$vuetify.theme.themes.light.primary = _get(
        activeTheme,
        "primaryColor"
      );
      this.$vuetify.theme.themes.light.secondary = _get(
        activeTheme,
        "secondaryColor"
      );
      this.$vuetify.theme.themes.light.accent = _get(
        activeTheme,
        "accentColor"
      );
    },
    showNotification(title, message, type) {
      this.$notify({
        type: type,
        title: title,
        text: message,
        duration: NotificationConstants.DURATION,
        speed: 1000,
      });
    },
    showErrorNotification(error) {
      if (
        error.response &&
        error.response.status &&
        error.response.statusText
      ) {
        this.showNotification(
          this.$i18n("common.label.error", "Error") +
            " " +
            error.response.status,
          error.response.statusText,
          "error"
        );
      } else {
        this.showNotification(
          this.$i18n("common.label.error", "Error"),
          error,
          "error"
        );
      }
    },
  },
  computed: {
    cssProps() {
      return {
        "--primary-color": this.$vuetify.theme.themes.light.primary,
        "--secondary-color": this.$vuetify.theme.themes.light.secondary,
        "--accent-color": this.$vuetify.theme.themes.light.accent,
        "--navigation-text-color":
          this.$vuetify.theme.themes.light.navigation_text,
        "--navigation-bg-color": this.$vuetify.theme.themes.light.navigation_bg,
      };
    },
    defaultAccountId() {
      let accounts = _get(this.userData, "accounts", []);
      let defaultAccount = accounts.filter((account) => {
        return account.type === "TMS";
      });
      return (
        _get(defaultAccount, "0.id") || _get(this.userData, "accounts.0.id")
      );
    },
    routeMetaLayout() {
      let layout = this.$route.matched
        .map((match) => {
          return _get(match, "meta.layout");
        })
        .filter((entry) => {
          return entry !== undefined;
        })
        .pop();

      switch (layout) {
        case LAYOUTS.WORKFLOWS:
          return "workflows-layout";
        case LAYOUTS.BLANK:
          return "blank-layout";
        case LAYOUTS.LOGIN:
          return "login-layout";
        default:
          return undefined;
      }
    },
    ...mapGetters("app", {
      appTransparent: "transparent",
    }),
    ...mapGetters("user", [
      "accountsNeedToAcceptTerms",
      "isAuthenticated",
      "userData",
      "userRoles",
      "tenantId",
      "authState",
      "isDriverOnly",
      "selectedTenantId",
      "userSecurityDependentsTimestamp",
      "userSecurityEntityHash",
      "token",
    ]),
    ...mapGetters("ui", ["showOverlay"]),
  },
  mounted() {
    this.versionCheck();
    EventBus.$on(EventBusConstants.SELECTED_TENANT_CHANGED, (tenantId) => {
      this.setSelectedTenant(tenantId);
    });
    EventBus.$on(EventBusConstants.UAM_PAYMENT_INFO_UPDATED, () => {
      this.userRolesHaveChanged = true;
    });
    EventBus.$on(EventBusConstants.UAM_TOKEN_CHANGED, () => {
      this.userTokenChanged = true;
    });
    EventBus.$on(EventBusConstants.THEME_SETUP, () => {
      if (this.activeTheme) {
        setTimeout(() => {
          this.resetTheme();
          this.applyActiveTheme(this.activeTheme);
        }, 1000);
      }
    });

    window.addEventListener("storage", this.onStorageChange);
  },
  beforeDestroy() {
    if (this.versionCheckTimeout) {
      clearTimeout(this.versionCheckTimeout);
    }

    window.removeEventListener("storage", this.onStorageChange);
  },
  mixins: [ParseURLQuery],
  components: {
    ModalTermsAcceptance,
    ModalUserBecameInactive,
    ModalUserRolesChanged,
    ModalUserTenantChanged,
    ModalTokenChanged,
    LoadingSpinner,
    BlankLayout,
    WorkflowsLayout,
    LoginLayout,
    AuthenticatedLayout,
    UnauthenticatedLayout,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.transparent {
  background: transparent !important;
}

.monospaced {
  font-family: "Roboto Mono", monospace !important;
}
</style>

<style lang="scss">
// ag-grid styles
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
@import "SC/scss/ag-grid-override.scss";

@import "scss/styles.scss";

.new-update {
  .v-application--wrap {
    transform: translateY(4rem);
  }

  .v-content {
    padding-top: 0 !important;
  }

  .update-alert {
    transform: translateY(-4rem);
    border-radius: 0 !important;
    margin-bottom: 0;
  }
}
</style>
