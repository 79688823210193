<template>
  <div>
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      z-index="1001"
      :close-on-content-click="false"
      v-model="showCompliance"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
          v-if="isTrial || !isCompliant"
          :class="{ 'icon-pulse': complianceChanged }"
        >
          <v-badge
            v-if="isTrial || !isCompliant"
            color="error"
            icon="mdi-exclamation-thick"
            overlap
            bordered
          >
            <v-icon size="24">mdi-clipboard-text</v-icon>
          </v-badge>
          <v-badge
            v-else
            color="success"
            icon="mdi-check-bold"
            overlap
            bordered
          >
            <v-icon size="24">mdi-clipboard-text</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-card flat width="650" class="mx-auto">
        <div class="d-flex justify-end pr-3 pt-3">
          <v-btn icon @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text style="min-height: 300px; max-height: 600px">
          <forms-account-management-policies-compliance
            :key="`policies-${policyKey}`"
            v-model="isCompliant"
            :account-id="accountId"
            :is-runbuggy-admin-view="false"
            :account-policies-updates="accountPolicies"
            @close="closeDialog"
          >
          </forms-account-management-policies-compliance>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script type="text/babel">
import OktaService from "@/services/okta";
import FormsAccountManagementPoliciesCompliance from "@/components/forms/account-management/policies-compliance";
import { mapGetters } from "vuex";
import _get from "lodash/get";

import LocalStorageService from "SC/services/localStorage";
import LocalStorageConstants from "@/constants/localstorage";

import OnboardingApiService from "SC/services/onboardingApi";

import EventBus from "@/services/eventBus";
import EventBusConstants from "@/constants/eventBus";

export default {
  name: "components-modal_user_policy_conformant-index",
  watch: {
    showCompliance(newValue) {
      this.policyKey = Math.random();
    },
    value(newValue) {
      this.complianceChanged = newValue;
      this.checkCompliance(this.accountPolicies);
    },
    isCompliant(newValue) {
      this.$emit("input", newValue);
    }
  },
  props: {
    value: undefined,
    accountId: {
      default() {
        return undefined;
      },
    },
    accountPolicies: {
      default() {
        return undefined;
      },
    },
  },
  data() {
    return {
      isCompliant: undefined,
      showCompliance: false,
      complianceChanged: false,

      policyKey: undefined,
      componentTimeouts: [],
    };
  },
  methods: {
    closeDialog() {
      this.showCompliance = false;
      //this.$emit("input", this.showCompliance);
    },
    checkCompliance(accountPolicies) {
      let noCompliantCount = 0;
      if (accountPolicies) {
        Object.entries(accountPolicies).some(([key, value]) => {
          if (_get(value, "status") === "NONCOMPLIANT") {
            noCompliantCount ++;
          }
        });
      
        let newCompliant = (noCompliantCount > 0) ? false : true;
        this.isCompliant = newCompliant;
        LocalStorageService.set(LocalStorageConstants.ACCOUNT_COMPLIANCE, newCompliant);
      }else{
        this.getAccountPoliciesInfo();
      }
    },
    getAccountPoliciesInfo() {
      OnboardingApiService.getAccountPolicies(this.accountId)
        .then((response) => {
          let accountPolicies = _get(response, "policies", {});
          Object.entries(accountPolicies).some(([key, value]) => {
            if (_get(value, "status") === "UNKNOWN") {
              this.componentTimeouts.push(
                setTimeout(() => this.getAccountPoliciesInfo(), 2000)
              );
              return true;
            } else {
              this.checkCompliance(accountPolicies);
            }
          });
        })
        .catch((error) => {
          console.log("getAccountPoliciesInfo: ", error);
        });
      
    },
    onMessagingAccountPolicyChangedEvent(params) {
      this.complianceChanged = true;
      this.checkCompliance(_get(params, "policies"));
    },
  },
  computed: {
    isTrial() {
      let accounts = _get(this.userData, "accounts", []);
      let activeAccount = accounts.find(
        (account) => account.id === this.accountId
      );
      return _get(activeAccount, "isTrial");
    },
    complianceBadge() {
      return this.isTrial ? "mdi-exclamation" : "mdi-check";
    },
    ...mapGetters("user", ["userData"]),
  },
  created() {
    this.checkCompliance(this.accountPolicies);
    EventBus.$on(
      EventBusConstants.MESSAGING_ACCOUNT_POLICY_CHANGED,
      (params) => {
        this.onMessagingAccountPolicyChangedEvent(params);
      }
    );
  },
  mixins: [],
  components: {
    FormsAccountManagementPoliciesCompliance,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../scss/variables";

.bottom-center {
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

.icon-pulse {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s 5;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
