import * as types from "./mutation-types";
import _without from "lodash/without";
import _get from "lodash/get";
import LocalStorageService from "SC/services/localStorage";
import LocalStorageKeys from "@/constants/localstorage";

const getInitialState = () => {
  return {
    showOverlay: false,
    navigationExpanded: _get(
      LocalStorageService.get(LocalStorageKeys.STATE_UI),
      "navigationExpanded",
      false
    ),
    collapsedGroupKeys: _get(
      LocalStorageService.get(LocalStorageKeys.STATE_UI),
      "collapsedGroupKeys",
      []
    ),
  };
};

const state = getInitialState();

// getters
const getters = {
  isNavigationExpanded(state) {
    return state.navigationExpanded;
  },
  showOverlay(state) {
    return state.showOverlay;
  },
  collapsedGroupKeys(state) {
    return state.collapsedGroupKeys;
  },
};

// actions
const actions = {
  toggleGroupExpanded(ctx, groupKey) {
    let collapsedGroupKeys = ctx.state.collapsedGroupKeys;
    if (collapsedGroupKeys.indexOf(groupKey) > -1) {
      collapsedGroupKeys = _without(collapsedGroupKeys, groupKey);
    } else {
      collapsedGroupKeys.push(groupKey);
    }

    ctx.commit(types.SET_COLLAPSED_GROUP_KEYS, collapsedGroupKeys);
    ctx.dispatch("saveState");
  },
  toggleNavigationExpanded(ctx, bool) {
    if (bool === undefined) {
      bool = !ctx.state.navigationExpanded;
    }

    ctx.commit(types.NAVIGATION_EXPANDED, bool);
    ctx.dispatch("saveState");
  },
  saveState(ctx) {
    LocalStorageService.set(LocalStorageKeys.STATE_UI, ctx.state);
  },
};

// mutations
const mutations = {
  [types.SHOW_OVERLAY](state, bool) {
    state.showOverlay = bool;
  },
  [types.NAVIGATION_EXPANDED](state, bool) {
    state.navigationExpanded = bool;
  },
  [types.SET_COLLAPSED_GROUP_KEYS](state, keys) {
    state.collapsedGroupKeys = keys;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
