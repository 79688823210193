<template>
  <div class="component">
    <v-autocomplete
      v-model="impersonatedUser"
      v-bind:id="inputId"
      v-bind:items="items"
      v-bind:loading="isRetrieving || isImpersonating"
      v-bind:search-input.sync="search"
      clearable
      hide-no-data
      outlined
      label="Impersonation"
      hide-details
      dense
      v-bind:disabled="isImpersonating"
      v-on:change="authenticateAsUser"
      v-on:click:clear.prevent="onClickClear"
      v-on:keydown="onKeyDown"
    >
    </v-autocomplete>
    <template v-if="userIsImpersonating">
      <small class="d-block text-right"
        ><strong>Impersonator:</strong> {{ impersonatorUsername }}</small
      >
    </template>
  </div>
</template>

<script type="text/babel">
import _debounce from "lodash/debounce";
import _get from "lodash/get";
import { mapGetters } from "vuex";
import OktaService from "@/services/okta";
import AccountsApiService from "SC/services/accountsApi";
import _replace from "lodash/replace";
import _includes from "lodash/includes";
import _map from "lodash/map";
import _escapeRegExp from "lodash/escapeRegExp";
import TokenService from "SC/services/tokenService";

import AccountTypes from "@/constants/accountTypes";

export default {
  name: "components-impersonator-index",
  watch: {
    search(newValue, oldValue) {
      if (this.isImpersonating) return;
      if (oldValue && newValue === oldValue.trim()) return;

      return (
        newValue &&
        newValue !== this.impersonatedUser &&
        this.retrieveItems(newValue.trim())
      );
    },
    impersonatedUser(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.clearImpersonation();
      }
    }
  },
  props: {},
  data() {
    return {
      search: null,
      items: [],
      impersonatedUser: undefined,
      isRetrieving: false,
      isImpersonating: false
    };
  },
  methods: {
    onKeyDown(e) {
      e.stopPropagation();
    },
    onClickClear() {
      if (this.userIsImpersonating) {
        this.clearImpersonation();
      }
    },
    returnToDefaultRoute() {
      if (this.$router.currentRoute.path !== "/dashboard") {
        this.$router
          .push({
            path: "/dashboard"
          })
          .then(() => {
            window.location.reload();
          });
      } else {
        window.location.reload();
      }
    },
    clearImpersonation() {
      this.isImpersonating = true;
      OktaService.revertToGrantorToken();

      TokenService.clearSpecifiedRegistrationIds();
      TokenService.clearImpersonationUsername();
      TokenService.clearImpersonationToken();
      window.location.reload();
    },
    authenticateAsUser() {
      this.isImpersonating = true;
      if (!this.impersonatedUser) {
        return;
      }

      return OktaService.setImpersonationToken(this.impersonatedUser)
        .then((res) => {
          TokenService.clearSpecifiedRegistrationIds();
          window.location.reload();
        })
        .catch((e) => {
          console.log("setImpersonationToken: ", e);
        })
        .finally(() => {
          this.isImpersonating = false;
        });
    },
    retrieveItems: _debounce(function (query) {
      this.search = query;
      let escapedQuery = _escapeRegExp(query);
      let encodedEscapedQuery = encodeURIComponent(escapedQuery);
      
      let queryString = `(firstName=re=.*${encodedEscapedQuery}.*,lastName=re=.*${encodedEscapedQuery}.*,email=re=.*${encodedEscapedQuery}.*);(status.isDeactivated=ex=false);(accounts.type!=RUNBUGGY)`;
      if(!this.hasRunbuggySuperAdminRole){
        queryString = `${queryString};(accounts.type!=TMS)`;
      }

      this.isRetrieving = true;
      return AccountsApiService.getUsersForImpersonation(0, 1000, undefined, {
        query: queryString
      })
        .then((res) => {
          this.items = res.content.map((item) => {
            let accountTypes = _map(_get(item, "accounts"), (account) => {
              return account.type;
            });
            let accountRoles = _map(_get(item, "accounts"), (account) => {
              return account.role;
            });

            if (
              _includes(accountTypes, AccountTypes.RUNBUGGY) &&
              (_includes(accountRoles, "ADMIN") ||
                _includes(accountRoles, "SUPER_ADMIN"))
            )
              return;

            if(this.hasTMSAdminRole){
              if (
                _includes(accountTypes, AccountTypes.TMS) &&
                (_includes(accountRoles, "ADMIN"))
              )
              return;
            }

            return {
              text: `${item.firstName} ${item.lastName} (${item.id})`,
              value: item.id
            };
          });
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.isRetrieving = false;
        });
    }, 500)
  },
  computed: {
    impersonatorUsername() {
      return _get(this.userUserData, "grantor.username");
    },
    inputId() {
      return `impersonator_${new Date().getTime()}`;
    },
    ...mapGetters("user", {
      userUserData: "userData",
      userUserName: "userName",
      userIsImpersonating: "isImpersonating",
      hasTMSAdminRole: "hasTMSAdminRole",
      hasRunbuggySuperAdminRole: "hasRunbuggySuperAdminRole"
    })
  },
  created() {},
  mounted() {
    if (this.userIsImpersonating) {
      console.log("Has impersonation token");
      this.impersonatedUser = this.userUserName;
      this.items.push(this.impersonatedUser);
    }
  },
  beforeDestroy() {},
  mixins: [],
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
