import _get from "lodash/get";
import _has from "lodash/has";

const Service = {
  init() {
    return new Promise((resolve, reject) => {
      if (_has(window, 'dataLayer')) {
        return resolve();
      }

      let googleAnalyticsTrackingId = _get(
        window,
        "CONFIG.google.analytics.spa.trackingId"
      );

      if (!googleAnalyticsTrackingId) {
        return reject("GA: No google analytics tracking id provided");
      }

      // Set up Global Site Tag (gtag.js) - Google Analytics 4 (GA4) property
      let globalSiteTag = document.createElement('script');
      let firstScriptTag = document.getElementsByTagName('script')[0];
      globalSiteTag.async = 1;
      globalSiteTag.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsTrackingId}`;
      firstScriptTag.parentNode.insertBefore(globalSiteTag, firstScriptTag);

      window.dataLayer = window.dataLayer || [];
    
      this.gtag('js', new Date());
      this.gtag('config', googleAnalyticsTrackingId);

      resolve();
    })
  },
  recordPageView(page) {
    this.init()
        .then(() => {
            let data = {
                page
            };
            this.gtag('event', 'pageview', data);
            console.log('GA: Page view recorded', data);
        })
        .catch((e) => {
            console.log(e);
        });
  },
  setUserId(userId) {
    this.init()
        .then(() => {
            this.gtag('event', 'userId', userId);
            console.log('GA: User ID set', userId)
        })
        .catch((e) => {
            console.log(e);
        });
  },
  unsetUserId() {
    this.init()
        .then(() => {
            this.gtag('event', 'userId', undefined);
            console.log('GA: User ID removed')
        })
        .catch((e) => {
            console.log(e);
        });
  },
  gtag() { 
    window.dataLayer.push(arguments); 
  }
};

export default Service;
