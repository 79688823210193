<template>
  <div class="component intercompany-switcher">
    <template v-if="isRetrieving">
      <v-progress-circular indeterminate></v-progress-circular>
    </template>
    <template v-else-if="items.length > 1">
      <template v-if="asListItem">
        <v-list-item>
          <v-autocomplete
            v-model="currentIntercompanies"
            v-bind:items="items"
            v-bind:loading="isRetrieving"
            clearable
            hide-no-data
            outlined
            multiple
            label="Intercompanies"
            hide-details
            dense
            v-on:click:clear.prevent="onClickClear"
            v-on:change="setIntercompanies"
          >
          </v-autocomplete>
        </v-list-item>
      </template>
      <template v-else>
        <div class="position-relative">
          <v-menu
            offset-y
            v-on:input="onMenuOpenChange"
            v-bind:close-on-content-click="false"
            v-model="menuOpen"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                v-bind="attrs"
                v-on="on"
                outlined
                class="switcher"
              >
                <div class="position-relative">
                  <template
                    v-if="
                      currentIntercompanies && currentIntercompanies.length > 0
                    "
                  >
                    <div
                      class="
                        intercompany-count
                        d-flex
                        justify-center
                        align-center
                      "
                    >
                      <div>{{ currentIntercompanies.length }}</div>
                    </div>
                  </template>

                  <span
                    class="d-inline-block"
                    v-bind:class="{
                      'pl-2':
                        currentIntercompanies &&
                        currentIntercompanies.length > 0,
                    }"
                    >{{ intercompanyMenuLabel }}</span
                  >
                </div>
              </v-btn>
            </template>
            <v-list>
              <v-list-item class="pa-0" v-if="items.length > 1">
                <v-list-item-title class="pa-4">
                  <v-checkbox
                    v-model="checkAllIntercompanies"
                    @change="toggleAllCompaniesSelection"
                    v-bind:label="$i18n('common.label.all', 'All')"
                    dense
                    hide-details
                    class="ma-0"
                  ></v-checkbox>
                </v-list-item-title>
              </v-list-item>

              <div class="overflow-auto" style="max-height: 400px">
                <v-list-item
                  v-for="(item, index) in items"
                  class="pa-0"
                  :key="`list-item-${index}`"
                >
                  <v-list-item-title class="pa-4">
                    <v-checkbox
                      v-model="currentIntercompanies"
                      v-on:change="checkIfAllCompaniesAreSelected"
                      v-bind:value="item.value"
                      v-bind:label="item.text"
                      dense
                      hide-details
                      class="ma-0"
                    ></v-checkbox>
                  </v-list-item-title>
                </v-list-item>
              </div>

              <div class="d-flex border-top pt-2 px-2">
                <div class="flex-grow-1 pa-1">
                  <v-btn
                    block
                    small
                    depressed
                    outlined
                    class="secondary-btn"
                    v-on:click="closeIntercompanyMenu"
                  >
                    {{ $i18n("common.label.cancel", "Cancel") }}
                  </v-btn>
                </div>
                <div class="flex-grow-1 pa-1">
                  <v-btn
                    block
                    small
                    depressed
                    color="primary"
                    v-on:click="setIntercompanies"
                    id="apply_set_intercompanies"
                  >
                    {{ $i18n("common.label.apply", "Apply") }}
                  </v-btn>
                </div>
              </div>
            </v-list>
          </v-menu>
        </div>
      </template>
    </template>
  </div>
</template>

<script type="text/babel">
import TokenService from "SC/services/tokenService";
import _findLast from "lodash/findLast";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import { mapGetters } from "vuex";
import AccountsApiService from "SC/services/accountsApi";
import _uniq from "lodash/uniq";
import _filter from "lodash/filter";
import _includes from "lodash/includes";

export default {
  name: "components-intercompany_switcher-index",
  watch: {},
  props: {
    asListItem: undefined
  },
  data() {
    return {
      currentIntercompany: undefined,
      currentIntercompanies: [],
      intercompanies: [],
      isRetrieving: false,
      checkAllIntercompanies: false,
      menuOpen: false
    };
  },
  methods: {
    onMenuOpenChange(value) {
      if (value === false) {
        this.closeIntercompanyMenu();
      }
    },
    closeIntercompanyMenu() {
      this.currentIntercompanies =
        TokenService.getSpecifiedRegistrationIds() || [];
      this.menuOpen = false;
      this.checkIfAllCompaniesAreSelected();
    },
    onClickClear() {
      TokenService.clearSpecifiedRegistrationIds();
      TokenService.clearIntercompanyName();
      window.location.reload();
    },
    setIntercompany() {
      let company = _findLast(this.intercompanies, (item) => {
        return item.id === this.currentIntercompany;
      });

      if (!company) {
        return;
      }

      TokenService.setIntercompanyName(company.name);
      TokenService.setSpecifiedRegistrationId(company.id);
      window.location.reload();
    },
    setIntercompanies() {
      let currentRegistrationIds = TokenService.getSpecifiedRegistrationIds();

      if (!_isEqual(this.currentIntercompanies, currentRegistrationIds)) {
        TokenService.setSpecifiedRegistrationIds(this.currentIntercompanies);
        TokenService.setIntercompaniesCount(this.intercompanies.length);
        window.location.reload();
      }

      this.menuOpen = false;
    },
    toggleAllCompaniesSelection() {
      if (this.currentIntercompanies.length === this.items.length) {
        this.currentIntercompanies = [];
      } else {
        this.currentIntercompanies = this.items.map((item) => {
          return item.value;
        });
      }
    },
    checkIfAllCompaniesAreSelected() {
      this.checkAllIntercompanies =
        this.currentIntercompanies.length === this.items.length;
    },
    retrieve(accountId) {
      this.isRetrieving = true;
      return AccountsApiService.getSubAccounts(accountId)
        .then((result) => {
          let filteredCompanies = _filter(_get(result, "content"), (item) =>
            _includes(["SHIPPER", "AUCTION"], item.type)
          );
          return filteredCompanies.map((company) => {
            return {
              id: _get(company, "id"),
              name: _get(company, "name")
            };
          });
        })
        .catch((e) => {
          this.$log(
            "components-intercompany_switcher-index -> retrieve()",
            e,
            "error"
          );
        })
        .finally(() => {
          this.isRetrieving = false;
        });
    }
  },
  computed: {
    defaultAccountId() {
      let accounts = _get(this.userData, "accounts", []);
      let defaultAccount = accounts.filter((account) => account.default);
      return _get(defaultAccount, "0.id");
    },
    intercompanyMenuLabel() {
      if (this.currentIntercompanies.length === 0) {
        return "Intercompanies";
      } else if (this.currentIntercompanies.length === 1) {
        return _get(
          this.intercompanies.filter((intercompany) => {
            return intercompany.id === this.currentIntercompanies[0];
          }),
          "0.name"
        );
      } else if (
        this.currentIntercompanies.length === this.intercompanies.length
      ) {
        return `All Intercompanies`;
      } else {
        return `${this.currentIntercompanies.length} Intercompanies`;
      }
    },
    items() {
      return this.intercompanies.map((intercompany) => {
        return {
          text: intercompany.name,
          value: intercompany.id
        };
      });
    },
    ...mapGetters("user", [
      "userRoles",
      "userName",
      "userData",
      "hasShipperRole",
      "hasAuctionRole"
    ])
  },
  mounted() {
    if (this.hasShipperRole || this.hasAuctionRole) {
      let accounts = _filter(_get(this.userData, "accounts", []), (item) =>
        _includes(["SHIPPER", "AUCTION"], item.type)
      );
      let mappedAccounts = accounts.map((item) => {
        return {
          id: _get(item, "id"),
          name: _get(item, "name")
        };
      });
      if (this.hasShipperRole) {
        let subAccounts = [];
        let promises = [];
        accounts.forEach((account) => {
          if (account.type === "SHIPPER") {
            let promise = this.retrieve(account.id).then((result) => {
              subAccounts = [...subAccounts, ...result];
            });
            promises.push(promise);
          }
        });
        Promise.all(promises).then(() => {
          this.intercompanies = _uniq([...mappedAccounts, ...subAccounts]);
          this.currentIntercompany = TokenService.getSpecifiedRegistrationId();
          this.currentIntercompanies =
            TokenService.getSpecifiedRegistrationIds() || [];
          this.checkIfAllCompaniesAreSelected();
        });
      } else {
        this.intercompanies = _uniq(accounts);
        this.currentIntercompany = TokenService.getSpecifiedRegistrationId();
        this.currentIntercompanies =
          TokenService.getSpecifiedRegistrationIds() || [];
        this.checkIfAllCompaniesAreSelected();
      }
    }
  },
  mixins: [],
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.intercompany-switcher {
  ::v-deep {
    .v-select__selections {
      height: 30px;
      overflow: hidden;
    }

    .intercompany-count {
      background: #06b0f2;
      width: 15px;
      height: 15px;
      font-size: 9px;
      border-radius: 25px;
      color: white;
      font-weight: bold;
      position: absolute;
      top: -6px;
      left: -14px;
      z-index: 1;
    }
  }
}

.switcher {
  height: 32px !important;
}

.select-all-label {
  font-size: 0.8125rem;
  font-weight: 500;
}

.clear-all {
  position: absolute;
  right: -0.5em;
  top: -0.25em;
  font-size: 1.5em;
}
</style>
