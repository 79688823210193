import axios from 'axios';
import _get from 'lodash/get';
import TokenService from '../services/tokenService';

const CancelToken = axios.CancelToken;

const Client = {
    axiosClient: axios.create(),
    _requestId: 0,
    cancellationSources: {},

    getGroups() {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/tasks/groups`;
        return this.get(path);
    },
    getTasks(data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/tasks/getRows`;
        return this.post(path, data);
    },

    getTasksByView(viewId, data, timezone, resourceType) {
        const rt = (resourceType && resourceType === 'task') ? '&profile=task-all' : '';
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/grid/${viewId}/getRows?count=true${rt}`;
        return this.post(path, data, timezone);
    },

    getRowsPerView(viewId, data, timezone, projection = undefined) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/grid/${viewId}/getRows`;
        if (projection) {
            path = path.concat(`?profile=${projection}`)
        }
        return this.post(path, data, timezone);
    },

    getRowsPerViewType(view, data, projection = undefined) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/grid/${view}/getRows`;
        if (projection) {
            path = path.concat(`?profile=${projection}`)
        }
        return this.post(path, data);
    },

    getDriverLocationsSearchNearOrderId(orderId, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/grid/search/near-order/${orderId}`;
        return this.post(path, data);
    },

    getFilterValuesFromServer(viewId, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/grid/${viewId}/getValues`;
        return this.post(path, data);
    },

    createTransportationTask(orderId, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/tasks/transportation-orders/${orderId}`;
        return this.post(path, data);
    },

    createOrderTask(orderId, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/tasks/orders/${orderId}`;
        return this.post(path, data);
    },

    updateTask(taskId, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/tasks/${taskId}`;
        return this.patch(path, data);
    },

    updatePutTask(taskId, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/tasks/${taskId}`;
        return this.put(path, data);
    },

    updateTasksBulk(data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/tasks/bulk`;
        return this.patch(path, data);
    },

    markTaskAsSeen(taskId, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/tasks/${taskId}/seen`;
        return this.post(path, data);
    },

    getViews(resource = undefined, data = {}) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/views/`;
        if (resource) {
            path = path.concat(`${resource}/resources`)
        }
        return this.post(path, data);
    },

    getViewsCount() {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/views/count`;
        return this.get(path);
    },

    getViewCount(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/views/${id}/count`;
        return this.get(path);
    },

    getViewCountWithParams(id, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/views/${id}/count`;
        return this.post(path, data);
    },

    getSingleView(viewId) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/views/${viewId}/with-children`;
        return this.get(path);
    },

    getViewByUser(viewId) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/views/${viewId}/by-user`;
        return this.get(path);
    },

    updateViewByUser(viewId, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/views/${viewId}/by-user`;
        return this.put(path, data);
    },

    resetViewByUser(viewId) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/views/${viewId}/by-user`;
        return this.delete(path);
    },

    saveViewAsDefault(viewId, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/views/${viewId}/custom`;
        return this.put(path, data);
    },

    createView(data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/views/`;
        return this.post(path, data);
    },

    deleteView(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/views/${id}`;
        return this.delete(path);
    },

    deleteViewByUser(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/views/${id}/by-user`;
        return this.delete(path);
    },

    restoreViewsDefaults() {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/views/apply/restoreDefaults`;
        return this.get(path);
    },

    searchResources(type, query = '') {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/search/resources/${type}`;
        if(query) {
            path += `?${query}`;
        }
        return this.get(path);
    },

    searchSingleViewById(id, params, query = null) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/search/${id}`;
        if (query) {
            path += `?${query}`;
        }
        return this.post(path, params);
    },

    searchGetByTypeById(type, id, projection) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/search/get/${type}?id=${id}&profile=${projection}`;
        return this.get(path);
    },

    search(payload) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/search`;
        return this.post(path, payload);
    },


    searchPaged(view, body, size = 10, page = 0) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/search/${view}?size=${size}&page=${page}`;
        return this.post(path, body);
    },

    searchDashboard(payload, query) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/search/dashboard`;
        if (query) {
            path += `?${query}`;
        }
        return this.post(path, payload);
    },

    getChartById(viewId, params) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/chart/${viewId}`;
        return this.post(path, params);
    },

    getVueChartById(viewId, params) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/chart/vue/${viewId}`;
        return this.post(path, params);
    },

    getCachedChartById(viewId, params) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/chart/${viewId}/template`;
        return this.post(path, params);
    },

    getCachedVueChartById(viewId, params) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/chart/vue/${viewId}/template`;
        return this.post(path, params);
    },

    getChartsDataSources() {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/chart/data-sources`;
        return this.get(path);
    },

    searchDrivers(point = 'pickup', orderId) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/search/drivers/${point}/${orderId}?size=500`;
        return this.get(path);
    },

    searchDriversByTransporterId(transporterId) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/search/drivers/${transporterId}?size=500`;
        return this.get(path);
    },

    setupTenant(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/views/setupTenant/${id}`;
        return this.get(path);
    },

    postSetupTenant(data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/views/setupTenant`;
        return this.post(path, data);
    },

    getTemplateViews() {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/views/task/tmsTemplates`;
        return this.get(path);
    },

    loadAnalyticsClusterData() {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/hz/load`;
        return this.get(path);
    },

    getAccessRules() {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/rules`;
        return this.get(path);
    },

    getAccessRule(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/rules/${id}`;
        return this.get(path);
    },

    createAccessRule(data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/rules`;
        return this.post(path, data);
    },

    updateAccessRule(id, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/rules/${id}`;
        return this.put(path, data);
    },

    deleteAccessRule(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/rules/${id}`;
        return this.delete(path);
    },

    getRevisionsForRuleId (id) {
        return this.get(`${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/rules/${id}/rev`)
    },

    getRevisionForRuleIdAndRevision (id, majorId, minorId) {
        return this.get(`${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/rules/${id}/rev/${majorId}.${minorId}`)
    },

    getAuditForRuleId (id) {
        return this.get(`${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/rules/audit/${id}`)
    },

    getResourceModel(type) {
        return this.get(`${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/ai/model/all/${type}`)
    },

    get(path) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        let headers = {
            'Authorization': `Bearer ${token}`
        }

        if (TokenService.hasSpecifiedRegistrationId() && TokenService.hasIntercompanyName()) {
            headers['X-Intercompany-ID'] = TokenService.getSpecifiedRegistrationId();
            headers['X-Intercompany-Name'] = TokenService.getIntercompanyName();
        }

        if (TokenService.hasSpecifiedRegistrationIds() && TokenService.getIntercompaniesCount() > TokenService.getSpecifiedRegistrationIds().length) {
            headers['X-Intercompany-Read-IDs'] = TokenService.getSpecifiedRegistrationIds().join(',');
        }

        const cancellationSource = CancelToken.source();
        const requestId = this._requestId++;
        this.cancellationSources[requestId] = {
            path: path,
            cancellationSource
        };

        return Client.axiosClient.get(path, {
            headers,
            params,
            cancelToken: cancellationSource.token
        })
            .finally(() => {
                delete this.cancellationSources[requestId];
            });
    },
    post(path, data, timezone = undefined) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        let headers = {
            'Authorization': `Bearer ${token}`
        }
        if (timezone) {
            headers['Request-Timezone'] = timezone;
        }

        if (TokenService.hasSpecifiedRegistrationId() && TokenService.hasIntercompanyName()) {
            headers['X-Intercompany-ID'] = TokenService.getSpecifiedRegistrationId();
            headers['X-Intercompany-Name'] = TokenService.getIntercompanyName();
        }

        if (TokenService.hasSpecifiedRegistrationIds() && TokenService.getIntercompaniesCount() > TokenService.getSpecifiedRegistrationIds().length) {
            headers['X-Intercompany-Read-IDs'] = TokenService.getSpecifiedRegistrationIds().join(',');
        }

        const cancellationSource = CancelToken.source();
        const requestId = this._requestId++;
        this.cancellationSources[requestId] = {
            path: path,
            cancellationSource,
            cancelToken: cancellationSource.token
        };

        return Client.axiosClient.post(path, data, {
            headers,
            params,
            cancelToken: cancellationSource.token
        })
        .finally(() => {
            delete this.cancellationSources[requestId];
        });
    },
    put(path, data) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        let headers = {
            'Authorization': `Bearer ${token}`
        }

        if (TokenService.hasSpecifiedRegistrationId() && TokenService.hasIntercompanyName()) {
            headers['X-Intercompany-ID'] = TokenService.getSpecifiedRegistrationId();
            headers['X-Intercompany-Name'] = TokenService.getIntercompanyName();
        }

        if (TokenService.hasSpecifiedRegistrationIds() && TokenService.getIntercompaniesCount() > TokenService.getSpecifiedRegistrationIds().length) {
            headers['X-Intercompany-Read-IDs'] = TokenService.getSpecifiedRegistrationIds().join(',');
        }

        const cancellationSource = CancelToken.source();
        const requestId = this._requestId++;
        this.cancellationSources[requestId] = {
            path: path,
            cancellationSource,
            cancelToken: cancellationSource.token
        };

        return Client.axiosClient.put(path, data, {
            headers,
            params,
            cancelToken: cancellationSource.token
        })
            .finally(() => {
                delete this.cancellationSources[requestId];
            });
    },
    patch(path, data) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};

        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        let headers = {
            'Authorization': `Bearer ${token}`
        }

        if (TokenService.hasSpecifiedRegistrationId() && TokenService.hasIntercompanyName()) {
            headers['X-Intercompany-ID'] = TokenService.getSpecifiedRegistrationId();
            headers['X-Intercompany-Name'] = TokenService.getIntercompanyName();
        }

        if (TokenService.hasSpecifiedRegistrationIds() && TokenService.getIntercompaniesCount() > TokenService.getSpecifiedRegistrationIds().length) {
            headers['X-Intercompany-Read-IDs'] = TokenService.getSpecifiedRegistrationIds().join(',');
        }

        const cancellationSource = CancelToken.source();
        const requestId = this._requestId++;
        this.cancellationSources[requestId] = {
            path: path,
            cancellationSource,
            cancelToken: cancellationSource.token
        };

        return Client.axiosClient.patch(path, data, {
            headers,
            params,
            cancelToken: cancellationSource.token
        })
            .finally(() => {
                delete this.cancellationSources[requestId];
            });
    },
    delete(path) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};

        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        let headers = {
            'Authorization': `Bearer ${token}`
        }

        if (TokenService.hasSpecifiedRegistrationId() && TokenService.hasIntercompanyName()) {
            headers['X-Intercompany-ID'] = TokenService.getSpecifiedRegistrationId();
            headers['X-Intercompany-Name'] = TokenService.getIntercompanyName();
        }

        if (TokenService.hasSpecifiedRegistrationIds() && TokenService.getIntercompaniesCount() > TokenService.getSpecifiedRegistrationIds().length) {
            headers['X-Intercompany-Read-IDs'] = TokenService.getSpecifiedRegistrationIds().join(',');
        }

        const cancellationSource = CancelToken.source();
        const requestId = this._requestId++;
        this.cancellationSources[requestId] = {
            path: path,
            cancellationSource,
            cancelToken: cancellationSource.token
        };

        return Client.axiosClient.delete(path, {
            headers,
            params,
            cancelToken: cancellationSource.token
        })
            .finally(() => {
                delete this.cancellationSources[requestId];
            });
    },

};

export default Client;
