import RunBuggyTasksAPIClient from '../clients/runBuggyTasksApi'

const Service = {

    async getGroups() {
        return RunBuggyTasksAPIClient.getGroups()
            .then((res) => {
                return res.data;
            })
    },

    async getTasks(params) {
        return RunBuggyTasksAPIClient.getTasks(params)
            .then((res) => {
                return res.data;
            })
    },

    async getTasksByView(id, params, timezone, resourceType) {
        return RunBuggyTasksAPIClient.getTasksByView(id, params, timezone, resourceType)
            .then((res) => {
                return res.data;
            })
    },

    async getRowsPerView(id, params, timezone, projection = undefined) {
        return RunBuggyTasksAPIClient.getRowsPerView(id, params, timezone, projection)
            .then((res) => {
                return res.data;
            })
    },

    async getRowsPerViewType(type, params, projection = undefined) {
        return RunBuggyTasksAPIClient.getRowsPerViewType(type, params, projection)
            .then((res) => {
                return res.data;
            })
    },

    async getDriverLocationsSearchNearOrderId(orderId, params) {
        return RunBuggyTasksAPIClient.getDriverLocationsSearchNearOrderId(orderId, params)
            .then((res) => {
                return res.data;
            })
    },

    async searchGetByTypeById(type, id, projection) {
        return RunBuggyTasksAPIClient.searchGetByTypeById(type, id, projection)
            .then((res) => {
                return res.data;
            })
    },

    async search(payload) {
        return RunBuggyTasksAPIClient.search(payload)
            .then((res) => {
                return res.data;
            })
    },

    async searchPaged(view, body, size, page) {
        return RunBuggyTasksAPIClient.searchPaged(view, body, size, page)
            .then((res) => {
                return res.data;
            })
    },

    async searchDashboard(payload, query) {
        return RunBuggyTasksAPIClient.searchDashboard(payload, query)
            .then((res) => {
                return res.data;
            })
    },

    async getChartById(viewId, params) {
        return RunBuggyTasksAPIClient.getChartById(viewId, params)
            .then((res) => {
                return res.data;
            })
    },

    async getVueChartById(viewId, params) {
        return RunBuggyTasksAPIClient.getVueChartById(viewId, params)
            .then((res) => {
                return res.data;
            })
    },

    async getCachedChartById(viewId, params) {
        return RunBuggyTasksAPIClient.getCachedChartById(viewId, params)
            .then((res) => {
                return res.data;
            })
    },

    async getCachedVueChartById(viewId, params) {
        return RunBuggyTasksAPIClient.getCachedVueChartById(viewId, params)
            .then((res) => {
                return res.data;
            })
    },

    async getChartsDataSources() {
        return RunBuggyTasksAPIClient.getChartsDataSources()
            .then((res) => {
                return res.data;
            })
    },

    async searchDrivers(point = 'pickup', orderId) {
        return RunBuggyTasksAPIClient.searchDrivers(point, orderId)
            .then((res) => {
                return res.data;
            })
    },

    async searchDriversByTransporterId(transporterId) {
        return RunBuggyTasksAPIClient.searchDriversByTransporterId(transporterId)
            .then((res) => {
                return res.data;
            })
    },

    async getFilterValuesFromServer(viewId, data) {
        return RunBuggyTasksAPIClient.getFilterValuesFromServer(viewId, data)
            .then((res) => {
                return res.data;
            })
    },

    async updateTask(taskId, data) {
        return RunBuggyTasksAPIClient.updateTask(taskId, data)
            .then((res) => {
                return res.data;
            })
    },

    async updatePutTask(taskId, data) {
        return RunBuggyTasksAPIClient.updatePutTask(taskId, data)
            .then((res) => {
                return res.data;
            })
    },

    async createTransportationTask(orderId, data) {
        return RunBuggyTasksAPIClient.createTransportationTask(orderId, data)
            .then((res) => {
                return res.data;
            })
    },

    async createOrderTask(orderId, data) {
        return RunBuggyTasksAPIClient.createOrderTask(orderId, data)
            .then((res) => {
                return res.data;
            })
    },

    async updateTasksBulk(data) {
        return RunBuggyTasksAPIClient.updateTasksBulk(data)
            .then((res) => {
                return res.data;
            })
    },

    async markTaskAsSeen(id, data) {
        return RunBuggyTasksAPIClient.markTaskAsSeen(id, data)
            .then((res) => {
                return res.data;
            })
    },

    async getViews(resource = undefined, data = {}) {
        return RunBuggyTasksAPIClient.getViews(resource, data)
            .then((res) => {
                return res.data;
            })
    },

    async getSingleView(id) {
        return RunBuggyTasksAPIClient.getSingleView(id)
            .then((res) => {
                return res.data;
            })
    },

    async searchSingleViewById(id, params, query = null) {
        return RunBuggyTasksAPIClient.searchSingleViewById(id, params, query)
            .then((res) => {
                return res.data;
            })
    },

    async getViewByUser(id) {
        return RunBuggyTasksAPIClient.getViewByUser(id)
            .then((res) => {
                return res.data;
            })
    },

    async updateViewByUser(id, data) {
        return RunBuggyTasksAPIClient.updateViewByUser(id, data)
            .then((res) => {
                return res.data;
            })
    },

    async resetViewByUser(id) {
        return RunBuggyTasksAPIClient.resetViewByUser(id)
            .then((res) => {
                return res.data;
            })
    },

    async getViewsCount() {
        return RunBuggyTasksAPIClient.getViewsCount()
            .then((res) => {
                return res.data;
            })
    },

    async saveViewAsDefault(id, data) {
        return RunBuggyTasksAPIClient.saveViewAsDefault(id, data)
            .then((res) => {
                return res.data;
            })
    },

    async getViewCount(id) {
        return RunBuggyTasksAPIClient.getViewCount(id)
            .then((res) => {
                return res.data;
            })
    },

    async getViewCountWithParams(id, data) {
        return RunBuggyTasksAPIClient.getViewCountWithParams(id, data)
            .then((res) => {
                return res.data;
            })
    },

    async createView(data) {
        return RunBuggyTasksAPIClient.createView(data)
            .then((res) => {
                return res;
            })
    },

    async deleteView(id) {
        return RunBuggyTasksAPIClient.deleteView(id)
            .then((res) => {
                return res;
            })
    },

    async deleteViewByUser(id) {
        return RunBuggyTasksAPIClient.deleteViewByUser(id)
            .then((res) => {
                return res;
            })
    },

    async restoreViewsDefaults() {
        return RunBuggyTasksAPIClient.restoreViewsDefaults()
            .then((res) => {
                return res;
            })
    },

    async searchResources(type, query = '') {
        return RunBuggyTasksAPIClient.searchResources(type, query)
            .then((res) => {
                return res;
            })
    },

    async setupTenant(id) {
        return RunBuggyTasksAPIClient.setupTenant(id)
            .then((res) => {
                return res;
            })
    },

    async postSetupTenant(data) {
        return RunBuggyTasksAPIClient.postSetupTenant(data)
            .then((res) => {
                return res;
            })
    },

    async getTemplateViews() {
        return RunBuggyTasksAPIClient.getTemplateViews()
            .then((res) => {
                return res;
            })
    },

    async loadAnalyticsClusterData() {
        return RunBuggyTasksAPIClient.loadAnalyticsClusterData()
            .then((res) => {
                return res;
            })
    },

    async getAccessRules() {
        return RunBuggyTasksAPIClient.getAccessRules()
            .then((res) => {
                return res;
            })
    },

    async getAccessRule(id) {
        return RunBuggyTasksAPIClient.getAccessRule(id)
            .then((res) => {
                return res;
            })
    },

    async createAccessRule(data) {
        return RunBuggyTasksAPIClient.createAccessRule(data)
            .then((res) => {
                return res;
            })
    },

    async updateAccessRule(id, data) {
        return RunBuggyTasksAPIClient.updateAccessRule(id, data)
            .then((res) => {
                return res;
            })
    },

    async deleteAccessRule(id) {
        return RunBuggyTasksAPIClient.deleteAccessRule(id)
            .then((res) => {
                return res;
            })
    },

    async getRevisionsForRuleId(id) {
        return RunBuggyTasksAPIClient.getRevisionsForRuleId(id)
            .then((res) => {
                return res;
            })
    },

    async getRevisionForRuleIdAndRevision(id, majorId, minorId) {
        return RunBuggyTasksAPIClient.getRevisionForRuleIdAndRevision(id, majorId, minorId)
            .then((res) => {
                return res;
            })
    },

    async getAuditForRuleId(id) {
        return RunBuggyTasksAPIClient.getAuditForRuleId(id)
            .then((res) => {
                return res;
            })
    },



    async getResourceModel(type) {
        return RunBuggyTasksAPIClient.getResourceModel(type)
            .then((res) => {
                return res;
            })
    }

};

export default Service;
