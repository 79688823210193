import * as types from "./mutation-types";

// initial state
const state = {
  activeKeys: [],
};

// getters
const getters = {
  activeKeys(state) {
    return state.activeKeys;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  [types.ADD_ACTIVE_KEY](state, key) {
    if (state.activeKeys.indexOf(key) === -1) {
      state.activeKeys.push(key);
    }
  },
  [types.CLEAR_ACTIVE_KEYS](state) {
    state.activeKeys = [];
  },
  [types.REMOVE_ACTIVE_KEY](state, key) {
    let index = state.activeKeys.indexOf(key);

    if (index > -1) {
      state.activeKeys.splice(index, 1);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
