import AccountsApiClient from '../clients/accountsApi'

const Service = {
  async unlock(email, factoryType = 'EMAIL', passCode) {
    return AccountsApiClient.unlock(email, factoryType, passCode)
        .then(response => response.data);
  },

  async acceptTerms(accountId) {
    return AccountsApiClient.acceptTerms(accountId)
        .then(response => response.data);
  },

  async factors(email, payload) {
    return AccountsApiClient.factors(email, payload)
        .then(response => response.data);
  },

  async mfaVerify(email, factoryType = 'EMAIL', passCode) {
    return AccountsApiClient.mfaVerify(email, factoryType, passCode)
        .then(response => response.data);
  },

  async forgotPassword(email) {
    return AccountsApiClient.forgotPassword(email)
      .then(response => response.data);
  },
  async resetPassword(email) {
    return AccountsApiClient.resetPassword(email)
      .then(response => response.data);
  },

  async createAccount(data) {
    return AccountsApiClient.createAccount(data)
      .then(response => response.data);
  },

  async getAccounts(page, size, sort, params = {}) {
    return AccountsApiClient.getAccounts(page, size, sort, params)
      .then(response => response.data);
  },

  async getAccountsFull(page, size, sort, params = {}) {
    return AccountsApiClient.getAccountsFull(page, size, sort, params)
      .then(response => response.data);
  },

  async getAccountRoles(accountId) {
    return AccountsApiClient.getAccountRoles(accountId)
      .then(response => response.data);
  },

  async getAllAccountRoles(accountId) {
    return AccountsApiClient.getAllAccountRoles(accountId)
      .then(response => response.data);
  },

  async patchAccountRoles(accountId, data) {
    return AccountsApiClient.patchAccountRoles(accountId, data)
      .then(response => response.data);
  },

  async enableSupportByEmail(email, data) {
    return AccountsApiClient.enableSupportByEmail(email, data)
      .then(response => response.data);
},

  async getAccount(accountId) {
    return AccountsApiClient.getAccount(accountId)
      .then(response => response.data);
  },

  async getAccountFull(accountId) {
    return AccountsApiClient.getAccountFull(accountId)
      .then(response => response.data);
  },

  async getAccountConfigStatuses(accountId) {
    return AccountsApiClient.getAccountConfigStatuses(accountId)
      .then(response => response.data);
  },

  async getAccountPayers(accountId) {
    return AccountsApiClient.getAccountPayers(accountId)
      .then(response => response.data);
  },

  async getPasswordStatus(accountId) {
    return AccountsApiClient.getPasswordStatus(accountId)
      .then(response => response.data);
  },

  async getSubAccounts(accountId, page, size, sort, params = {}) {
    return AccountsApiClient.getSubAccounts(accountId, page, size, sort, params)
      .then(response => response.data);
  },

  async getUserSubAccounts(email, page, size, sort, params = {}) {
    return AccountsApiClient.getUserSubAccounts(email, page, size, sort, params)
      .then(response => response.data);
  },

  async createSubAccount(accountId, data) {
    return AccountsApiClient.createSubAccount(accountId, data)
      .then(response => response.data);
  },

  async createSubContractorAccount(accountId, data) {
    return AccountsApiClient.createSubContractorAccount(accountId, data)
      .then(response => response.data);
  },

  async activateAccount(accountId, data) {
    return AccountsApiClient.activateAccount(accountId, data)
      .then(response => response.data);
  },

  async deactivateAccount(accountId, data) {
    return AccountsApiClient.deactivateAccount(accountId, data)
      .then(response => response.data);
  },

  async updateAccount(accountId, data) {
    return AccountsApiClient.updateAccount(accountId, data)
      .then(response => response.data);
  },

  async updateAccountFeatureStatus(accountId, feature, enable) {
    return AccountsApiClient.updateAccountFeatureStatus(accountId, feature, enable)
      .then(response => response.data);
  },

  async setAccountBankAccount(accountId, data) {
    return AccountsApiClient.setAccountBankAccount(accountId, data)
      .then(response => response.data);
  },

  async updateAccountContact(accountId, data) {
    return AccountsApiClient.updateAccountContact(accountId, data)
      .then(response => response.data);
  },

  async setAccountContacts(accountId, data) {
    return AccountsApiClient.setAccountContacts(accountId, data)
      .then(response => response.data);
  },

  async setAccountLocations(accountId, data) {
    return AccountsApiClient.setAccountLocations(accountId, data)
      .then(response => response.data);
  },

  async getAccountRatings(accountId) {
    return AccountsApiClient.getAccountRatings(accountId)
      .then(response => response.data);
  },

  async addAccountRating(accountId, data) {
    return AccountsApiClient.addAccountRating(accountId, data)
      .then(response => response.data);
  },

  async updateAccountRatings(accountId, data) {
    return AccountsApiClient.updateAccountRatings(accountId, data)
      .then(response => response.data);
  },

  async deleteAccountRating(accountId, category) {
    return AccountsApiClient.deleteAccountRating(accountId, category)
      .then(response => response.data);
  },

  async getAccountBadges(accountId) {
    return AccountsApiClient.getAccountBadges(accountId)
      .then(response => response.data);
  },

  async addAccountBadge(accountId, data) {
    return AccountsApiClient.addAccountBadge(accountId, data)
      .then(response => response.data);
  },

  async updateAccountBadges(accountId, data) {
    return AccountsApiClient.updateAccountBadges(accountId, data)
      .then(response => response.data);
  },

  async deleteAccountBadge(accountId, badgeName) {
    return AccountsApiClient.deleteAccountBadge(accountId, badgeName)
      .then(response => response.data);
  },

  async setAccountTrucks(accountId, data) {
    return AccountsApiClient.setAccountTrucks(accountId, data)
      .then(response => response.data);
  },

  async getAccountTrucks(accountId) {
    return AccountsApiClient.getAccountTrucks(accountId)
      .then(response => response.data);
  },

  async updateAccountTruck(accountId, data) {
    return AccountsApiClient.updateAccountTruck(accountId, data)
      .then(response => response.data);
  },

  async removeAccountTruck(accountId, data) {
    return AccountsApiClient.removeAccountTruck(accountId, data)
      .then(response => response.data);
  },

  async getLoggedInUser() {
    return AccountsApiClient.getLoggedInUser()
      .then(response => response.data);
  },

  async getAccountUsers(accountId, page, size, sort, params = {}) {
    return AccountsApiClient.getAccountUsers(accountId, page, size, sort, params)
      .then(response => response.data);
  },

  async createAccountUser(accountId, data) {
    return AccountsApiClient.createAccountUser(accountId, data)
      .then(response => response.data);
  },

  async createAccountNote(accountId, data) {
    return AccountsApiClient.createAccountNote(accountId, data)
      .then(response => response.data);
  },

  async createAccountTaskComment(accountId, taskId, message) {
    return AccountsApiClient.createAccountTaskComment(accountId,taskId, message)
      .then(response => response.data);
  },

  async getUsers(page, size, sort, params = {}) {
    return AccountsApiClient.getUsers(page, size, sort, params)
      .then(response => response.data);
  },

  async getUsersForImpersonation(page, size, sort, params = {}) {
    return AccountsApiClient.getUsersForImpersonation(page, size, sort, params)
      .then(response => response.data);
  },

  async getUsersFull(page, size, sort, params = {}) {
    return AccountsApiClient.getUsersFull(page, size, sort, params)
      .then(response => response.data);
  },

  async getDrivers(page, size, sort, params = {}) {
    return AccountsApiClient.getDrivers(page, size, sort, params)
      .then(response => response.data);
  },

  async getUser(email) {
    return AccountsApiClient.getUser(email)
      .then(response => response.data);
  },

  async getUserFull(email) {
    return AccountsApiClient.getUserFull(email)
      .then(response => response.data);
  },

  async signupUser(data) {
    return AccountsApiClient.signupUser(data)
      .then(response => response.data);
  },

  async updateUser(data) {
    return AccountsApiClient.updateUser(data)
      .then(response => response.data);
  },

  async updateUserPatch(userId, data) {
    return AccountsApiClient.updateUserPatch(userId, data)
      .then(response => response.data);
  },

  async deleteUserRequest() {
   return AccountsApiClient.deleteUserRequest()
     .then(response => response.data);
  },

  async addUserRole(email, role, accountId) {
    return AccountsApiClient.addUserRole(email, role, accountId)
      .then(response => response.data);
  },

  async removeUserRole(email, role, accountId) {
    return AccountsApiClient.removeUserRole(email, role, accountId)
      .then(response => response.data);
  },

  async updateUserRoles(userId, accountId, data) {
    return AccountsApiClient.updateUserRoles(userId, accountId, data)
      .then(response => response.data);
  },

  async setUserLocations(email, data) {
    return AccountsApiClient.setUserLocations(email, data)
      .then(response => response.data);
  },

  async getUserRatings(userId) {
    return AccountsApiClient.getUserRatings(userId)
      .then(response => response.data);
  },

  async addUserRating(userId, data) {
    return AccountsApiClient.addUserRating(userId, data)
      .then(response => response.data);
  },

  async upddateUserRatings(userId, data) {
    return AccountsApiClient.updateUserRatings(userId, data)
      .then(response => response.data);
  },

  async deleteUserRating(userId, category) {
    return AccountsApiClient.deleteUserRating(userId, category)
      .then(response => response.data);
  },

  async getUserBadges(userId) {
    return AccountsApiClient.getUserBadges(userId)
      .then(response => response.data);
  },

  async addUserBadge(userId, data) {
    return AccountsApiClient.addUserBadge(userId, data)
      .then(response => response.data);
  },

  async upddateUserBadges(userId, data) {
    return AccountsApiClient.updateUserBadges(userId, data)
      .then(response => response.data);
  },

  async deleteUserBadge(userId, badgeName) {
    return AccountsApiClient.deleteUserBadge(userId, badgeName)
      .then(response => response.data);
  },

  async linkAccount(email, role, accountId, data={}) {
    return AccountsApiClient.linkAccount(email, role, accountId, data)
      .then(response => response.data);
  },

  async linkUserToAccount(email, role, accountId) {
    return AccountsApiClient.linkUserToAccount(email, role, accountId)
      .then(response => response.data);
  },

  async detachUserFromAccount(email, role, accountId) {
    return AccountsApiClient.detachUserFromAccount(email, role, accountId)
      .then(response => response.data);
  },

  async enableUser(email) {
    return AccountsApiClient.enableUser(email)
      .then(response => response.data);
  },

  async disableUser(email, data) {
    return AccountsApiClient.disableUser(email, data)
      .then(response => response.data);
  },

  async deleteUser(email) {
    return AccountsApiClient.deleteUser(email)
      .then(response => response.data);
  },

  async changePassword(email, data) {
    return AccountsApiClient.changePassword(email, data)
      .then(response => response.data);
  },

  async getPasswordSettings() {
    return AccountsApiClient.getPasswordSettings()
      .then(response => response.data);
  },

  async linkAccounts(account, rel, toAccount, data={}) {
    return AccountsApiClient.linkAccounts(account, rel, toAccount, data)
      .then(response => response.data);
  },

  async detachAccounts(account, rel, toAccount) {
    return AccountsApiClient.detachAccounts(account, rel, toAccount)
      .then(response => response.data);
  },

  async getRelations(page, size, sort, params = {}) {
    return AccountsApiClient.getRelations(page, size, sort, params)
      .then(response => response.data);
  },

  async getRelatedAccounts(rel, page, size, sort, params = {}) {
    return AccountsApiClient.getRelatedAccounts(rel, page, size, sort, params)
      .then(response => response.data);
  },

  async getRelatedUserAccounts() {
    return AccountsApiClient.getRelatedUserAccounts()
      .then(response => response.data);
  },

  async getRelatedFromAccount(accountId, rel, page, size, sort, params = {}) {
    return AccountsApiClient.getRelatedFromAccount(accountId, rel, page, size, sort, params)
      .then(response => response.data);
  },
  async getRelatedToAccount(accountId, rel, page, size, sort, params = {}) {
    return AccountsApiClient.getRelatedToAccount(accountId, rel, page, size, sort, params)
      .then(response => response.data);
  },

  async updateRelationStatus(data) {
    return AccountsApiClient.updateRelationStatus(data)
      .then(response => response.data);
  },

  async recoverBeyondIdentity(externalId, bindingTokenDeliveryMethod) {
    return AccountsApiClient.recoverBeyondIdentity(externalId, bindingTokenDeliveryMethod)
      .then(response => response.data);
  },

  async listBeyondIdentityDevices() {
    return AccountsApiClient.listBeyondIdentityDevices()
      .then(response => response.data);
  },

  async convertByndidIdToken(idToken) {
    return AccountsApiClient.convertByndidIdToken(idToken)
      .then(response => response.data);
  },

  async removeBeyondIdentityDevices(data) {
    return AccountsApiClient.removeBeyondIdentityDevices(data)
      .then(response => response.data);
  },

  async unauthenticate() {
    return AccountsApiClient.unauthenticate()
      .then((res) => {
          return res;
      });
  },

};

export default Service;
