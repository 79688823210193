import axios from 'axios'

const Service = {
    convertFileToString(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = () => {
                let parts = reader.result.split('base64,');
                resolve(parts[1]);
            };
            reader.onerror = error => reject(error);
        });
    },
    getMimeType(path) {
        return axios.get(path)
            .then((res) => {
                return res.headers['content-type'].split(';')[0]
            });
    }
};

export default Service;
