var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{class:{
    collapsed: _vm.displayCollapsed,
    overlap: _vm.displayOverlap,
  },attrs:{"dark":"","color":_vm.navigationColor,"mini-variant":_vm.displayMini,"mini-variant-width":"70","width":"300","app":"","overflow":"","id":"main-navigation"},on:{"input":_vm.transitionend},model:{value:(_vm.isNavigationShowing),callback:function ($$v) {_vm.isNavigationShowing=$$v},expression:"isNavigationShowing"}},[_c('div',{staticClass:"logo",class:{ mini: _vm.displayMini }},[(!_vm.displayMini)?[_c('img',{attrs:{"src":require("../../assets/full-logo.svg")}})]:_vm._e(),_c('v-icon',{attrs:{"color":"navigation_text","dense":""},on:{"click":_vm.menuToggle}},[_vm._v(_vm._s(_vm.isNavigationExpanded ? "mdi-menu-open" : "mdi-menu"))])],2),_c('v-divider',{staticClass:"mt-2"}),_c('v-list',{attrs:{"flat":"","color":"transparent"}},[_vm._l((_vm.items),function(item,index){return [(item.type === _vm.ITEM_TYPE_CAMUNDA_FILTER)?[_c('camunda-filter-item',{key:`camunda_filter_${index}`,attrs:{"params":item.typeParams,"mini":_vm.displayMini,"item":item},on:{"navigated":_vm.onNavigated}})]:(
          item.type === _vm.TASK_MANAGEMENT_FILTER &&
          (_vm.hasAdminRole || _vm.hasAnyTmsRole)
        )?[_c('task-management-menu-item',{key:`navigation_item_${index}`,attrs:{"mini":_vm.displayMini,"item":item},on:{"navigated":_vm.onNavigated}})]:[_c('item',{key:`navigation_item_${index}`,attrs:{"mini":_vm.displayMini,"item":item},on:{"navigated":_vm.onNavigated}})]]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }